import { ExerciseChoicesModel, ExerciseDefinitionModel, ImageCdnModel } from '@shared/models';
import { Button, Form, Input, Radio, Tabs, Upload } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { layoutToContentType, uuid } from '@shared/utils';
import { ImageUpload } from '../../../ImageUpload';
import { ExerciseDefinitionInteractionType, ExerciseLayoutType, MediaType } from '@shared/constants';
import type { TabsProps } from 'antd';
import { ImageView } from '@shared/components/curriculum/ImageView';
import { CdnScope } from '@shared/components/curriculum/FileUpload';

type CreateExerciseProps = {
  item: ExerciseDefinitionModel;
  onSave: (item: ExerciseDefinitionModel) => void;
  onChange: (item: ExerciseDefinitionModel) => void;
  cdnScope: CdnScope;
};
export const CreateVisualSingleSelect = ({ item, onSave, onChange, cdnScope }: CreateExerciseProps) => {
  const [choiceName, setChoiceName] = useState<string>();
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false);

  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [image, setImage] = useState<ImageCdnModel>();

  const [choices, setChoices] = useState<ExerciseChoicesModel[]>([]);
  const [imageChoices, setImageChoices] = useState<ExerciseChoicesModel[]>();

  const [tabs, setTabs] = useState<TabsProps['items']>([
    {
      key: 'text',
      label: 'Metinsel Şıklı',
      disabled: false,
    },
    {
      key: 'image',
      label: 'Görsel Şıklı',
      disabled: false,
    },
  ]);
  const [selectedTab, setSelectedTab] = useState<string>('text');

  useEffect(() => {
    const mediaList = item.interaction?.choices?.filter((c) => c.media);
    if (mediaList && mediaList?.length > 0) {
      setImageChoices(mediaList);
    } else {
      setChoices(item.interaction?.choices ?? []);
    }
    setTitle(item.content?.content?.title);
    setDescription(item.content?.content?.description);
    setImage(item.content?.image?.resourceUrl ? { id: item.content?.image?.resourceId, variants: [item.content?.image?.resourceUrl] } : undefined);
  }, [item.id]);

  useEffect(() => {
    if ((imageChoices && imageChoices?.length > 0) || (choices && choices?.length > 0)) {
      if (imageChoices && imageChoices?.length > 0) {
        setSelectedTab('image');
      }
      if (choices && choices?.length > 0) {
        setSelectedTab('text');
      }
      setTabs(
        tabs?.map((t) => {
          return { ...t, disabled: true };
        })
      );
    } else {
      setSelectedTab('text');
      setTabs(
        tabs?.map((t) => {
          return { ...t, disabled: false };
        })
      );
    }
  }, [imageChoices, choices]);

  useEffect(() => {
    if (title || description || image || choices || imageChoices) {
      const i = {
        ...item,
        layout: ExerciseLayoutType.VISUAL_SINGLE_SELECT,
        content: {
          ...item.content,
          content: {
            ...(item.content ? item.content.content : {}),
            title: title,
            description: description,
          },
          image: image && selectedTab == 'text' ? { resourceId: image.id, resourceUrl: image.variants![0] } : undefined,
          type: layoutToContentType(ExerciseLayoutType.VISUAL_SINGLE_SELECT),
        },
        interaction: {
          ...item.interaction,
          choices: selectedTab == 'text' ? choices : imageChoices,
          type: ExerciseDefinitionInteractionType.SINGLE_CHOICE,
        },
      } as ExerciseDefinitionModel;
      onChange(i);
      console.log(i);
    }
  }, [title, description, image, choices, selectedTab, imageChoices]);

  const send = useCallback(() => {
    const i = {
      ...item,
      layout: ExerciseLayoutType.VISUAL_SINGLE_SELECT,
      content: {
        ...item.content,
        content: {
          ...(item.content ? item.content.content : {}),
          title: title,
          description: description,
        },
        image: image && selectedTab == 'text' ? { resourceId: image.id, resourceUrl: image.variants![0] } : undefined,
        type: layoutToContentType(ExerciseLayoutType.VISUAL_SINGLE_SELECT),
      },
      interaction: {
        ...item.interaction,
        choices: selectedTab == 'text' ? choices : imageChoices,
        type: ExerciseDefinitionInteractionType.SINGLE_CHOICE,
      },
    } as ExerciseDefinitionModel;
    onSave(i);
  }, [title, description, image, choices, selectedTab, imageChoices]);

  const clearAllProperties = () => {
    setTitle(undefined);
    setDescription(undefined);
    setImage(undefined);
    setChoices([]);
    setImageChoices(undefined);
    const i = {
      ...item,
      layout: ExerciseLayoutType.VISUAL_SINGLE_SELECT,
      content: {
        ...item.content,
        content: {
          ...(item.content ? item.content.content : {}),
          title: undefined,
          description: undefined,
        },
        image: undefined,
        type: layoutToContentType(ExerciseLayoutType.VISUAL_SINGLE_SELECT),
      },
      interaction: {
        ...item.interaction,
        choices: undefined,
        type: ExerciseDefinitionInteractionType.SINGLE_CHOICE,
      },
    } as ExerciseDefinitionModel;
    onChange(i);
  };

  return (
    <div className="p-5">
      <div className="flex justify-end">
        <Button type="primary" disabled={isMediaLoading} size="small" onClick={send}>
          {item.id ? 'Güncelle' : 'Ekle'}
        </Button>
      </div>
      <div className="flex flex-row justify-between mt-3">
        <Tabs
          activeKey={selectedTab}
          type="card"
          size="small"
          items={tabs}
          onChange={(key) => {
            setSelectedTab(key);
          }}
        />
        {tabs!.filter((t) => t.disabled).length > 0 && (
          <Button onClick={clearAllProperties} type="dashed" size="small">
            {selectedTab == 'text' ? 'Görsel Şıklı versiyon için sıfırla' : 'Metinsel Şıklı version için sıfırla'}
          </Button>
        )}
      </div>

      {selectedTab == 'text' && (
        <div className="flex flex-col">
          <div className="items-center content-center justify-center">
            <ImageUpload
              image={image ?? {}}
              onChange={(image) => {
                setImage(image);
              }}
              onChangeLoading={setIsMediaLoading}
              cdnScope={cdnScope}
            />
          </div>
          <div>
            <Form layout="vertical" className="grid grid-cols-3 gap-2">
              <Form.Item label={'Title'}>
                <Input
                  value={title}
                  disabled={isMediaLoading}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label={'Subtitle'}>
                <Input
                  value={description}
                  disabled={isMediaLoading}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Form.Item>
            </Form>
          </div>
          <Form layout="vertical">
            <div className="flex flex-row gap-x-2">
              <Form.Item key={'cmkdvkmsdklvmlk'}>
                <Input
                  placeholder="Seçenegin adı"
                  disabled={isMediaLoading}
                  value={choiceName}
                  onChange={(e) => {
                    setChoiceName(e.target.value);
                  }}
                />
              </Form.Item>
              <Button
                disabled={isMediaLoading}
                onClick={() => {
                  if (choiceName) {
                    setChoices([...choices, { id: uuid(), title: choiceName, isCorrect: false }]);
                    setChoiceName('');
                  }
                }}
                icon={<PlusCircleOutlined />}
              />
            </div>
            <div className="grid grid-cols-3 gap-1 ">
              {choices?.map((c) => (
                <Input
                  disabled={isMediaLoading}
                  key={c.id + 'ufsjdnfsdjn'}
                  value={c.title}
                  onChange={(e) => {
                    const index = choices.findIndex((t) => t.id == c.id);
                    if (index != undefined && index > -1) {
                      const _choices = [...(choices ? choices : [])];
                      _choices![index] = {
                        id: c.id,
                        isCorrect: c.isCorrect,
                        title: e.target.value,
                      };
                      setChoices(_choices);
                    }
                  }}
                  suffix={
                    <Button
                      size="small"
                      icon={<DeleteOutlined className="text-red-500" />}
                      onClick={() => {
                        const index = choices?.findIndex((t) => t.id == c.id);
                        if (index != undefined && index > -1) {
                          const _choices = [...(choices ? choices : [])];
                          _choices.splice(index, 1);
                          setChoices(_choices);
                        }
                      }}
                    />
                  }
                />
              ))}
            </div>

            <div>
              <p>Seçeneklerin hangisi doğru</p>
              <div className="grid grid-cols-3 gap-1">
                <Radio.Group
                  disabled={isMediaLoading}
                  value={choices?.find((c) => c.isCorrect)?.id}
                  onChange={(e) => {
                    const _targetId = e.target.id;
                    if (_targetId) {
                      const index = choices?.findIndex((t) => t.id == _targetId);
                      if (index != undefined && index > -1) {
                        const _choices = [...(choices ? choices : [])].map((c) => ({ ...c, isCorrect: false }));
                        const _findObj = {
                          ..._choices[index],
                        };
                        _choices![index] = {
                          id: _targetId,
                          isCorrect: e.target.checked,
                          title: _findObj.title,
                        };

                        setChoices(_choices);
                      }
                    }
                  }}
                  options={choices?.map((c) => ({ id: c.id, label: c.title!, value: c.id! }))}
                />
              </div>
            </div>
          </Form>
        </div>
      )}

      {selectedTab == 'image' && (
        <div className="flex flex-col">
          <span>Maksimum 4 görsel ekleyebilirsiniz</span>
          <div className="items-center content-center justify-center">
            <ImageUpload
              image={{}}
              onChange={(_) => {
                setImageChoices([...(imageChoices ?? []), { id: uuid(), media: { resourceId: _.id, resourceUrl: _.variants![0], type: MediaType.IMAGE }, isCorrect: false }]);
              }}
              onChangeLoading={setIsMediaLoading}
              cdnScope={cdnScope}
              disabled={imageChoices && (imageChoices?.length == 4 || imageChoices?.length > 4)}
            />
          </div>
          <Radio.Group>
            <div className="flex flex-row space-x-2 h-36">
              {imageChoices?.map((i) => (
                <div key={i.id} className="flex flex-col h-full w-36 items-center">
                  <ImageView image={i.media!} width="100%" height="100%" />
                  <div className="flex flex-row pt-1">
                    <Radio
                      value={i.id}
                      id={i.id}
                      checked={i.isCorrect}
                      onChange={(e) => {
                        const _targetId = e.target.id;
                        if (_targetId) {
                          const index = imageChoices?.findIndex((t) => t.id == _targetId);
                          if (index != undefined && index > -1) {
                            const _choices = [...(imageChoices ? imageChoices : [])].map((c) => ({ ...c, isCorrect: false }));
                            const _findObj = {
                              ..._choices[index],
                            };
                            _choices![index] = {
                              id: _targetId,
                              isCorrect: e.target.checked,
                              media: _findObj.media,
                            };
                            setImageChoices(_choices);
                          }
                        }
                      }}
                    >
                      Dogru Şık
                    </Radio>
                    <Button
                      size="small"
                      icon={<DeleteOutlined className="text-red-500" />}
                      onClick={() => {
                        const index = imageChoices?.findIndex((t) => t.id == i.id);
                        if (index != undefined && index > -1) {
                          const _choices = [...(imageChoices ? imageChoices : [])];
                          _choices.splice(index, 1);
                          setImageChoices(_choices);
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Radio.Group>
          <div className="pt-10">
            <Form layout="vertical" className="grid grid-cols-3 gap-2">
              <Form.Item label={'Title'}>
                <Input
                  value={title}
                  disabled={isMediaLoading}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label={'Subtitle'}>
                <Input
                  value={description}
                  disabled={isMediaLoading}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};
