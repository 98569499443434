import { AudioOutlined } from '@ant-design/icons';
import { numberToOrdinalWord, numberToWord, speechRecognitionMap } from '@shared/utils';
import { Button, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import levenshtein from 'js-levenshtein';

type PronunciationVerifierProps = {
  texts: string[];
  onVerify: (result: boolean,rate:number) => void;
  iconFontSize?: string;
};

export const CommonPronunciationVerifier = ({ texts, onVerify, iconFontSize = '3rem' }: PronunciationVerifierProps) => {
  const [commands, setCommands] = useState<any[]>([]);
  const { transcript, finalTranscript, listening, browserSupportsSpeechRecognition, isMicrophoneAvailable } = useSpeechRecognition({ commands });
  const [clickCount, setClickCount] = useState<number>(0);


  useEffect(() => {
    setClickCount(0);
  }, []);

 

  useEffect(() => {
    if (clickCount >= 3) {
      if (listening) {
        SpeechRecognition.stopListening();
        onVerify(false,0);
      } else {
        onVerify(false,0);
      }
    }

  }, [clickCount, listening]);

  useEffect(() => {
    if (transcript) {
      onCheckAnswer(transcript, false);
    }
  }, [transcript]);

  useEffect(() => {
    if (finalTranscript) {
      onCheckAnswer(transcript, true);
    }
  }, [finalTranscript]);

  const onCheckAnswer = useCallback((text: string, isFinal) => {
    let userText = clearLastPunctuation(text);
    let mainText = clearLastPunctuation(texts[0]);


    if (userText.split(" ").length == 1) {
      if (!isNaN(parseInt(userText))) {
        const numberWord = numberToText(userText);
        userText = numberWord;
      }
      const result = speechRecognitionMap(mainText.toLowerCase(), userText.toLowerCase());
      if (result) {
        onVerify(true,90);
        SpeechRecognition.stopListening();
        return;
      }
    }
    const diffCharCount = levenshtein(userText.toLowerCase(), mainText.toLowerCase());
    if (diffCharCount == 0) {
      onVerify(true,100);
      SpeechRecognition.stopListening();
      return;
    }

    const mainTextCharCount = mainText.split("").length;
    const resultConfidence = (1 - (diffCharCount / mainTextCharCount)) * 100;
    if (resultConfidence >= (mainText.length == 2 ? 50 : mainText.length == 3 ? 66 : mainText.length == 4 ? 75 : mainText.length >= 5 ? 80 : 85)) {
      onVerify(true,resultConfidence);
      SpeechRecognition.stopListening();
    } else {
      if (isFinal) {
        onVerify(false,resultConfidence);
        SpeechRecognition.stopListening();
      }
    }
  }, [texts, onVerify]);

  const numberToText = (text: string) => {
    const d = ["st", "nd", "rd", "th"];
    let result = text;
    if (d.find(i => text.includes(i))) {
      const number = parseInt(text);
      const words = numberToOrdinalWord(number);
      result = words;
    } else {
      const number = parseInt(text);
      const words = numberToWord(number);
      result = words;
    }
    return result;
  }

  const clearLastPunctuation = (text: string) => {
    const lastChar = text[text.length - 1];
    if (lastChar == "." || lastChar == "!" || lastChar == "?") {
      return text.substring(0, text.length - 1);
    }
    return text;
  }



  useEffect(() => {
    if (texts.length > 0) {
      setCommands([
        {
          command: texts[0],
          callback: () => {
          },
          isFuzzyMatch: true,
          fuzzyMatchingThreshold: 0.8,
          //bestMatchOnly: true,
          matchInterim: true,
        },
      ]);
    }
  }, [texts]);

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      alert('Browser does not support speech recognition.');
    }
    if (!isMicrophoneAvailable) {
      console.log('Microphone is not available.');
    }
  }, [isMicrophoneAvailable, browserSupportsSpeechRecognition]);


  const onClick = useCallback(() => {
    if(clickCount <3){
      if (listening) {
        SpeechRecognition.stopListening();
      } else {
        SpeechRecognition.startListening({
          continuous: false,
          language: 'en-US',
        });
        setClickCount(clickCount + 1);
      }
    }else{
      setClickCount(clickCount + 1);
    }
    
  }, [listening, clickCount]);

  return (
    <Tooltip
      title={
        !browserSupportsSpeechRecognition || !isMicrophoneAvailable ? !browserSupportsSpeechRecognition ?  'Browser does not support speech recognition.':!isMicrophoneAvailable ? 'Please allow access to microphone':'' : ''
      }
    >
    <Button
      type="text"
      disabled={!browserSupportsSpeechRecognition || !isMicrophoneAvailable}
      className="!w-max !h-max"
      size="large"
      onClick={onClick}
      icon={<AudioOutlined style={{ fontSize: iconFontSize, color: listening ? 'blueviolet' : '' }} />}
    />
    </Tooltip>
  );
};