import { PrePlacementDemandHistoryProps } from './pre_placement';

export type GanntCalendarType = {
  accountId: string;
  name: string;
  dates: GanntCalendarItemType[];
  enrollmentName: string;
};

export type GanntCalendarItemType = {
  text: string;
  status: string;
  isSuccess: boolean;
  startDate: Date;
  endDate: Date;
  dayStart?: number;
  daySpan?: number;
  rowIndex?: number;
  prePlacementId: string;
  demandId: string;
  levelName: string;
  history: PrePlacementDemandHistoryProps[];
};
