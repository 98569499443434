import { useFetch } from '@shared/hooks';
import { UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<any[]>('/class-planning/all', { method: 'GET', ...config }),

  create: async () => request(`/class-planning`, 'POST'),
  delete: async (classPlanningId: string) => request(`/class-planning/${classPlanningId}/`, 'DELETE'),

  addStudent: async (classPlanningId: string, prePlacementId: string, demandId: string) =>
    request(`/class-planning/${classPlanningId}/add-student/${prePlacementId}/demand/${demandId}`, 'PUT'),

  addSeance: async (classPlanningId: string, seanceId: string) => request(`/class-planning/${classPlanningId}/add-seance/${seanceId}`, 'PUT'),

  setClassRoom: async (classPlanningId: string, classPlanningSeanceId: string, classPlanningSeanceItemId: string, classRoomId: string) =>
    request(
      `/class-planning/${classPlanningId}/class-planning-seance/${classPlanningSeanceId}/class-planning-seance-item/${classPlanningSeanceItemId}/set-class-room/${classRoomId}`,
      'PUT'
    ),

  setTeacher: async (classPlanningId: string, classPlanningSeanceId: string, classPlanningSeanceItemId: string, teacherId: string) =>
    request(
      `/class-planning/${classPlanningId}/class-planning-seance/${classPlanningSeanceId}/class-planning-seance-item/${classPlanningSeanceItemId}/set-teacher/${teacherId}`,
      'PUT'
    ),

  setDayOfWeeks: async (classPlanningId: string, classPlanningSeanceId: string, days: number[]) =>
    request(`/class-planning/${classPlanningId}/class-planning-seance/${classPlanningSeanceId}/set-dayofweeks`, 'PUT', days),

  setStartDate: async (classPlanningId: string, startDate: string) => request(`/class-planning/${classPlanningId}/set-start-date`, 'PUT', { startDate }),

  removeStudent: async (classPlanningId: string, prePlacementId: string, demandId: string) =>
    request(`/class-planning/${classPlanningId}/remove-student/${prePlacementId}/demand/${demandId}`, 'DELETE'),

  removeSeance: async (classPlanningId: string, classPlanningSeanceId: string) => request(`/class-planning/${classPlanningId}/remove-seance/${classPlanningSeanceId}`, 'DELETE'),

  removeClassRoom: async (classPlanningId: string, classPlanningSeanceId: string, classPlanningSeanceItemId: string) =>
    request(
      `/class-planning/${classPlanningId}/class-planning-seance/${classPlanningSeanceId}/class-planning-seance-item/${classPlanningSeanceItemId}/remove-class-room`,
      'DELETE'
    ),

  removeTeacher: async (classPlanningId: string, classPlanningSeanceId: string, classPlanningSeanceItemId: string) =>
    request(`/class-planning/${classPlanningId}/class-planning-seance/${classPlanningSeanceId}/class-planning-seance-item/${classPlanningSeanceItemId}/remove-teacher`, 'DELETE'),

  setStatusAsReview: async (classPlanningId: string) => request(`/class-planning/${classPlanningId}/set-status-as-review`, 'PUT'),

  comment: async (classPlanningId: string, comment: string, status: string, className: string) =>
    request(`/class-planning/${classPlanningId}/comment`, 'POST', { comment, status, className }),

  retriveCreditInfo1: (classPlanningId: string, config: UseFetchOptions) =>
    useFetch<{ consumed: number }>(`/class-planning/${classPlanningId}/credit-info`, { method: 'GET', ...config }),
  retriveCreditInfo: (classPlanningId: string) => request(`/class-planning/${classPlanningId}/credit-info`, 'GET'),
};
