import { DatePicker } from 'antd';
import dayjs from 'dayjs';

type BirthdayCalendarProps = {
  form: any;
  disabled?: boolean;
  defaultValue?: dayjs.Dayjs;
};
export const BirthdayCalendar = ({ form, disabled = false, defaultValue = dayjs('2000-01-01', 'YYYY-MM-DD') }: BirthdayCalendarProps) => {
  return (
    <DatePicker
      disabled={disabled}
      
      {...form.inputDate('birthDate')}
      defaultPickerValue={defaultValue}
      style={{ width: '100%' }}
      maxDate={dayjs()}
      placeholder="Doğum tarihi seçiniz"
    />
  );
};
