import { BrandAndBranchUserCard, BrandAndBranchUserForm } from '@shared/components';
import { BrandOrBranch } from '@shared/constants';
import { BranchUserModel, CreateBranchUser, CreateBrandUser, PaginateModel } from '@shared/models';
import { Pagination } from 'antd';
import { useState } from 'react';

type CreateBranchUsersProps = {
  branchUsers?: PaginateModel<BranchUserModel>;
  enableProductTypes: string[];
  addBranchUser: (branchUser: CreateBranchUser) => void;
  onDelete: (id: string) => void;
  onPaginationChange: (page: number) => void;
  isBranchSearch?: boolean;
};

export const CreateBranchUsers = ({ branchUsers = {}, enableProductTypes = [], addBranchUser, onDelete, onPaginationChange, isBranchSearch = true }: CreateBranchUsersProps) => {
  const [selectedUser, setSelectedUser] = useState<CreateBranchUser | CreateBrandUser>({});

  return (
    <div className="grid grid-cols-3 items-start gap-8">
      <BrandAndBranchUserForm enableProductTypes={enableProductTypes} brandOrBranch={BrandOrBranch.BRANCH} onSave={addBranchUser} user={selectedUser} isBranchSearch={false} />

      <div className="col-span-2">
        <label className="text-black/45 font-normal">Eklenen Kullanıcılar</label>

        {branchUsers.data?.length == 0 ? (
          <div className="border pt-9 border-dashed border-mid-gray rounded p-5 text-center">Lütfen marka yetkilisi ekleyiniz</div>
        ) : (
          <div className="border border-dashed border-mid-gray rounded p-5 grid grid-cols-2 gap-x-4 gap-y-8">
            {branchUsers.data?.map((user) => (
              <BrandAndBranchUserCard
                key={(Math.random() + 1).toString(36).substring(7)}
                user={user}
                onDelete={({ id }) => {
                  onDelete(id!);
                }}
                onEdit={(user) => {
                  setSelectedUser({ ...user } as CreateBranchUser | CreateBrandUser);
                }}
              />
            ))}
            <Pagination className="col-span-2 text-center" pageSize={branchUsers?.limit} total={branchUsers?.count} onChange={(page) => onPaginationChange(page)} />
          </div>
        )}
      </div>
    </div>
  );
};
