import { CopyOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { useForm } from '@shared/hooks';
import i18n from '@shared/localization/i18n';
import { AccountIssueCategory, AccountIssueProps, AccountIssueType, CreateAccountIssueProps } from '@shared/models/account_issue';
import { Button, Drawer, Form, notification, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';
import { z } from 'zod';
import { TextInput } from '../form_inputs';

type QuickReportIssue = {
  open: boolean;
  closeDrawer: () => void;
  onReflesh: () => void;
  isUpdateMode?: boolean;
  selectedData?: AccountIssueProps | undefined;
};

export const QuickReportIssue = ({ open, closeDrawer, onReflesh, isUpdateMode, selectedData }: QuickReportIssue) => {
  const QuickReportIssueForm = z.object({
    title: z.string({ required_error: 'Kategori seçimi zorunludur.' }).min(1, 'Kur adı boş olamaz'),
    description: z.string({ required_error: 'Tip seçimi zorunludur.' }).min(1, 'Kur adı boş olamaz'),
    category: z.string({ required_error: 'Başlık alanı boş bırakılamaz.' }).min(1, 'Kur adı boş olamaz'),
    type: z.string({ required_error: 'Açıklama alanı boş bırakılamaz.' }).min(1, 'Kur adı boş olamaz'),
  });
  const form = useForm<Partial<AccountIssueProps>, CreateAccountIssueProps>(QuickReportIssueForm, {});

  useEffect(() => {
    if (isUpdateMode && open && selectedData) {
      form.setValue(selectedData);
    } else {
      form.setValue({});
    }
  }, [open]);

  const accountIssueCategory = Object.values(AccountIssueCategory).map((category) => ({
    label: <span>{i18n.t('app.accountIssueCategory.' + category)}</span>,
    value: category,
  }));
  const accountIssueType = Object.values(AccountIssueType).map((type) => ({
    label: <span>{i18n.t('app.accountIssueType.' + type)}</span>,
    value: type,
  }));

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      notification.success({ message: 'Talep numarası kopyalandı!' });
    });
  };

  const createOrUpdateIssue = async () => {
    const formResult = form.parse();

    if (formResult.success) {
      if (!isUpdateMode) {
        const response = await API.ACCOUNT_ISSUE.createIssue({
          ...formResult.data,
          referrerUrl: window.location.href,
        });

        if (response.ok) {
          const issue = response.data as AccountIssueProps;

          notification.success({
            message: (
              <span>
                Talebiniz başarıyla oluşturuldu.
                <div>
                  Talep no: {issue.no} <CopyOutlined onClick={() => handleCopy(issue.no)} style={{ cursor: 'pointer', color: '#1890ff' }} />
                </div>
              </span>
            ),
          });
        }
      } else {
        const response = await API.ACCOUNT_ISSUE.updateIssue(selectedData?.id!, {
          ...formResult.data,
          referrerUrl: window.location.href,
        });
        if (response.ok) {
          notification.success({ message: ' Talebiniz başarıyla güncellendi.' });
        }
      }
      closeDrawer();

      onReflesh();
    } else {
      form.setAllTouched();
    }
  };

  return (
    <>
      <Drawer open={open} onClose={() => closeDrawer()} size="large" title="Sorun Bildir/Destek Talebi Oluştur">
        <Form layout="vertical">
          <div className="grid">
            <div className="flex flex-col gap-0">
              <Form.Item {...form.formItem('category')} label={'Kategori'}>
                <Select showSearch {...form.inputSelected('category')} placeholder=" Seçiniz" className="w-full" value={form.value['category']} options={accountIssueCategory} />
              </Form.Item>
            </div>
            <div className="flex flex-col gap-0">
              <Form.Item {...form.formItem('type')} label={'Tip'}>
                <Select showSearch {...form.inputSelected('type')} placeholder="Seçiniz" className="w-full" value={form.value['type']} options={accountIssueType} />
              </Form.Item>
            </div>
            <TextInput formItem={form.formItem('title')} formInput={form.input('title')} label={'Başlık'} maxLength={500} />

            <Form.Item {...form.formItem('description')} label={'Detay:'}>
              <TextArea showCount autoSize={{ minRows: 5, maxRows: 10 }} {...form.input('description')} />
            </Form.Item>

            <div>
              <Button className="w-1/3" onClick={createOrUpdateIssue} type="primary">
                {isUpdateMode ? 'Destek Talebini Güncelle' : 'Destek Talebi Oluştur'}
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>
    </>
  );
};
