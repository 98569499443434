import { List, Typography } from 'antd';

export const EnrollmentWarnings = () => {
  return (
    <List
      header={null}
      footer={null}
      dataSource={[
        'Kayıt oluştururken e-posta adreslerinin doğru girildiğinden emin olunuz.',
        <Typography.Text>
          Kaydı tamamladıktan sonra, e-posta adresini yanlış girdiğinizi fark ettiğiniz durumlarda “Sorun Bildir” paneli üzerinden, ilgili kayıt için kayıt numarası ile birlikte
          e-posta adresi değişikliği talebi oluşturunuz.
          <div className=" underline font-bold">Aynı kaydı farklı bir e-posta adresi ile yeniden oluşturmanıza gerek yoktur.</div>
        </Typography.Text>,
        'Kayıt için gereken bilgileri girdikten sonra “Kaydı Planlamaya Gönder” işlemini yapmadığınız takdirde kayıt “Bilgi” statüsünde kalacak ve kaydın öğrencisi Talep Takvimi’nde ve Planlama Stüdyosu’nda görünmeyecektir.',
        'Kurumsal ve grup kayıtlarında gözetmen eklenmesi gereklidir. Gözetmen eklenmedikçe kayıt tamamlanamayacaktır.',
        '“Bilgi” statüsünde olmayan kayıtlarda kur güncellemesi ya da ekleme/çıkarma işlemleri yapılamaz. Yeni bir kur talep edildiğinde, aynı e-posta adresini kullanarak yalnızca talep edilen kurları seçip farklı bir kayıt oluşturulmalıdır.',
      ]}
      renderItem={(item) => <List.Item style={{ listStyleType: 'disc', display: 'list-item' }}>{item}</List.Item>}
    />
  );
};
