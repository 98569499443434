import { MinusCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { BranchUserModel, Enrollment, Observer, PrePlacement, TeacherModel } from '@shared/models';
import { Button, Form, Input } from 'antd';
import { useCallback, useEffect, useState } from 'react';

type EmailSearchProps = {
  form: any;
  onSearchResult: (data?: BranchUserModel | TeacherModel | PrePlacement | Observer | Enrollment) => void;
  onClear: () => void;
  email?: string;
  label?: string | JSX.Element;
  disabled?: boolean;
  isSearchOtherUser?: boolean;
  isBranchService?: boolean;
};

export const EmailSearch = ({ form, onSearchResult, onClear, email, isSearchOtherUser = true, disabled, label = 'E-Posta', isBranchService = true }: EmailSearchProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<BranchUserModel | TeacherModel | PrePlacement | Observer | Enrollment>();

  useEffect(() => {
    if (email) {
      form.setInitialValue({ email });
    }
  }, [email]);

  const fetchByEmail = useCallback(
    async (text) => {
      setIsLoading(true);
      const result = form.validateField('email');

      if (result.valid) {
        let response;
        if (isBranchService) {
          response = await API.BRANCH.getUserByEmail(text);
        } else {
          response = await API.BRAND.getUserByEmail(text);
        }
        if (response.ok) {
          onSearchResult(response.data);
          setData(response.data);
        } else if (response.status == 404) {
          onSearchResult(undefined);
        }
      }
      setIsLoading(false);
    },
    [form, onSearchResult, isLoading, data]
  );

  const clearForm = () => {
    onClear();
    setData(undefined);
  };

  return (
    <Form.Item {...form.formItem('email')} labelCol={null} wrapperCol={null}>
      <div className="flex justify-between items-center mb-2 pr-1">
       {
        typeof label == 'string' ? <span >{label}</span> : label
       }
        
        {disabled && isSearchOtherUser && (
          <Button
            onClick={() => {
              clearForm();
            }}
            type="text"
            size="small"
            icon={<MinusCircleOutlined />}
          >
            Başka kullanıcıyı ara
          </Button>
        )}
      </div>
      <Input.Search disabled={disabled} {...form.input('email')} onSearch={(value) => fetchByEmail(value)} loading={isLoading} />
    </Form.Item>
  );
};
