import { CheckCircleOutlined, FormOutlined } from '@ant-design/icons';
import { Product } from '@shared/models';
import { Avatar, Card, Form, Input, InputNumber, Select, Tooltip, Switch, notification, Popconfirm } from 'antd';
import { useState } from 'react';
const { Option } = Select;
import * as API from '@shared/api';
import { productColors } from '@shared/constants';

type ProductCardProps = {
  productProp: Product;
  onRefresh: () => void;
};

const input = <T extends object>(obj: T, key: keyof T, setter: (obj: T) => void) => {
  return {
    value: obj[key] as string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const _obj = { ...obj, [key]: e.target?.value };
      setter(_obj);
    },
  };
};

export const ProductCard = ({ productProp, onRefresh }: ProductCardProps) => {
  const [isEditMod, setEditMod] = useState(false);
  const [product, setProduct] = useState(productProp);

  const priceType = (
    <Select defaultValue="USD" style={{ width: 60 }} disabled={!isEditMod}>
      <Option value="USD">$</Option>
      <Option value="EUR">€</Option>
      <Option value="GBP">£</Option>
      <Option value="CNY">¥</Option>
    </Select>
  );

  return (
    <Card
      title={
        <Card.Meta
          className="flex items-center meta-avatar-padding"
          avatar={<Avatar size={40} shape="square" className={'border mr-4 ' + productColors[product.type?.toString() ?? 'CRM'].join(' ')} />}
          title={
            <Tooltip title={product.name}>
              <span>{product.name}</span>
            </Tooltip>
          }
        />
      }
      extra={
        <div className="flex items-center gap-2 flex-row">
          <Tooltip title="Active/Passive">
            <Switch
              size={'small'}
              defaultValue={product.active}
              disabled={!isEditMod}
              onChange={(e) => {
                const obj = { ...product, active: e.valueOf() };
                setProduct(obj);
              }}
            />
          </Tooltip>
          {isEditMod ? (
            <Popconfirm
              title="Ürünü yayınlamak istediğinizden emin misiniz ?"
              description="Tüm sisteme bu değişiklik yansıtılacak"
              onConfirm={async () => {
                await API.PRODUCT.update(product);
                notification.success({ message: 'Ürün yayınlandı' });
                onRefresh();
              }}
              onCancel={() => {}}
              okText="Evet"
              cancelText="İptal"
            >
              <Tooltip title="Yayınla">
                <CheckCircleOutlined style={{ fontSize: 16 }} />
              </Tooltip>
            </Popconfirm>
          ) : (
            <Tooltip title="Edit">
              <FormOutlined onClick={() => setEditMod(!isEditMod)} style={{ fontSize: 16 }} />
            </Tooltip>
          )}
        </div>
      }
    >
      <div className="grid grid-cols-2 gap-4  items-baseline ">
        <label>Baz tutar:</label>
        <Form.Item className="help-right">
          <InputNumber
            disabled={!isEditMod}
            min={1}
            value={product.baseProperties?.perBranch}
            onChange={(v) => {
              const baseProperties = { perBranch: v! };
              const _obj = { ...product, baseProperties };
              setProduct(_obj);
            }}
            style={{ width: '100%' }}
            addonAfter={priceType}
          />
        </Form.Item>
        <label>Ödeme tipi:</label>
        <Form.Item className="help-right">
          <Select
            defaultValue="kullanıcı"
            disabled={!isEditMod}
            onChange={(v) => {
              //TODO bunu konuşmalıyız
            }}
            options={[
              { value: 'kullanıcı', label: 'Kullanıcı başına' },
              { value: 'sube', label: 'Şube başına' },
            ]}
          />
        </Form.Item>

        {isEditMod ? <Input className="col-span-2" {...input(product, 'description', setProduct)} /> : <span className="col-span-2 text-dark-gray">{product.description}</span>}
      </div>
    </Card>
  );
};
