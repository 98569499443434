import { Gender } from '@shared/constants';
import { PhoneModel } from './information';

export type Account = Partial<{
  id: string;
  name: string;
  surname: string;
  nationalId: string;
  email: string;
  phone: PhoneModel;
  active: boolean;
  birthDate: Date;
  gender: Gender;
}>;

export type AccountUpdatePassword = Account & {
  password: string;
};

export type AccountWeakRefProps = {
  id: string;
  name: string;
  email: string;
};

export type DeviceProps = Partial<{
  deviceId: string;
  appVersion: string;
  fcmToken: string;
  manufacturer: string;
  model: string;
  sdkVersion: string;
  isWindows: boolean;
  isAndroid: boolean;
  isIos: boolean;
  isPhone: boolean;
  isTablet: boolean;
  isWeb: boolean;
  os: string;
  ip: string;
}>;
