import { useState, useEffect } from 'react';
import * as API from '@shared/api';
import { LayoutEnums } from '@shared/constants';
import { PrePlacementDemandBasicInfo, PrePlacementDemandDetail } from '@shared/models';

export const useStudentProfile = (layout: LayoutEnums, activeKey: string | undefined, demandList: PrePlacementDemandBasicInfo[] = []) => {
  const [demandDetails, setDemandDetails] = useState<PrePlacementDemandDetail | null>(null);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchDetails = async () => {
      const currentActiveKey = activeKey || (demandList && demandList.length > 0 ? demandList[0].id : undefined);

      if (currentActiveKey) {
        setRequestLoading(true);
        let response: any;
        switch (layout) {
          case LayoutEnums.STUDENT:
            response = await API.STUDENT.getStudentDemandDetail(currentActiveKey);
            break;
          case LayoutEnums.OBSERVER:
            response = await API.OBSERVER.getObserverStudentDemandDetail(currentActiveKey);
            break;
          case LayoutEnums.BRANCHMANAGER:
            response = await API.ENROLLMENT.getBranchStudentDemandDetail(currentActiveKey);
            break;
          default:
            break;
        }
        setRequestLoading(false);

        if (response.ok) {
          setDemandDetails(response.data);
        }
      }
    };

    fetchDetails();
  }, [activeKey, demandList, layout]); // demandList ve layout da dependency array'e eklendi

  return {
    demandList,
    demandDetails,
    layout,
    requestLoading,
    phone: demandDetails ? demandDetails.demand.prePlacement.phone.code! + demandDetails.demand.prePlacement.phone.number : '',
  };
};
