import { CalendarModel } from '@shared/components';
import { ClassActivityStatus, ClassCalendarEntryStatus, ClassCalendarEntryType, ClassStatus, NoteType, StudentEvaluateStatus } from '@shared/constants';
import { ClassSeance } from './class_seance';
import { ClassSeanceItem } from './class_seance_item';
import { EducationLevel } from './education_level';
import { StudentModel } from './student';
import { TeacherModel } from './teacher';

export type ClassModel = Partial<{
  id: string;
  status: ClassStatus;
  level: any;
  startedAt: Date;
  completedAt: Date;
  calculatedStartDate: Date;
  calculatedEndDate: Date;
  classSeances: ClassSeance[];
  studentCount: number;
  name: string;
  contentVersionId: string;
}>;

export type ClassDetailModel = Partial<{
  id: string;
  status: ClassStatus;
  level: any;
  startedAt: Date;
  completedAt: Date;
  calculatedStartDate: Date;
  calculatedEndDate: Date;
  classSeances: ClassSeance[];
  students: StudentModel[];
  name: string;
  notes: ClassNote[];
  contentVersionId: string;
  studentEvaluateStatus: StudentEvaluateStatus;
}>;

export type ClassDetailAttendances = Partial<{
  name: string;
  weekDays: WeekDays[][];
}>;

export type WeekDays = Partial<{
  title: string;
  at: string;
  attended: boolean;
}>;

export type ClassNote = Partial<{
  id: string;
  account: { id: string; name: string };
  note: string;
  type: NoteType;
  createdAt: Date;
  updated?: boolean;
  updatedAt: Date;
}>;

export type ClassCalendarEntryModel = Partial<{
  id: string;
  status: ClassStatus;
  name: string;
  description: string;
  //TODO the type will be enum
  type: string;
  class: ClassModel;
}>;

export type ClassActivity = Partial<{
  id: string;
  brandId: string;
  branchId: string;
  classCalendarEntry: ClassCalendarEntryModel;
  classSeanceItem: ClassSeanceItem;
  durationInMinutes: number;
  status: ClassActivityStatus;
  timeZone: string;
  completedAt: Date;
  createdAt: Date;
  startedAt: Date;
}>;

export type ClassTeacherStudentEvaluation = Partial<{
  createdAt: string;
  id: string;
  items: StudentItem[];
  teacher: TeacherModel;
  updatedAt: string;
}>;
export type StudentItem = Partial<{
  id: string;
  student: StudentModel;
  description: string;
  score: number;
}>;

export type CreateClassTeacherStudentEvaluation = Partial<{
  items: CreateStudentItem[];
}>;

export type CreateStudentItem = Partial<{
  studentId: string;
  description: string;
  score: number;
}>;

export type InClassInfo = Partial<{
  classId: string;
  className: string;
  classSeanceItem: ClassSeanceItem;
}>;

export type ClassCalendarModel = CalendarModel &
  Partial<{
    isHoliday: boolean;
    type: ClassCalendarEntryType;
    status: ClassCalendarEntryStatus;
  }>;

export type ContentVersion = Partial<{
  id: string;
  name: string;
  description: string;
  level: EducationLevel;
  version: number;
  brandVersion: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  publishAt: string;
  archivedAt: string;
  fromId: string;
  reviewedAt: string;
  reviewStatus: string;
  isDefault: boolean;
}>;

export type StudentsSuccessSummary = Partial<{
  items: StudentsSuccessSummaryItems[];
}>;

export type StudentsSuccessSummaryItems = Partial<{
  status: string;
  student: StudentModel;
  attendanceRate: number;
  avgScore: number;
  teacherEvaluationsAvg: string;
  teacherEvaluations: TeacherEvaluations[];
}>;

export type TeacherEvaluations = Partial<{
  score: string;
  description: string;
  teacher: TeacherModel;
}>;
