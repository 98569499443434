import { BranchCalendarEntryType, BrandCalendarEntryType, CalendarEntryType, CalendarPeriod, CalendarRange, ProductsEnum } from '@shared/constants';

export type AdminCalendarModel = {
  id: string;
  name: string;
  description: string;
  countryCode: string;
  date: Date;
  day: number;
  month: number;
  year: number;
  rangeType: CalendarRange;
  beginHour: number;
  beginMinute: number;
  endHour: number;
  endMinute: number;
  durationInMinutes: number;
  period: CalendarPeriod;
  entryType: CalendarEntryType;
  isHoliday: boolean;
};

export type BrandCalendarModel = {
  id: string;
  brandId: string;
  product: ProductsEnum;
  name: string;
  description: string;
  countryCode: string;
  date: Date;
  day: number;
  month: number;
  year: number;
  rangeType: CalendarRange;
  beginHour: number;
  beginMinute: number;
  endHour: number;
  endMinute: number;
  durationInMinutes: number;
  period: CalendarPeriod;
  entryType: BrandCalendarEntryType;
  isHoliday: boolean;
  isSystemEntry: boolean;
  isExcluded: boolean;
};

export type BranchCalendarModel = {
  id: string;
  branchId: string;
  product: ProductsEnum;
  name: string;
  description: string;
  countryCode: string;
  date: Date;
  day: number;
  month: number;
  year: number;
  rangeType: CalendarRange;
  beginHour: number;
  beginMinute: number;
  endHour: number;
  endMinute: number;
  durationInMinutes: number;
  period: CalendarPeriod;
  entryType: BranchCalendarEntryType;
  isHoliday: boolean;
  isSystemEntry: boolean;
  isBrandEntry: boolean;
  isExcluded: boolean;
};
