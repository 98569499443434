import { Card } from 'antd';
import { ReactElement } from 'react';

export type KanbanCardProps = {
  title: string | ReactElement;
  content?: string | ReactElement;
  action?: ReactElement;
  footer?: ReactElement;
  onClick?: () => void;
};
//TODO mt-10 yerien footer en alta olmalı
export const KanbanCard = (props: KanbanCardProps) => {
  return (
    <Card onClick={props.onClick} className={' p-2 ' + (props.onClick ? 'cursor-pointer' : '')} style={{ backgroundColor: '#FBFBFB' }} bodyStyle={{ padding: '0' }}>
      <div className="text-sm  flex flex-col space-y-3">
        <div className="flex flex-row justify-between font-bold">
          {props.title}
          <span>{props.action}</span>
        </div>
        {props.content}
        <div className="inset-x-2 bottom-0  mb-2">{props.footer}</div>
      </div>
    </Card>
  );
};
