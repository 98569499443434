import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { PrePlacementDemandStatus } from '@shared/constants';
import {
  CascaderOption,
  ClassRoomModel,
  EducationField,
  EducationLevel,
  EducationTheme,
  EducationType,
  PaginateModel,
  SessionDefinitionsModel,
  TeacherModel,
} from '@shared/models';
import { convertDate, createLevelCascader, localizeDemandStatus } from '@shared/utils/extensions';
import { Badge, Button, Cascader, Space } from 'antd';
import Search, { SearchProps } from 'antd/es/input/Search';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { TbFilterX } from 'react-icons/tb';

dayjs.locale('tr');

const initialFilters = {
  name: '',
  email: '',
  levels: [],
  statuses: [],
  successStatuses: [],
  classRooms: [],
  seances: [],
  teachers: [],
  startDate: dayjs().subtract(1.5 * 30, 'day'), // Günümüzden 1.5 ay öncesi
  endDate: dayjs().add(1.5 * 30, 'day'), // Günümüzden 1.5 ay sonrası
};

const statuOptions = [
  PrePlacementDemandStatus.ACTION_REQUIRED,
  PrePlacementDemandStatus.DELAYED_ACTION_REQUIRED,
  PrePlacementDemandStatus.PLANNING,
  PrePlacementDemandStatus.ACTIVE,
  PrePlacementDemandStatus.FROZEN,
  PrePlacementDemandStatus.CANCELED,
].map((key) => ({
  label: localizeDemandStatus(key),
  value: PrePlacementDemandStatus[key],
}));

const basariOptions = [PrePlacementDemandStatus.SUCCESS, PrePlacementDemandStatus.FAILED, PrePlacementDemandStatus.CONDITIONAL].map((key) => ({
  label: localizeDemandStatus(key),
  value: PrePlacementDemandStatus[key],
}));

type DemandTableFilterProps = {
  onFilter: (initParams: object) => void;
  setStartPoint: any;
  levels: PaginateModel<EducationLevel>;
  teachers: PaginateModel<TeacherModel>;
  classrooms: PaginateModel<ClassRoomModel>;
  seances: PaginateModel<SessionDefinitionsModel>;
};

export const DemandTableFilter = ({ onFilter, setStartPoint, levels, teachers, classrooms, seances }: DemandTableFilterProps) => {
  const [searchInput, setSearchInput] = useState(initialFilters.name);
  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);
  const [teacherOptions, setTeacherOptions] = useState<CascaderOption[]>([]);
  const [classroomOptions, setClasroomOptions] = useState<CascaderOption[]>([]);
  const [seanceOptions, setSeanceOptions] = useState<CascaderOption[]>([]);
  const [timeDuration, setTimeDuration] = useState<number>(3);

  const [filters, setFilters] = useState(initialFilters);

  const [selectedKur, setSelectedKur] = useState();
  const [cascaderKey, setCascaderKey] = useState(Date.now());

  useEffect(() => {
    onFilter(filters);
  }, [filters]);

  useEffect(() => {
    if (levels && levels.data) {
      const groupedLevels = createLevelCascader(levels.data)
      setAbilityLevelOptions(groupedLevels ?? []);
    }
    else{
      setAbilityLevelOptions([])
    }
  }, [levels]);

  useEffect(() => {
    if (teachers) {
      const items: CascaderOption[] = [];
      teachers.data?.map((teacher) => {
        items.push({
          value: teacher.id!,
          label: `${teacher.name!} ${teacher.surname!}`,
        });
      });

      setTeacherOptions(items);
    }
  }, [teachers]);

  useEffect(() => {
    if (classrooms) {
      const items: CascaderOption[] = [];
      classrooms.data?.map((clasroom) => {
        items.push({
          value: clasroom.id!,
          label: clasroom.name!,
        });
      });

      setClasroomOptions(items);
    }
  }, [classrooms]);

  useEffect(() => {
    if (seances) {
      const items: CascaderOption[] = [];
      seances.data?.map((seance) => {
        items.push({
          value: seance.id!,
          label: seance.name!,
        });
      });

      setSeanceOptions(items);
    }
  }, [seances]);

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    updateFilter('name', value);
    updateFilter('email', value);
  };

  const onKurChange = (value) => {
    //MultiCascader'dan Tüm ağacın(field,theme,type,level) id'leri geliyor, levelid 3.index olduğu için onları tutuyoruz.
    const selectedLevels = value.map((v) => v[3]);

    updateFilter('levels', selectedLevels);
  };

  const resetFiltre = () => {
    setCascaderKey(Date.now()); //Cascader'ı butonla resetlemek için key'ini değiştiriyoruz.
    setFilters({ ...initialFilters, statuses: [] });
    setSearchInput('');
  };

  const updateFilter = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const inputControl = () => {
    //TODO Semih abi input onChange'de her karakter değiştiğinde backende istek atmasını engelleyen düzenleme yaptığında burası kaldırılacak
    if (
      searchInput === '' &&
      Object.values(filters)
        .filter((filter) => Array.isArray(filter))
        .every((filter: any) => filter.length === 0)
    ) {
      setFilters(initialFilters);
    }
  };

  const timeOptions = [
    { value: 3, label: '3 ay' },
    { value: 6, label: '6 ay' },
    { value: 12, label: '12 ay' },
  ];

  const handlePreviousTime = () => {
    const startTime = dayjs(filters.startDate).subtract((timeDuration / 2) * 30, 'day');
    const endTime = dayjs(filters.endDate).subtract((timeDuration / 2) * 30, 'day');
    const duration = endTime.diff(startTime, 'millisecond');
    const midPointDate = startTime.add(duration / 2, 'millisecond');

    setStartPoint(midPointDate.startOf('month'));
    setFilters((prev) => ({
      ...prev,
      startDate: startTime,
      endDate: endTime,
    }));
  };

  const handleNextTime = () => {
    const startTime = dayjs(filters.startDate).add((timeDuration / 2) * 30, 'day');
    const endTime = dayjs(filters.endDate).add((timeDuration / 2) * 30, 'day');
    const duration = endTime.diff(startTime, 'millisecond');
    const midPointDate = startTime.add(duration / 2, 'millisecond');
    setStartPoint(midPointDate.startOf('month'));
    setFilters((prev) => ({
      ...prev,
      startDate: startTime,
      endDate: endTime,
    }));
  };

  const anyFilterActive = filters.name !== '' || Object.values(filters).some((filter) => Array.isArray(filter) && filter.length > 0);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex mb-2 w-full">
        <Search
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className="max-w-[230px]"
          placeholder="Ad/Soyad/E-posta..."
          onSearch={onSearch}
          onBlur={inputControl}
          enterButton
        />

        <div className="flex ml-2 mt-2 justify-around w-7/12">
          <Badge dot={filters.levels.length > 0}>
            <Cascader
              value={selectedKur}
              options={abilityLevelOptions}
              expandTrigger="hover"
              multiple
              key={cascaderKey}
              showCheckedStrategy={Cascader.SHOW_CHILD}
              onChange={(value) => onKurChange(value)}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Kur
                  <DownOutlined />
                </Space>
              </a>
            </Cascader>
          </Badge>

          <Badge dot={filters.statuses.length > 0}>
            <Cascader value={filters.statuses} options={statuOptions} expandTrigger="hover" multiple onChange={(value) => updateFilter('statuses', value)}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Statü
                  <DownOutlined />
                </Space>
              </a>
            </Cascader>
          </Badge>

          <Badge dot={filters.successStatuses.length > 0}>
            <Cascader value={filters.successStatuses} options={basariOptions} expandTrigger="hover" multiple onChange={(value) => updateFilter('successStatuses', value)}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Başarım
                  <DownOutlined />
                </Space>
              </a>
            </Cascader>
          </Badge>

          <Badge dot={filters.classRooms.length > 0}>
            <Cascader value={filters.classRooms} options={classroomOptions} expandTrigger="hover" multiple onChange={(value) => updateFilter('classRooms', value)}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Derslik
                  <DownOutlined />
                </Space>
              </a>
            </Cascader>
          </Badge>

          <Badge dot={filters.seances.length > 0}>
            <Cascader value={filters.seances} options={seanceOptions} expandTrigger="hover" multiple onChange={(value) => updateFilter('seances', value)}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Seans
                  <DownOutlined />
                </Space>
              </a>
            </Cascader>
          </Badge>

          <Badge dot={filters.teachers.length > 0}>
            <Cascader value={filters.teachers} options={teacherOptions} expandTrigger="hover" multiple onChange={(value) => updateFilter('teachers', value)}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Öğretmen
                  <DownOutlined />
                </Space>
              </a>
            </Cascader>
          </Badge>
        </div>

        {anyFilterActive && (
          <div className="mt-1 justify-end">
            <Button onClick={resetFiltre} type="text" size="small" icon={<TbFilterX />}>
              Filtreyi Kaldır
            </Button>
          </div>
        )}
      </div>
      <div className="text-center mt-1">
        <LeftOutlined className="text-[#1677FF]" onClick={handlePreviousTime} />
        <span className="ml-5 mr-5 font-bold">
          {convertDate(filters.startDate, 'DD MMMM YYYY')} - {convertDate(filters.endDate, 'DD MMMM YYYY')}
        </span>
        <RightOutlined className="text-[#1677FF]" onClick={handleNextTime} />
        {/* TODO Daha sonra Bakılacak 3 ay 6 ay */}
        {/* <Select className="ml-5 w-20" defaultValue={3} options={timeOptions} onChange={(value) => setTimeDuration(value)} /> */}
      </div>
    </div>
  );
};
