import { ImageView } from '@shared/components/curriculum/ImageView';
import { ExerciseAnswerRequestModel, ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel, MediaModel, MediaOrderAnswerResponse, MediaOrderItem } from '@shared/models';
import { shuffleToArray } from '@shared/utils';
import { useEffect, useState } from 'react';

type ExerciseProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;
  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel: ExerciseAnswerRequestModel) => void;
  answerResponseModel?: MediaOrderAnswerResponse;
};

export const MatchingImage = ({ content, interaction, isPreview = false, isInClass, answerRequestModel, onChangeAnswerRequestModel, answerResponseModel }: ExerciseProps) => {
  const [answers, setAnswers] = useState<(MediaModel | string | null)[]>(Array(interaction.mediaOrderItems?.length).fill(null));
  const [disabledItems, setDisabledItems] = useState<(string | null)[]>(Array(interaction.mediaOrderItems?.length).fill(null));
  const [shuffledItems, setShuffledItems] = useState<(MediaOrderItem)[]>([]);

  useEffect(() => {
    if (interaction.mediaOrderItems) {
      setAnswers(Array(interaction.mediaOrderItems?.length).fill(null));
      setDisabledItems(Array(interaction.mediaOrderItems?.length).fill(null));
      const _items = [...interaction.mediaOrderItems];
      setShuffledItems(_items.sort(()=>Math.random()-0.5));
    }
  }, [interaction, interaction.mediaOrderItems]);

  const handleSelectAnswer = (answer: { type: 'word' | 'media'; value: string | MediaModel }, index: number) => {
    // Seçilen item daha önceden seçildiyse tekrar sol tarafa konmasını engelliyoruz.
    if (disabledItems.includes(answer.value as any)) return;

    const nextValidIndex = answers.findIndex((a, index) => {
      const expectedType = interaction.mediaOrderItems![index].askFor;
      return !a && expectedType === answer.type;
    });

    if (nextValidIndex !== -1) {
      const updatedAnswers = [...answers];
      updatedAnswers[nextValidIndex] = answer.value; // Yazı beklenen yere yazı, resim beklenen yere resim konmasını sağlıyoruz.
      setAnswers(updatedAnswers);

      // Update the mediaOrderItems in the answerRequestModel
      const updatedMediaOrderItems = [...(answerRequestModel?.mediaOrderItems || [])];

      if (answer.type === 'media' && typeof answer.value === 'object') {
        updatedMediaOrderItems[nextValidIndex] = {
          mediaId: answer.value.resourceId,
        };
      } else if (answer.type === 'word' && typeof answer.value === 'string') {
        updatedMediaOrderItems[nextValidIndex] = {
          word: answer.value,
        };
      }

      // Trigger onChangeAnswerRequestModel with updated mediaOrderItems
      if (onChangeAnswerRequestModel) {
        const updatedModel = {
          ...answerRequestModel,
          mediaOrderItems: updatedMediaOrderItems,
        };
        onChangeAnswerRequestModel(updatedModel);
      }

      // Seçilen resim veya metni disabled listesine koyuyoruz tekrar tekrar seçilmemesi için
      const updatedDisabledItems = [...disabledItems];
      updatedDisabledItems[index] = answer.value as any;
      setDisabledItems(updatedDisabledItems);
    }
  };

  // Daha öncedne seçilmiş resmi listeden çıkarmak istiyorsak..
  const handleLeftSideClick = (index: number) => {
    const updatedAnswers = [...answers];
    const removedAnswer = updatedAnswers[index];
    updatedAnswers[index] = null;
    setAnswers(updatedAnswers);

    // Update the answerRequestModel to remove the selected media/word
    const updatedMediaOrderItems = [...(answerRequestModel?.mediaOrderItems || [])];
    updatedMediaOrderItems[index] = {};
    if (onChangeAnswerRequestModel) {
      const updatedModel = {
        ...answerRequestModel,
        mediaOrderItems: updatedMediaOrderItems,
      };
      onChangeAnswerRequestModel(updatedModel);
    }

    // DisabledItem listesinden çıkartıyoruz
    const updatedDisabledItems = disabledItems.filter((item) => item !== removedAnswer);
    setDisabledItems(updatedDisabledItems);
  };

  return (
    <>
      {isInClass ? (
        <div className="flex flex-row items-center h-full w-full bg-white px-1 pt-5 space-x-2">
          {/* Sol Taraf */}
          <div className="w-3/6 h-full grid grid-cols-2 gap-2 ">
            {interaction.mediaOrderItems?.map((m, index) => {
              return (
                <div key={'matching_image' + index} className={` h-auto w-full text-center p-2 `} onClick={() => handleLeftSideClick(index)}>
                  <div
                    className={`border-2 border-solid w-full h-full ${answerResponseModel ? (answerResponseModel.corrections![index].isCorrect ? 'border-green-500' : 'border-red-400') : 'border-gray-300'
                      }`}
                  >
                    {m.askFor === 'word' && m.media ? (
                      <>
                        <ImageView image={m.media} width="110px" height="110px" /> {/* Display the image */}
                        <div className="text-center mt-2">
                          <span>{answers[index] || ('?' as any)}</span> {/* Show ? if no word is selected */}
                        </div>
                      </>
                    ) : (
                      m.askFor === 'media' &&
                      (answers[index] && typeof answers[index] === 'object' ? (
                        <ImageView image={answers[index] as MediaModel} width="110px" height="110px" />
                      ) : (
                        <div className="border h-full w-full flex justify-center items-center bg-gray-200" style={{ height: '110px' }}>
                          {/* Empty placeholder box for images */}
                        </div>
                      ))
                    )}

                    {m.askFor === 'media' && (
                      <div className="text-center p-1">
                        <span>{m.word || '?'}</span>
                      </div>
                    )}

                    {m.askFor === 'word' && !m.media && (
                      <div className="border border-double border-mid-gray text-center text-wrap">
                        <span className="text-wrap">{answers[index] || ('?' as any)}</span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Sağ Taraf */}
          <div className="w-3/6 h-full flex flex-col justify-center items-center ">
            <div className="flex flex-col justify-center content-center items-center mb-1">
              <span className="text-lg font-bold text-custom-purple line-clamp-1 text-wrap text-center">{content.content?.title}</span>
              <span className="text-xs text-light-purple line-clamp-1 text-wrap text-center">{content.content?.description}</span>
            </div>
            <div className="grid grid-cols-2 overflow-x-auto justify-center items-center gap-2 cursor-pointer">
              {interaction.mediaOrderItems?.map((m, index) => (
                <div
                  key={'matching_image_answer' + index}
                  className={`border border-double border-mid-gray h-24 w-full justify-center items-center flex cursor-pointer ${disabledItems.includes(m.media?.resourceUrl || (m.word as any)) ? 'opacity-50 pointer-events-none' : ''
                    }`}
                  onClick={() =>
                    handleSelectAnswer(
                      {
                        type: m.askFor === 'word' ? 'word' : 'media',
                        value: m.askFor === 'word' ? m.word! : m.media!,
                      },
                      index
                    )
                  }
                >
                  {m.askFor === 'media' && m.media ? (
                    <ImageView image={m.media} width="100%" height="100%" className="object-scale-down" />
                  ) : (
                    <div className="text-center text-wrap">
                      <span className="text-wrap">{m.word}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : isPreview ? (
        <div className="flex flex-col items-center h-full w-full bg-white px-1 pt-1 relative">
          <div className="h-3/6 w-full grid grid-cols-2 gap-3">
            {interaction.mediaOrderItems?.map((m, index) => {
              return (
                <div key={'matching_image' + index} className=" border-mid-gray h-24 space-y-1 w-full text-center relative">
                  <div className="h-5/6">
                    <ImageView image={m.askFor == 'media' ? {} : m.media!} width="100%" />
                  </div>
                  <div className="border border-double border-mid-gray text-center flex flex-wrap justify-center items-center content-center text-wrap  whitespace-normal h-auto">
                    <span className="text-wrap">{m.askFor == 'word' ? '?' : m.word}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="h-3/6 w-full flex flex-col  items-center">
            <div className="flex flex-col w-full h-1/3 justify-center content-center items-center mb-1 mt-2">
              <span className="text-lg font-bold text-custom-purple line-clamp-1 text-wrap text-center">{content.content?.title}</span>
              <span className="text-xs text-light-purple line-clamp-1 text-wrap text-center">{content.content?.description}</span>
            </div>
            <div className="flex flex-row overflow-x-auto justify-center h-2/3 items-center content-center gap-4 cursor-pointer">
              {interaction.mediaOrderItems?.map((m, index) => {
                return (
                  <div key={'matching_image_answer' + index} className=" w-full text-center flex justify-center items-center content-center">
                    {m.askFor == 'media' ? (
                      <ImageView image={m.media!} />
                    ) : (
                      <div className=" text-center text-wrap">
                        <span className="text-wrap">{m.word}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center h-full w-full bg-white px-1 pt-1 relative ">
          <div className=" w-full grid grid-cols-2 gap-1">
            {interaction.mediaOrderItems?.map((m, index) => {
              return (
                <div key={'matching_image' + index} className={` h-auto w-full text-center p-2 `} onClick={() => handleLeftSideClick(index)}>
                  <div
                    className={`border-2 border-solid w-full h-full ${answerResponseModel ? (answerResponseModel.corrections![index].isCorrect ? 'border-green-500' : 'border-red-400') : 'border-gray-300'
                      }`}
                  >
                    {m.askFor === 'word' && m.media ? (
                      <>
                        <ImageView image={m.media} width="70px" height="70px" /> {/* Display the image */}
                        <div className="text-center mt-2">
                          <span>{answers[index] || ('?' as any)}</span> {/* Show ? if no word is selected */}
                        </div>
                      </>
                    ) : (
                      m.askFor === 'media' &&
                      (answers[index] && typeof answers[index] === 'object' ? (
                        <ImageView image={answers[index] as MediaModel} width="70px" height="70px" />
                      ) : (
                        <div className="border h-full w-full flex justify-center items-center bg-gray-200" style={{ height: '70px' }}>
                          {/* Empty placeholder box for images */}
                        </div>
                      ))
                    )}

                    {m.askFor === 'media' && (
                      <div className="text-center p-1">
                        <span>{m.word || '?'}</span>
                      </div>
                    )}

                    {m.askFor === 'word' && !m.media && (
                      <div className="border border-double border-mid-gray text-center text-wrap">
                        <span className="text-wrap">{answers[index] || ('?' as any)}</span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full flex flex-col  items-center justify-center h-full">
            <div className="flex flex-col w-full justify-center content-center items-center mb-1 mt-2">
              <span className="text-lg font-bold text-custom-purple line-clamp-1 text-wrap text-center">{content.content?.title}</span>
              <span className="text-xs text-light-purple line-clamp-1 text-wrap text-center">{content.content?.description}</span>
            </div>
            <div className="flex flex-row overflow-x-auto justify-center items-center content-center gap-4 ">
              {shuffledItems?.map((m, index) => (
                <div
                  key={'matching_image_answer' + index}
                  className={`border border-double border-mid-gray p-2 max-h-24 w-full justify-center items-center flex cursor-pointer ${disabledItems.includes(m.media?.resourceUrl || (m.word as any)) ? 'opacity-50 ' : ''
                    }`}
                  onClick={() =>
                    handleSelectAnswer(
                      {
                        type: m.askFor === 'word' ? 'word' : 'media',
                        value: m.askFor === 'word' ? m.word! : m.media!,
                      },
                      index
                    )
                  }
                >
                  {m.askFor === 'media' && m.media ? (
                    <ImageView image={m.media} width="100%" height="100%" className="object-scale-down" />
                  ) : (
                    <div className="text-center text-wrap">
                      <span className="text-wrap">{m.word}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
