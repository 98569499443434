import { ExclamationCircleOutlined, TagOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ClassDetailModel, ContentVersion } from '@shared/models';
import { convertDate, renderLevelsAsString } from '@shared/utils';
import { Button, Divider, Empty, notification, Popconfirm, Select, Spin, Tag } from 'antd';
import { useEffect, useState } from 'react';

type ClassContentVersionProps = {
  classDetail: ClassDetailModel | undefined;
  getClassDetail: () => void;
};

enum ContentOptionTypes {
  SAME_LEVELS = 'sameLevels',
  ALL_LEVELS = 'allLevels',
}

export const ClassContentVersion = ({ classDetail, getClassDetail }: ClassContentVersionProps) => {
  const [contentVersionDataAll, setContentVersionDataAll] = useState<ContentVersion[]>([]);
  const [contentVersionData, setContentVersionData] = useState<ContentVersion[]>([]);
  const [contentOption, setContentOption] = useState<ContentOptionTypes>(ContentOptionTypes.SAME_LEVELS);
  const [contentOptionLoading, setContentOptionLoading] = useState<boolean>(false);

  useEffect(() => {
    getContentVersion();
  }, []);

  useEffect(() => {
    // Müfredat gösterimi her değiştiğinde yarım saniyelik spinner göster
    setContentOptionLoading(true);
    const timer = setTimeout(() => {
      setContentOptionLoading(false);
    }, 500); // 500ms delay
    return () => clearTimeout(timer);
  }, [contentOption]);

  const getContentVersion = async () => {
    const response = await API.CLASS.getBranchContentVersion();
    setContentVersionDataAll(response.data);
  };

  useEffect(() => {
    setContentVersionData(
      contentVersionDataAll
        .filter((data) => {
          if (contentOption === ContentOptionTypes.SAME_LEVELS) {
            return data.level?.id === classDetail?.level.brandDefinitionId;
          }
          return true; // ContentOption.ALL_LEVELS ise filtreleme yok
        })
        .sort((a, b) => {
          if (a.id === classDetail?.contentVersionId) return -1;
          if (b.id === classDetail?.contentVersionId) return 1;
          if (a.isDefault) return -1;
          if (b.isDefault) return 1;
          return 0;
        })
    );
  }, [contentOption, contentVersionDataAll]);

  const updateContentVersion = async (data) => {
    const response = await API.CLASS.updateContentVersion(classDetail?.id, data.id);
    if (response.ok) {
      notification.success({ message: 'Mevcut ders içeriği başarıyla değiştirildi.' });
      getContentVersion();
      getClassDetail();
    } else {
      notification.error({ message: 'Mevcut ders içeriği değiştirilemedi.' });
    }
  };

  return (
    <div>
      <h3>{classDetail?.name}</h3>

      <Select
        className="w-full"
        defaultValue={'Sınıfın eğitim seviyesine uygun içerikler'}
        onChange={(value) => setContentOption(value as ContentOptionTypes)}
        options={[
          {
            value: ContentOptionTypes.SAME_LEVELS,
            label: 'Sınıfın eğitim seviyesine uygun içerikler',
          },
          {
            value: ContentOptionTypes.ALL_LEVELS,
            label: 'Tüm seviyelere ait içerikler',
          },
        ]}
      />
      <Spin spinning={contentOptionLoading}>
        <div className="mx-1 mt-3 max-h-[500px] overflow-y-auto">
          {contentVersionData?.length > 0 ? (
            contentVersionData.map((data) => (
              <div key={data.id} className="mt-1">
                <div className="flex justify-between">
                  <div className="flex justify-center item-center">
                    <TagOutlined className="text-lg mr-2" />
                    Versiyon {data.version}.{data.brandVersion}
                    <div>
                      {data.id == classDetail?.contentVersionId ? (
                        <Tag className="ml-3" color="orange">
                          Aktif
                        </Tag>
                      ) : data.isDefault ? (
                        <Tag className="ml-3" color="blue">
                          Markanın tavsiye ettiği
                        </Tag>
                      ) : null}
                    </div>
                  </div>

                  <div>{convertDate(data.createdAt)}</div>
                </div>
                <div className="bg-gray-100 mt-1 rounded-md">
                  <div className="px-3 py-3">{renderLevelsAsString(data.level)}</div>
                </div>
                <div className="bg-gray-100 mt-1 rounded-md">
                  <div className="px-3 py-3">{data.description}</div>
                </div>
                <div className=" mt-3 mb-3">
                  {!(data.id == classDetail?.contentVersionId) && (
                    <Popconfirm
                      title="Müfredat değişikliğini onaylıyor musunuz?"
                      description="Sınıfın varolan müfredatı değiştirildiğinde, öğrencilerin ilerleme durumları sıfırlanacaktır!"
                      okText="Evet"
                      cancelText="Hayır"
                      icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={() => updateContentVersion(data)}
                      overlayStyle={{ maxWidth: '400px' }}
                    >
                      <Button type="primary" className="w-full">
                        Versiyonu sınıf müfredatına ekle
                      </Button>
                    </Popconfirm>
                  )}
                </div>
                <Divider className="m-0"></Divider>
              </div>
            ))
          ) : (
            <>
              <Empty description="Uygun ders içeriği bulunamadı." />
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};
