import * as API from '@shared/api';
import { LearningContentVersion } from '@shared/components';
import { ContentVersionStatus, Role } from '@shared/constants';
import { useNavigate } from 'react-router-dom';

export const AdminLearningContentVersions = () => {
  const [published, publishedLoading, getPublished] = API.CURRICULUM.COURSE_VERSION.fetchWithStatus(ContentVersionStatus.PUBLISHED, { init: true });
  const [draft, draftLoading, getDraft] = API.CURRICULUM.COURSE_VERSION.fetchWithStatus(ContentVersionStatus.DRAFT, { init: true });
  const [levels, levelsLoading, getLevels] = API.EDUCATION_LEVEL.SYSTEM.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });

  const navigate = useNavigate();

  const getVersions = () => {
    getDraft();
    getPublished();
  };

  const onForkVersion = async (id: string) => {
    if (id) {
      await API.CURRICULUM.COURSE_VERSION.fork(id);
      getVersions();
    }
  };

  const onPublishVersion = (id: string) => {
    const params = new URLSearchParams({
      versionId: id,
    });
    navigate('/learning-content-versions/review-version?' + params);
  };

  return (
    <LearningContentVersion
      draft={draft ? draft : []}
      published={published ? published : []}
      levels={levels?.data || []}
      onForkVersion={onForkVersion}
      newVersionRequest={() => {
        navigate('/learning-content-versions/admin-draft-version');
      }}
      onDeleteVersion={async (id) => {
        try {
          await API.CURRICULUM.COURSE_VERSION.delete(id);
          getVersions();
        } catch (error) {
          console.error(error);
        }
      }}
      onPublishVersion={onPublishVersion}
      onEditVersion={(id) => {
        const params = new URLSearchParams({
          versionId: id,
        });
        navigate('/learning-content-versions/admin-draft-version?' + params);
      }}
    />
  );
};

AdminLearningContentVersions.path = 'admin-learning-content-versions';
AdminLearningContentVersions.title = 'Ders İçeriği Versiyonları';
AdminLearningContentVersions.group = 'curriculum';
AdminLearningContentVersions.roles = [Role.ADMIN];
