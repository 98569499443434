import { CalendarPeriod } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { BranchCalendarModel, BrandCalendarModel } from '@shared/models';
import { Button, DatePicker, Form, Input, Select, Switch } from 'antd';
import { z } from 'zod';

const CreateHolidayForm = z.object({
  name: z.string({ required_error: 'Tatil ismini giriniz' }).min(1, 'Tatil ismini giriniz'),
  description: z.string({ required_error: 'Tatil açıklaması giriniz' }),
  period: z.string({ required_error: 'Yineleme bilgisini giriniz' }),
  date: z.string({ required_error: 'Tatil gününü giriniz' }),
});

type CreateHolidayProps = {
  onCreateHoliday: (data: BrandCalendarModel | BranchCalendarModel) => void;
  onClose: () => void;
};

export const CreateHoliday = ({ onClose, onCreateHoliday }: CreateHolidayProps) => {
  const form = useForm<
    Partial<{
      name: string;
      description: string;
      period: string;
      date: string;
      isHoliday: boolean;
    }>,
    BrandCalendarModel | BranchCalendarModel
  >(CreateHolidayForm, { isHoliday: true });

  const createHoliday = () => {
    const result = form.parse();
    if (result.success) {
      onCreateHoliday({
        ...result.data,
      });
      form.reset();
    } else {
      form.setAllTouched();
    }
  };

  const closeModal = () => {
    form.reset();
    onClose();
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Tatil Günü Adı" {...form.formItem('name')}>
        <Input placeholder="Tatil adı giriniz" {...form.input('name')} />
      </Form.Item>

      <Form.Item label="Açıklama" {...form.formItem('description')}>
        <Input placeholder="Açıklama ekleyiniz" {...form.input('description')} />
      </Form.Item>

      <Form.Item {...form.formItem('isHoliday')}>
        <Switch checkedChildren="Tatil" unCheckedChildren="Tatil Değil" {...form.inputSwitch('isHoliday')} />
      </Form.Item>

      <Form.Item {...form.formItem('period')} label="Yineleme">
        <Select
          {...form.inputSelected('period')}
          placeholder="Seçiniz"
          className="w-full"
          options={[
            { label: 'Yıllık', value: CalendarPeriod.EVERY_YEAR },
            { label: 'Bir Kez', value: CalendarPeriod.ONCE },
          ]}
        />
      </Form.Item>

      <Form.Item {...form.formItem('date')} label={<span className="truncate">Tatil Günü</span>}>
        <DatePicker className="w-1/2" placeholder="Seçiniz" {...form.inputDate('date')} />
      </Form.Item>

      <div className="flex justify-end gap-4">
        <Form.Item>
          <Button type="default" onClick={closeModal}>
            Vazgeç
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={createHoliday}>
            Oluştur
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
