export type PaginateModel<T> = Partial<{
  count: number;
  limit: number;
  page: number;
  pageCount: number;
  hasNext: boolean;
  data: T[];
}>;

export type PaginationQuery = Partial<{
  count: number;
  limit: number;
  page: number;
  pageCount: number;
  orders: string[];
  name: string;
  theme: string;
  brandId: string;
  branchId: string;
  enrollmentId: string;
  levelIds: string[];
  status: string;
  statuses: string[][];
  withDeleted: boolean;
  [key: string]: any;
}>;
