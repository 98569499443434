import * as API from '@shared/api';
import { TextInput } from '@shared/components';
import { CdnScope, FileUpload } from '@shared/components/curriculum/FileUpload';
import { ClassStatus, Role, UploadFileType } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { BrandHomeworkDefinition, CascaderOption, EducationLevel, HomeworkTeacherDefinition, PaginationQuery } from '@shared/models';
import { renderLevelsAsString } from '@shared/utils';
import { Button, DatePicker, Drawer, notification, Select, UploadFile } from 'antd';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill-new';
import { z } from 'zod';

type AssignDraftDrawerProps = {
  assignDraftDrawerOpen: boolean;
  assignDraftDrawerOnClose: () => void;
  dataDraftCard: HomeworkTeacherDefinition | BrandHomeworkDefinition;
};

//TODO düzelt zodları
const AssignDraftDrawerForm = z.object({
  name: z.string({ required_error: 'Ödev başlığı zorunlu alandır' }).min(1, 'Ödev başlığı 1 karekterden fazla olmalı'),
  description: z.string({ required_error: 'Ödev açıklaması zorunlu alandır' }).min(1, 'Ödev açıklaması 1 karekterden fazla olmalı'),
  files: z.array(z.any()).optional(),
});

export const AssignDraftDrawer = ({ assignDraftDrawerOpen, assignDraftDrawerOnClose, dataDraftCard }: AssignDraftDrawerProps) => {
  const [abilityLevelCascaderValue, setAbilityLevelCascaderValue] = useState<string>();
  const [files, setFiles] = useState<UploadFile[]>();
  const [startUpload_, startUpload] = useState<boolean>(false);
  const [levelId, setLevelId] = useState<string>();
  const { RangePicker } = DatePicker;
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 1000, levelIds: [], statuses: [[ClassStatus.STARTED]] });
  const [classList, classListLoading, getClassList] = API.CLASS.getClassList({ init: false });
  const [classOptions, setClassOptions] = useState([]);
  const [classValue, setClassValue] = useState<string[]>([]);
  const [dateValue, setDateValue] = useState();
  const form = useForm<Partial<HomeworkTeacherDefinition | BrandHomeworkDefinition>, HomeworkTeacherDefinition>(AssignDraftDrawerForm, {});

  useEffect(() => {
    const filter = classList?.data?.map((t) => {
      return { label: t.name!, value: t.id! };
    });
    setClassOptions(filter as never[]);
  }, [classList]);

  useEffect(() => {
    if (dataDraftCard && assignDraftDrawerOpen) {
      if (dataDraftCard.level) {
        getClassList(undefined, { ...paginationQuery });
      }
      getTeacherLevel();
      setFiles(dataDraftCard.files ? dataDraftCard.files.map((f) => ({ name: f.name!, uid: f.resourceId!, url: f.resourceUrl })) : []);
      if ('classIds' in dataDraftCard) {
        setClassValue(dataDraftCard.classIds ? dataDraftCard.classIds : []);
      }
      form.setValue({ ...dataDraftCard });
      setAbilityLevelCascaderValue(renderLevelsAsString(dataDraftCard.level));
    }
  }, [dataDraftCard?.id]);

  const getTeacherLevel = async () => {
    try {
      const teacherResult = await API.TEACHER.getTeacherLevels();
      const teacher = teacherResult.data as EducationLevel[];

      const _items: CascaderOption[] = [];

      teacher?.forEach((f) => {
        let fieldOption = _items.find((item) => item.value === f.theme?.type?.field?.id);

        if (!fieldOption) {
          fieldOption = {
            value: f.theme?.type?.field?.id!,
            label: f.theme?.type?.field?.name!,
            children: [],
          };
          _items.push(fieldOption);
        }

        let typeOption = fieldOption.children?.find((item) => item.value === f.theme?.type?.id);

        if (!typeOption) {
          typeOption = {
            value: f.theme?.type?.id!,
            label: f.theme?.type?.name!,
            children: [],
          };
          fieldOption.children?.push(typeOption);
        }

        let themeOption = typeOption.children?.find((item) => item.value === f.theme?.id);

        if (!themeOption) {
          themeOption = {
            value: f.theme?.id!,
            label: f.theme?.name!,
            children: [],
          };
          typeOption.children?.push(themeOption);
        }

        themeOption.children?.push({
          value: f.id!,
          label: f.name!,
        });
      });
    } catch (error) {}
  };

  const onCreateDraftHomework = async (files) => {
    const result = form.parse();

    if (result.success) {
      const d = result.data as HomeworkTeacherDefinition;
      const response = await API.HOMEWORK.createHomeworkDefinition({ ...result.data, files: files, levelId: levelId! });
      if (response.ok) {
        notification.success({ message: 'Taslak oluşturuldu' });
        assignDraftDrawerOnClose();
      }

      startUpload(false);
    } else {
      form.setAllTouched();
    }
  };

  const onAssignHomeworkClasses = async () => {
    const result = form.parse();

    if (result.data.startDate == undefined || result.data.dueDate == undefined) {
      return notification.info({ message: 'Ödev teslim tarihi zorunludur' });
    }
    const response = await API.HOMEWORK.createHomeworkTeacher({ ...result.data, definitionId: result.data.id, classIds: classValue as any });

    if (response.ok) {
      notification.success({ message: 'Ödev atama gerçekleştirildi' });
      setClassValue([]);
      setDateValue(undefined);
      assignDraftDrawerOnClose();
    }
  };

  const handleTimeChange = (value, valueFormat) => {
    setDateValue(value);
    form.setValue({
      ...form.value,
      startDate: valueFormat[0],
      dueDate: valueFormat[1],
    });
  };

  const handleChangeSelect = (value) => {
    setClassValue(value);
    form.setValue({ ...form.value, classIds: value });
  };

  return (
    <Drawer width={500} forceRender={true} open={assignDraftDrawerOpen} onClose={assignDraftDrawerOnClose} closable={true} title={'Ödev taslağını sınıflara ata'}>
      <div>
        <div className="mb-3 text-md font-bold">{abilityLevelCascaderValue}</div>
      </div>
      <div className="mt-4">
        <h4 className="mt-3">Sınıf</h4>
        <div className="mt-1">
          <Select mode="multiple" value={classValue} allowClear style={{ width: '100%' }} placeholder="Sınıf seçiniz" onChange={handleChangeSelect} options={classOptions} />
        </div>
      </div>

      <div className="mt-4">
        <h4 className="mt-3">Ödev Başlığı</h4>
        <div className="mt-1">
          <TextInput formItem={form.formItem('name')} formInput={form.input('name')} />
        </div>
      </div>
      <div className="mt-4  mb-5 relative h-72">
        <h4 className="mt-3">Ödev açıklama</h4>
        <div className="mt-1">
          <ReactQuill
            {...form.inputTextEditor('description')}
            className="h-48 p-0 mb-3 "
            theme="snow"
            modules={{
              toolbar: {
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ['bold', 'italic', 'underline'],
                  [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    {
                      color: [
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                      ],
                    },
                    { background: [] },
                    'link',
                  ],
                ],
              },
            }}
          />
        </div>
      </div>
      <div className="mt-4">
        <h4 className="mt-3">Ödev teslim tarihi</h4>
        <div className="mt-1">
          <RangePicker value={dateValue} className="w-full" onChange={(value, valueFormat) => handleTimeChange(value, valueFormat)} />
        </div>
      </div>

      <div className="mt-4">
        <div className="mb-3">Yüklemek istediğiniz dosyaları seçiniz</div>
        <div className="mb-3">
          <FileUpload
            watchThisIfTrueStartUpload={startUpload_}
            multiple={true}
            files={files}
            onChange={(files) => {
              setFiles(files);
            }}
            onChangeLoading={(b) => {
              //TODO upload işlemlerinde loading çalışır olamlı
            }}
            cdnScope={CdnScope.BRANCH}
            onUploadCompleted={(files) => {
              onCreateDraftHomework(files);
            }}
            allowedMimes={UploadFileType('*')}
          />
        </div>
        <div className="mb-3 text-xs">
          <span className="text-red-500">*</span> Desteklenen türler: Resim, Doküman, Ses, Video, Zip
        </div>
      </div>
      <Button className="w-full mt-5" type="primary" onClick={() => onAssignHomeworkClasses()}>
        Ödev ata
      </Button>
    </Drawer>
  );
};

AssignDraftDrawer.roles = [Role.ADMIN];
