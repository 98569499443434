import { Button, Form, Input, DatePicker } from 'antd';
import { z } from 'zod';
import { BeginEndTimeRange, SessionDefinitionsCreateModel, SessionDefinitionsModel } from '@shared/models';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useAccessableSeachParams, useForm } from '@shared/hooks';
import { getPreviousHours } from '@shared/utils/range_picker_calculate';
import { CloseCircleOutlined, CloseOutlined, InfoCircleOutlined, InfoOutlined, MinusCircleFilled, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const SessionDefinitionsPropsForm = z.object({
  name: z.string({ required_error: 'Seans adı zorunlu alandır' }).min(1, 'Seans adı 1 karekterden fazla olmalı'),
  times: z
    .array(
      z.object({
        begin: z.object({
          hour: z.number({}),
          minute: z.number({}),
        }),
        end: z.object({
          hour: z.number({}),
          minute: z.number({}),
        }),
      }),
      { required_error: 'Lütfen en az bir seans aralığı seçiniz' }
    )
    .refine((arr) => {
      let isValid: boolean = true;
      arr.forEach((t) => {
        if (isValid) {
          isValid = !(t.begin.hour < 0);
        }
      });
      return isValid;
    }, 'Lütfen tüm seans saatlerini doldurunuz.'),
});

type CreateSessionDefinitionsProps = {
  isUpdate: boolean;
  selectData: SessionDefinitionsModel;
  onSave: (value: SessionDefinitionsCreateModel) => void;
};

export const CreateSessionDefinitions = ({ isUpdate, selectData, onSave }: CreateSessionDefinitionsProps) => {
  const [convertedRange, setConvertedRange] = useState<RangeValueType<Dayjs>[]>([]);
  type RangeValueType<DateType> = [start: DateType | null | undefined, end: DateType | null | undefined];
  const form = useForm<SessionDefinitionsCreateModel>(SessionDefinitionsPropsForm, { ...selectData });
  const { RangePicker } = DatePicker;
  const [calculateMinute, setCalculateMinute] = useState(0);
  const [selectedTimeRanges, setSelectedTimeRanges] = useState<BeginEndTimeRange[]>([]);

  useEffect(() => {
    if (Object.keys(selectData).length == 0) {
      clearAll();
    }
  }, [selectData]);
  useEffect(() => {
    if (convertedRange?.length > 0) {
      const initialRange = convertedRange?.map((dayjs: RangeValueType<Dayjs>) => {
        if (dayjs[0] == null && dayjs[1] == null) {
          return {
            begin: { hour: -1, minute: -1 },
            end: { hour: -1, minute: -1 },
          } as BeginEndTimeRange;
        } else {
          return {
            begin: {
              hour: dayjs[0]!.hour(),
              minute: dayjs[0]!.minute(),
            },
            end: {
              hour: dayjs[1]!.hour(),
              minute: dayjs[1]!.minute(),
            },
          } as BeginEndTimeRange;
        }
      });
      form.setValue({ ...form.value, times: initialRange });
    }
  }, [convertedRange]);

  //İki saati de seçilmesi durumunda yapılanlar
  const handleTimeChange = (value, selectedIndex) => {
    if (isUpdate) {
      const totalMinutes = calculateTotalMinutes(selectedTimeRanges);
      setCalculateMinute(totalMinutes);
    }

    const newSelectedTimeRanges = [...selectedTimeRanges];

    if (selectedIndex !== null && selectedIndex < convertedRange.length) {
      const selectedRange = newSelectedTimeRanges[selectedIndex];

      if (selectedRange) {
        const recalculate = calculateMinuteDifference(selectedRange?.begin?.hour, selectedRange?.begin?.minute, selectedRange?.end?.hour, selectedRange?.end?.minute);

        setCalculateMinute((prevCalculateMinute) => prevCalculateMinute - recalculate);
      }
    }

    // Eğer değer null ve seçili bir indeks varsa
    if (value === null && selectedIndex !== null) {
      // Seçili indeksi filtrele
      const newSelectedTimeRanges = selectedTimeRanges.filter((_, index) => index !== selectedIndex);
      setSelectedTimeRanges(newSelectedTimeRanges);

      // ConvertedRange'den seçili indeksi filtrele
      const newConvertedRange = convertedRange.filter((_, index) => index !== selectedIndex);
      setConvertedRange(newConvertedRange);

      // Toplam dakikayı yeniden hesapla
      const calculate = calculateTotalMinutes(newSelectedTimeRanges);
      setCalculateMinute(calculate);

      // Form değerlerini güncelle
      form.setValue({
        ...form.value,
        times: newSelectedTimeRanges,
      });

      return;
    }

    if (value !== null) {
      if (selectedIndex !== null) {
        newSelectedTimeRanges[selectedIndex] = {
          begin: {
            hour: value[0].hour(),
            minute: value[0].minute(),
          },
          end: {
            hour: value[1].hour(),
            minute: value[1].minute(),
          },
        };
        setSelectedTimeRanges(newSelectedTimeRanges);

        const convertedValues = convertToRangeValueType([newSelectedTimeRanges[selectedIndex]]);
        const newConvertedRange = [...convertedRange];
        newConvertedRange.splice(selectedIndex, 1, ...convertedValues);
        setConvertedRange(newConvertedRange);
      } else {
        selectedTimeRanges.push({
          begin: {
            hour: value[0].hour(),
            minute: value[0].minute(),
          },
          end: {
            hour: value[1].hour(),
            minute: value[1].minute(),
          },
        });

        const convertedValues = convertToRangeValueType([
          {
            begin: {
              hour: value[0].hour(),
              minute: value[0].minute(),
            },
            end: {
              hour: value[1].hour(),
              minute: value[1].minute(),
            },
          },
        ]);
        setConvertedRange([...convertedRange, ...convertedValues]);
      }
    }

    if (value == null) {
      setCalculateMinute(0);
    }

    if (value && value[0] && value[1]) {
      const calculate = calculateMinuteDifference(value[0].hour(), value[0].minute(), value[1].hour(), value[1].minute());
      setCalculateMinute((prevCalculateMinute) => prevCalculateMinute + calculate);
      form.setValue({
        ...form.value,
        times: newSelectedTimeRanges, // Güncellenmiş state değerini kullan
      });
    }
  };

  //Aradaki dakikayı hesaplamak için
  function calculateMinuteDifference(startHour, startMinute, endHour, endMinute) {
    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;
    const minuteDifference = endTotalMinutes - startTotalMinutes;

    return minuteDifference;
  }

  //Tüm dakikayı hesaplamak için
  function calculateTotalMinutes(timeRanges) {
    let totalMinutes = 0;
    timeRanges.forEach((range) => {
      if (range && range.begin && range.end) {
        const { begin, end } = range;
        totalMinutes += calculateMinuteDifference(begin.hour, begin.minute, end.hour, end.minute);
      }
    });
    return totalMinutes;
  }

  //Tip dönüşümü RangeValue
  function convertToRangeValueType(selectedTimeRanges: BeginEndTimeRange[]): RangeValueType<Dayjs>[] {
    const convertedRange: RangeValueType<Dayjs>[] = selectedTimeRanges?.map(({ begin, end }) => [
      begin
        ? dayjs()
            .hour(begin.hour ?? 0)
            .minute(begin.minute ?? 0)
        : null,
      end
        ? dayjs()
            .hour(end.hour ?? 0)
            .minute(end.minute ?? 0)
        : null,
    ]);

    return convertedRange;
  }

  //Yeni bir seans oluşturma
  const handleAddRange = () => {
    setConvertedRange((prevRanges = []) => [...prevRanges, [null, null]]);
  };

  //Seçilen değer
  useEffect(() => {
    if (selectData) {
      if (selectData.seanceItems && selectData.seanceItems?.length > 0) {
        setSelectedTimeRanges(selectData.seanceItems);
        setConvertedRange(convertToRangeValueType(selectData.seanceItems).filter((range) => range[0] && range[1]));
        const calculate = calculateTotalMinutes(selectData.seanceItems);
        setCalculateMinute(calculate);
        form.setValue({
          ...form.value,
          id: selectData.id,
          name: selectData.name,
          times: selectData.seanceItems,
        });
      }
    }
  }, [selectData]);

  const clearAll = () => {
    setSelectedTimeRanges([]);
    selectedTimeRanges.push({});
    setConvertedRange([]);
    setCalculateMinute(0);
    form.setValue({});
    form.reset();
  };

  const onSend = () => {
    const result = form.parse();
    if (result.success) {
      onSave(result.data);
    } else {
      form.setAllTouched();
    }
  };

  const handleCloseButtonClick = (selectedIndex) => {
    handleTimeChange(null, selectedIndex);
  };

  return (
    <div>
      <Form layout="vertical">
        <div className="gap-8">
          <div className="flex flex-col">
            <Form.Item {...form.formItem('name')} label={'Seans Adı:'}>
              <Input {...form.input('name')} />
            </Form.Item>

            <span className="text-[#1777FF]">
              <div>
                <InfoCircleOutlined /> Önemli:
              </div>
              <div className="mt-2"> Seans tanımlarken ders işleme süresi ve tenefüsleri dikkate alarak tanımlayınız.</div>

              <div className="mt-2">Örneğin ders süreniz 40 dk ise; 09:00-09:40, 09:50-10:30 ... gibi 40dk ders 10 dk tenefüs olarak tanımlanmalıdır.</div>
              <div className="mt-2">Blok işlenen seanslar için ise 09:00-10:20 gibi 80dklık ders işleme süresi tanımlayabilirsiniz.</div>
            </span>
            <Form.Item className="mt-4" label={'Seans Saati:'} {...form.formItem('times')}>
              <div>
                {convertedRange?.map((range, index) => (
                  <div className="mt-2 flex justify-between gap-2" key={index}>
                    <div className="w-11/12">
                      <RangePicker
                        key={index}
                        value={[range[0], range[1]]}
                        minuteStep={5}
                        disabledTime={() => {
                          if (index > 0) {
                            const previousStartHour = convertedRange[index - 1][0]?.hour() || 0;
                            return { disabledHours: () => getPreviousHours(previousStartHour) };
                          }
                          return { disabledHours: () => [] };
                        }}
                        showTime
                        format="HH:mm"
                        className="w-full"
                        picker="time"
                        onChange={(value) => handleTimeChange(value, index)}
                      />
                    </div>
                    <div>
                      <Button
                        className="w-2/12 justify-end"
                        type="default"
                        icon={<MinusCircleOutlined className="text-gray-400" />}
                        onClick={() => handleCloseButtonClick(index)}
                      ></Button>
                    </div>
                  </div>
                ))}
                <div className="mt-2">
                  <Button className="w-full" type="dashed" icon={<PlusCircleOutlined />} onClick={handleAddRange}>
                    Seans Ekle
                  </Button>
                </div>
              </div>
            </Form.Item>
            <div className="grid grid-cols-3">{calculateMinute !== 0 && <span className="text-success mb-4 italic">{calculateMinute} dakika</span>}</div>
          </div>
        </div>
      </Form>
      <div className="grid grid-cols-2 gap-4">
        <Button type="primary" onClick={onSend}>
          {isUpdate ? <span> Güncelle</span> : <span> Oluştur</span>}
        </Button>
        {!isUpdate && (
          <Button onClick={clearAll}>
            <span> Temizle</span>
          </Button>
        )}
      </div>
    </div>
  );
};
