import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import { PiMedal } from 'react-icons/pi';

const CreditBar = ({ usedCredit, totalCredit }) => {
  return (
    <div className="flex items-center">
      <div className="flex items-center mr-2 ">
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center rounded-full bg-[#FFF1F0] p-1">
            <PiMedal className="text-[#F5222D]" />
          </div>
        </div>
        <span className="ml-1 text-[#F5222D]">{usedCredit}</span>
      </div>
      <Tooltip
        title={
          <div>
            <div>
              Şubenin mevcut kredisi: {totalCredit}, tüketeceğiniz kredi: {usedCredit}
            </div>
            <div>Yeterli krediniz bulunmadığından, sınıf oluşturulamayacaktır.</div>
          </div>
        }
      >
        <div className="relative w-full">
          <div className="relative w-full border-2 border-[#FFA39E] rounded-full p-0.5 outline outline-gray-300 outline-1 outline-offset-1 opacity-70">
            <div className="bg-green-200 rounded-full h-2">
              <div className="bg-[#FFA39E] h-full rounded-full w-full" />
            </div>
          </div>
        </div>
      </Tooltip>
      <div className="flex items-center ml-2">
        <span className="mr-1 text-[#F5222D]">{totalCredit}</span>
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center rounded-full bg-[#FFF1F0] p-1">
            <StopOutlined className="text-[#F5222D]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditBar;
