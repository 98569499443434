import { ExerciseLayoutType } from '@shared/constants';
import { ChoiceAnswerResponse, ExerciseAnswerRequestModel, ExerciseDefinitionModel, MediaOrderAnswerResponse, OrderAnswerResponse } from '@shared/models';
import { ReactElement, useEffect, useState } from 'react';
import {
  DialogBase,
  MatchBase,
  GapFillingKeyboard,
  GapFillingSelected,
  ListiningBase,
  ListiningMultiSelect,
  ListeningSingleSelect,
  ReadingMultiSelect,
  ReadingSingilSelect,
  VisualBase,
  VisualMultiSelect,
  VisualSingleSelect,
  ReadingGapFilling,
} from '../exercise_types';
import { MatchingImage } from '../exercise_types/match/MatchingImage';
import { CdnScope } from '../../FileUpload';

type ExerciseHandlerProp = {
  item: ExerciseDefinitionModel;
  isPreview?: boolean;
  cdnScope: CdnScope;
  isInClass: boolean;
  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel: ExerciseAnswerRequestModel) => void;
  onSendDialogResult?: () => void;
  onNextExerciseDialog?: () => void;
  answerResponseModel?: ChoiceAnswerResponse | OrderAnswerResponse | MediaOrderAnswerResponse;
  onResponseModelPronounciation?: (success: boolean,rate:number) => void;
  onResponseModelDialogPronounciation?:(conversationItemId: string, success: boolean, rate: number) => void
};
export const ExerciseHandler = ({
  item,
  isPreview = false,
  cdnScope,
  isInClass,
  answerRequestModel,
  onChangeAnswerRequestModel,
  onSendDialogResult,
  answerResponseModel,
  onNextExerciseDialog,
  onResponseModelPronounciation,
  onResponseModelDialogPronounciation,
}: ExerciseHandlerProp) => {
  const [exercise, setExercise] = useState<ReactElement>();

  useEffect(() => {
    if (item) {
      switch (item.layout) {
        case ExerciseLayoutType.VISUAL_BASE:
          setExercise(
            <VisualBase
              key={item?.id}
              content={item.content ? item.content : {}}
              interaction={{}}
              isPreview={isPreview}
              cdnScope={cdnScope}
              isInClass={isInClass}
              onResponseModelPronounciation={onResponseModelPronounciation}
            />
          );
          break;
        case ExerciseLayoutType.VISUAL_SINGLE_SELECT:
          setExercise(
            <VisualSingleSelect
              content={item.content ? item.content : {}}
              interaction={item.interaction ? item.interaction : {}}
              isPreview={isPreview}
              isInClass={isInClass}
              answerRequestModel={answerRequestModel}
              onChangeAnswerRequestModel={onChangeAnswerRequestModel}
              answerResponseModel={answerResponseModel as ChoiceAnswerResponse}
            />
          );
          break;
        case ExerciseLayoutType.VISUAL_MULTI_SELECT:
          setExercise(<VisualMultiSelect content={item.content ? item.content : {}} interaction={{}} isPreview={isPreview} isInClass={isInClass} />);
          break;
        case ExerciseLayoutType.LISTENING_BASE:
          setExercise(<ListiningBase content={item.content ? item.content : {}} interaction={{}} isPreview={isPreview} isInClass={isInClass} cdnScope={cdnScope} onChangeAnswerRequestModel={onChangeAnswerRequestModel}/>);
          break;
        case ExerciseLayoutType.LISTENING_SINGLE_SELECT:
          setExercise(
            <ListeningSingleSelect
              content={item.content ? item.content : {}}
              interaction={item.interaction ? item.interaction : {}}
              isPreview={isPreview}
              isInClass={isInClass}
              cdnScope={cdnScope}
              answerRequestModel={answerRequestModel}
              onChangeAnswerRequestModel={onChangeAnswerRequestModel}
              answerResponseModel={answerResponseModel as ChoiceAnswerResponse}
            />
          );
          break;
        case ExerciseLayoutType.LISTENING_MULTI_SELECT:
          setExercise(<ListiningMultiSelect />);
          break;
        case ExerciseLayoutType.READING_SINGLE_SELECT:
          setExercise(
            <ReadingSingilSelect
              content={item.content ? item.content : {}}
              interaction={item.interaction ? item.interaction : {}}
              isPreview={isPreview}
              isInClass={isInClass}
              answerRequestModel={answerRequestModel}
              onChangeAnswerRequestModel={onChangeAnswerRequestModel}
              answerResponseModel={answerResponseModel as ChoiceAnswerResponse}
            />
          );
          break;
        case ExerciseLayoutType.READING_MULTI_SELECT:
          setExercise(
            <ReadingMultiSelect
              content={item.content ? item.content : {}}
              interaction={item.interaction ? item.interaction : {}}
              isPreview={isPreview}
              isInClass={isInClass}
              answerRequestModel={answerRequestModel}
              onChangeAnswerRequestModel={onChangeAnswerRequestModel}
              answerResponseModel={answerResponseModel as ChoiceAnswerResponse}
            />
          );
          break;
        case ExerciseLayoutType.READING_GAP_FILLING:
          setExercise(
            <ReadingGapFilling
              content={item.content ? item.content : {}}
              interaction={item.interaction ? item.interaction : {}}
              isPreview={isPreview}
              isInClass={isInClass}
              answerRequestModel={answerRequestModel}
              onChangeAnswerRequestModel={onChangeAnswerRequestModel}
              answerResponseModel={answerResponseModel as OrderAnswerResponse}
            />
          );
          break;
        case ExerciseLayoutType.DIALOG_BASE || ExerciseLayoutType.DIALOG_SELECT || ExerciseLayoutType.DIALOG_SORTING:
          setExercise(
            <DialogBase
              key={item?.id}
              content={item.content ? item.content : {}}
              interaction={{}}
              isPreview={isPreview}
              isInClass={isInClass}
              answerRequestModel={answerRequestModel}
              onChangeAnswerRequestModel={onChangeAnswerRequestModel}
              onSendDialogResult={onSendDialogResult}
              onNextExerciseDialog={onNextExerciseDialog}
              answerResponseModel={answerResponseModel as ChoiceAnswerResponse | OrderAnswerResponse | MediaOrderAnswerResponse}
              onResponseModelDialogPronounciation={onResponseModelDialogPronounciation}
            />
          );
          break;
        case ExerciseLayoutType.MATCHING_BASE:
          setExercise(
            <MatchBase
              content={item.content ? item.content : {}}
              interaction={item.interaction ? item.interaction : {}}
              isPreview={isPreview}
              isInClass={isInClass}
              answerRequestModel={answerRequestModel}
              onChangeAnswerRequestModel={onChangeAnswerRequestModel}
              answerResponseModel={answerResponseModel as OrderAnswerResponse}
            />
          );
          break;
        case ExerciseLayoutType.MATCHING_IMAGE:
          setExercise(
            <MatchingImage
              content={item.content ? item.content : {}}
              interaction={item.interaction ? item.interaction : {}}
              isPreview={isPreview}
              isInClass={isInClass}
              answerRequestModel={answerRequestModel}
              onChangeAnswerRequestModel={onChangeAnswerRequestModel}
              answerResponseModel={answerResponseModel as MediaOrderAnswerResponse}
            />
          );
          break;
        case ExerciseLayoutType.GAP_FILLING_SELECT:
          setExercise(
            <GapFillingSelected
              content={item.content ? item.content : {}}
              interaction={item.interaction ? item.interaction : {}}
              isPreview={isPreview}
              isInClass={isInClass}
              cdnScope={cdnScope}
              answerRequestModel={answerRequestModel}
              onChangeAnswerRequestModel={onChangeAnswerRequestModel}
              answerResponseModel={answerResponseModel as OrderAnswerResponse}
            />
          );
          break;
        case ExerciseLayoutType.GAP_FILLING_KEYBOARD:
          setExercise(<GapFillingKeyboard />);
          break;
        default:
          setExercise(<div>Tanımsız layout</div>);
          break;
      }
    }
  }, [item, item?.id, answerRequestModel, answerResponseModel]);

  return exercise!;
};
