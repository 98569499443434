import {
  BuildOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CommentOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  ManOutlined,
  MoreOutlined,
  SendOutlined,
  WomanOutlined,
} from '@ant-design/icons';
import * as API from '@shared/api';
import { CommentTimeline, CommentTimelineItem } from '@shared/components';
import { Droppable } from '@shared/components/dragdrop';
import i18n from '@shared/localization/i18n';
import { PrePlacementDemand } from '@shared/models';
import { calculateAge, convertDate, generateRoomName, renderLevelsAsString } from '@shared/utils';
import { Button, Card, DatePicker, Divider, Dropdown, Input, Modal, Popconfirm, Select, Tabs, Tooltip, notification } from 'antd';
import dayjs from 'dayjs';
import { HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LuBaby } from 'react-icons/lu';
import { MdOutlineAnalytics } from 'react-icons/md';
import { TbAlertSquare, TbFilter } from 'react-icons/tb';
import AverageAgeBar from './AverageAgeBar';
import CreditBar from './CreditBar';
import CreditBarReject from './CreditBarReject';
import GenderBar from './GenderBar';
import { PlanBlock } from './PlanBlock';

const padTime = (value: number) => {
  return String(value).padStart(2, '0');
};

const seanceStr = (seanceItem: any) => {
  return `${padTime(seanceItem.beginHour)}:${padTime(seanceItem.beginMinute)}-${padTime(seanceItem.endHour)}:${padTime(seanceItem.endMinute)}`;
};

export interface PlanCardProps extends HTMLAttributes<HTMLElement> {
  id: string;
  plan?: any;
  kurFilter: any;
  updateKurFilter: (plan: any) => void;
  onUpdate?: VoidFunction;
  currentBalanceBranch: number;
}

export const PlanCard = (props: PlanCardProps) => {
  let { id, plan, kurFilter, updateKurFilter, onUpdate, ...domProps } = props;

  const [comment, setComment] = useState<string>('');
  const [roomName, setRoomName] = useState('');
  const [startDatePicker, setStartDatePicker] = useState(dayjs());
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState<boolean>(false);
  const [isRejectedModalOpen, setIsRejectedModalOpen] = useState<boolean>(false);
  const [creditInfo, setCreditInfo] = useState<any>({});
  const [tempDays, setTempDays] = useState<any>({});

  useEffect(() => {
    //Mevcutta oluşturulmuş her ClassPlanningSeance için dayOfWeeks değerini o seance'ın id'siyle eşleştiriyoruz
    //Böylece hangi seans hangi dayOfWeeks seçilmiş anlaşılacak

    const initialTempDays = {};
    plan?.classPlanningSeances?.forEach((classPlanningSeance) => {
      initialTempDays[classPlanningSeance.id] = classPlanningSeance.dayOfWeeks || [];
    });
    setTempDays(initialTempDays);
  }, [plan?.classPlanningSeances]);

  const retriveCreditInfo = async (id) => {
    const response = await API.CLASS_PLANNING.retriveCreditInfo(plan.id);

    if (response.ok) {
      setCreditInfo({
        ...response.data,
      });
    }
  };
  useEffect(() => {
    if (plan) {
      retriveCreditInfo(plan.id);
    }
  }, [plan.prePlacementDemands.length]);

  useEffect(() => {
    if (plan) {
      setStartDatePicker(dayjs(plan.startDate));
    }
  }, [plan]);

  const deletePlan = useCallback(async () => {
    await API.CLASS_PLANNING.delete(id);
    onUpdate?.();
  }, [id]);

  const addStudent = useCallback(
    async (student: PrePlacementDemand) => {
      await API.CLASS_PLANNING.addStudent(id, student.prePlacement.id!, student.id);
      onUpdate?.();
    },
    [id]
  );

  const setSeance = useCallback(
    async (seance: any) => {
      await API.CLASS_PLANNING.addSeance(id, seance.id);
      onUpdate?.();
    },
    [id]
  );

  const setClassRoom = useCallback(
    async (classPlanningSeance: any, classPlanningSeanceItem: any, classRoom: any) => {
      await API.CLASS_PLANNING.setClassRoom(id, classPlanningSeance.id, classPlanningSeanceItem.id, classRoom.id);
      onUpdate?.();
    },
    [id]
  );

  const setTeacher = useCallback(
    async (classPlanningSeance: any, classPlanningSeanceItem: any, teacher: any) => {
      await API.CLASS_PLANNING.setTeacher(id, classPlanningSeance.id, classPlanningSeanceItem.id, teacher.id);
      onUpdate?.();
    },
    [id]
  );

  const setDayOfWeeks = useCallback(
    async (classPlanningSeance: any, days: any) => {
      await API.CLASS_PLANNING.setDayOfWeeks(id, classPlanningSeance.id, days);
      onUpdate?.();
    },
    [id]
  );

  const setStartDate = useCallback(
    async (date: any) => {
      setStartDatePicker(date);
      await API.CLASS_PLANNING.setStartDate(id, date?.toISOString());
      onUpdate?.();
    },
    [id]
  );

  const removeStudent = useCallback(async (student: PrePlacementDemand) => {
    await API.CLASS_PLANNING.removeStudent(id, student.prePlacement.id!, student.id);
    onUpdate?.();
  }, []);

  const clearSeance = useCallback(
    async (classPlanningSeanceId: string) => {
      await API.CLASS_PLANNING.removeSeance(id, classPlanningSeanceId);
      onUpdate?.();
    },
    [id]
  );

  const removeClassRoom = useCallback(
    async (classPlanningSeance: any, classPlanningSeanceItem: any) => {
      await API.CLASS_PLANNING.removeClassRoom(id, classPlanningSeance.id, classPlanningSeanceItem.id);
      onUpdate?.();
    },
    [id]
  );

  const removeTeacher = useCallback(
    async (classPlanningSeance: any, classPlanningSeanceItem: any) => {
      await API.CLASS_PLANNING.removeTeacher(id, classPlanningSeance.id, classPlanningSeanceItem.id);
      onUpdate?.();
    },
    [id]
  );

  const setStatusAsReview = useCallback(async () => {
    await API.CLASS_PLANNING.setStatusAsReview(id);
    onUpdate?.();
  }, [id]);

  const sendComment = useCallback(
    async (status: string) => {
      if (comment) {
        const response = await API.CLASS_PLANNING.comment(id, comment, status, roomName);
        if (response.ok) {
          setComment('');
          onUpdate?.();
        }
      } else {
        notification.warning({ message: 'Yorum giriniz.' });
      }
    },
    [id, plan, comment, roomName]
  );

  const findMeta = useCallback(
    (seanceId: string, seanceItemId: string, propName: 'classRoom' | 'teacher') => {
      if (plan.componentsMeta) {
        let meta = plan.componentsMeta.seances?.[seanceId]?.seanceItems?.[seanceItemId]?.[propName];
        if (meta && meta.length > 0) {
          return meta.map((i) => ({ ...i, status: i18n.t('app.planningMeta.' + i.status) }));
        }
        return null;
      }
      return null;
    },
    [plan]
  );

  const findMetaStatus = useCallback(
    (seanceId: string, seanceItemId: string, propName: 'classRoom' | 'teacher') => {
      const meta = findMeta(seanceId, seanceItemId, propName);

      if (meta) {
        if (meta.some((item) => item.successType === 'ERROR')) {
          return 'ERROR';
        }
        return 'WARNING';
      }
      return 'SUCCESS';
    },
    [plan, findMeta]
  );

  const findStudentMeta = useCallback(
    (id: string) => {
      if (plan.componentsMeta) {
        let meta = plan.componentsMeta['students']?.[id];
        if (meta && meta.length > 0) {
          return meta.map((i) => ({ ...i, status: i18n.t('app.planningMeta.' + i.status) }));
        }
        return null;
      }
      return null;
    },
    [plan]
  );

  const findStudentMetaStatus = useCallback(
    (id: string) => {
      const meta = findStudentMeta(id);
      if (meta) {
        if (meta.some((item) => item.successType === 'ERROR')) {
          return 'ERROR';
        }
        return 'WARNING';
      }
      return 'SUCCESS';
    },
    [plan, findMeta]
  );

  const disabled = useMemo(() => {
    return ['IN_REVIEW', 'APPROVED'].includes(plan.status);
  }, [plan.status]);

  const deleteAction = useMemo(() => {
    return {
      key: '4',
      label: (
        <Popconfirm placement="bottom" title="Planı silmek istediğinize emin misiniz?" okType="danger" okText="Evet" cancelText="İptal" onConfirm={deletePlan}>
          <div className="flex gap-2.5 px-2">
            <DeleteOutlined />
            <span>Sil</span>
          </div>
        </Popconfirm>
      ),
    };
  }, [deletePlan]);

  const filterAction = useMemo(() => {
    return {
      key: '3',
      label: (
        <div className="flex gap-2.5 px-2" onClick={() => updateKurFilter(plan)}>
          <TbFilter />
          <span>Filtrele</span>
        </div>
      ),
    };
  }, [plan]);

  const actions = useMemo(() => {
    switch (plan.status) {
      case 'PLANNING':
        return [
          {
            key: '1',
            label: (
              <div className="flex gap-2.5 px-2" onClick={setStatusAsReview}>
                <CheckCircleOutlined />
                <span>İncele</span>
              </div>
            ),
          },
          plan?.level && filterAction,
          deleteAction,
        ].filter(Boolean);
      case 'REJECTED':
        return [
          {
            key: '1',
            label: (
              <div className="flex gap-2.5 px-2" onClick={setStatusAsReview}>
                <CheckCircleOutlined />
                <span>İncele</span>
              </div>
            ),
          },
          filterAction,
          deleteAction,
        ];
      case 'IN_REVIEW':
        return [
          {
            key: '1',
            label: (
              <div
                className="flex gap-2.5 px-2"
                onClick={() => {
                  setIsApprovalModalOpen(true);
                  setRoomName(generateRoomName(plan.classPlanningSeances, plan.level?.theme?.type?.field?.name ? plan.level?.name : ''));
                }}
              >
                <CheckCircleOutlined />
                <span>Onayla</span>
              </div>
            ),
          },
          {
            key: '2',
            label: (
              <div
                className="flex gap-2.5 px-2"
                onClick={() => {
                  //sendComment('REJECTED')
                  setIsRejectedModalOpen(true);
                }}
              >
                <CloseCircleOutlined />
                <span>Reddet</span>
              </div>
            ),
          },
          filterAction,
          deleteAction,
        ];
      default:
        return [];
    }
  }, [plan.status, setStatusAsReview, sendComment, deleteAction]);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current && plan.prePlacementDemands.length > 0) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [plan.prePlacementDemands]);

  const calculateFemaleCount = () => {
    return plan.prePlacementDemands.filter((item) => item.prePlacement.gender === 'FEMALE').length;
  };

  const calculateMaleCount = () => {
    return plan.prePlacementDemands.filter((item) => item.prePlacement.gender === 'MALE').length;
  };

  function calculateAges() {
    const currentDate = new Date();

    const calculateAge = (birthDate) => {
      const birth = new Date(birthDate);
      let age = currentDate.getFullYear() - birth.getFullYear();
      const monthDiff = currentDate.getMonth() - birth.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birth.getDate())) {
        age--;
      }
      return age;
    };

    const ages = plan.prePlacementDemands.map((item) => calculateAge(item.prePlacement.birthDate));

    const totalAge = ages.reduce((acc, age) => acc + age, 0);
    const averageAge = totalAge / ages.length;
    const oldestAge = Math.max(...ages);
    const youngestAge = Math.min(...ages);

    const underAverage = ages.filter((age) => age < averageAge).length;
    const aboveAverage = ages.filter((age) => age > averageAge).length;

    return {
      averageAge: averageAge.toFixed(0),
      oldestAge,
      youngestAge,
      underAverageCount: underAverage,
      aboveAverageCount: aboveAverage,
    };
  }

  return (
    <div className="plan-card-container snap-start">
      <Card
        title={renderLevelsAsString(plan?.level)}
        data-status={plan.status}
        extra={
          <Dropdown menu={{ items: actions }} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
              <MoreOutlined className="text-black" />
            </a>
          </Dropdown>
        }
        className={`plan-card h-full border-black/15`}
      >
        <Tabs
          tabBarGutter={25}
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: `Planlama`,
              children: (
                <div ref={scrollRef} className="flex flex-col gap-[4px] overflow-y-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
                  <Droppable disabled={disabled} id={id + '-seance-drop'} type="SEANCE" className="dropzone" setFn={setSeance}>
                    <div className="dropzone-icon">
                      <InboxOutlined />
                    </div>
                    <span>Seans sürükleyiniz</span>
                  </Droppable>
                  {plan?.classPlanningSeances?.map((classPlanningSeance) => (
                    <div key={classPlanningSeance.id} className="pb-2">
                      <div className="rounded border border-solid border-black/10 p-[5px]  bg-blue-50/[.6]">
                        <div>
                          <PlanBlock type="SEANCE" seance={classPlanningSeance} disabled={disabled} variant="drop" onDelete={() => clearSeance(classPlanningSeance.id)}>
                            {classPlanningSeance.name}
                          </PlanBlock>
                        </div>
                        <div className="flex flex-col gap-[7px] mt-[14px]">
                          {classPlanningSeance.classPlanningSeanceItems?.map((classPlanningSeanceItem) => (
                            <div key={classPlanningSeanceItem.id} className="flex flex-col gap-1">
                              <div className="plan-seance-hour">{seanceStr(classPlanningSeanceItem.seanceItem)}</div>
                              <div className="grid grid-cols-2 gap-[3px]">
                                <div>
                                  {classPlanningSeanceItem.classRoom ? (
                                    <PlanBlock
                                      disabled={disabled}
                                      variant="drop"
                                      type="ROOM"
                                      room={classPlanningSeanceItem.classRoom}
                                      status={findMetaStatus(classPlanningSeance.id, classPlanningSeanceItem.id, 'classRoom')}
                                      tooltip={findMeta(classPlanningSeance.id, classPlanningSeanceItem.id, 'classRoom')?.[0].status}
                                      onDelete={() => removeClassRoom(classPlanningSeance, classPlanningSeanceItem)}
                                    >
                                      {classPlanningSeanceItem.classRoom.name}
                                    </PlanBlock>
                                  ) : (
                                    <Droppable
                                      disabled={disabled}
                                      id={id + classPlanningSeanceItem.seanceItem.id + '-room-drop'}
                                      type="ROOM"
                                      className="dropzone"
                                      setFn={(value) => setClassRoom(classPlanningSeance, classPlanningSeanceItem, value)}
                                    >
                                      <div className="dropzone-icon">
                                        <InboxOutlined />
                                      </div>
                                      <span>Derslik</span>
                                    </Droppable>
                                  )}
                                </div>

                                <div>
                                  {classPlanningSeanceItem.teacher ? (
                                    <PlanBlock
                                      disabled={disabled}
                                      variant="drop"
                                      type="TEACHER"
                                      teacher={classPlanningSeanceItem.teacher}
                                      status={findMetaStatus(classPlanningSeance.id, classPlanningSeanceItem.id, 'teacher')}
                                      tooltip={findMeta(classPlanningSeance.id, classPlanningSeanceItem.id, 'teacher')?.[0].status}
                                      onDelete={() => removeTeacher(classPlanningSeance, classPlanningSeanceItem)}
                                    >
                                      <Tooltip
                                        title={
                                          <span>
                                            {classPlanningSeanceItem.teacher.name} {classPlanningSeanceItem.teacher.surname}
                                          </span>
                                        }
                                      >
                                        {classPlanningSeanceItem.teacher.gender == 'MALE' && (
                                          <span className="mr-1">
                                            <Tooltip title="Erkek">
                                              <ManOutlined style={{ color: 'blue' }} />
                                            </Tooltip>
                                          </span>
                                        )}
                                        {classPlanningSeanceItem.teacher.gender == 'FEMALE' && (
                                          <span className="mr-1">
                                            <Tooltip title="Kadın">
                                              <WomanOutlined style={{ color: 'magenta' }} />
                                            </Tooltip>
                                          </span>
                                        )}
                                        {classPlanningSeanceItem.teacher.name} {classPlanningSeanceItem.teacher.surname}
                                      </Tooltip>
                                    </PlanBlock>
                                  ) : (
                                    <Droppable
                                      disabled={disabled}
                                      id={id + classPlanningSeanceItem.seanceItem.id + '-teacher-drop'}
                                      type="TEACHER"
                                      className="dropzone"
                                      setFn={(value) => setTeacher(classPlanningSeance, classPlanningSeanceItem, value)}
                                    >
                                      <div className="dropzone-icon">
                                        <InboxOutlined />
                                      </div>
                                      <span>Öğretmen</span>
                                    </Droppable>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <Select
                            disabled={disabled}
                            className="w-full mt-1"
                            mode="multiple"
                            allowClear
                            placeholder="Haftanın Günleri"
                            value={tempDays[classPlanningSeance.id] || []}
                            onChange={(days) => {
                              const previousDays = tempDays[classPlanningSeance.id] || [];

                              // Length büyükse günlerden biri seçilmiş demek oluyor buna göre güncel durumu updateliyoruz
                              if (previousDays.length > days.length) {
                                setDayOfWeeks(classPlanningSeance, days);
                              }

                              setTempDays((prev) => ({
                                ...prev,
                                [classPlanningSeance.id]: days,
                              }));
                            }}
                            onBlur={() => setDayOfWeeks(classPlanningSeance, tempDays[classPlanningSeance.id] || [])}
                            options={[
                              { label: 'Pazartesi', value: 0 },
                              { label: 'Salı', value: 1 },
                              { label: 'Çarşamba', value: 2 },
                              { label: 'Perşembe', value: 3 },
                              { label: 'Cuma', value: 4 },
                              { label: 'Cumartesi', value: 5 },
                              { label: 'Pazar', value: 6 },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div>
                    <span>Planlanan Başlangıç Tarihi</span>
                    <DatePicker
                      placeholder="Seçiniz"
                      value={startDatePicker}
                      className="calender-small-text"
                      format="DD.MM.YYYY"
                      style={{ width: '100%' }}
                      onChange={setStartDate}
                      disabled={disabled || plan.prePlacementDemands.length <= 0}
                    />

                    {plan.calculatedStartDate && (
                      <div className="text-xs text-gray-500">
                        Hesaplanan: {convertDate(plan.calculatedStartDate)} - {convertDate(plan.calculatedEndDate)}
                      </div>
                    )}
                  </div>
                  <div>
                    {/* <span>Öğrenci</span> */}
                    <div className="flex flex-col gap-1">
                      <Droppable disabled={disabled} id={id + '-student-drop'} type="STUDENT" className="dropzone" setFn={addStudent}>
                        <div className="dropzone-icon">
                          <InboxOutlined />
                        </div>
                        <span>Öğrenci sürükleyiniz</span>
                      </Droppable>
                      {plan.prePlacementDemands.map((student) => {
                        const metaStatus = findStudentMetaStatus(student.id);
                        return (
                          <PlanBlock
                            disabled={disabled}
                            key={student.id}
                            type={'STUDENT'}
                            student={student}
                            variant="drop"
                            status={metaStatus}
                            tooltip={findStudentMeta(student.id)?.[0].status}
                            onDelete={() => {
                              removeStudent(student);
                              setStartDatePicker(undefined!);
                            }}
                          >
                            <div className="flex items-center">
                              {student.prePlacement.gender == 'MALE' && (
                                <span className="mr-1">
                                  <Tooltip title="Erkek">
                                    <ManOutlined style={{ color: 'blue' }} />
                                  </Tooltip>
                                </span>
                              )}

                              {student.prePlacement.gender == 'FEMALE' && (
                                <span className="mr-1">
                                  <Tooltip title="Kadın">
                                    <WomanOutlined style={{ color: 'magenta' }} />
                                  </Tooltip>
                                </span>
                              )}
                              {calculateAge(student.prePlacement.birthDate) < 18 && (
                                <span className="mr-1">
                                  <Tooltip title="18 yaş altı">
                                    <LuBaby size={15} color="orange" />
                                  </Tooltip>
                                </span>
                              )}
                              {metaStatus == 'ERROR' ? <small> ({student.level.name}) </small> : <></>}
                              {student.prePlacement.name + ' ' + student.prePlacement.surname}
                            </div>
                          </PlanBlock>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ),
              icon: <BuildOutlined />,
            },
            {
              key: '2',
              label: `Yorumlar (${plan.comments.length})`,
              children: (
                <div className="px-[10px]">
                  <div className="flex flex-row space-x-1">
                    <Input value={comment} placeholder="Yorum" onChange={(e) => setComment(e.target.value)} onPressEnter={() => sendComment(plan.status)} />
                    <Button icon={<SendOutlined />} onClick={() => sendComment(plan.status)} />
                  </div>
                  <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
                    <CommentTimeline>
                      {plan.comments.map((comment, index) => (
                        <CommentTimelineItem
                          key={index}
                          status={comment.status}
                          at={comment.at}
                          name={comment.branchUser.name}
                          comment={comment.comment}
                          last={index === plan.comments.length - 1}
                        />
                      ))}
                    </CommentTimeline>
                  </div>
                </div>
              ),
              icon: <CommentOutlined />,
            },
            {
              key: '3',
              label: `Ayrıntılar`,
              children: (
                <div className="px-[10px] overflow-y-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
                  {plan?.prePlacementDemands?.length > 0 && (
                    <div>
                      <div className="font-bold flex items-center">
                        <MdOutlineAnalytics size={20} />
                        <span className="ml-2">Planlama Detayları</span>
                      </div>
                      <div className="opacity-45 mt-2"> Harcanacak Kredi Miktarı</div>
                      <div className="mt-4">
                        {creditInfo.consumed <= props.currentBalanceBranch ? (
                          <CreditBar usedCredit={creditInfo.consumed} totalCredit={props.currentBalanceBranch} />
                        ) : (
                          <CreditBarReject usedCredit={creditInfo.consumed} totalCredit={props.currentBalanceBranch} />
                        )}
                      </div>
                      <Divider style={{ margin: '8px 0' }}></Divider>
                      <div className="opacity-45"> Cinsiyet Dağılımı</div>
                      <div className="mt-4">
                        <GenderBar femaleCount={calculateFemaleCount()} maleCount={calculateMaleCount()} />
                      </div>
                      <Divider style={{ margin: '8px 0' }}></Divider>
                      {plan?.prePlacementDemands?.length > 1 && (
                        <div>
                          <div className="mt-2">
                            <div className="opacity-45">Yaş Dağılımı</div>
                            <div className="mt-4">
                              <AverageAgeBar
                                oldAge={calculateAges().oldestAge}
                                youngAge={calculateAges().youngestAge}
                                averageAge={calculateAges().averageAge}
                                aboveAverageCount={calculateAges().aboveAverageCount}
                                underAverageCount={calculateAges().underAverageCount}
                              />
                            </div>
                          </div>
                          <Divider style={{ margin: '8px 0' }}></Divider>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="mt-2 font-bold flex items-center">
                    <TbAlertSquare size={20} className="mr-2" /> Dikkat Edilmesi Gerekenler
                  </div>

                  <ul className="list-disc">
                    <li>Sınıfın bitiş tarihini, sınıfa ait hangi ders içeriği versiyonun kullanılacağını sınıf oluşturulduktan sonra güncelleyebileceksiniz.</li>

                    <li>
                      Sınıfın tahmini başlangıç ve bitiş tarihi tanımlarınızda yer alan tatil günleri, toplam kur dakikası, planlamada yer alan haftanın günleri ve planlamada
                      kullanılan seans aralıkları dikkate alınarak otomatik olarak oluşturulmaktadır.
                    </li>
                    <li>
                      Şubenize ait yeni bir tatil günü eklendiğinde mevcut sınıflara ait ders günleri de tatil edilecek ve otomatik olarak sınıfın ders takvimine uygun yeni bir
                      ders tarihi daha eklenecektir.
                    </li>
                    <li>Şubeye ait yeterli kredi bekiyeniz mevcut değil ise bu planlamayı tamamlayamayacaksınız.</li>
                  </ul>
                </div>
              ),
              icon: <InfoCircleOutlined />,
            },
          ]}
        />
      </Card>
      <Modal
        title={
          <div className="flex flex-row space-x-2">
            <ExclamationCircleOutlined style={{ color: 'red' }} />
            <span>Planı onaylamak istediğinize emin misiniz?</span>
          </div>
        }
        closable={false}
        open={isApprovalModalOpen}
        onOk={() => {
          if (comment && comment != '' && roomName && roomName != '') {
            sendComment('APPROVED');
            setIsApprovalModalOpen(false);
          } else {
            notification.warning({ message: 'Tüm alanları doldurunuz.' });
          }
        }}
        onCancel={() => {
          setIsApprovalModalOpen(false);
        }}
        okText="Onayla"
        cancelText="Vazgeç"
      >
        <label>Sınıf Adı:</label>
        <Input value={roomName} onChange={(e) => setRoomName(e.target.value)} />
        <label>Yorum:</label>
        <Input value={comment} onChange={(e) => setComment(e.target.value)} />
      </Modal>

      <Modal
        title={
          <div className="flex flex-row space-x-2">
            <ExclamationCircleOutlined style={{ color: 'red' }} />
            <span>Planı reddetmek istediğinize emin misiniz?</span>
          </div>
        }
        closable={false}
        open={isRejectedModalOpen}
        onOk={() => {
          if (comment && comment != '') {
            sendComment('REJECTED');
            setIsRejectedModalOpen(false);
          } else {
            notification.warning({ message: 'Yorum giriniz.' });
          }
        }}
        onCancel={() => {
          setIsRejectedModalOpen(false);
        }}
        okText="Reddet"
        cancelText="Vazgeç"
      >
        <Input value={comment} placeholder="Yorum" onChange={(e) => setComment(e.target.value)} />
      </Modal>
    </div>
  );
};
