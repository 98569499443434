import { ExerciseDefinitionContentType, ExerciseLayoutType, LayoutEnums, Role } from '@shared/constants';
import i18n from '@shared/localization/i18n';
import { CascaderOption, EducationLevel, LoginUser, PaginationQuery } from '@shared/models';
import { SorterResult } from 'antd/lib/table/interface';
import { parsePhoneNumber } from 'react-phone-number-input';
import NumberToWords from 'number-to-words';
import dayjs from 'dayjs';

const speechMap: Map<string, string> = new Map([
  ['bee', 'b'],
  ['sea', 'c'],
  ['dee', 'd'],
  ['e', 'e'],
  ['eff', 'f'],
  ['gee', 'g'],
  ['aitch', 'h'],
  ['eye', 'i'],
  ['jay', 'j'],
  ['kay', 'k'],
  ['el', 'l'],
  ['em', 'm'],
  ['en', 'n'],
  ['o', 'o'],
  ['pee', 'p'],
  ['queue', 'q'],
  ['are', 'r'],
  ['ess', 's'],
  ['tea', 't'],
  ['you', 'u'],
  ['vee', 'v'],
  ['see', 'c'],
  ['ex', 'x'],
  ['why', 'y'],
  ['zee', 'z'],
  ['zed', 'z'],
  ['star', '*'],
  ['hash', '#'],
  ['dash', '-'],
  ['dot', '.'],
  ['comma', ','],
  ['colon', ':'],
  ['space', ' '],
]);

export const uuid = () => {
  return '' + new Date().getTime();
};

export const numberToWord = (number: number) => {
  return NumberToWords.toWords(number).replace(/[-]/g, ' ').replace(/[,]/g, '');
};

export const numberToOrdinalWord = (number: number) => {
  return NumberToWords.toWordsOrdinal(number).replace(/[-]/g, ' ').replace(/[,]/g, '');
};

export const speechRecognitionMap = (key, value) => {
  if (speechMap.has(key)) {
    return speechMap.get(key) == value;
  }
  return false;
};

/*
export const debounce = (func, timeout=300)=>{
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}*/

export const localizeDemandStatus = (value) => i18n.t('app.demandStatus.' + value);

export const formatCurrency = (value) => {
  value = parseFloat(value);

  if (typeof value !== 'number' || isNaN(value)) {
    return '0';
  }
  let formattedValue = value.toFixed(2);
  let [integerPart, decimalPart] = formattedValue.split('.');
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return integerPart + ',' + decimalPart;
};

export const querytoUrlString = (query?: object) => {
  let params = new URLSearchParams();

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      if (value == undefined) {
        return '';
      }

      if (Array.isArray(value)) {
        value.forEach((value) => params.append(`${key}[]`, value.toString()));
      } else if (typeof value === 'string' && value === '') {
        //Eğer içi boş string yollanırsa bişey yapma
      } else {
        params.append(key, value?.toString());
      }
    });
    return '?' + params.toString();
  }
  return '';
};

export const shuffleToArray = (array: Array<any>) => {
  let currentIndex = array.length;
  const shuffleArray = [...array];
  while (currentIndex != 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [shuffleArray[currentIndex], shuffleArray[randomIndex]] = [shuffleArray[randomIndex], shuffleArray[currentIndex]];
  }
  if (shuffleArray.length > 1 && shuffleArray.join(' ') == array.join(' ')) {
    return shuffleToArray(shuffleArray);
  }
  return shuffleArray;
};

export const layoutToContentType = (layout: ExerciseLayoutType) => {
  let type: ExerciseDefinitionContentType;
  switch (layout) {
    case ExerciseLayoutType.VISUAL_BASE:
      type = ExerciseDefinitionContentType.INFO;
      break;
    case ExerciseLayoutType.VISUAL_SINGLE_SELECT || ExerciseLayoutType.VISUAL_MULTI_SELECT:
      type = ExerciseDefinitionContentType.IMAGE;
      break;
    case ExerciseLayoutType.DIALOG_BASE || ExerciseLayoutType.DIALOG_SELECT || ExerciseLayoutType.DIALOG_SORTING:
      type = ExerciseDefinitionContentType.CHAT;
      break;
    case ExerciseLayoutType.MATCHING_BASE:
      type = ExerciseDefinitionContentType.MATCHING;
      break;
    case ExerciseLayoutType.MATCHING_IMAGE:
      type = ExerciseDefinitionContentType.MATCHING;
      break;
    case ExerciseLayoutType.GAP_FILLING_SELECT:
      type = ExerciseDefinitionContentType.FILL_IN_THE_BLANKS;
      break;
    case ExerciseLayoutType.LISTENING_SINGLE_SELECT:
      type = ExerciseDefinitionContentType.AUDIO;
      break;
    default:
      type = ExerciseDefinitionContentType.INFO;
      break;
  }
  return type;
};

export const urlCleaner = (searchParams: URLSearchParams) => {
  const layout = searchParams.get('layout');
  const brandId = searchParams.get('brandId');
  const branchId = searchParams.get('branchId');
  const params = new URLSearchParams();
  if (layout) {
    params.set('layout', layout);
  }
  if (brandId) {
    params.set('brandId', brandId);
  }
  if (branchId) {
    params.set('branchId', branchId);
  }
  return params.toString();
};

export const convertDate = (dateString, format = 'DD.MM.YYYY') => {
  return dayjs(dateString).format(format);
};

//+905554443322 olarak tutulan numarayı 90 ve 5554443322 olarak bölüyoruz
export const formatPhone = (phoneNumber: string) => {
  const code = parsePhoneNumber(phoneNumber)?.countryCallingCode; //Ülke kodu 90
  const number = parsePhoneNumber(phoneNumber)?.nationalNumber; //Ülke kodu çıkarılmış numara
  return { code, number };
};

const getInitials = (seanceItems) => {
  for (const seanceItem of seanceItems) {
    const dayOfWeeks = seanceItem.dayOfWeeks;

    if (dayOfWeeks && dayOfWeeks.some((day) => day < 5)) {
      return i18n.t('app.midWeek');
    }
  }

  return i18n.t('app.weekend');
};

const getEarliestBeginHourLabel = (hour) => {
  if (hour >= 0 && hour <= 12) {
    return i18n.t('app.morning');
  } else if (hour > 12 && hour <= 16) {
    return i18n.t('app.afternoon');
  } else {
    return i18n.t('app.night');
  }
};

const getDayLabel = (seanceItems) => {
  const dayMap = [i18n.t('app.monday'), i18n.t('app.tuesday'), i18n.t('app.wednesday'), i18n.t('app.thursday'), i18n.t('app.friday'), i18n.t('app.saturday'), i18n.t('app.sunday')];

  const uniqueDays: string[] = [];

  seanceItems.forEach((seanceItem) => {
    seanceItem.dayOfWeeks.forEach((day: number) => {
      const dayLabel = dayMap[day];
      if (!uniqueDays.includes(dayLabel)) {
        uniqueDays.push(dayLabel);
      }
    });
  });

  return uniqueDays.join('');
};

export const generateRoomName = (seanceItems, course) => {
  if (!seanceItems || seanceItems.length === 0) return '';

  let earliestBeginHour = seanceItems[0].classPlanningSeanceItems[0].seanceItem.beginHour;
  let roomName = seanceItems[0].classPlanningSeanceItems[0].classRoom?.name;

  for (let i = 1; i < seanceItems.length; i++) {
    const currentBeginHour = seanceItems[i].classPlanningSeanceItems[0].seanceItem.beginHour;
    if (currentBeginHour < earliestBeginHour) {
      earliestBeginHour = currentBeginHour;
      roomName = seanceItems[i].classPlanningSeanceItems[0].classRoom?.name;
    }
  }

  const initials = getInitials(seanceItems);
  const beginHourLabel = getEarliestBeginHourLabel(earliestBeginHour);
  const dayLabel = getDayLabel(seanceItems);

  return `${initials}${beginHourLabel}.${dayLabel}.${roomName}.${course}`;
};

export const calculateAge = (birthDate) => {
  const birth = new Date(birthDate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  const monthDifference = today.getMonth() - birth.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  return age;
};

// Function to get start and end dates of a month
export const getMonthRange = (date) => {
  const startOfMonth = date.startOf('month').format('YYYY-MM-DD');
  const endOfMonth = date.endOf('month').format('YYYY-MM-DD');
  return { startOfMonth, endOfMonth };
};

export const renderLevelsAsString = (item) => {
  return item ? `${item.theme?.type?.field?.name || ''}/${item.theme?.type?.name || ''}/${item.theme?.name || ''}/${item.name || ''}` : '';
};
export const singlePermissionUserRouter = (loginUser: LoginUser) => {
  const { features } = loginUser;
  if (features) {
    const { brand, branch } = features;
    if (brand && Object.keys(brand).length == 1) {
      const brandKey = Object.keys(brand)[0];
      if (brand[brandKey].permissions.role == Role.USER && brand[brandKey].permissions.products?.length == 0 && brand[brandKey].permissions.values?.length == 0) {
        if (branch && Object.keys(branch).length == 1) {
          const branchKey = Object.keys(branch)[0];
          const { permissions, isObserver, isStudent, isTeacher } = branch[branchKey];

          if (!permissions && isStudent && !isTeacher && !isObserver) {
            const params = new URLSearchParams({
              layout: LayoutEnums.STUDENT,
              brandId: brandKey,
              branchId: branchKey,
            });
            return '/' + LayoutEnums.STUDENT + '/dashboard?' + params.toString();
          } else if (permissions && !isStudent && isTeacher && !isObserver) {
            const params = new URLSearchParams({
              layout: LayoutEnums.TEACHER,
              brandId: brandKey,
              branchId: branchKey,
            });
            return '/' + LayoutEnums.TEACHER + '/dashboard?' + params.toString();
          } else {
            return '/';
          }
        } else {
          return '/';
        }
      }
    } else {
      return '/';
    }
  }
  return '/';
};

export const sortFields = (query: PaginationQuery, sorter: SorterResult<any> | SorterResult<any>[]): string[] => {
  let result: string[] = [];

  // Eğer 'orders' zaten query içinde varsa, mevcut 'orders'ı al
  if (query.hasOwnProperty('orders')) {
    result = query['orders']!;
  }

  // Sorter'ı bir diziye dönüştür
  const sorters = Array.isArray(sorter) ? sorter : [sorter];

  // Şu anda sıralama durumunda olan alanların bir kümesini oluştur
  const currentFields = new Set(sorters.map((singleSorter) => singleSorter.field));

  // Önceden var olan ancak şu anda sıralamada bulunmayan alanları filtrele
  result = result.filter((item) => {
    const field = item.substring(1); // Prefix (+ veya -) kaldır
    return currentFields.has(field);
  });

  sorters.forEach((singleSorter) => {
    const field = singleSorter.field;
    if (!field) return; // Field yoksa işlemi atla

    const prefix = singleSorter.order === 'ascend' ? '+' : '-';
    const sortField = `${prefix}${field}`;

    // Mevcut 'result' içinde bu alan var mı kontrol et
    const fieldIndex = result.findIndex((item) => typeof item === 'string' && item.endsWith(field.toString()));

    if (singleSorter.order) {
      if (fieldIndex !== -1) {
        result[fieldIndex] = sortField; // Mevcut sıralama yönünü güncelle
      } else {
        result.push(sortField); // Yeni bir sıralama alanı ekle
      }
    } else {
      // Eğer sıralama iptal edildiyse, ilgili alanı 'result'tan kaldır
      if (fieldIndex !== -1) {
        result.splice(fieldIndex, 1);
      }
    }
  });

  return result;
};

export const convertDateRangePicker = (dateString: string, format = 'DD.MM.YYYY'): string => {
  const [day, month, year] = dateString.split('.');

  const date = new Date(`${year}-${month}-${day}`);

  const map = {
    DD: date.getUTCDate().toString().padStart(2, '0'),
    MM: (date.getUTCMonth() + 1).toString().padStart(2, '0'),
    YYYY: date.getUTCFullYear().toString(),
    HH: date.getUTCHours().toString().padStart(2, '0'),
    mm: date.getUTCMinutes().toString().padStart(2, '0'),
    ss: date.getUTCSeconds().toString().padStart(2, '0'),
  };

  return format.replace(/DD|MM|YYYY|HH|mm|ss/g, (matched) => map[matched]);
};

//i ve ı harflerini beraber search ediyor (izmir'i ızmır şeklinde yazarsak da çalışacak)
export const latinize = (str: string) => str.toLocaleLowerCase('tr-TR').replace(/i/g, 'ı').replace(/ı/g, 'i');

export const createLevelCascader = (levels:EducationLevel[]) => {
  return levels.reduce((acc: CascaderOption[], level) => {
    const fieldId = level.theme?.type?.field?.id;
    const fieldName = level.theme?.type?.field?.name;
    const typeId = level.theme?.type?.id;
    const typeName = level.theme?.type?.name;
    const themeId = level.theme?.id;
    const themeName = level.theme?.name;

    if (!fieldId || !typeId || !themeId) return acc;

    // Level içinde Field bul
    let fieldGroup = acc.find(f => f.value === fieldId);
    if (!fieldGroup) {
      fieldGroup = { value: fieldId, label: fieldName, children: [] };
      acc.push(fieldGroup);
    }

    // Field içinde Type bul
    let typeGroup = fieldGroup.children?.find(t => t.value === typeId);
    if (!typeGroup) {
      typeGroup = { value: typeId, label: typeName, children: [] };
      fieldGroup.children?.push(typeGroup);
    }

    // Type içinde Theme bul
    let themeGroup = typeGroup.children?.find(th => th.value === themeId);
    if (!themeGroup) {
      themeGroup = { value: themeId, label: themeName, children: [] };
      typeGroup.children?.push(themeGroup);
    }

    // Ortak olan theme'lara levellari pushla
    themeGroup.children?.push({
      value: level.id,
      label: level.name,
    });

    return acc;
  }, [] as CascaderOption[]);
}