import { ProductPermissionValues, Role } from '@shared/constants';
import { AuthGuardContext } from './context';

export const AuthGuard = () => {
  const context = AuthGuardContext();
  const { Scope, Accessables, HasBranchPermission, HasBrandPermission } = context;

  const isTeacher = (): boolean => !!Scope.branchId && !!Accessables.branch[Scope.branchId].isTeacher;
  const isBranchAdmin = (): boolean => !!Scope.branchId && Accessables.branch[Scope.branchId].permissions?.role == Role.ADMIN;
  const isBrandAdmin = (): boolean => !!Scope.brandId && Accessables.brand[Scope.brandId].permissions?.role == Role.ADMIN;

  return {
    ...context,
    isBranchAdmin,
    isBrandAdmin,
    isTeacher,
    BranchGuards: {
      LMS: {
        Class: {
          update: (): boolean => isTeacher() || HasBranchPermission([ProductPermissionValues.branch.LMS.Class.UPDATE]),
        },
      },
    },
  };
};
