import { SortableContext, rectSortingStrategy, useSortable, sortableKeyboardCoordinates, arrayMove } from '@dnd-kit/sortable';
import { DndContext, PointerSensor, KeyboardSensor, useSensor, useSensors } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { ExerciseDefinitionModel } from '@shared/models';
import { useEffect, useState } from 'react';
import { Button, Card, Dropdown, Popconfirm, Spin, Tooltip } from 'antd';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';

type ExerciseListComponentProps = {
  exercies: ExerciseDefinitionModel[];
  selected?: ExerciseDefinitionModel;
  onOrder: (exercies: ExerciseDefinitionModel[]) => void;
  onSelect: (exercis: ExerciseDefinitionModel) => void;
  onRemove: (exercisId: string) => void;
};

export const ExerciseListComponent = ({ exercies, selected, onOrder, onSelect, onRemove }: ExerciseListComponentProps) => {
  const [items, setItems] = useState<ExerciseDefinitionModel[]>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [draggedItemId, setDraggedItemId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setItems(exercies);
  }, [exercies]);

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }
    if (active.id == over.id) {
      return;
    }
    /*const activeIndex = items.findIndex((it) => it.id == active.id);
    const overIndex = items.findIndex((it) => it.id == over.id);
    const activeObj = {
      ...items[activeIndex],
      order: overIndex,
    };
    const overObj = {
      ...items[overIndex],
      order: activeIndex,
    };
    items[activeIndex] = activeObj;
    items[overIndex] = overObj;*/
    const _item = arrayMove(
      items,
      items.findIndex((it) => it.id == active.id),
      items.findIndex((it) => it.id == over.id)
    ).map((e, i) => {
      return {
        ...e,
        order: i,
      };
    });
    setItems(_item);
    onOrder(_item);

    setDraggedItemId(active.id);
    setTimeout(() => setDraggedItemId(null), 1000);
  };

  const SortableItem = (item) => {
    const { id, name, order, index } = item;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const isDragging = draggedItemId === id;

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      backgroundColor: isDragging ? '#e6f4ff' : 'transparent',
      animation: isDragging ? 'fadeOut 1s ease-out' : 'none',
    };

    return (
      <Tooltip title={id ? '' : 'Kaydı tamamlanmamış egzersiz bu egzersiz, kaydedilmeden sıralama çalışmayacaktır'} color="red">
        <Card
          ref={setNodeRef}
          key={order + 'exercise'}
          size="small"
          type="inner"
          hoverable
          style={{ ...style }}
          {...attributes}
          {...listeners}
          onClick={() => {
            onSelect(item);
          }}
          className={
            selected && selected.order == order
              ? 'cursor-ew-resize flex flex-col rounded-md border-2 border-solid border-[#91CAFF] flex-wrap bg-[#E6F4FF] m-2 hover:bg-gray-100'
              : 'cursor-ew-resize flex flex-col rounded-md border border-solid flex-wrap  m-2 hover:bg-gray-100'
          }
        >
          <Card.Meta
            title={
              <div className="flex flex-row justify-between content-between items-center min-w-max space-x-2">
                <span className={id ? (selected && selected.order == order ? 'text-[#4096FF] text-sm text-wrap ' : 'text-sm text-wrap ') : 'text-red-500 text-sm text-wrap'}>
                  {index + 1}. {name}
                </span>
                <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => onRemove(id)} okText="Evet" cancelText="İptal">
                  <DeleteOutlined className="text-red-500" />
                </Popconfirm>
              </div>
            }
          />
        </Card>
      </Tooltip>
    );
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext
        disabled={items.filter((i) => !i.id).length > 0}
        items={items.map((t) => {
          return { id: t.id! };
        })}
        strategy={rectSortingStrategy}
      >
        {items.map((item, i) => (
          <SortableItem key={item.order} id={item.id} name={item.layout} order={item.order} index={i} />
        ))}
      </SortableContext>
    </DndContext>
  );
};

//<Button type='dashed' className='bg-red-50' size='small' loading={deleteLoading} onClick={() => onRemove(id)} icon={<DeleteOutlined className='text-red-500'/>} />
