import { Account } from './account';
import { ClassModel } from './class';
import { MediaModel } from './curriculum';
import { EducationLevel } from './education_level';
import { StudentModel } from './student';
import { TeacherModel } from './teacher';

//Teacher Homework Definitions

//Class HomeWork
export type MediaResourceProps = {
  resourceId: string;
  resourceUrl?: string;
  requireSignedURLs?: boolean;
};

export enum ClassHomeworkStatus {
  ACTIVE = 'ACTIVE',
  WAITING = 'WAITING',
  EXPIRED = 'EXPIRED',
}

export type StudentHomeworkProps = {
  id?: string;
  classHomework: ClassHomeworkProps;
  student?: StudentModel;
  files?: MediaModel[];
  note?: string;
  isEvaluated?: boolean;
  score?: number;
  evaluatedBy?: Account;
  evaluateFiles?: MediaModel[];
  evaluateNote?: string;
  evaluatedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type HomeworkTeacherDefinition = {
  id: string;
  name: string;
  definitionId: string;
  description: string;
  levelId: string;
  class: ClassModel;
  files: MediaModel[];
  createdAt: string;
  level: EducationLevel;
  startDate: string;
  dueDate: string;
  classIds: string[];
};

export type HomeworkTeacherDefinitionDetail = {
  id: string;
  name: string;
  definitionId: string;
  description: string;
  levelId: EducationLevel;
  class: ClassModel;
  files: MediaModel[];
  createdAt: string;
  level: EducationLevel;
  startDate: string;
  dueDate: string;
  classIds: [];
  status: ClassHomeworkStatus;
};

export type ClassHomeworkProps = {
  id?: string;
  definitionId: string;
  status: ClassHomeworkStatus;
  name: string;
  description: string;
  files: MediaModel[];
  class: ClassModel;
  createdBy: TeacherModel;
  startDate: Date;
  dueDate: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type HomeworkTeacherCreate = {
  name: string;
  definitionId: string;
  description: string;
  levelId: string;
  files: MediaModel[];
  createdAt: string;
  level: EducationLevel;
  startDate: string;
  dueDate: string;
  classIds: [];
};

export type NotSubmitedStudents = {
  name: string;
  definitionId: string;
  description: string;
  levelId: string;
  files: MediaModel[];
  createdAt: string;
  level: EducationLevel;
  startDate: string;
  dueDate: string;
  classIds: string[];
};

// export type SubmitedStudents = {
//   id: string;
//   branchId: string;
//   student: StudentModel;
//   files: MediaModel[];
//   note: string;
//   isEvaluated: boolean;
//   score: number;
//   evaluatedBy: Record<string, any>;
//   evaluateFiles: MediaModel[];
//   evaluateNote: string | null;
//   evaluatedAt: string | null;
//   createdAt: string;
//   updatedAt: string;
//   description: string;
// };

export type HomeworkTeacherDetail = {
  classHomework: ClassHomeworkProps;
  notSubmitedStudents: StudentModel[];
  submitedStudents: StudentHomeworkProps[];
};

//BRAND

export type BrandHomeworkDefinition = {
  id: string;
  brandId: string;
  level: EducationLevel;
  name: string;
  description: string;
  files: MediaModel[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type CreateBrandHomeworkDefinitionProps = {
  id?: string;
  levelId: string;
  name: string;
  description: string;
  files: MediaModel[];
};
