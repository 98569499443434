import { DayOfWeek, NoteType } from '@shared/constants';
import { useFetch } from '@shared/hooks';
import { AddSeanceToClassModel, AttendancesRequest, ContentVersionNavigation, PaginateModel, PrePlacementDemandBasicInfo, UseFetchOptions } from '@shared/models';
import { ClassCalendarModel, ClassDetailModel, ClassModel, ClassTeacherStudentEvaluation, CreateClassTeacherStudentEvaluation } from '@shared/models/class';
import { request } from '@shared/utils';

export default {
  getClassList: (config: UseFetchOptions) => useFetch<PaginateModel<ClassModel>>('/class', { method: 'GET', ...config }),
  getTeacherClassList: (config: UseFetchOptions) => useFetch<PaginateModel<ClassModel>>('/class/my', { method: 'GET', ...config }),
  getClassDetail: (classId: string | undefined, config: UseFetchOptions) => useFetch<ClassDetailModel>('/class/' + classId, { method: 'GET', ...config }),
  getTeacherClassDetail: (classId: string | undefined, config: UseFetchOptions) => useFetch<ClassDetailModel>('/class/my/' + classId, { method: 'GET', ...config }),
  getAvailableStudentsToAdd: (classId: string | undefined, config: UseFetchOptions) =>
    useFetch<PrePlacementDemandBasicInfo[]>('/class/' + classId + '/demands-for-available-to-add', { method: 'GET', ...config }),
  getClassCalendar: (classId: string | undefined, config: UseFetchOptions) => useFetch<ClassCalendarModel[]>('/class/' + classId + '/calendar', { method: 'GET', ...config }),
  addStudentToClass: async (classId: string | undefined, demandId: string) => request(`/class/${classId}/student/${demandId}/add`, 'POST'),
  removeStudentFromClass: async (classId: string | undefined, studentId: string) => request(`/class/${classId}/student/${studentId}/remove`, 'DELETE'),
  updateTeacher: async (classId: string, classSeanceId: string, classSeanceItemId: string, teacherId: string) =>
    request(`/class/${classId}/seance/${classSeanceId}/seance-item/${classSeanceItemId}/teacher/${teacherId}`, 'PUT'),
  updateClasroom: async (classId: string, classSeanceId: string, classSeanceItemId: string, classRoomId: string) =>
    request(`/class/${classId}/seance/${classSeanceId}/seance-item/${classSeanceItemId}/class-room/${classRoomId}`, 'PUT'),
  updateDayOfWeeks: async (classId: string, classSeanceId: string, dayOfWeeks: DayOfWeek[]) => request(`/class/${classId}/seance/${classSeanceId}`, 'PUT', { dayOfWeeks }),
  updateClassEndDate: async (classId: string, endDate: Date | undefined) => request(`/class/${classId}/update-end-date`, 'PUT', { endDate }),
  addSeanceToClass: async (classId: string, seance: AddSeanceToClassModel) => request(`/class/${classId}/seance`, 'POST', seance),
  removeSeanceFromClass: async (classId: string, classSeanceId: string) => request(`/class/${classId}/seance/${classSeanceId}`, 'DELETE'),
  startInClass: async (classId: string | null) => request(`/in-class/${classId}/start`, 'GET'),
  updateClassAttendances: async (attendances: AttendancesRequest[] | undefined, classId: string | null, activityId: string | null) =>
    request(`/in-class/${classId}/activity/${activityId}`, 'PUT', { attendances }),
  nextPage: async (classId: string | null, activityId: string | null) => request(`/in-class/${classId}/activity/${activityId}/next-page`, 'GET'),
  prevPage: async (classId: string | null, activityId: string | null) => request(`/in-class/${classId}/activity/${activityId}/prev-page`, 'GET'),
  gotoPage: async (classId: string | null, activityId: string | null, pageId: string | null) => request(`/in-class/${classId}/activity/${activityId}/go-to-page/${pageId}`, 'GET'),
  getClassAttendances: async (classId, requestBody) => request(`/class/${classId}/attendances`, 'POST', requestBody),
  compeletedInClass: async (classId: string | null, activityId: string | null) => request(`/in-class/${classId}/complete/${activityId}`, 'GET'),
  createClassNote: async (classId: string, note: string, type: NoteType) => request(`/class/${classId}/note`, 'POST', { note, type }),
  getTeacherStudentEvaluationAll: (classId: string | undefined, config: UseFetchOptions) =>
    useFetch<ClassTeacherStudentEvaluation[]>('/class/' + classId + '/teacher-student-evaluation', { method: 'GET', ...config }),
  getTeacherStudentEvaluation: async (classId: string | undefined) => request(`/class/${classId}/teacher-student-evaluation/my`, 'GET'),
  upsertTeacherStudentEvaluation: async (classId: string, items: CreateClassTeacherStudentEvaluation) => request(`/class/${classId}/teacher-student-evaluation`, 'PUT', items),
  getInClassInfo: async (classId: string | undefined) => request(`/in-class/${classId}/info`, 'GET'),
  getBranchContentVersion: async () => request(`/lms/branch-content-version`, 'GET'),
  updateClassName: async (classId: string | undefined, data) => request(`/class/` + classId + `/name`, 'PUT', data),

  //Öğretmen InClass Preview
  getClassBranchContentVersion: (contentVersionId: string | undefined) => request(`/lms/branch-content-version/${contentVersionId}/next-page`, 'GET'),
  goToNextClassContentVersionPage: (contentVersionId: string | undefined, brandPageId: string) =>
    request(`/lms/branch-content-version/${contentVersionId}/next-page/${brandPageId}`, 'GET'),
  goToPrevClassContentVersionPage: (contentVersionId: string | undefined, brandPageId: string) =>
    request(`/lms/branch-content-version/${contentVersionId}/prev-page/${brandPageId}`, 'GET'),
  goToClassContentVersionPage: (contentVersionId: string | undefined, brandPageId: string) =>
    request(`/lms/branch-content-version/${contentVersionId}/go-to-page/${brandPageId}`, 'GET'),

  updateContentVersion: async (classId: string | undefined, contentVersionId: string | undefined) => request(`/class/${classId}/content-version/${contentVersionId}`, 'PUT'),
  getStudentsSuccessSummary: async (classId: string | undefined) => request(`/class/${classId}/students-success-summary`, 'GET', null, null, {}, true, [404]),
  classComplete: async (classId: string | undefined) => request(`/class/${classId}/complete`, 'PUT'),
  deleteClass: async (classId: string | undefined) => request(`/class/${classId}`, 'DELETE'),
  classCalendarPostpone: async (classId: string | undefined, calendarId: string | undefined) => request(`/class/${classId}/calendar/${calendarId}/postpone`, 'PUT'),
  classCalendarRevertPostpone: async (classId: string | undefined, calendarId: string | undefined) => request(`/class/${classId}/calendar/${calendarId}/revert-postpone`, 'PUT'),
};
