import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import tr from './locales/tr.json';
import trTR from 'antd/locale/tr_TR';
import enUs from 'antd/locale/en_US';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
      tr: {
        translation: tr,
        ant: trTR,
      },
      en: {
        translation: en,
        ant: enUs,
      },
    },
  });

export default i18n;
