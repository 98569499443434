import * as API from '@shared/api';
import { TextInput } from '@shared/components';
import { LocalStorageKeys } from '@shared/constants';
import { TheUser, auth, useUserContext } from '@shared/contexts';
import { useForm } from '@shared/hooks';
import { LoginRequest, LoginUser } from '@shared/models';
import { singlePermissionUserRouter } from '@shared/utils';
import { Button, Card, Divider, Form, Image, Input, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

const oneHour = 1000 * 60 * 60 * 24 * 7;

const LoginForm = z.object({
  email: z.string({ required_error: 'Kullanıcı Adı veya E-posta Zorunludur' }).min(1, 'Ad Zorunludur').email('Geçersiz e-mail adresi'),
  password: z.string({ required_error: 'Parola Zorunludur' }).min(1, 'Parola Zorunludur'),
});

export const Login = () => {
  const { setUser } = useUserContext();
  const form = useForm<LoginRequest>(LoginForm, {});
  const [read, setRead] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const str = localStorage.getItem(LocalStorageKeys.USER);
      const strThen = localStorage.getItem(LocalStorageKeys.THEN);
      const strLast = localStorage.getItem(LocalStorageKeys.LAST);
      setRead(true);
      if (str && strThen && strLast) {
        const data = JSON.parse(str);
        const dataThen = JSON.parse(strThen);
        const dataLast = JSON.parse(strLast);
        if (data && dataThen && dataLast) {
          if (new Date().getTime() - dataLast <= oneHour) {
            Object.assign(TheUser, data);
            Object.assign(auth, dataThen);
            setUser(data);
            //TODO single page kaldırıldığında bu kaldırılması gerekiyor.
            if (window.location.pathname.includes('/login')) {
              navigate('/');
            }
          }
        }
      }
    } catch {}
  }, []);

  //TODO localStorage ve diger süreçleri düzenlemeliyiz
  const login = useCallback(async () => {
    const formResult = form.parse();
    if (formResult.success) {
      const result = await API.AUTH.login(formResult.data);

      if (result.ok) {
        const loginUser = result.data as LoginUser;

        Object.assign(TheUser, loginUser);
        Object.assign(auth, { basic: loginUser.jwt });

        localStorage.setItem(LocalStorageKeys.LAST, new Date().getTime() + '');
        localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(TheUser));
        localStorage.setItem(LocalStorageKeys.THEN, JSON.stringify(auth));
        setUser(loginUser);
        const path = singlePermissionUserRouter(loginUser);
        navigate(path);
      } else {
        notification.error({ message: 'Kullanıcı adı veya parola hatalı.Lütfen tekrar deneyiniz.' });
        form.setAllTouched();
      }
    }
  }, [form]);

  if (!read) {
    return <div className="w-screen h-screen flex items-center justify-center bg-slate-300 "></div>;
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-slate-300">
      <Card className="flex justify-center items-center h-screen" style={{ height: '650px', width: '600px' }}>
        <div className="flex justify-center items-center">
          <Image preview={false} src={require('../../../statics/americanlife.png')} />
        </div>
        <div
          className="flex flex-col gap-0.5 mt-4"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              login();
            }
          }}
        >
          <div className="text-center m-3 flex flex-col justify-center items-center"></div>

          <Form layout="vertical">
            <TextInput formItem={form.formItem('email')} formInput={form.input('email')} label={'Kullanıcı Adı:'} maxLength={50} />

            <Form.Item {...form.formItem('password')} label={'Parola:'}>
              <Input.Password {...form.input('password')} />
            </Form.Item>
            <Button block type="primary" className="my-4" onClick={login}>
              Giriş Yap
            </Button>
          </Form>

          <Divider plain>
            <span className="text-[#999999]">Parolanı mı unuttun?</span>
          </Divider>
          <Button type="link" block onClick={() => navigate('/account/forgot-password')}>
            Yeni Parola Belirlemek İstiyorum
          </Button>
        </div>
      </Card>
    </div>
  );
};

Login.path = '/*';
Login.title = 'Login';
Login.group = 'flat';
