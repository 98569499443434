import { DashboardOutlined, EditOutlined, LeftOutlined, LikeOutlined, RightOutlined } from '@ant-design/icons';
import { ComingSoon } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { AvailabilityCalendar, ClassDetailAttendances } from '@shared/models';
import { Card, Divider, Empty, Radio, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { convertDate } from '@shared/utils';
import * as API from '@shared/api';
import { useSearchParams } from 'react-router-dom';
dayjs.extend(isoWeek);

export const TeacherInspection = () => {
  useAccessableSeachParams();
  const [searchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(dayjs().startOf('isoWeek'));
  const [data, setData] = useState<ClassDetailAttendances[]>([]);

  const classId = searchParams.get('class_id');

  const endDate = dayjs(startDate).endOf('isoWeek');

  useEffect(() => {
    if (searchParams.size > 0) {
      const requestBody = {
        begin: startDate.toISOString(),
        end: endDate.toISOString(),
      };

      getAttendances(classId, requestBody);
    }
  }, [startDate, searchParams]);

  const handlePreviousWeek = () => {
    setStartDate((prevDate) => dayjs(prevDate).subtract(1, 'week').startOf('isoWeek'));
  };

  const handleNextWeek = () => {
    setStartDate((prevDate) => dayjs(prevDate).add(1, 'week').startOf('isoWeek'));
  };

  const getAttendances = async (classId: string | null, requestBody: { begin: string; end: string }) => {
    if (!classId) return;
    const response = await API.CLASS.getClassAttendances(classId, requestBody);
    setData(response.data);
  };

  return (
    <div>
      <div className="">
        <div>
          <div className="grid grid-rows-2 grid-cols-4">
            <Card bodyStyle={{ padding: '0' }} className="p-3 row-span-2 col-span-1 rounded-none">
              Öğrenci Listesi
            </Card>

            <div className="col-span-3 ... row-span-2">
              <Card bodyStyle={{ padding: '0' }} className="p-3 text-center rounded-none">
                <LeftOutlined style={{ color: '#1677FF' }} onClick={handlePreviousWeek} />
                <span className="ml-5 mr-5">
                  {convertDate(startDate, 'DD MMMM YYYY')} - {convertDate(endDate, 'DD MMMM YYYY')}
                </span>
                <RightOutlined style={{ color: '#1677FF' }} onClick={handleNextWeek} />
                <div className="grid grid-cols-7 mt-2 gap-3 text-center bg-white opacity-45">
                  <div>Pt</div>
                  <div>S</div>
                  <div>Ç</div>
                  <div>Pş</div>
                  <div>C</div>
                  <div>Ct</div>
                  <div>Pz</div>
                </div>
              </Card>
            </div>
          </div>
          {data && data.length > 0 ? (
            <div className="grid grid-rows-2 grid-cols-4 overflow-y-auto h-64">
              <div className="row-span-2 col-span-1">
                {data.map((student, studentIndex) => (
                  <Card bodyStyle={{ padding: '0' }} className=" p-3  rounded-none  bg-white" key={(Math.random() + studentIndex).toString(36).substring(7)}>
                    <div className="flex justify-between space-x-2">
                      <span className=" h-6">{student.name}</span>
                    </div>
                  </Card>
                ))}
              </div>

              <div className="col-span-3 row-span-1">
                {data.map((student, studentIndex) => (
                  <div key={(Math.random() + studentIndex).toString(36).substring(7)}>
                    <div className="grid grid-cols-7">
                      {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => (
                        <Card key={(Math.random() + dayIndex).toString(36).substring(7)} bodyStyle={{ padding: '0' }} className="p-3 rounded-none">
                          <div className="flex items-center justify-center space-x-2 h-6">
                            {(student.weekDays?.[dayIndex] || []).map((session, sessionIndex) => (
                              <Tooltip
                                key={(Math.random() + sessionIndex).toString(36).substring(7)}
                                title={`${convertDate(session.at)} tarihli ${session.title} seans aralığındaki derse ${session.attended ? 'katılmıştır.' : 'katılmamıştır.'}`}
                              >
                                <span
                                  className={`w-3 h-3 rounded-full ${
                                    session.attended ? 'bg-green-100 border-1 border-solid border-green-300' : 'bg-red-100 border-solid border-1 border-red-300'
                                  }`}
                                ></span>
                              </Tooltip>
                            ))}
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-full bg-white">
              <Empty style={{ marginBlock: '50px' }} className="h-40" image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Bu tarih aralığında yoklama bulunamadı'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TeacherInspection.roles = [Role.ADMIN];
TeacherInspection.layouts = [LayoutEnums.TEACHER];
