import { CreateBrandProduct, CreateBrandProps, CreateBrandUsers } from '@admin/admin_components';
import { BuildOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { Spinner } from '@shared/components';
import { Role } from '@shared/constants';
import { CreateBrandModel, CreateBrandUser, EnableProductModel, PaginationQuery } from '@shared/models';
import { Button, Steps, notification } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreateBrand = () => {
  const [brand, setBrand] = useState<CreateBrandModel>({ active: true });
  const [enableProductModel, setEnableProductModel] = useState<EnableProductModel>({ products: [] });
  const [enableProductTypes, setEnableProductTypes] = useState<string[]>([]);
  const [current, setCurrent] = useState(0);
  const [systemProduct, systemProductLoading, getSystemProduct] = API.PRODUCT.useFetch({ init: true });
  const [brandUsers, usersLoading, getBrandUsers] = API.BRAND.getBrandUsers({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 4 });

  const navigate = useNavigate();

  const next = async () => {
    if (current + 1 == 2) {
      await API.PRODUCT.enableBrandProduct(enableProductModel);
      const enableProductTypeList = enableProductModel.products?.map((p) => {
        const product = systemProduct?.data?.find((s) => {
          return s.id == p.productInitialId;
        });
        return product?.type;
      }) as string[];
      getBrandUsers(undefined, { ...paginationQuery, brandId: brand.id });
      if (enableProductTypeList && enableProductTypeList.length > 0) {
        setEnableProductTypes(enableProductTypeList);
      }
      setCurrent(current + 1);
    } else {
      setCurrent(current + 1);
    }
  };

  const saveBrandProps = async (brandProps: CreateBrandModel) => {
    const result = await API.BRAND.create({ ...brandProps, active: true });
    if (result.ok) {
      setBrand(result.data);
      setEnableProductModel({
        brandId: result.data.id,
        products: [],
      });
      notification.success({ message: 'Marka oluşturuldu' });
      next();
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const addBrandUser = async (brandUser: CreateBrandUser) => {
    await API.BRAND.createBrandUser({ ...brandUser, brandId: brand.id });
    getBrandUsers(undefined, { ...paginationQuery, brandId: brand.id });
  };

  const steps = [
    {
      title: 'Marka Oluştur',
      content: <CreateBrandProps brand={brand} onSave={saveBrandProps} />,
      description: '',
    },
    {
      title: 'Ürünleri Yönet',
      content:
        systemProductLoading && brand.id ? (
          <div></div>
        ) : (
          <CreateBrandProduct
            systemProducts={systemProduct?.data!}
            addBrandProduct={(p) => {
              const _enableProductModel = { ...enableProductModel };
              if (!_enableProductModel.products) {
                _enableProductModel.products = [];
              }
              _enableProductModel.products = [...p];
              setEnableProductModel(_enableProductModel);
            }}
          />
        ),
      description: '',
    },
    {
      title: 'Kullanıcı Ekle',
      content: (
        <>
          <Spinner loading={usersLoading} />
          <CreateBrandUsers
            brandUsers={brandUsers}
            enableProductTypes={enableProductTypes}
            addBrandUser={addBrandUser}
            onPaginationChange={(page) => getBrandUsers(undefined, { ...paginationQuery, page, brandId: brand.id })}
          />
        </>
      ),
      description: '',
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title, description: item.description }));

  return (
    //TODO çoklu dil ve navigasyon butonnlarını tek merkeze almaya çalış
    <div>
      <Steps current={current} items={items} />

      <div style={{ marginTop: 16, padding: 20 }}>{steps[current].content}</div>
      {current != 0 ? (
        <div className="flex justify-end" style={{ marginTop: 15 }}>
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Sonraki adım
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => navigate('/brands')}>
              Tamamla
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Geri Dön
            </Button>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

//TODO çoklu dil destegi
CreateBrand.path = 'create-brand';
CreateBrand.group = 'admin_brands';
CreateBrand.title = 'Marka Oluştur';
CreateBrand.roles = [Role.ADMIN];
