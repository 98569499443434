import { AuditOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { TextInput } from '@shared/components';
import { LayoutEnums, Role } from '@shared/constants';
import { useAccessableSeachParams, useForm } from '@shared/hooks';
import { BranchLmsCreditBalance, BranchLmsCreditOrder, CreateLmsCreditOrderProps, CreditBalanceType, PaginationQuery } from '@shared/models';
import { convertDate, sortFields } from '@shared/utils';
import { Button, Drawer, Form, InputNumber, Table, Tag } from 'antd';
import { TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { z } from 'zod';

type CreateBranchCreditDrawerProps = {
  isUpdateMode: boolean;
  seledtedData: BranchLmsCreditOrder;
  saveBranchLmsCreditOrder: (formValue) => void;
  open: boolean;
  closeDrawer: () => void;
};

export const CreateBranchCreditDrawer = ({ isUpdateMode, seledtedData, saveBranchLmsCreditOrder, open, closeDrawer }: CreateBranchCreditDrawerProps) => {
  useAccessableSeachParams();

  const CreateLmsBranchCreditOrderForm = z.object({
    count: z.number({ required_error: 'Kredi sayısı zorunlu alandır' }).min(1, 'Marka adı 1 karekterden fazla olmalı'),
  });
  const form = useForm<Partial<BranchLmsCreditOrder>, CreateLmsCreditOrderProps>(CreateLmsBranchCreditOrderForm, { ...seledtedData });

  useEffect(() => {
    if (isUpdateMode) {
      form.setValue(seledtedData);
    } else {
      form.setValue({});
    }
  }, [open]);

  return (
    <Drawer title={isUpdateMode ? 'LMS Kredi Talebini Güncelle' : 'LMS Kredi Talebi Oluştur'} onClose={() => closeDrawer()} open={open}>
      <Form layout="vertical">
        <div className="grid">
          <div className="flex flex-col gap-0">
            <Form.Item {...form.formItem('count')} label={'Kredi Talebi:'}>
              <InputNumber min={1} {...form.inputNumber('count')} />
            </Form.Item>

            <TextInput formItem={form.formItem('note')} formInput={form.input('note')} label={'Not:'} />
          </div>
          <div>
            <Button onClick={() => saveBranchLmsCreditOrder(form.value)} type="primary">
              {isUpdateMode ? 'Güncelle' : 'Oluştur'}
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

CreateBranchCreditDrawer.roles = [Role.ADMIN];
CreateBranchCreditDrawer.layouts = [LayoutEnums.BRANCHMANAGER, LayoutEnums.LMS];
