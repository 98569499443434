import { EnableProductModel, PaginateModel, Product, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';
import { useFetch } from '../hooks';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<PaginateModel<Product>>('/product', { method: 'GET', ...config }),
  update: async (product: Product) => request('/product', 'PUT', product),
  enableBrandProduct: async (enableProductModel: EnableProductModel) => request('/product/brand', 'POST', enableProductModel),
  getEnableBrandProduct: (config: UseFetchOptions) => useFetch<Product[]>('/brand/product', { method: 'GET', ...config }),
  getEnableBranchProduct: (config: UseFetchOptions) => useFetch<Product[]>('/branch/brand/product', { method: 'GET', ...config }),
  getEnableBrandProductDetail: (product: string, config: UseFetchOptions) => useFetch<Product>('/brand/product/' + product, { method: 'GET', ...config }),
  getEnableProductBrand: (brandId: string, config: UseFetchOptions) => useFetch<Product[]>('/product/brand/' + brandId, { method: 'GET', ...config }),

  //save: async (product: Product) => request('/products/add', 'POST', product),
};
