/*
"branch.lms.seance.create",
      "branch.lms.seance.delete",
      "branch.lms.seance.update",
      "branch.lms.seance.paginate",
      "branch.lms.class-room.create",
      "branch.lms.class-room.delete",
      "branch.lms.class-room.update",
      "branch.lms.class-room.paginate"
*/

export const ProductPermissionValues = {
  brand: {
    Calendar: {
      VIEW: 'brand.calendar.view',
      DELETE: 'brand.calendar.delete',
      CREATE: 'brand.calendar.create',
      UPDATE: 'brand.calendar.update',
    },
    User: {
      VIEW: 'brand.user.view',
      DELETE: 'brand.user.delete',
      CREATE: 'brand.user.create',
      UPDATE: 'brand.user.update',
    },
    Branch: {
      CREATE: 'branch.create',
      DELETE: 'branch.delete',
      VIEW: 'branch.view',
      UPDATE: 'branch.update',
    },
    Product: {
      VIEW: 'brand.product.view',
    },
    CDN: {
      UPLOAD: 'brand.cdn.upload',
    },
    LMS: {
      Credit: {
        MANAGE: 'branch.lms.credit.manage',
        VIEW: 'branch.lms.credit.view',
      },
      Definition: {
        CREATE: 'brand.lms.definition.create',
        DELETE: 'brand.lms.definition.delete',
        UPDATE: 'brand.lms.definition.update',
        VIEW: 'brand.lms.definition.view',
      },
      ContentVersion: {
        VIEW: 'brand.lms.content.version.view',
        CREATE: 'brand.lms.content.version.create',
        DELETE: 'brand.lms.content.version.delete',
        UPDATE: 'brand.lms.content.version.update',
        APPROVE: 'brand.lms.content.version.approve',
        PUBLISH: 'brand.lms.content.version.publish',
      },
      Exercise: {
        CREATE: 'brand.lms.exercise.create',
        DELETE: 'brand.lms.exercise.delete',
        UPDATE: 'brand.lms.exercise.update',
        VIEW: 'brand.lms.exercise.view',
        APPROVE: 'brand.lms.exercise.approve',
      },
    },
  },
  branch: {
    Calendar: {
      VIEW: 'branch.calendar.view',
      DELETE: 'branch.calendar.delete',
      CREATE: 'branch.calendar.create',
      UPDATE: 'branch.calendar.update',
    },
    User: {
      VIEW: 'branch.user.view',
      DELETE: 'branch.user.delete',
      CREATE: 'branch.user.create',
      UPDATE: 'branch.user.update',
    },
    Product: {
      VIEW: 'branch.product.view',
    },
    LMS: {
      Credit: {
        MANAGE: 'branch.lms.credit.manage',
        VIEW: 'branch.lms.credit.view',
      },
      Class: {
        CREATE: 'branch.lms.class.create',
        DELETE: 'branch.lms.class.delete',
        UPDATE: 'branch.lms.class.update',
        VIEW: 'branch.lms.class.view',
        ADD_STUDENT: 'branch.lms.class.add.student',
        REMOVE_STUDENT: 'branch.lms.class.remove.student',
      },
      ClassPlanning: {
        CREATE: 'branch.lms.class.planning.create',
        DELETE: 'branch.lms.class.planning.delete',
        UPDATE: 'branch.lms.class.planning.update',
        VIEW: 'branch.lms.class.planning.view',
      },
      Enrollment: {
        CREATE: 'branch.lms.enrollment.create',
        DELETE: 'branch.lms.enrollment.delete',
        UPDATE: 'branch.lms.enrollment.update',
        VIEW: 'branch.lms.enrollment.view',
      },
      Teacher: {
        CREATE: 'branch.lms.teacher.create',
        DELETE: 'branch.lms.teacher.delete',
        UPDATE: 'branch.lms.teacher.update',
        VIEW: 'branch.lms.teacher.view',
      },
      Definition: {
        CREATE: 'branch.lms.definition.create',
        DELETE: 'branch.lms.definition.delete',
        UPDATE: 'branch.lms.definition.update',
        VIEW: 'branch.lms.definition.view',
      },
      Seance: {
        CREATE: 'branch.lms.seance.create',
        DELETE: 'branch.lms.seance.delete',
        UPDATE: 'branch.lms.seance.update',
        VIEW: 'branch.lms.seance.view',
      },
      ClassRoom: {
        CREATE: 'branch.lms.class.room.create',
        DELETE: 'branch.lms.class.room.delete',
        UPDATE: 'branch.lms.class.room.update',
        VIEW: 'branch.lms.class.room.view',
      },
      ContentVersion: {
        VIEW: 'branch.lms.content.version.view',
      },
    },
  },
};
