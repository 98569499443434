import * as API from '@shared/api';
import { BrandOrBranch, LayoutColors, LayoutEnums, ProductsEnum, Role, productColors } from '@shared/constants';
import { useAccessableContext, usePlatformLayoutContext, useUserContext } from '@shared/contexts';
import i18n from '@shared/localization/i18n';
import { AccessableBranch, BranchPermissionAndSubFeatures, PaginateModel, PermissionsModel } from '@shared/models';
import { latinize } from '@shared/utils';
import { Divider, Select } from 'antd';
import { useEffect, useState } from 'react';
import { MdLabelOutline } from 'react-icons/md';
import { PiBuildingOffice, PiChalkboardTeacherLight, PiUserLight } from 'react-icons/pi';

export const AppNavigation = () => {
  const { user } = useUserContext();
  const { brand, branch } = useAccessableContext();
  const { selectedLayout } = usePlatformLayoutContext();
  const [brandsResult, brandsLoading, getBrands] = API.BRAND.accessableBrands({ init: true, initParams: { limit: 99999 } });

  const [selectedBrandPermissions, setSelectedBrandPermissions] = useState<PermissionsModel>();
  const [selectedBrandId, setSelectedBrandId] = useState<string>();

  const [selectedBranchPermissionsAndFeature, setSelectedBranchPermissionsAndFeature] = useState<BranchPermissionAndSubFeatures>();
  const [selectedBranchId, setSelectedBranchId] = useState<string>();

  const [productAndSubProducts, setProductAndSubProducts] = useState<[{ product: string; subProducts: LayoutEnums[] }]>();

  const [brandSelecList, setBrandSelecList] = useState<{ value: string; label: string }[]>([]);
  const [branchSelecList, setBranchSelecList] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (brandsResult && user) {
      if (brand) {
        setSelectedBrandId(brand.id!);
        setSelectedBrandPermissions(brand.permissions);
        getAccessableBranchWithBrandId(brand.id!, false);
      }
      if (branch) {
        setSelectedBranchId(branch.id!);
        setSelectedBranchPermissionsAndFeature(user.features?.branch[branch.id!]);
        localStorage.setItem(BrandOrBranch.BRANCH, branch?.id!);
      }
    }
  }, [brandsResult, user, brand, branch]);

  useEffect(() => {
    if (!brandsResult) {
      return;
    }

    const brandId = localStorage.getItem(BrandOrBranch.BRAND);
    const branchId = localStorage.getItem(BrandOrBranch.BRANCH);
    if (brandId) {
      const hasBrand = user.features?.brand.hasOwnProperty(brandId);
      if (hasBrand) {
        //TODO lazım mı gerçekten?
        //getAccessableBranchWithBrandId(brandId);
      } else {
        localStorage.removeItem(BrandOrBranch.BRAND);
      }
    }
    const hasBranch = user.features?.branch.hasOwnProperty(branchId!);
    if (hasBranch) {
      setSelectedBranchId(branchId!);
    } else {
      localStorage.removeItem(BrandOrBranch.BRANCH);
    }

    if (brandsResult && user) {
      const _ = brandsResult.data?.map((b) => {
        return {
          value: b.id!,
          label: b.name!,
        };
      });
      setBrandSelecList(_ ?? []);
    }
  }, [brandsResult, user]);

  useEffect(() => {
    if (selectedBrandId && user) {
      setSelectedBrandPermissions(user.features?.brand[selectedBrandId].permissions!);
    }
  }, [selectedBrandId, user]);

  useEffect(() => {
    if (selectedBrandId && user && selectedBranchId) {
      setSelectedBranchPermissionsAndFeature(user.features?.branch[selectedBranchId]);
    } else {
      setSelectedBranchPermissionsAndFeature(undefined);
    }
  }, [selectedBranchId, selectedBrandId, user]);

  useEffect(() => {
    clearSubProductsValue();
    //TODO this method will chatch user role e.g. isStudent, isTeacher,
    if (selectedBrandPermissions && selectedBranchPermissionsAndFeature) {
      const subProducts: LayoutEnums[] = [];

      const { isStudent, isTeacher, isObserver, permissions } = selectedBranchPermissionsAndFeature;
      if (isStudent || isTeacher || isObserver) {
        if (isStudent) {
          subProducts.push(LayoutEnums.STUDENT);
        }
        if (isTeacher) {
          subProducts.push(LayoutEnums.TEACHER);
        }
        if (isObserver) {
          subProducts.push(LayoutEnums.OBSERVER);
        }
      }
      if (subProducts.length > 0) {
        setProductAndSubProducts([
          {
            product: ProductsEnum.LMS,
            subProducts,
          },
        ]);
      }
    }
  }, [selectedBrandPermissions, selectedBranchPermissionsAndFeature]);

  const clearSubProductsValue = () => {
    setProductAndSubProducts(undefined);
  };

  const onChangeUrlParams = (layout: LayoutEnums) => {
    const params = new URLSearchParams({
      layout,
    });
    if (selectedBrandId) {
      params.append('brandId', selectedBrandId);
    }
    if (selectedBranchId) {
      params.append('branchId', selectedBranchId);
    }
    location.replace('/' + layout + '/dashboard?' + params);
  };

  const getAccessableBranchWithBrandId = async (brandId: string, reset: boolean) => {
    localStorage.removeItem(BrandOrBranch.BRAND);
    localStorage.setItem(BrandOrBranch.BRAND, brandId);
    if (brandId != selectedBrandId) {
      setSelectedBrandPermissions(undefined);
    }
    setSelectedBrandId(brandId);
    const result = await API.BRANCH.accessableBranchesWithBrandId(brandId, 99999);
    if (result.ok) {
      const { data } = result.data as PaginateModel<AccessableBranch>;
      const _ = data?.map((b) => {
        return {
          value: b.id!,
          label: b.name!,
        };
      });
      setBranchSelecList(_ ?? []);

      if (_) {
        if (_?.length == 0) {
          setSelectedBranchId(undefined);
        }

        if (reset) {
          setSelectedBranchId(undefined);
        }
      }
    }
  };

  const setSelectedBrandIdAndLocalStorage = (branchId: string) => {
    localStorage.removeItem(BrandOrBranch.BRANCH);
    localStorage.setItem(BrandOrBranch.BRANCH, branchId);
    setSelectedBranchId(branchId);
  };

  const renderProductHeader = (title: string, color: string) => {
    return (
      <div>
        <div className="flex flex-row gap-x-2 mt-2 mb-2 content-center items-center">
          <div className="border border-solid h-6 w-6 flex text-center rounded-sm items-center content-center justify-center ml-2" style={{ borderColor: color }}>
            <span className={'text-lg font-extrabold'} style={{ color: color }}>
              {title.substring(0, 1)}
            </span>
          </div>
          <span className="text-sm">{title}</span>
        </div>
        <Divider orientationMargin={0} className="mt-1 mb-2" style={{ backgroundColor: color }} />
      </div>
    );
  };

  //TODO icon seti eklendiğinde buradan map karşılıklarına yaz
  const IconComponent = ({ title, color }) => {
    if (title == 'teacher') {
      return <PiChalkboardTeacherLight color={color} size={22} />;
    } else if (title == 'student') {
      return <PiUserLight size={22} color={color} />;
    } else if ((title = 'observer')) {
      return <PiUserLight size={22} color={color} />;
    } else {
      return <PiUserLight size={22} color={color} />;
    }
  };

  const renderProductSubPermissions = (title: LayoutEnums, selectedColor: string) => {
    return (
      <div
        key={title}
        onClick={() => {
          onChangeUrlParams(title);
        }}
        className="flex flex-col border-solid rounded-lg text-center content-center items-center p-3 gap-y-1"
        style={{
          borderColor: selectedLayout == title ? selectedColor : 'rgba(127, 127, 127, 0.04)',
          backgroundColor: selectedLayout == title ? selectedColor + '20' : 'rgba(127, 127, 127, 0.04)',
        }}
      >
        <IconComponent title={title} color={selectedLayout == title ? selectedColor : ''} />
        <Divider className="m-2" style={{ backgroundColor: selectedLayout == title ? '#FFBB96' : 'rgba(0, 0, 0, 0.45)' }} />
        <span style={{ color: selectedLayout == title ? selectedColor : 'rgba(0, 0, 0, 0.45)' }} className="text-xs">
          {i18n.t('app.subPermissions.' + title)}
        </span>
      </div>
    );
  };

  return (
    <div>
      <div className={branchSelecList && branchSelecList.length > 0 ? 'grid grid-cols-2 mt-3 gap-x-3' : 'mt-3'}>
        <Select
          showSearch
          placeholder="Marka seçiniz"
          style={{ width: '100%' }}
          loading={brandsLoading}
          value={selectedBrandId}
          filterOption={(input, option) => latinize(option?.label ?? '').includes(latinize(input))}
          onChange={(val) => getAccessableBranchWithBrandId(val, true)}
          onSearch={(v) => {}}
          options={brandSelecList}
        />
        {branchSelecList && branchSelecList.length > 0 && (
          <Select
            showSearch
            placeholder="Şube seçiniz"
            style={{ width: '100%' }}
            value={selectedBranchId}
            filterOption={(input, option) => latinize(option?.label ?? '').includes(latinize(input))}
            disabled={!selectedBrandId}
            onChange={setSelectedBrandIdAndLocalStorage}
            onSearch={(v) => {}}
            options={branchSelecList}
          />
        )}
      </div>
      {selectedBrandPermissions && (
        <div className={'grid grid-cols-2 mt-3 gap-x-3'}>
          {
            //TODO this controller will be just for admin
            (selectedBrandPermissions.role == 'ADMIN' || (selectedBrandPermissions.role == 'USER' && selectedBrandPermissions.values?.length! > 0)) && (
              <div
                onClick={() => {
                  onChangeUrlParams(LayoutEnums.BRANDMANAGER);
                }}
                className="cursor-pointer flex flex-row h-10 items-center space-x-2 border border-[rgba(0,0,0,0.15)] border-solid rounded-lg"
                style={{
                  backgroundColor: selectedLayout === LayoutEnums.BRANDMANAGER ? LayoutColors[LayoutEnums.BRANDMANAGER].backgroundColor : '',
                  color: selectedLayout === LayoutEnums.BRANDMANAGER ? LayoutColors[LayoutEnums.BRANDMANAGER].color : '',
                }}
              >
                <div className="h-10 w-10 items-center content-center justify-center flex">
                  <MdLabelOutline size={17} color={LayoutColors[LayoutEnums.BRANDMANAGER].color} />
                </div>
                <span className="text-sm">{selectedBrandPermissions.role == Role.ADMIN ? 'Marka Yöneticisi' : 'Marka Kullanıcısı'}</span>
              </div>
            )
          }
          {
            //TODO this controller will be just for admin
            selectedBranchPermissionsAndFeature?.permissions && (
              <div
                onClick={() => {
                  onChangeUrlParams(LayoutEnums.BRANCHMANAGER);
                }}
                className="cursor-pointer flex flex-row h-10 items-center space-x-2 border border-[rgba(0,0,0,0.15)] border-solid rounded-lg"
                style={{
                  backgroundColor: selectedLayout === LayoutEnums.BRANCHMANAGER ? LayoutColors[LayoutEnums.BRANCHMANAGER].backgroundColor : '',
                  color: selectedLayout === LayoutEnums.BRANCHMANAGER ? LayoutColors[LayoutEnums.BRANCHMANAGER].color : '',
                }}
              >
                <div className=" h-10 w-10 items-center content-center justify-center flex">
                  <PiBuildingOffice size={17} color={LayoutColors[LayoutEnums.BRANCHMANAGER].color} />
                </div>
                <span className="text-sm">{selectedBranchPermissionsAndFeature?.permissions?.role == Role.ADMIN ? 'Şube Yöneticisi' : 'Şube Kullanıcısı'}</span>
              </div>
            )
          }
        </div>
      )}

      <div className="grid grid-cols-2 gap-x-3">
        {productAndSubProducts &&
          productAndSubProducts.map((p, index) => (
            <div key={p + '' + index} className="flex flex-col mt-3">
              {renderProductHeader(p.product, productColors[p.product][productColors[p.product].length - 1])}
              <div className="grid grid-cols-3 gap-2 mt-1 cursor-pointer">
                {p.subProducts.map((s, index) => renderProductSubPermissions(s, productColors[p.product][productColors[p.product].length - 1]))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
