import { DownOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ClassStatus } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import i18n from '@shared/localization/i18n';
import { CascaderOption, PaginationQuery } from '@shared/models';
import { createLevelCascader } from '@shared/utils';
import { Badge, Button, Cascader, Space } from 'antd';
import { useEffect, useState } from 'react';
import { TbFilterX } from 'react-icons/tb';

type ClassFilterProps = {
  onFilter: (key: any, value: any, reset?: boolean) => void;
  filters: PaginationQuery;
};

export const ClassFilter = ({ onFilter, filters }: ClassFilterProps) => {
  useAccessableSeachParams();
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.BRANCH.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });

  const [selectedKur, setSelectedKur] = useState();
  const [cascaderKey, setCascaderKey] = useState(Date.now());

  const [abilityLevelOptions, setAbilityLevelOptions] = useState<CascaderOption[]>([]);

  useEffect(() => {
    if (levels && levels.data) {
      const groupedLevels = createLevelCascader(levels.data);
      setAbilityLevelOptions(groupedLevels ?? []);
    } else {
      setAbilityLevelOptions([]);
    }
  }, [levels]);

  const statuOptions = Object.keys(ClassStatus).map((key) => ({
    label: i18n.t('app.classStatus.' + key),
    value: ClassStatus[key],
  }));

  const anyFilterActive = Object.entries(filters).some(([key, filter]) => (Array.isArray(filter) && filter.length > 0) || (typeof filter === 'string' && filter.trim() !== ''));

  const onKurChange = (value) => {
    //MultiCascader'dan Tüm ağacın(field,theme,type,level) id'leri geliyor, levelid 3.index olduğu için onları tutuyoruz.
    const selectedLevels = value.map((v) => v[3]);
    onFilter('levelIds', selectedLevels);
  };

  return (
    <div className="flex w-1/3 items-center">
      <div className="flex w-1/2 justify-around">
        <Badge style={{ lineHeight: '0' }} dot={filters.levelIds!.length > 0} className={filters.levelIds!.length > 0 ? 'bg-[#E6F4FF]' : ''}>
          <Cascader
            key={cascaderKey}
            value={selectedKur}
            options={abilityLevelOptions}
            expandTrigger="hover"
            showCheckedStrategy={Cascader.SHOW_CHILD}
            multiple
            onChange={(value) => onKurChange(value)}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Kur
                <DownOutlined />
              </Space>
            </a>
          </Cascader>
        </Badge>

        <Badge style={{ lineHeight: '0' }} dot={filters.statuses!.length > 0} className={filters.statuses!.length > 0 ? 'bg-[#E6F4FF]' : ''}>
          <Cascader
            value={filters.statuses}
            options={statuOptions}
            expandTrigger="hover"
            multiple
            onChange={(value) => {
              onFilter('statuses', value);
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Statü
                <DownOutlined />
              </Space>
            </a>
          </Cascader>
        </Badge>
      </div>

      {anyFilterActive && (
        <div className="">
          <Button
            onClick={() => {
              setCascaderKey(Date.now()); //Cascader'ı butonla resetlemek için key'ini değiştiriyoruz.
              onFilter(null, null, true);
            }}
            type="text"
            size="small"
            icon={<TbFilterX />}
          >
            Filtreyi Kaldır
          </Button>
        </div>
      )}
    </div>
  );
};
