import { useFetch } from '@shared/hooks';
import { PaginateModel, UseFetchOptions } from '@shared/models';
import {
  BrandHomeworkDefinition,
  ClassHomeworkProps,
  CreateBrandHomeworkDefinitionProps,
  HomeworkTeacherCreate,
  HomeworkTeacherDefinition,
  StudentHomeworkProps,
} from '@shared/models/homework';
import { request } from '@shared/utils';

export default {
  //Teacher Homework Definitions
  createHomeworkDefinition: (data: HomeworkTeacherDefinition) => request('/homework-definition/teacher', 'POST', data),
  updateHomeworkDefinition: (data: HomeworkTeacherDefinition, id: string) => request('/homework-definition/teacher/' + id, 'PUT', data),

  getPaginationDefinition: (config: UseFetchOptions) => useFetch<PaginateModel<HomeworkTeacherDefinition>>('/homework-definition/teacher', { method: 'GET', ...config }),
  getBrandPaginationDefinition: (config: UseFetchOptions) => useFetch<PaginateModel<HomeworkTeacherDefinition>>('/homework-definition/teacher/brand', { method: 'GET', ...config }),

  deleteHomeworkDefinition: (id: string) => request('/homework-definition/teacher/' + id, 'DELETE'),
  //Teacher Class Homework
  paginateClassHomeworkByRequestedTeacher: (config: UseFetchOptions) => useFetch<PaginateModel<ClassHomeworkProps>>('/teacher/homework', { method: 'GET', ...config }),
  paginateClassHomeworkByRequestedTeacherClass: (config: UseFetchOptions, classId: string) =>
    useFetch<PaginateModel<ClassHomeworkProps>>('/class/' + classId + '/homework', { method: 'GET', ...config }),
  createHomeworkTeacher: (data: HomeworkTeacherCreate) => request('/teacher/homework', 'POST', data),
  deleteHomeworkTeacher: (id: string) => request('/teacher/homework/' + id, 'DELETE'),
  updateHomeworkTeacher: (id: string, data: ClassHomeworkProps) => request('/teacher/homework/' + id, 'PUT', data),

  //Student Homework
  paginateClassHomeworkByRequestedStudent: (config: UseFetchOptions) => useFetch<PaginateModel<ClassHomeworkProps>>('/student/homework', { method: 'GET', ...config }),
  paginateSubmitedHomeworkByRequestedStudent: (config: UseFetchOptions) => useFetch<PaginateModel<StudentHomeworkProps>>('/student/homework/submits', { method: 'GET', ...config }),

  listClassHomeworkDetail: (id: string) => request('/teacher/homework/' + id + '/detail', 'GET'),
  studentEvulate: (homeworkId: string, studentId: string, data: StudentHomeworkProps) => request('/teacher/homework/' + homeworkId + '/student/' + studentId, 'PUT', data),
  studentSubmit: (classHomeworkId: string, data: StudentHomeworkProps) => request('/student/homework/' + classHomeworkId, 'POST', data),
  deleteStudentHomework: (studentHomeworkId: string) => request('/student/homework/' + studentHomeworkId, 'DELETE'),

  //BRAND

  BRAND: {
    createHomeworkDefinition: (data: CreateBrandHomeworkDefinitionProps) => request('/lms/brand-homework-definition', 'POST', data),
    updateHomeworkDefinition: (data: CreateBrandHomeworkDefinitionProps, id: string) => request('/lms/brand-homework-definition/' + id, 'PUT', data),
    getPaginationDefinition: (config: UseFetchOptions) => useFetch<PaginateModel<BrandHomeworkDefinition>>('/lms/brand-homework-definition', { method: 'GET', ...config }),
    deleteHomeworkDefinition: (id: string) => request('/lms/brand-homework-definition/' + id, 'DELETE'),
  },
};
