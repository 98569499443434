import { SortableContext, rectSortingStrategy, useSortable, sortableKeyboardCoordinates, arrayMove } from '@dnd-kit/sortable';
import { DndContext, PointerSensor, KeyboardSensor, useSensor, useSensors } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { CourseTopicModel } from '@shared/models';
import { useEffect, useState } from 'react';
import { Button, Card, Dropdown, Popconfirm, Spin } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';

type CourseTopicListComponentProps = {
  topics: CourseTopicModel[];
  selected?: CourseTopicModel;
  onOrder: (topics: CourseTopicModel[]) => void;
  onSelect: (topic: CourseTopicModel) => void;
  onRemove: (topicId: string) => void;
  onEdit: (topicId: string) => void;
};
export const CourseTopicListComponent = ({ topics, selected, onOrder, onSelect, onRemove, onEdit }: CourseTopicListComponentProps) => {
  const [items, setItems] = useState<CourseTopicModel[]>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [draggedItemId, setDraggedItemId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setItems(topics);
  }, [topics]);

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }

    if (active.id == over.id) {
      return;
    }

    /*const activeIndex = items.findIndex((it) => it.id == active.id);
    const overIndex = items.findIndex((it) => it.id == over.id);
    const activeObj = {
      ...items[activeIndex],
      order: overIndex,
    };
    const overObj = {
      ...items[overIndex],
      order: activeIndex,
    };
    items[activeIndex] = activeObj;
    items[overIndex] = overObj;*/

    const _item = arrayMove(
      items,
      items.findIndex((it) => it.id == active.id),
      items.findIndex((it) => it.id == over.id)
    ).map((e, i) => {
      return {
        ...e,
        order: i,
      }
    });
    setItems(_item);
    onOrder(_item);

    setDraggedItemId(active.id);
    setTimeout(() => setDraggedItemId(null), 1000);
  };

  const SortableItem = (item) => {
    const { id, name, type, description } = item;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const isDragging = draggedItemId === id;

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      backgroundColor: isDragging ? '#e6f4ff' : 'transparent',
      animation: isDragging ? 'fadeOut 1s ease-out' : 'none',
    };

    return (
      <Card
        ref={setNodeRef}
        key={item.id}
        size="small"

        hoverable
        style={{ ...style }}
        {...attributes}
        {...listeners}
        onClick={() => {
          onSelect(item);
        }}
        className={
          selected && selected.id == id
            ? 'cursor-ew-resize flex flex-col min-w-max rounded-md border-2 border-solid border-[#91CAFF]  bg-[#E6F4FF] m-2 hover:bg-gray-100'
            : 'cursor-ew-resize flex flex-col min-w-max rounded-md border border-solid  m-2 hover:bg-gray-100'
        }
      >
        <Card.Meta
          title={
            <div className="flex flex-row justify-between content-between w-full items-center space-x-2">
              <span className={selected && selected.id == id ? 'text-[#4096FF] text-sm' : 'text-sm'}>{name}</span>
              <Button type='dashed' className='bg-red-50' size='small' loading={deleteLoading} onClick={() => onRemove(id)} icon={<DeleteOutlined className='text-red-500' />} />
            </div>
          }
          description={
            deleteLoading && selected && selected.id == id ? <Spin /> :
              <div className='flex flex-col space-y-2'>
                <div className={selected && selected.id == id ? 'text-[#4096FF] text-xs' : 'text-xs'}>{description}</div>
                <span className={selected && selected.id == id ? 'text-[#4096FF] text-xs' : 'text-xs'}>Type: {type}</span>

              </div>
          }
        />
      </Card>
    );
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext
        items={items.map((t) => {
          return { id: t.id! };
        })}
        strategy={rectSortingStrategy}
      >
        {items.map((item) => (
          <SortableItem key={item.id} id={item.id} name={item.name} type={item.type} description={item.description} />
        ))}
      </SortableContext>
    </DndContext>
  );
};
