import * as API from '@shared/api';
import { CalendarModel } from '@shared/components';
import { ClassCalendarEntryStatus, ClassCalendarEntryType, StudentCalendarType } from '@shared/constants';
import { convertDate } from '@shared/utils';
import { notification, Popconfirm, Tag, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { LuCalendarClock } from 'react-icons/lu';

export type ClassCalendarModel = CalendarModel & {
  type: ClassCalendarEntryType;
  status: ClassCalendarEntryStatus;
};

export type StudentCalendarModel = CalendarModel & {
  type: StudentCalendarType;
  status: ClassCalendarEntryStatus;
  className: string;
};

export const ClassCalendarRenderer = (classId, classClandarRefreshForDate: (date: Dayjs) => void) => {
  return (dayEvents: ClassCalendarModel[]) => {
    return (
      <div>
        {dayEvents?.map((event) => (
          <>
            {event.type == ClassCalendarEntryType.IN_CLASS &&
              CalendarInClassEntryRenderer(event, {
                postpone: async () => {
                  const response = await API.CLASS.classCalendarPostpone(classId, event.id);
                  if (response.ok) {
                    classClandarRefreshForDate?.(dayjs(event.date));
                    notification.success({ message: convertDate(event.date) + ' tarihinde sınıf için ders işlenmeyecek' });
                  }
                },
                revertPostpone: async () => {
                  const response = await API.CLASS.classCalendarRevertPostpone(classId, event.id);
                  if (response.ok) {
                    classClandarRefreshForDate?.(dayjs(event.date));
                    notification.success({ message: convertDate(event.date) + ' tarihinde sınıf için ders işlenecek' });
                  }
                },
              })}

            {event.type == ClassCalendarEntryType.EVENT && CalendarEventEntryRenderer(event)}
            {event.type == ClassCalendarEntryType.HOMEWORK && CalendarHomeworkEntryRenderer(event)}
          </>
        ))}
      </div>
    );
  };
};

export const StudentCalendarRenderer = () => {
  return (dayEvents: StudentCalendarModel[]) => {
    return (
      <div>
        {dayEvents?.map((event) => (
          <>
            {event.type == StudentCalendarType.IN_CLASS && CalendarInClassEntryForNonClassLayoutRenderer(event)}
            {event.type == StudentCalendarType.BRANCH_ITEM && CalendarEventEntryRenderer(event)}
            {event.type == StudentCalendarType.HOMEWORK && CalendarHomeworkEntryRenderer(event)}
          </>
        ))}
      </div>
    );
  };
};

//

export const CalendarEventEntryRenderer = (event: CalendarModel) => {
  return (
    <div>
      <div className="flex items-center">
        <Tooltip title={event.title}>
          <Tag className="whitespace-nowrap overflow-hidden text-ellipsis" color="magenta" key={event.id}>
            {event.isHoliday ? 'Tatil-' : ''}
            {event.title}
          </Tag>
        </Tooltip>
      </div>
    </div>
  );
};

export const CalendarHomeworkEntryRenderer = (event: CalendarModel & { className?: string }) => {
  const title = (event.className ? event.className + ' / ' : '') + event.title;
  return (
    <div>
      <div className="flex items-center">
        <Tooltip title={title}>
          <Tag className="whitespace-nowrap overflow-hidden text-ellipsis" color="gold" key={event.id}>
            {title}
          </Tag>
        </Tooltip>
      </div>
    </div>
  );
};
export const CalendarInClassEntryRenderer = (
  event: CalendarModel,
  actions: {
    postpone?: () => Promise<void>;
    revertPostpone?: () => Promise<void>;
  }
) => {
  let color = 'purple';

  if (event.status == ClassCalendarEntryStatus.POSTPONED) {
    color = 'rgb(168 168 168)';
  } else if (event.status == ClassCalendarEntryStatus.STARTED) {
    color = 'blue';
  }

  return (
    <div>
      <div className="flex items-center">
        <Tooltip title={event.title}>
          <Tag className="w-11/12 whitespace-nowrap overflow-hidden text-ellipsis" color={color} key={event.id}>
            {event.title}
          </Tag>
        </Tooltip>

        {actions.postpone && event.status == ClassCalendarEntryStatus.WAITING && (
          <Popconfirm
            title={<span>{convertDate(event.date)} tarihinde bulunan dersleri iptal etmek istediğinize emin misiniz? </span>}
            onConfirm={actions.postpone}
            okText="Evet"
            cancelText="İptal"
          >
            <Tooltip title="Ders İşlenmeyecek Olarak İşaretle" placement="bottom">
              <LuCalendarClock color="gray" />
            </Tooltip>
          </Popconfirm>
        )}

        {actions.revertPostpone && event.status == ClassCalendarEntryStatus.POSTPONED && (
          <Popconfirm
            title={<span>{convertDate(event.date)} tarihinde bulunan iptal edilmiş dersleri, ders işlenecek olarak işaretlemek istediğinizden emin misiniz? </span>}
            onConfirm={actions.revertPostpone}
            okText="Evet"
            cancelText="İptal"
          >
            <Tooltip title="Ders İşlenecek Olarak İşaretle" placement="bottom">
              <LuCalendarClock color="gray" />
            </Tooltip>
          </Popconfirm>
        )}
      </div>
    </div>
  );
};

export const CalendarInClassEntryForNonClassLayoutRenderer = (event: StudentCalendarModel) => {
  let color = 'purple';

  if (event.status == ClassCalendarEntryStatus.POSTPONED) {
    color = 'rgb(168 168 168)';
  } else if (event.status == ClassCalendarEntryStatus.STARTED) {
    color = 'blue';
  }

  return (
    <div>
      <div className="flex items-center">
        <Tooltip title={event.className + ' / ' + event.title}>
          <Tag className="overflow-hidden text-ellipsis" color={color} key={event.id}>
            {event.className + ' / ' + event.title}
          </Tag>
        </Tooltip>
      </div>
    </div>
  );
};
