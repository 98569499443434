import { InfoCircleTwoTone, MoonOutlined } from '@ant-design/icons';
import { Avatar, Badge, Popover, Tooltip } from 'antd';

export const ClassTableInfo = () => {
  const BadgeWithAvatarSize = ({ status }) => {
    return (
      <div className="w-5 h-5 flex items-center justify-center rounded border border-solid border-[#f5f5f5]">
        <Badge status={status} />
      </div>
    );
  };

  const legendContent = (
    <div className="flex flex-col gap-3">
      <div>
        <Avatar size="small" shape="square" className="border-dashed border-[#faad14] rounded bg-[#fffbe6]" />

        <span className="ml-2">Aktif-Henüz başlatılmamış</span>
      </div>
      <div>
        <Avatar size="small" shape="square" className="border-solid border-[#52c41a] rounded bg-[#f6ffed]" />
        <span className="ml-2">Aktif</span>
      </div>
      <div>
        <Avatar size="small" shape="square" className="border-solid border-[#69b1ff] rounded bg-[#e6f4ff]" />
        <span className="ml-2">Tamamlandı</span>
      </div>
      <div>
        <Avatar size="small" shape="square" className="border-solid border-[#00000040] rounded bg-[#F5F5F5]" />
        <span className="ml-2">İptal edildi</span>
      </div>
    </div>
  );

  return (
    <div className="flex mb-1 justify-end">
      <Popover content={legendContent} placement="left" trigger="click">
        <InfoCircleTwoTone className="text-2xl" />
      </Popover>
    </div>
  );
};
