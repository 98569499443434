import { useFetch } from '@shared/hooks';
import { AdminCalendarModel, BranchCalendarModel, BrandCalendarModel, PaginateModel, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  //Admin
  getAdminCalendar: (config: UseFetchOptions) => useFetch<AdminCalendarModel[]>('/system-calendar', { method: 'GET', ...config }),
  deleteAdminHoliday: (id: string) => request('/system-calendar/' + id, 'DELETE'),

  //Brand
  getBrandCalendar: (config: UseFetchOptions) => useFetch<BrandCalendarModel[]>('/brand-calendar', { method: 'GET', ...config }),
  getBrandExcludedCalendar: (config: UseFetchOptions) => useFetch<PaginateModel<BrandCalendarModel>>('/brand-calendar/exclude', { method: 'GET', ...config }),
  deleteBrandHoliday: (id: string) => request('/brand-calendar/' + id, 'DElETE'),
  bringBackBrandHoliday: (id: string) => request('/brand-calendar/exclude/' + id, 'DELETE'),
  createBrandHoliday: (data: BrandCalendarModel) => request('/brand-calendar', 'POST', data),

  //Branch
  getBranchCalendar: (config: UseFetchOptions) => useFetch<BranchCalendarModel[]>('/branch-calendar', { method: 'GET', ...config }),
  getBranchExcludedCalendar: (config: UseFetchOptions) => useFetch<PaginateModel<BranchCalendarModel>>('/branch-calendar/exclude', { method: 'GET', ...config }),
  deleteBranchHoliday: (id: string) => request('/branch-calendar/' + id, 'DElETE'),
  bringBackBranchHoliday: (id: string) => request('/branch-calendar/exclude/' + id, 'DELETE'),
  createBranchHoliday: (data: BranchCalendarModel) => request('/branch-calendar', 'POST', data),
};
