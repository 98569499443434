import * as API from '@shared/api';
import { useForm } from '@shared/hooks';
import { CascaderOption, CreateEducationLevel, EducationLevel } from '@shared/models';
import { Button, Form, Input, InputNumber, notification } from 'antd';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { EducationCascader } from './EducationCascader';
import { EducationLevelSorting } from './EducationLevelSorting';
import { EducationPropsDrawer } from './EducationPropsDrawer';

const EducationLevelDefinitionComponentForm = z.object({
  name: z.string({ required_error: 'Kur adı zorunlu alandır' }).min(1, 'Kur adı 1 karekterden fazla olmalı'),
  durationInMinutes: z.number({ required_error: 'Kur süresi zorunlu alandır' }).min(1, 'Kur süresi en az 1 saat olmalıdır'),
  theme: z.object(
    {
      id: z.string({ required_error: 'Lütfen kur için tema seçimi yapınız' }),
    },
    { required_error: 'Lütfen kur için tema seçiniz' }
  ),
});

export const EducationLevelDefinitionComponent = () => {
  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.SYSTEM.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.SYSTEM.useFetchType({ init: true });
  const [theme, themeLoading, getThemes] = API.EDUCATION_LEVEL.SYSTEM.useFetchTheme({ init: true });

  //TODO burası kaldırılacak apide theme'ya göre filtre olmalı
  const [levels, levelsLoading, getLevels] = API.EDUCATION_LEVEL.SYSTEM.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });
  const [filterLevels, setFilterLevels] = useState<EducationLevel[]>([]);
  const [themeId, setThemeId] = useState<string>();
  const [levelSortingHeader, setLevelSortingHeader] = useState<string>();

  const [items, setItems] = useState<CascaderOption[]>([]);
  const [open, setOpen] = useState(false);
  const form = useForm<CreateEducationLevel>(EducationLevelDefinitionComponentForm, {});

  useEffect(() => {
    if (fields && types && theme) {
      const _items: CascaderOption[] = [];
      fields.data?.map((f) => {
        _items.push({
          value: f.id!,
          label: f.name!,
          children: types.data
            ?.filter((t) => t.field?.id == f.id)
            .map((t) => {
              return {
                value: t.id!,
                label: t.name,
                children: theme
                  .data!.filter((m) => m.type?.id == t.id)
                  .map((m) => {
                    return { value: m.id, label: m.name };
                  }) as CascaderOption[],
              };
            }) as CascaderOption[],
        });
      });
      setItems(_items);
    }
  }, [fields, types, theme]);

  useEffect(() => {
    if (levels && themeId) {
      const result = levels.data?.filter((level) => {
        return level.theme?.id == themeId;
      }) as EducationLevel[];
      setFilterLevels(result);
      if (result.length > 0) {
        const l = result[0];
        setLevelSortingHeader(l.theme?.type?.field?.name + ' / ' + l.theme?.type?.name + ' / ' + l.theme?.name);
      }
    } else {
      setFilterLevels([]);
    }
  }, [themeId, levels]);

  const onCloseDrawer = () => {
    setOpen(false);
    getFields();
    getTypes();
    getThemes();
  };

  const onChangeEducationCascader = (value: (string | number)[]) => {
    if (value) {
      const id = value[value.length - 1];
      setThemeId(id.toString());
      form.setValue({
        ...form.value,
        theme: {
          id: id.toString(),
        },
      });
    }
  };

  const createLevel = async () => {
    const formResult = form.parse();
    if (formResult.success) {
      try {
        const result = await API.EDUCATION_LEVEL.SYSTEM.createLevel({ ...formResult.data, order: filterLevels.length });
        if (result.ok) {
          getLevels();
          notification.success({ message: 'Kur başarıyla oluşturuldu' });
        }
      } catch (error) {}
    } else {
      form.setAllTouched();
    }
  };

  const onOrderLevels = async (list: EducationLevel[]) => {
    const orderList = list.map((l) => {
      return {
        id: l.id,
        order: l.order,
      };
    });
    await API.EDUCATION_LEVEL.SYSTEM.orderLevel(orderList);
    getLevels();
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <Form layout="vertical">
          <div>
            <div className="grid grid-cols-6 gap-x-2">
              <Form.Item {...form.formItem('name')} className="col-span-4" label={'Kur Adı'}>
                <Input {...form.input('name')} />
              </Form.Item>
              <Form.Item {...form.formItem('durationInMinutes')} label={'Toplam ders saati'} className=" col-span-2">
                <InputNumber {...form.inputNumber('durationInMinutes')} className="w-full" />
              </Form.Item>
            </div>
            <Form.Item {...form.formItem('theme')}>
              <div className="flex flex-row justify-between max-w pb-1">
                <label>Kur bileşenleri</label>
                <Button
                  className="dark-gra"
                  size="small"
                  type="dashed"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  + Ekle
                </Button>
              </div>
              <EducationCascader items={items} loading={fieldLoading || typeLoading || themeLoading} onChange={onChangeEducationCascader} />
            </Form.Item>
          </div>

          <div className="flex gap-x-2">
            <Button type="primary" onClick={createLevel}>
              Tanımla
            </Button>
            <Button
              type="default"
              onClick={() => {
                form.reset();
              }}
            >
              Sıfırla
            </Button>
          </div>
        </Form>
        <div className="flex flex-col">
          <label className="p-0 m-0">Kur Sıralama</label>
          <h4 className="p-0 m-0">{levelSortingHeader}</h4>
          <div className="flex flex-row  mr-6 overflow-auto h-32">
            <EducationLevelSorting levels={filterLevels} onOrder={onOrderLevels} />
          </div>
        </div>
      </div>

      <EducationPropsDrawer isOpen={open} onClose={onCloseDrawer} />
    </div>
  );
};
