import { Calendar } from '@shared/components';
import { NoteType } from '@shared/constants';
import { useAccessableSeachParams } from '@shared/hooks';
import { ClassCalendarModel, ClassDetailModel } from '@shared/models/class';
import { Button } from 'antd';
import { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClassCalendarRenderer } from '../calendar/CalenderRenderers';
import { ClassInfo, ClassTimeline } from '../class';

type TeacherClassDashboardProps = {
  classDetail: ClassDetailModel | undefined;
  createClassNote: (note: string, noteType: NoteType) => void;
  calendarData: ClassCalendarModel[] | undefined;
  getClassDetail: () => void;
  classId?: string;
  onRefresh: () => void;
  currentMonth: Dayjs;
  onChangeCurrentMonth: (date: Dayjs) => void;
};

export const TeacherClassDashboard = ({ classDetail, createClassNote, calendarData, getClassDetail, classId, onRefresh }: TeacherClassDashboardProps) => {
  useAccessableSeachParams();
  const navigate = useNavigate();
  const location = useLocation();
  const classCalendar = <Calendar events={calendarData} onDateChange={onRefresh} cellRenderer={ClassCalendarRenderer(classId, onRefresh)} />;

  useEffect(() => {
    if (classId) {
      getClassDetail();
    }
  }, [classId]);

  return (
    <div className="grid grid-cols-3 gap-x-2">
      <div className="col-span-2" style={{ height: 'calc(100vh - 227px)' }}>
        <ClassTimeline classDetail={classDetail} notes={classDetail?.notes} createClassNote={createClassNote} isTeacher={true} />
      </div>
      <div className="col-span-1 h-full" style={{ height: 'calc(100vh - 227px)' }}>
        {classDetail?.contentVersionId && (
          <Button
            size="large"
            block
            type="default"
            className="w-full"
            onClick={() => {
              const searchParams = new URLSearchParams(location.search);
              searchParams.set('content_version', classDetail.contentVersionId!);
              navigate(`inclass_preview?${searchParams.toString()}`);
            }}
          >
            Sınıfa Ait Ders İçeriğini Önizle
          </Button>
        )}

        <ClassInfo classDetail={classDetail} isSettings={false} calendar={classCalendar} getClassDetail={getClassDetail} isTeacherLayout={true} />
      </div>
    </div>
  );
};
