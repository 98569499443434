import { useFetch } from '@shared/hooks';
import { PaginateModel, UseFetchOptions } from '@shared/models';
import { AccountIssueProps, CreateAccountIssueProps } from '@shared/models/account_issue';
import { request } from '@shared/utils';

export default {
  paginate: (config: UseFetchOptions) => useFetch<PaginateModel<AccountIssueProps>>('/account/issue', { method: 'GET', ...config }),
  createIssue: (data: CreateAccountIssueProps) => request('/account/issue', 'POST', data),
  retrive: (id: string) => request('/account/issue/' + id, 'GET'),
  createComment: (issueId: string, comment: string) => request('/account/issue/' + issueId + '/comment', 'PUT', { comment }),
  removeComment: (issueId: string, commentId: string) => request('/account/issue/' + issueId + '/comment/' + commentId, 'DELETE'),
  transition: (issueId: string, status: string) => request('/account/issue/' + issueId + '/' + status, 'PUT'),
};
