import * as API from '@shared/api';
import { CdnScope, FileUpload } from '@shared/components/curriculum/FileUpload';
import { Role, UploadFileType } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { StudentHomeworkProps } from '@shared/models';
import { Button, Drawer, notification, UploadFile } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { HomeworkDetailContentsDrawer } from './HomeworkDetailContentsDrawer';

type ActiveHomeworkDetailDrawerProps = {
  open: boolean;
  onCloseDrawer: () => void;
  studentHomework: StudentHomeworkProps;
};

const EvulatedHomeworkDetailDrawerForm = z.object({
  description: z.string({ required_error: 'Öğretmen soyadı zorunlu alandır' }).min(1, 'Öğretmen soyadı 1 karekterden fazla olmalı'),
});

export const ActiveHomeworkDetailDrawer = ({ open, onCloseDrawer, studentHomework }: ActiveHomeworkDetailDrawerProps) => {
  const [files, setFiles] = useState<UploadFile[]>();
  const [startUpload_, startUpload] = useState<boolean>(false);
  const form = useForm<Partial<StudentHomeworkProps>, StudentHomeworkProps>(EvulatedHomeworkDetailDrawerForm, {});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (studentHomework) {
      setFiles(studentHomework?.files?.map((f) => ({ name: f.name!, uid: f.resourceId!, url: f.resourceUrl })));
      form.setValue({ ...studentHomework });
    } else {
      setFiles([]);
      form.reset();
    }
  }, [studentHomework?.id, open]);

  const onCreateDraftHomework = async (files) => {
    const result = form.parse();
    const response = await API.HOMEWORK.studentSubmit(studentHomework.classHomework.id!, { ...result.data, files });
    if (response.ok) {
      startUpload(false);
      notification.success({ message: 'Ödev teslim edildi' });
      form.reset();
      onCloseDrawer();
    }
  };

  return (
    <Drawer forceRender={true} open={open} onClose={onCloseDrawer} closable={true} title={'Aktif Ödev Detayı'}>
      <div className="text-lg font-bold">{studentHomework?.classHomework?.name}</div>
      <div className="italic opacity-45">{studentHomework?.classHomework?.name}</div>
      <div className="mt-4"></div>
      <div className="mb-3">Yüklemek istediğiniz dosyaları seçiniz</div>
      <div className="mb-3">
        <FileUpload
          watchThisIfTrueStartUpload={startUpload_}
          multiple={true}
          files={files}
          onChange={(files) => {
            setFiles(files);
          }}
          onChangeLoading={(b) => {
            setLoading(b);
          }}
          cdnScope={CdnScope.BRANCH}
          onUploadCompleted={(files) => {
            onCreateDraftHomework(files);
          }}
          allowedMimes={UploadFileType('*')}
        />
      </div>
      <div className="mb-3 text-xs">
        <span className="text-red-500">*</span> Desteklenen türler: Resim, Doküman, Ses, Video, Zip
      </div>
      <div className="mt-4">
        <h4 className="mb-2">Açıklama</h4>
        <TextArea showCount style={{ resize: 'none' }} autoSize={{ minRows: 5, maxRows: 9 }} {...form.input('note')} />
      </div>
      <div className="mt-8">
        <Button className="w-full" loading={loading} type="primary" onClick={() => startUpload(true)}>
          Ödevi teslim et
        </Button>
      </div>
      <div className="mt-4">
        <HomeworkDetailContentsDrawer studentHomework={studentHomework!} />
      </div>
    </Drawer>
  );
};

ActiveHomeworkDetailDrawer.roles = [Role.ADMIN];
