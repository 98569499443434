import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { ImageView } from '@shared/components/curriculum/ImageView';
import { IosPronunciationVerifier, CommonPronunciationVerifier } from '@shared/components/curriculum/pronounciation';
import { SoundPlayer } from '@shared/components/curriculum/SoundPlayer';
import { ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from '@shared/models';
import {
  isMobile,
  isIOS,
} from 'react-device-detect';

type ExerciseProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  cdnScope: CdnScope;
  isInClass: boolean;
  onResponseModelPronounciation?: (success: boolean,rate:number) => void;
};
export const VisualBase = ({ content, interaction, isPreview = false, cdnScope, isInClass, onResponseModelPronounciation }: ExerciseProps) => {

  return (
    <>
      {isInClass ? (
        <div className="flex flex-row items-center h-full w-full bg-white pt-10 pb-10">
          <div className="border border-double border-mid-gray rounded m-2 h-full w-3/6 text-center ">
            <ImageView image={content?.image ? content.image : {}} />
          </div>
          <div className="flex flex-col w-3/6 justify-around h-full ">
            <div className="items-center w-full flex flex-col  m-2">
              <p className="p-0 m-0 font-extrabold text-3xl text-custom-purple text-center">
                {isPreview ? (content.content ? content.content?.title : '') : content.content?.title}
              </p>
              <p className="text-lg text-light-purple text-center">
                {isPreview ? (content.content?.description ? content.content.description : '') : content.content?.description}
              </p>
            </div>
            <div className="flex flex-row  w-full justify-center content-center items-center text-center ">
              {content.sound && (
                <div className={content.isPronounEnabled ? '!p-2' : 'col-span-2'}>
                  <SoundPlayer sound={content.sound} onChange={() => { }} isInit={true} isPreview={isPreview} cdnScope={cdnScope} iconFontSize="4rem" />
                </div>
              )}
              {content?.isPronounEnabled && (

                <div className={content.sound ? '!p-2' : 'col-span-2'}>
                  {
                    isIOS && isMobile ? (<IosPronunciationVerifier texts={[content.content?.title!]}
                      onVerify={(e,rate) => {
                        if (onResponseModelPronounciation) {
                          onResponseModelPronounciation(e,rate);
                        }
                      }}
                      iconFontSize='4rem' />) : (<CommonPronunciationVerifier texts={[content.content?.title!]}
                        onVerify={(e,rate) => {
                          if (onResponseModelPronounciation) {
                            onResponseModelPronounciation(e,rate);
                          }
                        }}
                        iconFontSize='4rem' />)
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      ) : isPreview ? (
        <div className="flex flex-col items-center h-full bg-white pt-10 pb-10 relative">
          <div className="border border-double border-mid-gray rounded m-2 h-4/6 text-center">
            <ImageView image={content?.image ? content.image : {}} />
          </div>
          <div className="items-center w-full flex flex-col h-1/6 m-2">
            <p className="p-0 m-0 font-extrabold text-3xl text-center text-custom-purple">{isPreview ? (content.content ? content.content?.title : '') : content.content?.title}</p>
            <p className="text-lg text-light-purple text-center">{isPreview ? (content.content?.description ? content.content.description : '') : content.content?.description}</p>
          </div>
          <div className="grid grid-cols-2 h-1/6  w-full justify-center content-center items-center text-center">
            {content.sound && (
              <div className={content.isPronounEnabled ? '!p-4' : 'col-span-2'}>
                <SoundPlayer sound={content.sound} onChange={() => { }} isInit={true} isPreview={isPreview} cdnScope={cdnScope} iconFontSize="3rem" />
              </div>
            )}
            {content?.isPronounEnabled && (
              <div className={content.sound ? '!p-4' : 'col-span-2'}>
                {
                  isIOS && isMobile ? (<IosPronunciationVerifier texts={[content.content?.title!]} onVerify={(e) => { }} />) : (<CommonPronunciationVerifier texts={[content.content?.title!]} onVerify={(e) => { }} />)
                }
              </div>
            )}
          </div>
          {/*<div className="absolute bottom-0 left-0 right-0 top-0">LottiFile</div>*/}
        </div>
      ) : (
        <div className="flex flex-col items-center h-full bg-white relative">
          <div className={'border border-mid-gray rounded m-2 relative  max-h-min w-full text-center '+ (isMobile ? 'h-2/6' : 'h-3/6')}>
            <ImageView image={content?.image ? content.image : {}}  height='100%' width='100%' className={"object-scale-down"}/>
          </div>
          <div className="items-center w-full flex flex-col h-2/6 m-2">
            <p className="p-0 m-0 font-extrabold lg:text-2xl xl:text-3xl 2xl:text-4xl text-center text-custom-purple">{isPreview ? (content.content ? content.content?.title : '') : content.content?.title}</p>
            <p className="lg:text-sm xl:text-base 2xl:text-lg text-light-purple text-center">{isPreview ? (content.content?.description ? content.content.description : '') : content.content?.description}</p>
          </div>
          <div className="grid grid-cols-2 h-full w-full justify-center content-center items-center text-center">
            {content.sound && (
              <div className={content.isPronounEnabled ? '!p-4' : 'col-span-2'}>
                <SoundPlayer sound={content.sound} onChange={() => { }} isInit={true} isPreview={isPreview} cdnScope={cdnScope} iconFontSize="3rem" />
              </div>
            )}
            {content?.isPronounEnabled && (
              <div className={content.sound ? '!p-4' : 'col-span-2'}>
                {
                  isIOS && isMobile ? (<IosPronunciationVerifier texts={[content.content?.title!]}
                    onVerify={(e,rate) => {
                      if (onResponseModelPronounciation) {
                        onResponseModelPronounciation(e,rate);
                      }
                    }} />) : (<CommonPronunciationVerifier texts={[content.content?.title!]}
                      onVerify={(e,rate) => {
                        if (onResponseModelPronounciation) {
                          onResponseModelPronounciation(e,rate);
                        }
                      }} />)
                }
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
