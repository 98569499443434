import { CameraOutlined, LoadingOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { MediaModel } from '@shared/models';
import { uuid } from '@shared/utils';
import { Upload, notification } from 'antd';
import { GetProp } from 'antd/lib/_util/type';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { CdnScope } from './FileUpload';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type VideoUploadProps = {
  video?: MediaModel;
  onChange: (video: MediaModel) => void;
  onChangeLoading?: (loading: boolean) => void;
  cdnScope: CdnScope;
};

export const VideoUpload = ({ video, onChange, onChangeLoading, cdnScope }: VideoUploadProps) => {
  const [videoUploadLoading, setVideoUploadLoading] = useState<boolean>(false);
  const [contentType, setContentType] = useState<string>();
  const [media, setMedia] = useState<MediaModel>();
  const [uploadVideoFile, setUploadVideoFile] = useState<UploadFile>();

  const restMap = {
    [CdnScope.ADMIN]: API.CDN.obtainCreateUploadVideoAndSoundUrl,
    [CdnScope.BRAND]: API.CDN.brandObtainCreateUploadVideoAndSoundUrl,
    [CdnScope.BRANCH]: API.CDN.branchObtainCreateUploadVideoAndSoundUrl,
  };


  useEffect(() => {
    if (video) {
      setMedia(media);
    }
  }, [video]);

  const beforeUploadVideo = (file: FileType) => {
    const type = file.type;
    const isTypeCorrect = type === 'video/mp4' || type === 'video/ogg' || type === 'video/webm';
    if (!isTypeCorrect) {
      notification.warning({ message: 'You can only upload video/mp4 file!' });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      notification.warning({ message: 'Image must smaller than 50MB!' });
      return false;
    }
    setContentType(type);
    setUploadVideoFile(file);
    return true;
  };

  const handleUploadVideo = async () => {
    const uuId = uuid() + '.' + contentType?.split('/')[1];
    /*const formData = new FormData();
    formData.append('file', (uploadVideoFile as FileType));
    formData.append('metadata', JSON.stringify({ name: uuId, filetype: contentType! }));*/

    if (onChangeLoading) {
      onChangeLoading(true);
    }
    setVideoUploadLoading(true);
    const {
      data: { url },
    } = await restMap[cdnScope](uuId);
    /*let _url = '';
    if (isAdminCdn) {
      const {
        data: { url },
      } = await API.CDN.obtainCreateUploadVideoAndSoundUrl(uuId);
      _url = url;
    } else {
      const {
        data: { url },
      } = await API.CDN.brandObtainCreateUploadVideoAndSoundUrl(uuId);
      _url = url;
    }*/

    const request = await fetch(url, {
      method: 'POST',
      body: uploadVideoFile as FileType,
      headers: { 'Content-Type': contentType! },
    });
    if (request.ok) {
      await request.body;
      const model = { resourceId: uuId } as MediaModel;
      setMedia(model);
      onChange(model);
    }
    if (onChangeLoading) {
      onChangeLoading(false);
    }
    setVideoUploadLoading(false);
  };

  const videoUploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {videoUploadLoading ? <LoadingOutlined /> : <CameraOutlined />}
      <div style={{ marginTop: 0 }}>{media ? 'Media yüklendi' : 'Upload'}</div>
    </button>
  );
  return (
    <Upload
      name="video"
      listType="picture-card"
      className="video-uploader"
      showUploadList={false}
      multiple={false}
      maxCount={1}
      supportServerRender={false}
      beforeUpload={beforeUploadVideo}
      customRequest={handleUploadVideo}
    >
      {videoUploadButton}
    </Upload>
  );
};
