import { ExerciseDefinitionInteractionType } from '@shared/constants';
import { ExerciseDefinitionInteractionModel } from '@shared/models';
import { shuffleToArray } from '@shared/utils';
import { Button, Form, Input, notification, Tag } from 'antd';
import { useEffect, useState } from 'react';

type InteractionFillingComponentProp = {
  isEditMode: boolean;
  value: ExerciseDefinitionInteractionModel;
  onChange: (interaction: ExerciseDefinitionInteractionModel) => void;
};
export const InteractionFillingComponent = ({ isEditMode, value, onChange }: InteractionFillingComponentProp) => {
  const [inputText, setInputText] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [interaction, setInteraction] = useState<ExerciseDefinitionInteractionModel>(value);

  useEffect(() => {
    if (isEditMode && value) {
      setInputText(value.orderItems?.join(' '));
    } else {
      setInputText(undefined);
    }
    setTitle(value.instruction?.title || '');
    setInteraction(value);
  }, [value]);

  const sendInteraction = () => {
    if (inputText) {
      onChange({ ...interaction, instruction: { title: title!, description: '' } });
    } else {
      notification.warning({ message: 'Lütfen boşluk doldurma alanını doldurunuz!' });
    }
  };

  return (
    <div>
      <div>
        <Form layout="vertical">
          <Form.Item label={'Başlık:'}>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>

          <Form.Item label={'Karıştırılacak cümleyi yazınız:'}>
            <Input
              value={inputText}
              onChange={(e) => {
                const text = e.target.value.trim();
                setInputText(e.target.value);
                const d = text.split(' ');
                setInteraction({
                  ...interaction,
                  type: ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS,
                  orderItems: [...d.map((o) => [o])],
                });
              }}
            />
          </Form.Item>
          <Form.Item label={'Cümlenin doğru hali:'}>{interaction.orderItems ? interaction.orderItems.join(' ') : ''}</Form.Item>
          {interaction.orderItems ? (
            <Form.Item label={'Cümlenin soru hali:'}>
              <div className="flex flex-row gap-2">
                {shuffleToArray(interaction.orderItems!).map((o, i) => (
                  <Tag key={o + i} color="default">
                    {o}
                  </Tag>
                ))}
              </div>
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
      </div>
      <div className="text-right mt-2">
        <Button type="primary" onClick={() => sendInteraction()}>
          {isEditMode ? 'Güncelle' : 'Kaydet'}
        </Button>
      </div>
    </div>
  );
};
