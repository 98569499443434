export const productColors = {
  LMS: ['bg-[#FFFBE6]', 'border-[#FAAD14]', '#FA541C'],
  CRM: ['bg-[#F0F5FF]', 'border-[#2F54EB]', '#EB2F96'],
  HR: ['bg-[#FFF0F6]', 'border-[#EB2F96]', '#722ED1'],
  ACCOUNDING: ['bg-[#F0F5FF]', 'border-[#2F54EB]', '#2F54EB'],
};

export const LayoutColors = {
  LMS: {
    backgroundColor: '',
    color: '',
  },
  HR: { backgroundColor: '', color: '' },
  CRM: { backgroundColor: '', color: '' },
  brand_manager: { backgroundColor: '#E6F4FF', color: '#1677FF' },
  branch_manager: { backgroundColor: '#FFF0F6', color: '#EB2F96' },
  WELCOME_LAYOUT: { backgroundColor: '', color: '' },
  student: { backgroundColor: 'rgb(197 226 248)', color: '#001D66', selectedBackground: 'rgb(22,119,255,0.15)' },
  teacher: { backgroundColor: '#191919', color: 'rgba(255, 255, 255, 0.65)', selectedBackground: 'rgba(255, 255, 255, 0.15)' },
  observer: { backgroundColor: '#191919', color: 'rgba(255, 255, 255, 0.65)', selectedBackground: 'rgba(255, 255, 255, 0.15)' },
};
