import { BellOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { QuickReportIssue, UserInformationComponent } from '@shared/components';
import { useUserNotificationContext } from '@shared/contexts';
import { Menu as AntMenu, Badge, Button, Checkbox, CheckboxProps, Image, Layout, Popover, Tour, TourProps } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { ReactElement, useEffect, useRef, useState } from 'react';

const { Header } = Layout;

type HeaderComponentProps = {
  logoSrc: string;
  appNavigationView: ReactElement;
  menuItems?: ItemType<MenuItemType>[];
  openKeys?: string[];
  selectedKeys?: string[];
  headerBackground?: string;
  appNavigationViewBackgroundColor: string;
  onMenuOpenChange?: (openKeys: string[]) => void;
  headerDropDown: ReactElement;
  isNotifications?: boolean;
  notificationIconColor?: string;
};
export const HeaderComponent = ({
  logoSrc,
  appNavigationView,
  menuItems = [],
  openKeys,
  selectedKeys,
  headerBackground = 'white',
  appNavigationViewBackgroundColor,
  onMenuOpenChange,
  headerDropDown,
  isNotifications = true,
  notificationIconColor,
}: HeaderComponentProps) => {
  const { unreadCount } = useUserNotificationContext();
  const [quickReportIssueOpen, setQuickReportIssueOpen] = useState(false);
  const [open, setOpen] = useState<boolean>(true);
  const [showTour, setShowTour] = useState<boolean>(true);
  const accountIssueButtonRef = useRef(null);
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(unreadCount > 0);

  useEffect(() => {
    setIsNotificationPanelOpen(unreadCount > 0);
  }, [unreadCount]);

  useEffect(() => {
    const shouldShowTour = localStorage.getItem('showTour');
    if (shouldShowTour === 'false') {
      setShowTour(false);
    }
  }, []);

  const onChange: CheckboxProps['onChange'] = (e) => {
    localStorage.setItem('showTour', e.target.checked ? 'false' : 'true');
  };

  const steps: TourProps['steps'] = [
    {
      title: 'Sorun Bildir/Destek Talebi Oluştur',
      description: (
        <>
          <div> Karşılaştığınız sorunları ya da destek almak istediğiniz konularda destek talebi oluşturabilirsiniz.</div>
          <div className="mt-5">
            <Checkbox onChange={onChange}>Bunu bir daha gösterme</Checkbox>
          </div>
        </>
      ),
      target: () => accountIssueButtonRef.current,
    },
  ];
  return (
    <Header style={{ display: 'flex', alignItems: 'center', backgroundColor: headerBackground, lineHeight: '0' }} className="rounded-b-[20px] p-4 ">
      {logoSrc && <Image preview={false} src={logoSrc} />}
      {appNavigationView && (
        <div className="ml-4 rounded-xl" style={{ background: appNavigationViewBackgroundColor }}>
          {appNavigationView}
        </div>
      )}
      {menuItems && (
        <AntMenu
          mode="horizontal"
          items={menuItems}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onOpenChange={onMenuOpenChange}
          style={{ flex: 1, backgroundColor: headerBackground, borderBottom: 'none' }}
          className="header-menu items-center justify-center"
        />
      )}
      <div className="flex flex-row justify-between">
        <div>
          <Button
            ref={accountIssueButtonRef}
            onClick={() => setQuickReportIssueOpen(true)}
            type="text"
            icon={<QuestionCircleOutlined style={{ fontSize: '20px', color: 'blue' }} />}
          />
        </div>

        {isNotifications ? (
          <div>
            <Popover
              content={
                <div className="w-[500]">
                  <UserInformationComponent
                    title={
                      <>
                        <h3>{unreadCount > 0 ? 'Okunmamış Bildirimleriniz Mevcut' : 'Bildirimler'}</h3>
                      </>
                    }
                  />
                </div>
              }
              open={isNotificationPanelOpen}
              trigger={'click'}
              placement="bottomLeft"
              onOpenChange={(visible) => {
                setIsNotificationPanelOpen(visible);
              }}
            >
              <Badge count={unreadCount} overflowCount={99} offset={[-10, 9]} size="small">
                <Button type="text" icon={<BellOutlined style={{ fontSize: '20px', color: notificationIconColor }} />} />
              </Badge>
            </Popover>
          </div>
        ) : (
          <></>
        )}
        {headerDropDown && <div className="ml-4">{headerDropDown}</div>}
        <QuickReportIssue open={quickReportIssueOpen} closeDrawer={() => setQuickReportIssueOpen(false)} onReflesh={() => {}} />
        {showTour && <Tour open={open} onClose={() => setOpen(false)} steps={steps} />}
      </div>
    </Header>
  );
};
