import { ImageView, SoundPlayer, TextPage } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { CourseTopicPageModel } from '@shared/models';
import { useEffect, useState } from 'react';

type ImagePageProps = {
  page: CourseTopicPageModel;
  cdnScope: CdnScope;
  isExpand?: boolean;
};

export const ImagePage = ({ page, cdnScope, isExpand = false }: ImagePageProps) => {
  const [isJustText, setIsJustText] = useState<boolean>(false);
  const [isTextAvailable, setIsTextAvailable] = useState<boolean>(false);
  const [isSoundAvailable, setIsSoundAvailable] = useState<boolean>(false);
  const [singelText, setSingleText] = useState<string>();

  useEffect(() => {
    setIsJustText(false);
    setIsTextAvailable(false);
    setIsSoundAvailable(false);
    setSingleText(undefined);
    if (page.content) {
      if (page.content?.text && page.content?.text != '<p><br></p>') {
        setIsTextAvailable(true);
      }
      if (page.content?.sound) {
        setIsSoundAvailable(true);
      }
      if (page.content?.text && page.content?.text != '<p><br></p>' && !page.content?.sound) {
        const texts = page.content?.text.replace(/<(br|li|ul|p)(.*?)>/g, '. ').replace(/<[^>]*>/g, '');
        const sentences = texts.match(/[^.!?]+[.!?]+/g) || [];
        if (sentences.length == 1) {
          setIsJustText(true);
          setSingleText(sentences[0]);
        }
      }
    }
  }, [page.id, page.content]);

  return (
    <>
      {!isJustText && !isTextAvailable && !isSoundAvailable && (
        <div className="flex h-full w-full text-center">
          <div className="flex max-h-min min-h-min min-w-full ">
            <ImageView image={page.content?.image ? page.content?.image : {}} height="100%" width="100%" className={'object-scale-down'} isPreview={!isExpand} />
          </div>
        </div>
      )}

      {isTextAvailable && !isJustText && isSoundAvailable && (
        <div
          className={
            page.content?.sound || (page.content?.text && page.content?.text != '<p><br></p>') ? 'grid grid-cols-2 space-x-4 h-full w-full' : 'flex h-full w-full text-center'
          }
        >
          <div className="flex max-h-min min-h-min min-w-full ">
            <ImageView image={page.content?.image ? page.content?.image : {}} height="100%" width="100%" className={'object-scale-down'} isPreview={!isExpand} />
          </div>
          <div className="flex flex-col pt-3 min-h-full w-full">
            {page.content?.sound && (
              <div className="h-[10%] w-full">
                <SoundPlayer sound={page.content?.sound} onChange={() => {}} isInit={false} inline={true} cdnScope={cdnScope} />
              </div>
            )}
            <div className={page.content?.sound ? 'h-[90%] w-full' : 'h-[100%] w-full'}>
              {page.content?.text && page.content?.text != '<p><br></p>' && <TextPage text={page.content?.text} />}
            </div>
          </div>
        </div>
      )}

      {!isJustText && !isTextAvailable && isSoundAvailable && (
        <div className="flex flex-col h-full w-full space-y-2 justify-center content-center items-center">
          {page.content?.sound && (
            <div className="h-[10%] w-full px-10">
              <SoundPlayer sound={page.content?.sound} onChange={() => {}} isInit={false} inline={true} cdnScope={cdnScope} />
            </div>
          )}
          <div className="flex h-[90%] w-full ">
            <ImageView image={page.content?.image ? page.content?.image : {}} height="100%" width="100%" className={'object-scale-down'} isPreview={!isExpand} />
          </div>
        </div>
      )}

      {!isJustText && isTextAvailable && !isSoundAvailable && (
        <div
          className={
            page.content?.sound || (page.content?.text && page.content?.text != '<p><br></p>') ? 'grid grid-cols-2 space-x-4 h-full w-full' : 'flex h-full w-full text-center'
          }
        >
          <div className="flex max-h-min min-h-min min-w-full ">
            <ImageView image={page.content?.image ? page.content?.image : {}} height="100%" width="100%" className={'object-scale-down'} isPreview={!isExpand} />
          </div>
          <div className="flex flex-col pt-3 min-h-full w-full">
            {page.content?.sound && (
              <div className="h-[10%] w-full">
                <SoundPlayer sound={page.content?.sound} onChange={() => {}} isInit={false} inline={true} cdnScope={cdnScope} />
              </div>
            )}
            <div className={page.content?.sound ? 'h-[90%] w-full' : 'h-[100%] w-full'}>
              {page.content?.text && page.content?.text != '<p><br></p>' && <TextPage text={page.content?.text} />}
            </div>
          </div>
        </div>
      )}

      {isJustText && (
        <div className="flex flex-col h-full w-full justify-center content-center items-center">
          <div className="h-[10%] text-center text-xl font-bold">{singelText}</div>
          <div className="flex h-[90%] w-full ">
            <ImageView image={page.content?.image ? page.content?.image : {}} height="100%" width="100%" className={'object-scale-down'} isPreview={isExpand} />
          </div>
        </div>
      )}
    </>
  );
};

/*
<div className={page.content?.sound || (page.content?.text && page.content?.text != "<p><br></p>") ? "grid grid-cols-2 space-x-4 h-full w-full" : "flex h-full w-full text-center"}>
                <div className="flex max-h-min min-h-min min-w-full ">
                    <ImageView image={page.content?.image ? page.content?.image : {}} height="100%" width="100%" className={"object-scale-down"} isPreview={true} />
                </div>
                <div className="flex flex-col pt-3 min-h-full w-full">
                    {page.content?.sound && (<div className="h-[10%] w-full"><SoundPlayer sound={page.content?.sound} onChange={() => { }} isInit={false} inline={true} cdnScope={cdnScope} /></div>)}
                    <div className={page.content?.sound ? "h-[90%] w-full" : "h-[100%] w-full"}>
                        {page.content?.text && page.content?.text != "<p><br></p>" && <TextPage text={page.content?.text} />}
                    </div>
                </div>
            </div>

*/
