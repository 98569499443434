import { ImageView, SoundPlayer } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { useWindowSize } from '@shared/hooks';
import { ChoiceAnswerResponse, ExerciseAnswerRequestModel, ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from '@shared/models';
import { Button } from 'antd';

type ListeningSingleSelectProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;
  cdnScope: CdnScope;

  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel: ExerciseAnswerRequestModel) => void;
  answerResponseModel?: ChoiceAnswerResponse;
};

export const ListeningSingleSelect = ({
  content,
  interaction,
  isPreview = false,
  isInClass,
  cdnScope,
  answerRequestModel,
  onChangeAnswerRequestModel,
  answerResponseModel,
}: ListeningSingleSelectProps) => {
  const { lg } = useWindowSize();
  return (
    <>
      {isInClass ? (
        <div className="flex flex-row items-center h-full w-full bg-white pt-5 pb-10 ">
          <div className="h-full w-3/6">
            {content.sound && (
              <div>
                <SoundPlayer
                  sound={content.sound}
                  onChange={() => { }}
                  isInit={true}
                  isPreview={isPreview}
                  cdnScope={cdnScope}
                  iconFontSize="3rem"
                  inline={true}
                  onCurrentTimeChange={(currentTime) => { }}
                />
              </div>
            )}

            {content.image && (
              <div className="rounded text-center h-full w-full">
                <ImageView image={content?.image ? content.image : {}} height="100%" width="100%" className={'object-scale-down'} />
              </div>
            )}
          </div>
          <div className="h-full w-3/6 flex flex-col justify-around items-center">
            <div className="mt-10 text-center">
              <div className="p-0 m-0 font-extrabold lg:text-xl xl:text-xl 2xl:text-2xl text-custom-purple text-center">{interaction.instruction?.title}</div>
              <div className="lg:text-lg xl:text-lg 2xl:text-xl  text-light-purple text-center">{interaction.instruction?.description}</div>
            </div>

            <div className="text-center mt-7">
              {interaction.choices ? (
                <div className="flex flex-col justify-center gap-3 px-4">
                  {interaction.choices.map((c) => (
                    <Button
                      key={c.id}
                      type="default"
                      className={
                        'h-auto items-center justify-center flex flex-wrap whitespace-normal' +
                        (answerResponseModel
                          ? answerRequestModel?.choiceIds?.includes(c.id!)
                            ? answerResponseModel?.corrections?.find((corrections) => corrections.choiceId == c.id)?.isCorrect
                              ? 'bg-[#F6FFED] border-2 border-[#52C41A] text-success hover:!text-success hover:!bg-[#F6FFED] hover:!border-[#52C41A] whitespace-normal'
                              : 'bg-[#FFF1F0] border-2 border-[#FF4D4F] text-error hover:!text-error hover:!bg-[#FFF1F0] hover:!border-[#FF4D4F] whitespace-normal'
                            : ''
                          : answerRequestModel?.choiceIds?.includes(c.id!)
                            ? 'border-[#1677FF] bg-[#E6F4FF] border whitespace-normal'
                            : '')
                      }
                      onClick={() => {
                        if (onChangeAnswerRequestModel) {
                          onChangeAnswerRequestModel({ ...answerRequestModel, choiceIds: [c.id!] });
                        }
                      }}
                    >
                      <span>{c.title}</span>
                    </Button>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-full w-full bg-white m-1  relative">
          {content.sound && (
            <div className="h-[10%] mt-1">
              <SoundPlayer
                sound={content.sound}
                onChange={() => { }}
                isInit={true}
                isPreview={isPreview}
                cdnScope={cdnScope}
                iconFontSize="3rem"
                inline={true}
                onCurrentTimeChange={(currentTime) => { }}
              />
            </div>
          )}

          {content.image && (
            <div className="rounded mt-1 h-2/6 text-center">
              <ImageView image={content?.image ? content.image : {}} width={'100%'} height='100%' className='object-scale-down' />
            </div>
          )}
          <div className="mt-1 text-center">
            <div className="text-center lg:text-2xl xl:text-3xl 2xl:text-4xl  text-[#722ED1]">{interaction.instruction?.title}</div>
            <div className="text-center lg:text-sm xl:text-base 2xl:text-lg mt-1 text-[#D3ADF7]">{interaction.instruction?.description}</div>
          </div>
          <div className="h-full w-full flex flex-col justify-center ">


            <div className="text-center mt-1 overflow-y-auto">
              {interaction.choices ? (
                <div className="flex flex-col justify-center gap-3 text-wrap">
                  {interaction.choices.map((item, index) => (
                    <Button
                      key={index}
                      type="default"
                      className={
                        'h-auto whitespace-normal ' +
                        (answerResponseModel
                          ? answerRequestModel?.choiceIds?.includes(item.id!)
                            ? answerResponseModel?.corrections?.find((corrections) => corrections.choiceId == item.id)?.isCorrect
                              ? 'bg-[#F6FFED] border-2 border-[#52C41A] text-success hover:!text-success hover:!bg-[#F6FFED] hover:!border-[#52C41A] whitespace-normal'
                              : 'bg-[#FFF1F0] border-2 border-[#FF4D4F] text-error hover:!text-error hover:!bg-[#FFF1F0] hover:!border-[#FF4D4F] whitespace-normal'
                            : ''
                          : answerRequestModel?.choiceIds?.includes(item.id!)
                            ? 'border-[#1677FF] bg-[#E6F4FF] border whitespace-normal'
                            : '')
                      }
                      onClick={() => {
                        if (onChangeAnswerRequestModel) {
                          onChangeAnswerRequestModel({ ...answerRequestModel, choiceIds: [item.id!] });
                        }
                      }}
                    >
                      <span className="text-wrap md:text-xs lg:text-sm xl:text-base 2xl:text-lg">{item.title}</span>
                    </Button>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
