import { ExerciseDefinitionInteractionType } from '@shared/constants';
import { ExerciseDefinitionInteractionModel } from '@shared/models';
import { Button, Form, Input, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';

type InteractionPronounciationComponentProps = {
  isEditMode: boolean;
  value: ExerciseDefinitionInteractionModel;
  onChange: (interaction: ExerciseDefinitionInteractionModel) => void;
};
export const InteractionPronounciationComponent = ({ isEditMode, value, onChange }: InteractionPronounciationComponentProps) => {
  const [text, setText] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [interaction, setInteraction] = useState<ExerciseDefinitionInteractionModel>(value);

  useEffect(() => {
    if (isEditMode && value.textSpeech) {
      setText(value.textSpeech[0]);
    } else {
      setText('');
    }
    setTitle(value.instruction?.title || '');
  }, [value]);

  const send = (v: ExerciseDefinitionInteractionModel) => {
    onChange({ ...v, instruction: { title: title!, description: '' } });
  };

  return (
    <div>
      <div>
        <Form layout="vertical">
          <Form.Item label={'Başlık:'}>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
          <Form.Item label={'Kullanıcının söylemesini istediğiniz cümleyi giriniz:'}>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              enterButton={<Button type="primary">{isEditMode ? 'Güncelle' : 'Kaydet'}</Button>}
              onSearch={(e) => {
                if (e != '') {
                  send({
                    ...interaction,
                    textSpeech: [e],
                    type: ExerciseDefinitionInteractionType.PRONOUNCE,
                  });
                }
              }}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
