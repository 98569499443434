import { VideoPlayer } from "@shared/components/curriculum/VideoPlayer";
import { CourseTopicPageModel } from "@shared/models";
import { TextPage } from "../text_page";
import { CdnScope } from "@shared/components/curriculum/FileUpload";

type VideoPageProps = {
    page: CourseTopicPageModel;
    cdnScope: CdnScope;
}

export const VideoPage = ({ page, cdnScope }: VideoPageProps) => {

    return (
        <div className={page.content?.text && page.content?.text != "<p><br></p>" ? "grid grid-cols-2 space-x-4 h-full w-full" : "flex h-full w-full text-center"}>
            <div className="flex max-h-min min-h-min min-w-full ">
                <VideoPlayer video={page.content?.video ?? {}} isInit={false} onChange={() => { }} width="100%" height="100%" cdnScope={cdnScope} className="object-scale-down" />
            </div>
            <div className="flex flex-col pt-3 min-h-full w-full">
                {page.content?.text && page.content?.text != "<p><br></p>" && 
                <div className={ "h-[100%] w-full"}>
                <TextPage text={page.content?.text} /></div>}
            </div>
        </div>
    );
}