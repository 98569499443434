import { CheckCircleOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import { PiMedal } from 'react-icons/pi';

const CreditBar = ({ usedCredit, totalCredit }) => {
  const percentage = (usedCredit / totalCredit) * 100;

  return (
    <div className="flex items-center">
      <div className="flex items-center mr-2 ">
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center rounded-full bg-[#E8FFC9] p-1">
            <PiMedal className="text-[#52C41A]" />
          </div>
        </div>
        <span className="ml-1 text-[#52C41A]">{usedCredit}</span>
      </div>
      <Tooltip title={`Şubenin mevcut kredisi: ${totalCredit}, bu planlamanın tüketeceği kredi: ${usedCredit} `}>
        <div className="relative w-full">
          <div className="relative w-full border-2 border-green-500 rounded-full p-0.5 outline outline-gray-300 outline-1 outline-offset-1 opacity-70">
            <div className="bg-[#C5EE90] rounded-full h-2">
              <div className="bg-[#1AC442] h-full rounded-full" style={{ width: `${percentage}%` }} />
            </div>
          </div>
        </div>
      </Tooltip>
      <div className="flex items-center ml-2">
        <span className="mr-1 text-[#52C41A]">{totalCredit}</span>
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center rounded-full bg-[#E8FFC9] p-1">
            <CheckCircleOutlined className="text-[#52C41A]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditBar;
