export type ChoiceAnswerResponse = Partial<{
  success: boolean;
  corrections: { choiceId: string; isCorrect: boolean }[];
  expected: { id: string; title: string; isCorrect: boolean }[];
}>;

export type OrderAnswerResponse = Partial<{
  success: boolean;
  corrections: { expected: string[]; actual: string; isCorrect: boolean }[];
}>;

export type MediaOrderAnswerResponse = Partial<{
  success: boolean;
  corrections: { expected: string; actual: string; isCorrect: boolean }[];
}>;
