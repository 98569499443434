import { Role } from '@shared/constants';
import { StudentHomeworkProps } from '@shared/models';
import { Drawer } from 'antd';
import { HomeworkDetailContentsDrawer } from './HomeworkDetailContentsDrawer';

type WaitingHomeworkDetailDrawerProps = {
  open: boolean;
  onCloseDrawer: () => void;
  homework: StudentHomeworkProps;
};

export const WaitingHomeworkDetailDrawer = ({ open, onCloseDrawer, homework }: WaitingHomeworkDetailDrawerProps) => {
  return (
    <Drawer forceRender={true} open={open} onClose={onCloseDrawer} closable={true} title={'Planlanmış Ödev Detayı'}>
      <div className="text-lg font-bold">{homework?.classHomework?.name}</div>
      <div className="italic opacity-45">{homework?.classHomework?.name}</div>
      <HomeworkDetailContentsDrawer studentHomework={homework!} />
    </Drawer>
  );
};

WaitingHomeworkDetailDrawer.roles = [Role.ADMIN];
