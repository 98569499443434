import { Account, AccountWeakRefProps, DeviceProps } from './account';
import { MediaModel } from './curriculum';

export enum AccountIssueCategory {
  BUG = 'BUG',
  IMPROVEMENT = 'IMPROVEMENT',
  PERFORMANCE = 'PERFORMANCE',
  FEATURE_REQUEST = 'FEATURE_REQUEST',
  OTHER = 'OTHER',
}

export enum AccountIssueType {
  FUNCTIONAL = 'FUNCTIONAL',
  UI = 'UI',
  CONTENT = 'CONTENT',
  COMPATIBILITY = 'COMPATIBILITY',
  OTHER = 'OTHER',
}

export enum AccountIssueStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED',
  NOT_RESOLVED = 'NOT_RESOLVED',
  REJECTED = 'REJECTED',
}

export enum AccountIssueHistoryType {
  TRANSITION = 'TRANSITION',
  COMMENT_CREATED = 'COMMENT_CREATED',
  COMMENT_DELETED = 'COMMENT_DELETED',
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
}
export type AccountIssueCommentOwnerProps = AccountWeakRefProps & {
  isPlatformReply?: boolean;
};

export type AccountIssueCommentProps = {
  id: string;
  account: AccountIssueCommentOwnerProps;
  at: Date;
  comment: string;
};
export type AccountIssueHistoryProps = {
  account: AccountWeakRefProps;
  at: Date;
  status: AccountIssueStatus;
  type: AccountIssueHistoryType;
  description?: string;
};
export type AccountIssueProps = {
  id?: string;
  account: Account;
  no: string;
  title: string;
  description: string;
  category: AccountIssueCategory;
  type: AccountIssueType;
  status: AccountIssueStatus;
  device: DeviceProps;
  files?: MediaModel[];
  comments?: Array<AccountIssueCommentProps>;
  history: Array<AccountIssueHistoryProps>;
  referrerUrl?: string;
  transitionedBy?: AccountWeakRefProps;
  transitionedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type CreateAccountIssueProps = {
  id?: string;
  title: string;
  description: string;
  category: AccountIssueCategory;
  type: AccountIssueType;
  referrerUrl: string;
  files?: MediaModel[];
};
