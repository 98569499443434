import { MinusCircleOutlined, PlusCircleOutlined, QuestionOutlined, SendOutlined } from '@ant-design/icons';
import { AvatarFromName } from '@shared/components/AvatarFromName';
import { ExerciseChatModel, ExerciseDefinitionInteractionModel, ExerciseDefinitionModel, ExerciseMemberInfoModel } from '@shared/models';
import { uuid } from '@shared/utils';
import { Button, Checkbox, Dropdown, Form, Input, Modal, notification, Space, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { DialogMessageBox } from './DialogMessageBox';
import { InteractionSelectionComponent } from '../../InteractionSelectionComponent';
import { InteractionFillingComponent } from '../../InteractionFillingComponent';
import { InteractionPronounciationComponent } from '../../InteractionPronounciationComponent';
import { ExerciseDefinitionInteractionType } from '@shared/constants';

type CreateDialogBaseProps = {
  item: ExerciseDefinitionModel;
  onSave: (item: ExerciseDefinitionModel) => void;
  onChange: (item: ExerciseDefinitionModel) => void;
  isPreview?: boolean;
};

enum QuestionType {
  SELECT = 'SELECT',
  FILLING = 'FILLING',
  PRONOUNCIATION = 'PRONOUNCIATION',
}
export const CreateDialogBase = ({ item, onSave, onChange, isPreview = false }: CreateDialogBaseProps) => {
  const [exercise, setExercise] = useState<ExerciseDefinitionModel>(item);

  const [interaction, setInteraction] = useState<ExerciseDefinitionInteractionModel>({});

  const [newMemeber, setNewMember] = useState<ExerciseMemberInfoModel>({});
  const [selectMemberId, setSelectMemberId] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newChatItem, setNewChatItem] = useState<ExerciseChatModel>({});
  const [questionType, setQuestionType] = useState<QuestionType>();
  const [isMainMemberSelected, setIsMainMemberSelected] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [chatToBeEditted, setChatToBeEditted] = useState<ExerciseDefinitionInteractionModel>();
  const [chatIndex, setChatIndex] = useState<number>();

  useEffect(() => {
    if (item) {
      setExercise(item);
      if (item.content && item.content?.conversation && item.content.conversation.members) {
        const mainMember = Object.keys(item.content.conversation.members).find((id) => {
          return item.content?.conversation?.members![id]?.isMainMember;
        });
        if (mainMember) {
          setIsMainMemberSelected(true);
        } else {
          setIsMainMemberSelected(false);
        }
      }
      setSelectMemberId(undefined);
    }
  }, [item]);

  const send = () => {
    onSave(exercise);
  };

  const addMember = () => {
    onChange({
      ...exercise,
      content: {
        ...exercise.content,
        conversation: {
          ...exercise.content?.conversation,
          members: {
            ...exercise.content?.conversation?.members,
            [uuid()]: newMemeber,
          },
        },
      },
    });
    setNewMember({ name: '', isMainMember: false });
  };

  const removeMember = (id: string) => {
    const members = exercise.content?.conversation?.members;
    if (members) {
      delete members[id];
    }
    onChange({
      ...exercise,
      content: {
        ...exercise.content,
        conversation: {
          ...exercise.content?.conversation,
          members,
        },
      },
    });
  };

  const addConversationText = () => {
    if (newChatItem && newChatItem.text && newChatItem.text != '') {
      onChange({
        ...exercise,
        content: {
          ...exercise.content,
          conversation: {
            ...exercise.content?.conversation,
            chat: [
              ...(exercise.content?.conversation?.chat ? exercise.content?.conversation?.chat : []),
              {
                ...newChatItem,
                id: uuid(),
                who: selectMemberId,
              },
            ],
          },
        },
      });
    }
    setNewChatItem({});
  };

  const onRemoveChat = (chat, index) => {
    const newChats = exercise.content?.conversation?.chat?.filter((c) => c.id != chat.id);
    onChange({
      ...exercise,
      content: {
        ...exercise.content,
        conversation: {
          ...exercise.content?.conversation,
          chat: [...(newChats ?? [])],
        },
      },
    });
  };

  const onUpdateNormalDialog = (newValue: string, chat: ExerciseChatModel, index: number) => {
    const updatedChat = exercise.content?.conversation?.chat?.map((c, i) => {
      if (i == index) {
        return {
          ...c,
          text: newValue,
        };
      }
      return c;
    });

    onChange({ ...exercise, content: { ...exercise.content, conversation: { ...exercise.content?.conversation, chat: updatedChat } } });
  };

  const addInteraction = (interaction: ExerciseDefinitionInteractionModel) => {
    if (isEditMode) {
      //Eğer güncellemek için geldiysek güncellenecek olan chat'i yeni gelen değer ile değiştirip onChange ile yukarıya gönderiyoruz.
      const updatedChat = exercise.content?.conversation?.chat!.map((item, index) => {
        if (index === chatIndex) {
          return {
            ...item,
            interaction: {
              ...interaction,
              orderItems: questionType === QuestionType.FILLING ? interaction.orderItems : undefined,
              choices: questionType === QuestionType.SELECT ? interaction.choices : undefined,
              textSpeech: questionType === QuestionType.PRONOUNCIATION ? interaction.textSpeech : undefined,
              title: interaction.instruction?.title,
            },
            text: questionType === QuestionType.PRONOUNCIATION && interaction.textSpeech ? interaction.textSpeech[0] : undefined,
          };
        }
        return item;
      });

      onChange({
        ...exercise,
        content: {
          ...exercise.content,
          conversation: {
            ...exercise.content?.conversation,
            chat: updatedChat,
          },
        },
      });

      //Yeni Interaction veya chat oluşturulacaksa oluşturulacak chat'i listeye ekliyoruz
    } else {
      onChange({
        ...exercise,
        content: {
          ...exercise.content,
          conversation: {
            ...exercise.content?.conversation,
            chat: [
              ...(exercise.content?.conversation?.chat ? exercise.content?.conversation?.chat : []),
              {
                id: uuid(),
                who: selectMemberId,
                interaction: interaction,
                text: interaction.textSpeech ? interaction.textSpeech![0] : undefined,
              },
            ],
          },
        },
      });
    }

    setIsModalOpen(false);
    setInteraction({});
    setChatIndex(undefined);
  };

  const onSetQuestionType = (type: QuestionType) => {
    setQuestionType(type);
    setNewChatItem({});
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="p-5 h-full">
        <div className="flex justify-end">
          <Button type="primary" size="small" onClick={send}>
            {item.id ? 'Güncelle' : 'Ekle'}
          </Button>
        </div>
        <div className="grid grid-cols-2 h-full gap-2">
          <div className="flex flex-col">
            <Form layout="vertical">
              <div className="flex flex-row gap-x-2">
                <Form.Item label={'Konuşmacı adı:'}>
                  <Input
                    value={newMemeber.name}
                    onChange={(e) => {
                      setNewMember({ ...newMemeber, name: e.target.value });
                    }}
                  />
                </Form.Item>
                <Form.Item label={'Ana konuşmacı:'}>
                  <Checkbox
                    disabled={isMainMemberSelected}
                    checked={newMemeber.isMainMember}
                    onChange={(v) => {
                      setNewMember({ ...newMemeber, isMainMember: v.target.checked });
                    }}
                  />
                </Form.Item>
                <Form.Item label={' '}>
                  <Button type="primary" icon={<PlusCircleOutlined />} onClick={addMember} />
                </Form.Item>
              </div>
            </Form>
            <div>
              {exercise.content?.conversation?.members &&
                Object.keys(exercise.content?.conversation?.members!).map((id) => (
                  <div key={id} className="border border-dashed border-mid-gray rounded mb-1 p-2">
                    <div className="grid grid-cols-3 gap-x-2 items-center">
                      <p>{exercise.content?.conversation?.members![id]?.name}</p>
                      <p>{exercise.content?.conversation?.members![id]?.isMainMember ? 'Ana Konuşmacı' : 'Konuşmacı'}</p>
                      <Button
                        onClick={() => {
                          removeMember(id);
                        }}
                        icon={<MinusCircleOutlined />}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex flex-col min-h-max p-1 bg-white justify-around">
            <div className="h-5/6">
              <DialogMessageBox
                conversation={exercise.content?.conversation && Object.keys(exercise.content?.conversation!).length > 0 ? exercise.content?.conversation : {}}
                isPreview={isPreview}
                isCreateDialog={true}
                onRemove={onRemoveChat}
                onUpdateNormalDialog={onUpdateNormalDialog}
                onEdit={(c, index) => {
                  setIsEditMode(true);
                  setChatToBeEditted(c);
                  setIsModalOpen(true);
                  setChatIndex(index);

                  switch (c.type) {
                    case ExerciseDefinitionInteractionType.SINGLE_CHOICE:
                      setQuestionType(QuestionType.SELECT);
                      break;
                    case ExerciseDefinitionInteractionType.MULTI_CHOICE:
                      setQuestionType(QuestionType.SELECT);
                      break;
                    case ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS:
                      setQuestionType(QuestionType.FILLING);
                      break;
                    case ExerciseDefinitionInteractionType.PRONOUNCE:
                      setQuestionType(QuestionType.PRONOUNCIATION);
                      break;
                    default:
                      setQuestionType(undefined);
                  }
                }}
              />
            </div>
            <div className={'flex flex-row gap-x-2'}>
              <Dropdown
                trigger={['click']}
                arrow={false}
                disabled={!isMainMemberSelected}
                menu={{
                  items: exercise.content?.conversation?.members
                    ? Object.keys(exercise.content?.conversation?.members!).map((id) => ({
                        label: exercise.content?.conversation?.members![id]?.name,
                        key: id,
                        onClick: () => {
                          setSelectMemberId(id);
                        },
                      }))
                    : [],
                  selectable: true,
                }}
                placement="top"
              >
                <a onClick={(e) => e.preventDefault()} className={'!p-0 !m-0'} style={{ display: 'inline-block' }}>
                  <AvatarFromName title={selectMemberId ? exercise.content?.conversation?.members![selectMemberId]?.name! : 'L'} subtitle="" />
                </a>
              </Dropdown>

              <Space.Compact className="w-full">
                <Input
                  disabled={selectMemberId == undefined}
                  value={newChatItem.text}
                  onChange={(e) => {
                    setNewChatItem({
                      ...newChatItem,
                      text: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addConversationText();
                    }
                  }}
                />
                <Button type="primary" disabled={selectMemberId == undefined} icon={<SendOutlined />} onClick={addConversationText} />
              </Space.Compact>
              {selectMemberId != undefined && exercise.content?.conversation?.members![selectMemberId!]!.isMainMember ? (
                <Dropdown
                  disabled={selectMemberId == undefined}
                  trigger={['click']}
                  arrow={false}
                  menu={{
                    items: [
                      {
                        label: 'Seçenekli',
                        key: QuestionType.SELECT,
                        onClick: () => {
                          setIsEditMode(false);
                          onSetQuestionType(QuestionType.SELECT);
                        },
                      },
                      {
                        label: 'Boşluk doldurma',
                        key: QuestionType.FILLING,
                        onClick: () => {
                          setIsEditMode(false);
                          onSetQuestionType(QuestionType.FILLING);
                        },
                      },
                      {
                        label: 'Telaffuz',
                        key: QuestionType.PRONOUNCIATION,
                        onClick: () => {
                          setIsEditMode(false);
                          onSetQuestionType(QuestionType.PRONOUNCIATION);
                        },
                      },
                    ],
                  }}
                  placement="top"
                >
                  <a onClick={(e) => e.preventDefault()} className={'text-center pt-1'} style={{ display: 'inline-block' }}>
                    <QuestionOutlined style={{ fontSize: '150%' }} />
                  </a>
                </Dropdown>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={
          selectMemberId == undefined
            ? 'Lütfen konuşmacı seçiniz'
            : isEditMode
            ? exercise.content?.conversation?.members![selectMemberId]!.name + ' için soruyu güncelleyiniz'
            : exercise.content?.conversation?.members![selectMemberId]!.name + ' için sıradaki soruyu oluşturunuz'
        }
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setQuestionType(undefined);
          setIsEditMode(false);
        }}
        footer={<></>}
      >
        <Tabs
          type="card"
          activeKey={questionType}
          onChange={(e) => setQuestionType(e as QuestionType)}
          items={[
            {
              key: QuestionType.SELECT,
              label: 'Seçenekli',
              children: (
                <InteractionSelectionComponent
                  value={isEditMode ? chatToBeEditted! : interaction}
                  onChange={(i) => {
                    addInteraction(i);
                  }}
                  isEditMode={isEditMode}
                />
              ),
            },
            {
              key: QuestionType.FILLING,
              label: 'Boşluk Doldurma',
              children: (
                <InteractionFillingComponent
                  isEditMode={isEditMode}
                  value={isEditMode ? chatToBeEditted! : interaction}
                  onChange={(i) => {
                    addInteraction(i);
                  }}
                />
              ),
            },
            {
              key: QuestionType.PRONOUNCIATION,
              label: 'Telaffuz',
              children: (
                <InteractionPronounciationComponent
                  isEditMode={isEditMode}
                  value={isEditMode ? chatToBeEditted! : interaction}
                  onChange={(i) => {
                    addInteraction(i);
                  }}
                />
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
};
