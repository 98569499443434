import { BrandUserAvatarGroup } from '@admin/admin_components';
import * as API from '@shared/api';
import { ActionDropdown } from '@shared/components';
import { Role } from '@shared/constants';
import { useBrandContext } from '@shared/contexts';
import { BrandModel, PaginationQuery } from '@shared/models';
import { Table, TableProps, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateBrand } from './UpdateBrand';

export const Brands = () => {
  const [result, loading, getBrands] = API.BRAND.useFetch({ init: false });
  const { brand, setBrand } = useBrandContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10, orders: ['+createdAt'] });

  useEffect(() => {
    getBrands(undefined, paginationQuery);
  }, [paginationQuery]);

  const onCancelDrawer = () => {
    setOpen(false);
    getBrands(undefined, paginationQuery);
  };

  const columns: TableProps<BrandModel>['columns'] = [
    {
      title: 'Brand',
      dataIndex: 'name',
      key: 'name',
      className: 'text-center',
      render: (text, _brand) => {
        return (
          <a
            key={_brand.id}
            onClick={() => {
              setBrand(_brand);
              const params = new URLSearchParams({
                brandId: _brand.id || '1',
                brandName: _brand.name || '',
              });
              navigate('/brand-dashboard?' + params);
            }}
          >
            {text}
          </a>
        );
      },
    },
    {
      title: 'Email',
      key: 'email',
      className: 'text-center',
      render: (_, item) => (
        <>
          <span>{item.email}</span>
        </>
      ),
    },
    {
      title: 'Managers',
      key: 'managers',
      className: 'text-center',
      dataIndex: 'managers',
      render: (_, { id }) => (
        <>
          <BrandUserAvatarGroup brandId={id!} />
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'text-center',
      render: (_, record) => (
        <div className="text-center">
          <ActionDropdown
            data={record}
            onDelete={async () => {
              const result = await API.BRAND.delete(record.id!);
              if (result.status == 201 || result.status == 200) {
                notification.success({ message: 'Marka silindi' });
              }
              getBrands(undefined, { ...paginationQuery, page: 1 });
            }}
            onEdit={(_brand) => {
              setBrand(_brand);
              setOpen(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={result?.data}
        rowKey={'id'}
        pagination={{ showSizeChanger: true, showPrevNextJumpers: true, total: result?.count, pageSize: paginationQuery.limit, position: ['bottomCenter'] }}
        onChange={(pagination) => setPaginationQuery((prev) => ({ ...prev, page: pagination.current, limit: pagination.pageSize }))}
      />
      <UpdateBrand brand={brand} open={open} onCancelDrawer={onCancelDrawer} />
    </>
  );
};

Brands.path = 'brands';
Brands.title = 'Markaları Yönet';
Brands.group = 'admin_brands';
Brands.roles = [Role.ADMIN];
