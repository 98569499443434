import * as API from '@shared/api';
import { useForm } from '@shared/hooks';
import { Enrollment, EnrollmentStatus, PrePlacement } from '@shared/models';
import { Alert, Button, Form, Input, notification } from 'antd';
import { z } from 'zod';

type UpdatePasswordStudentProfileProps = {
  student: PrePlacement;
  enrollment: Enrollment;
};

const UpdateStudentProfileForm = z.object({
  password: z.string({ required_error: 'Parola boş bırakılamaz' }).min(1, 'Parola boş bırakılamaz!'),
  confirmPassword: z.string({ required_error: 'Parola onayı boş bırakılamaz' }).min(1, 'Parola onayı boş bırakılamaz!'),
});

export const UpdatePasswordStudentProfile = ({ student, enrollment }: UpdatePasswordStudentProfileProps) => {
  const form = useForm<
    Partial<{
      password: string;
      confirmPassword: string;
    }>,
    PrePlacement
  >(UpdateStudentProfileForm, { ...student });

  const onUpdate = async () => {
    const result = form.parse();

    if (result.success && form.value.password) {
      if (result.success && form.value.password !== form.value.confirmPassword) {
        return notification.warning({ message: 'Girilen parolar eşleşmiyor' });
      }

      const response = await API.STUDENT.updatePasswordStudent(student.id, {
        name: student.name,
        surname: student.surname,
        phone: student.phone,
        gender: student.gender,
        nationalId: student.nationalId,
        password: form.value.password,
      });
      if (response.ok) {
        notification.success({ message: 'Parola sıfırlama gerçekleşti.' });
      } else {
        notification.error({ message: 'Parola sıfırlama gerçekleşmedi.' });
      }
      form.reset();
    } else {
      form.setAllTouched();
    }
  };
  return (
    <Form layout="vertical">
      {enrollment.status === EnrollmentStatus.READY_FOR_PLANNING || enrollment.status === EnrollmentStatus.STARTED ? (
        <>
          <div className="mb-3">
            <b> {student.name}</b>
            <div>{student.email}</div>
          </div>
          <Form.Item {...form.formItem('password')} label={'Parola:'}>
            <Input.Password {...form.input('password')} />
          </Form.Item>
          <Form.Item {...form.formItem('confirmPassword')} label={'Parola Tekrarı:'}>
            <Input.Password {...form.input('confirmPassword')} />
          </Form.Item>
          <div className="flex justify-end">
            <Button type="primary" onClick={() => onUpdate()}>
              Parola Güncelle
            </Button>
          </div>
        </>
      ) : (
        <div>
          <Alert
            message={
              <span>
                <div>
                  Kayıt bilgi alma aşamasında olduğu için öğrenciye ait hesap henüz oluşturulmadı. Kaydı planlamaya gönderdikten sonra talep/ihtiyaç halinde öğrencinin parolasını
                  güncelleyebilirsiniz.
                </div>
              </span>
            }
            type="warning"
            showIcon
            closable
          />
        </div>
      )}
    </Form>
  );
};
