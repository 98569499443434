import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { shuffleToArray } from '@shared/utils';
import { ExerciseAnswerRequestModel, ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel, OrderAnswerResponse } from '@shared/models';

type ReadingGapFillingProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;

  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel: ExerciseAnswerRequestModel) => void;
  answerResponseModel?: OrderAnswerResponse;
};

export const ReadingGapFilling = ({
  content,
  interaction,
  isPreview = false,
  isInClass,
  answerRequestModel,
  onChangeAnswerRequestModel,
  answerResponseModel,
}: ReadingGapFillingProps) => {
  const [currentText, setCurrentText] = useState<string>('');
  const [remainingOrderItems, setRemainingOrderItems] = useState<string[]>([]);
  const [filledWords, setFilledWords] = useState<{ [index: number]: string }>({});
  const [disabledWords, setDisabledWords] = useState<{ [word: string]: boolean }>({});
  const [correctedText, setCorrectedText] = useState<string>(''); // Corrected text

  useEffect(() => {
    if (isInClass) {
      const initialText = content?.content?.description
        ? content.content?.description?.replace(/\[([^\]]+)\]/g, (match, p1) => {
            return `<span class="gap" style="padding-left: 5px; padding-right: 5px;">___</span>`;
          })
        : '';
      setCurrentText(initialText);
      setCorrectedText('');
      setDisabledWords({});

      // Shuffle order items
      setRemainingOrderItems(shuffleToArray(interaction.orderItems!).map((item) => item[0]));
    } else {
      const initialText = content?.content?.description
        ? content.content?.description?.replace(/\[([^\]]+)\]/g, (match, p1) => {
            return `<span class="gap" style="padding-left: 5px; padding-right: 5px;">___</span>`;
          })
        : '';
      setCurrentText(initialText);
      setCorrectedText('');
      setDisabledWords({});
      if (!isPreview) {
        // Shuffle order items
        setRemainingOrderItems(shuffleToArray(interaction.orderItems!).map((item) => item[0]));
      }
    }
  }, [content, interaction]);

  useEffect(() => {
    if (answerResponseModel && answerResponseModel.corrections) {
      // Kelimelerin doğru ya da yanlış olduğuna göre stillerini güncelleyip metni yeniden oluşturuyoruz
      let updatedText = currentText;

      answerResponseModel.corrections.forEach((correction, idx) => {
        const color = correction.isCorrect ? { bg: '#f6ffec', border: '#8ad764' } : { bg: '#fff0ef', border: '#ffa8a9' }; // Renk seçimi
        updatedText = updatedText.replace(
          `<button class="filled-word">${correction.actual}</button>`,
          `<button class="filled-word" style="margin-bottom:2px; background-color: ${color.bg}; border-color:${color.border};">${correction.actual}</button>`
        );
      });

      setCorrectedText(updatedText);
    }
  }, [answerResponseModel, currentText]);

  const handleWordClick = (word: string, index: number) => {
    const nextBlankIndex = currentText.indexOf('___');
    if (nextBlankIndex === -1) return; // No more blanks

    // Boşluğu kelimeyle değiştiriyoruz
    const updatedText = currentText.replace('___', `<button class="filled-word">${word}</button>`);
    setCurrentText(updatedText);

    const filledIndex = Object.keys(filledWords).length;
    setFilledWords({ ...filledWords, [filledIndex]: word });

    setDisabledWords((prev) => ({ ...prev, [word]: true }));

    // onChangeAnswerRequestModel ile seçilen kelimeleri backende gönderiyoruz
    if (onChangeAnswerRequestModel) {
      onChangeAnswerRequestModel({
        ...(answerRequestModel ? answerRequestModel : { orderItems: [] }),
        orderItems: [...(answerRequestModel?.orderItems ? answerRequestModel?.orderItems : []), word],
      });
    }
  };

  const handleRemoveWord = (index: number, word: string) => {
    const updatedText = currentText.replace(`<button class="filled-word">${word}</button>`, '___');
    setCurrentText(updatedText);

    setDisabledWords((prev) => ({ ...prev, [word]: false }));

    const updatedFilledWords = { ...filledWords };
    delete updatedFilledWords[index];
    setFilledWords(updatedFilledWords);

    const updatedOrderItems = answerRequestModel?.orderItems?.filter((item) => item !== word) || [];

    if (onChangeAnswerRequestModel) {
      onChangeAnswerRequestModel({
        ...(answerRequestModel ? answerRequestModel : { orderItems: [] }),
        orderItems: updatedOrderItems,
      });
    }
  };

  return (
    <>
      {isInClass ? (
        <div className="flex flex-row items-center h-full w-full bg-white pt-10 pb-10 gap-4">
          <div className="flex flex-col h-full w-3/6">
            <div className="font-bold text-base">{content.content?.title}</div>
            {/* Metin dinamik olarak güncelleniyor */}
            <div
              className="mt-5 overflow-y-auto h-full w-full md:text-lg xl:text-lg 2xl:text-xl"
              dangerouslySetInnerHTML={{ __html: correctedText || currentText }}
              onClick={(e) => {
                const target = e.target as HTMLElement;
                if (target.classList.contains('filled-word')) {
                  const word = target.textContent || '';
                  const wordIndex = Object.values(filledWords).indexOf(word);
                  if (wordIndex !== -1) {
                    handleRemoveWord(wordIndex, word);
                  }
                }
              }}
            />
          </div>
          <div className="flex flex-col h-full w-3/6 justify-around items-center">
            <div className="mt-10 text-center">
              <div className="p-0 m-0 font-extrabold lg:text-xl xl:text-xl 2xl:text-2xl text-custom-purple text-center">{interaction.instruction?.title}</div>
              <div className="lg:text-base xl:text-base 2xl:text-xl  text-light-purple text-center">{interaction.instruction?.description}</div>
            </div>

            <div className="ml-5 text-center flex items-center content-center justify-center">
              {remainingOrderItems.length ? (
                <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center mt-5">
                  {remainingOrderItems.map((item, index) => (
                    <Button
                      size="small"
                      key={index}
                      onClick={() => handleWordClick(item, index)}
                      disabled={disabledWords[item]} // Disable button if already used
                      className="h-auto whitespace-normal"
                    >
                      {item}
                    </Button>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-full w-full bg-white py-3 px-5 relative">
          <div className="font-bold text-base">{content.content?.title}</div>
          <div
            className="mt-5 overflow-y-auto h-3/6 w-full "
            dangerouslySetInnerHTML={{ __html: correctedText || currentText }}
            onClick={(e) => {
              const target = e.target as HTMLElement;
              if (target.classList.contains('filled-word')) {
                const word = target.textContent || '';
                const wordIndex = Object.values(filledWords).indexOf(word);
                if (wordIndex !== -1) {
                  handleRemoveWord(wordIndex, word);
                }
              }
            }}
          />

          <div className="mt-1 text-center">
            <div className="text-center  text-[#722ED1]">{interaction.instruction?.title}</div>
            <div className="mt-2 text-[#D3ADF7]">{interaction.instruction?.description}</div>
          </div>

          {isPreview ? (
            <div className="ml-5 text-center flex items-center content-center justify-center">
              {interaction.orderItems && (
                <div className="grid grid-cols-2 gap-4 w-fit items-center justify-center content-center">
                  {shuffleToArray(interaction.orderItems).map((item, index) => (
                    <Button key={index} type="default" className="h-auto items-center justify-center flex flex-wrap">
                      <span className="text-wrap"> {item[0]}</span>
                    </Button>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="text-center flex items-center content-center justify-center pb-3">
              {remainingOrderItems.length ? (
                <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center mt-5">
                  {remainingOrderItems.map((item, index) => (
                    <Button
                      size="small"
                      key={index}
                      onClick={() => handleWordClick(item, index)}
                      disabled={disabledWords[item]} // Disable button if already used
                      className="h-auto whitespace-normal"
                    >
                      {item}
                    </Button>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
