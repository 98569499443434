import { UploadOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { MediaModel } from '@shared/models';
import { Button, Upload, notification } from 'antd';
import { GetProp } from 'antd/lib/_util/type';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export enum CdnScope {
  ADMIN = 'ADMIN',
  BRANCH = 'BRANCH',
  BRAND = 'BRAND',
}

type FileUploadProps = {
  files?: UploadFile[];
  onChange: (file: UploadFile[]) => void;
  onChangeLoading?: (loading: boolean) => void;
  onUploadCompleted?: (uploadedMedias: MediaModel[]) => void;
  cdnScope: CdnScope;
  disabled?: boolean;
  multiple?: boolean;
  maxCount?: number;
  maxSize?: number;
  uploadOnChange?: boolean;
  watchThisIfTrueStartUpload: boolean;
  allowedMimes?: string[];
};
export const FileUpload = ({
  watchThisIfTrueStartUpload,
  onUploadCompleted,
  files,
  onChange,
  onChangeLoading,
  cdnScope,
  disabled,
  multiple,
  maxCount = 10,
  maxSize = 10,
  uploadOnChange,
  allowedMimes,
}: FileUploadProps) => {
  useEffect(() => {
    if (watchThisIfTrueStartUpload) {
      handleUpload();
    }
  }, [watchThisIfTrueStartUpload]);

  const [uploadSoundFile, setUploadSoundFile] = useState<UploadFile[]>(files ?? []);
  const beforeUpload = (file: FileType, willUploadFiles: FileType[]) => {
    if (willUploadFiles.length > maxCount) {
      notification.warning({ message: 'You can only upload 10 files!' });
      return false;
    }

    for (const f of willUploadFiles) {
      const isLt2M = file.size / 1024 / 1024 < maxSize;
      if (!isLt2M) {
        notification.warning({ message: `Image must smaller than ${maxSize}MB!` });
        return false;
      }
    }

    setUploadSoundFile([...willUploadFiles, ...(files ?? [])]);
    return !!uploadOnChange;
  };

  const restMap = {
    [CdnScope.ADMIN]: API.CDN.brandObtainCreateUploadVideoAndSoundUrl,
    [CdnScope.BRANCH]: API.CDN.branchObtainCreateUploadVideoAndSoundUrl,
    [CdnScope.BRAND]: API.CDN.obtainCreateUploadVideoAndSoundUrl,
  };
  const handleUpload = async () => {
    /*const formData = new FormData();
    formData.append('file', uploadSoundFile as FileType);*/

    if (onChangeLoading) {
      onChangeLoading(true);
    }

    const resultFiles: MediaModel[] = [];

    for (const file of uploadSoundFile ?? []) {
      const resourceId = file.uid;
      const contentType = file.type;
      const name = file.name;
      if (contentType && allowedMimes && !allowedMimes.includes(contentType)) {
        notification.warning({ message: 'Bu dosya türü yüklenemez! ' + contentType });
        continue;
      }
      if (!file.lastModifiedDate) {
        resultFiles.push({ resourceId, name, type: contentType } as MediaModel);
        continue;
      }
      const {
        data: { url },
      } = await restMap[cdnScope](resourceId);

      const request = await fetch(url, {
        method: 'POST',
        body: file as FileType,
        headers: { 'Content-Type': contentType! },
      });

      if (request.ok) {
        await request.body;
        resultFiles.push({ resourceId, name, type: contentType } as MediaModel);
      }
    }

    if (onChangeLoading) {
      onChangeLoading(false);
    }

    if (onUploadCompleted) {
      await onUploadCompleted(resultFiles);
    }
  };

  const uploadButton = (
    <Button className="w-full" icon={<UploadOutlined />}>
      Dosyaları seç
    </Button>
  );

  return (
    <Upload
      name="file"
      accept={allowedMimes?.join(',')}
      showUploadList={true}
      multiple={!!multiple}
      maxCount={maxCount}
      fileList={files}
      supportServerRender={false}
      beforeUpload={beforeUpload}
      customRequest={uploadOnChange ? handleUpload : undefined}
      disabled={disabled}
      onChange={(file) => {
        onChange(file.fileList);
      }}
      onRemove={(file) => {
        const filteredFiles = files?.filter((f) => f.uid !== file.uid) || [];
        onChange(filteredFiles!);
      }}
    >
      {uploadButton}
    </Upload>
  );
};
