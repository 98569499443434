import { MediaModel } from '@shared/models';
import { ReactElement, useEffect, useRef, useState } from 'react';
import * as API from '@shared/api';
import { CdnScope } from './FileUpload';

type FileDownloadProps = {
  file: MediaModel;
  icon?: ReactElement;
  cdnScope: CdnScope;
};

export const FileDownload = ({ file, icon, cdnScope }: FileDownloadProps) => {
  const fileRef = useRef<HTMLAnchorElement | null>(null);

  const restMap = {
    //TODO ADMIN VE BRAND ICIN BAK
    [CdnScope.ADMIN]: API.CDN.brandObtainCreateUploadVideoAndSoundUrl,
    [CdnScope.BRANCH]: API.CDN.branchObtainDownloadVideoAndSoundUrl,
    [CdnScope.BRAND]: API.CDN.obtainCreateUploadVideoAndSoundUrl,
  };

  const getFileUrl = async (id: string): Promise<string | undefined> => {
    try {
      const {
        data: { url },
      } = await restMap[cdnScope](id);
      return url;
    } catch (error) {
      console.error('Error fetching file URL:', error);
    }
    return undefined;
  };

  return (
    <div>
      <a
        ref={fileRef}
        download
        onClick={async () => {
          const privateUrl = await getFileUrl(file.resourceId!);
          const link = document.createElement('a');
          link.href = privateUrl!;
          link.setAttribute('target', '_blank');
          link.setAttribute('download', file.name!);

          document.body.appendChild(link);

          link.click();

          link.parentNode!.removeChild(link);
        }}
        className="mt-2 inline-block "
      >
        <span className="text-gray-500">{icon}</span> {file.name}
      </a>
    </div>
  );
};
