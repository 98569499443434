import { ReactElement, useState } from 'react';
import { NotificationList } from './NotificationList';

export const UserInformationComponent = (params: { title?: string | ReactElement } /*sövmeyin anladık*/) => {
  const [selectedSegmend, setSelectedSegmend] = useState<ReactElement>(<NotificationList />);
  // const onChangeSegmented = (key) => {
  //     if (key == "Bildirimlerim") {
  //         return setSelectedSegmend(<NotificationList />);
  //     } else if (key == "Taleplerim") {
  //         return setSelectedSegmend(<RequestList />);
  //     } else {
  //         return setSelectedSegmend(<NewsList />);
  //     }
  // }
  return (
    <>
      {params.title}
      {selectedSegmend}
    </>
  );
};
