import { EducationLevel } from './education_level';

//TODO servisler hazır oldugunda bu modeller düzenlenmeli
export type ConditionCourse = Partial<{
  id: string;
  levelId: string;
  teacherRate: number;
  exerciseRate: number;
  homeworkRate: number;
  attendanceRateThreshold: number;
  failThreshold: number;
  successThreshold: number;
  level: EducationLevel;
}>;
