import { PlusCircleOutlined } from '@ant-design/icons';
import { BirthdayCalendar, RoleAndPermissons } from '@shared/components';
import { BrandOrBranch, Gender, Role } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { BranchUserModel, BrandUserModel, CreateBranchUser, CreateBrandUser, PermissionsModel } from '@shared/models';
import { Button, Form, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { EmailSearch, NationalIdInput, PhoneInput, TextInput } from './form_inputs';

const BrandAndBranchUserFormZod = z.object({
  name: z.string({ required_error: 'Ad zorunlu alandır' }).min(1, 'Ad 1 karekterden fazla olmalı'),
  surname: z.string({ required_error: 'Soyad zorunlu alandır' }).min(1, 'Soyad 1 karekterden fazla olmalı'),
  email: z.string({ required_error: 'E-posta zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçerli bir e-mail giriniz'),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
  gender: z.string({ required_error: 'Lütfen cinsiyet seçimi yapınız' }),
  nationalId: z.string({ required_error: 'Lütfen tc/vergi no alanını doldurunuz' }),
  birthDate: z.string({ required_error: 'Lütfen doğum tarihi seçiniz' }),
  permissions: z.object(
    {
      role: z.nativeEnum(Role),
      products: z.array(z.string()),
      values: z.array(z.string()),
    },
    { required_error: 'Lütfen rol seçimi yapınız' }
  ),
});

type BrandAndBranchUserFormType = {
  user?: BranchUserModel | BrandUserModel;
  enableProductTypes: string[];
  onSave: (user: BranchUserModel | CreateBrandUser) => void;
  brandOrBranch: BrandOrBranch;
  isBranchSearch?: boolean;
};
export const BrandAndBranchUserForm = ({ user, enableProductTypes, brandOrBranch, onSave, isBranchSearch = true }: BrandAndBranchUserFormType) => {
  const form = useForm<
    Partial<{
      name: string;
      surname: string;
      email: string;
      'phone.number': string;
      'phone.code': string;
      permissions: PermissionsModel;
      nationalId: string;
      gender: Gender;
      birthDate: string;
    }>,
    CreateBranchUser | CreateBrandUser
  >(BrandAndBranchUserFormZod, { ...user });

  const [isEditMod, setIsEditMod] = useState<boolean>(false);
  const [isEmailSearch, setIsEmailSearch] = useState(false);
  const [isEmailSearchFound, setIsEmailSearchFound] = useState(false);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      form.setInitialValue({
        ...user,
        permissions: {
          ...(user.accessables?.permissions ? user.accessables?.permissions : {}),
        },
      });
      setIsEditMod(true);
      setIsEmailSearchFound(true);
      setIsEmailSearch(true);
    } else {
      form.setInitialValue({});
      setIsEditMod(false);
      setIsEmailSearchFound(false);
      setIsEmailSearch(false);
    }
  }, [user]);

  const onSearchResult = (data?: BranchUserModel) => {
    if (data) {
      form.setInitialValue({
        ...form.value,
        email: data.email,
        name: data.name,
        surname: data.surname,
        phone: data.phone,
        gender: data.gender,
        nationalId: data.nationalId,
        birthDate: data.birthDate,
      });
      setIsEmailSearchFound(true);
    } else {
      setIsEmailSearchFound(false);
      form.setInitialValue({
        ...form.value,
        name: undefined,
        surname: undefined,
        phone: {
          code: undefined,
          number: undefined,
        },
        gender: undefined,
        nationalId: undefined,
        birthDate: undefined,
      });
    }
    setIsEmailSearch(true);
  };

  return (
    <Form className=" gap-4 gap-y-2" layout="vertical">
      {brandOrBranch == BrandOrBranch.BRANCH ? (
        <EmailSearch
          form={form}
          disabled={isEditMod || isEmailSearchFound}
          isSearchOtherUser={false}
          isBranchService={isBranchSearch}
          onSearchResult={onSearchResult}
          onClear={() => {
            form.setInitialValue({
              ...form.value,
              name: undefined,
              email: undefined,
              surname: undefined,
              phone: {
                code: undefined,
                number: undefined,
              },
              gender: undefined,
              nationalId: undefined,
              birthDate: undefined,
            });
            setIsEmailSearchFound(false);
            setIsEmailSearch(false);
          }}
        />
      ) : (
        <TextInput formItem={form.formItem('email')} formInput={form.input('email')} label={'E-posta:'} maxLength={50} disabled={isEditMod} />
      )}

      <div className="grid grid-cols-2 gap-x-2">
        <TextInput
          formItem={form.formItem('name')}
          formInput={form.input('name')}
          disabled={isEditMod || brandOrBranch == BrandOrBranch.BRANCH ? !isEmailSearch || isEmailSearchFound : false}
          label={'Adı:'}
          maxLength={25}
        />
        <TextInput
          formItem={form.formItem('surname')}
          formInput={form.input('surname')}
          disabled={isEditMod || brandOrBranch == BrandOrBranch.BRANCH ? !isEmailSearch || isEmailSearchFound : false}
          label={'Soyadı:'}
          maxLength={25}
        />
      </div>

      <PhoneInput form={form} disabled={isEditMod || brandOrBranch == BrandOrBranch.BRANCH ? !isEmailSearch || isEmailSearchFound : false} />
      <div className="grid grid-cols-2 gap-x-2">
        <Form.Item {...form.formItem('gender')} label={'Cinsiyet:'}>
          <Radio.Group {...form.input('gender')} disabled={isEditMod || brandOrBranch == BrandOrBranch.BRANCH ? !isEmailSearch || isEmailSearchFound : false}>
            <Radio value={Gender.FEMALE}>Kadın</Radio>
            <Radio value={Gender.MALE}>Erkek</Radio>
          </Radio.Group>
        </Form.Item>
        <NationalIdInput form={form} disabled={isEditMod || brandOrBranch == BrandOrBranch.BRANCH ? !isEmailSearch || isEmailSearchFound : false} />
      </div>
      <Form.Item {...form.formItem('birthDate')} label={'Doğum tarihi:'}>
        <BirthdayCalendar form={form} disabled={isEditMod || brandOrBranch == BrandOrBranch.BRANCH ? !isEmailSearch || isEmailSearchFound : false} />
      </Form.Item>
      <Form.Item {...form.formItem('permissions')} label={'Rol / Rol Yetkinlikleri:'}>
        <RoleAndPermissons
          enableProductTypes={enableProductTypes}
          permission={form.value.permissions ? form.value.permissions : user?.accessables?.permissions!}
          brandOrBranch={brandOrBranch}
          onChange={(v) => {
            form.setValue({
              ...form.value,
              permissions: {
                ...form.value.permissions,
                ...v,
              },
            });
          }}
        />
      </Form.Item>
      <div className="grid grid-cols-2 gap-2">
        <Button
          type="primary"
          className="w-full	max-w-48"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            const result = form.parse();
            if (result.success) {
              onSave({
                ...result.data,
              });
              setIsEditMod(false);
              setIsEmailSearch(false);
              setIsEmailSearchFound(false);
              form.reset();
              form.setValue({});
            } else {
              form.setAllTouched();
            }
          }}
        >
          {isEditMod ? 'Kullanıcı Güncelle' : 'Kullanıcı Ekle '}
        </Button>
        {!isEditMod && (
          <Button
            type="default"
            className="w-full max-w-48"
            onClick={() => {
              form.reset();
              if (isEditMod) {
                form.setValue({
                  ...form.value,
                  permissions: {},
                });
              } else {
                {
                  form.setValue({});
                }
              }
            }}
          >
            Temizle
          </Button>
        )}
      </div>
    </Form>
  );
};
