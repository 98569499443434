import { CaretDownOutlined, ForkOutlined, ShareAltOutlined, SlidersOutlined, TagOutlined } from '@ant-design/icons';
import { ActionDropdown } from '@shared/components/ActionDropdown';
import { BrandContentVersionUpdatePackageStatus, ContentVersionStatus } from '@shared/constants';
import { BrandContentVersionUpdatePackage, CourseVersionModel, EducationLevel } from '@shared/models';
import type { TabsProps } from 'antd';
import { Alert, Anchor, Button, Card, Empty, Input, Tabs, Tag } from 'antd';
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor';
import { MenuProps } from 'antd/lib/menu';
import { useEffect, useState } from 'react';

type LearningContentVersionProps = {
  published: CourseVersionModel[];
  draft: CourseVersionModel[];
  levels: EducationLevel[];
  brandContentVersionPackage?: BrandContentVersionUpdatePackage[];
  onDeleteVersion: (versionId: string) => void;
  onEditVersion: (versionId: string) => void;
  newVersionRequest: () => void;
  onPublishVersion: (versionId: string) => void;
  onForkVersion: (versionId: string) => void;
  onDefaultVersion?: (versionId: string) => void;
};

export const LearningContentVersion = ({
  published,
  draft,
  levels,
  onDeleteVersion,
  onEditVersion,
  newVersionRequest,
  onPublishVersion,
  brandContentVersionPackage,
  onForkVersion,
  onDefaultVersion,
}: LearningContentVersionProps) => {
  const [tabKey, setTabKey] = useState<string>(ContentVersionStatus.PUBLISHED);
  const [filterLevels, setFilterLevels] = useState<EducationLevel[]>();
  const [publishedAnchorItems, setPublishedAnchorItems] = useState<AnchorLinkItemProps[]>([]);
  const [draftAnchorItems, setDraftAnchorItems] = useState<AnchorLinkItemProps[]>([]);
  const [filterBrandContentVersionPackage, setFilterBrandContentVersionPackage] = useState<BrandContentVersionUpdatePackage[]>([]);
  const [tabs, setTabs] = useState<TabsProps['items']>([]);

  useEffect(() => {
    if (levels) {
      setFilterLevels(levels);
    }
  }, [levels]);

  useEffect(() => {
    if (brandContentVersionPackage) {
      setTabs([
        { key: ContentVersionStatus.PUBLISHED, label: 'Mevcut versiyonlar' },
        { key: ContentVersionStatus.DRAFT, label: 'Taslak versiyonlar' },
        { key: 'package', label: 'Güncelleme Paketler' },
      ]);
      setFilterBrandContentVersionPackage(brandContentVersionPackage);
    } else {
      setTabs([
        { key: ContentVersionStatus.PUBLISHED, label: 'Mevcut versiyonlar' },
        { key: ContentVersionStatus.DRAFT, label: 'Taslak versiyonlar' },
      ]);
    }
  }, [brandContentVersionPackage]);

  useEffect(() => {
    if (filterLevels && brandContentVersionPackage) {
      const newFilter = brandContentVersionPackage.filter((b) => {
        const result = filterLevels.find((l) => {
          return l.systemDefinitionId == b.systemContentVersion?.level?.id;
        });
        if (result) {
          return true;
        } else {
          false;
        }
      });
      setFilterBrandContentVersionPackage(newFilter);
    }
  }, [filterLevels, brandContentVersionPackage]);

  useEffect(() => {
    if (filterLevels && draft) {
      const newFilter = draft
        .map((version) => {
          const r = filterLevels.find((l) => {
            return (l.systemDefinitionId ? l.systemDefinitionId : l.id) == (version?.level?.systemDefinitionId ? version?.level?.systemDefinitionId : version?.level?.id);
          });
          return r;
        })
        .filter((d) => {
          if (d) {
            return d;
          }
        });
      setDraftAnchorItems(createAnchorItem(newFilter));
    }
  }, [filterLevels, draft]);

  useEffect(() => {
    if (filterLevels && published) {
      const newFilter = published
        .map((version) => {
          const r = filterLevels.find((l) => {
            return (l.systemDefinitionId ? l.systemDefinitionId : l.id) == (version?.level?.systemDefinitionId ? version?.level?.systemDefinitionId : version?.level?.id);
          });
          return r;
        })
        .filter((d) => {
          if (d) {
            return d;
          }
        });
      setPublishedAnchorItems(createAnchorItem(newFilter));
    }
  }, [filterLevels, published]);

  const createAnchorItem = (_levels) => {
    const levels = _levels.filter((value, index, array) => array.indexOf(value) === index);
    const items: AnchorLinkItemProps[] = [];
    levels?.forEach((l) => {
      const _field = l.theme?.type?.field;
      const item = items.find((i) => i.title == _field?.name);
      const itemIndex = items.findIndex((i) => i.title == _field?.name);
      if (item) {
        const typeChild = item.children?.find((t) => t.title == l.theme?.type?.name);
        const typeIndex = item.children?.findIndex((t) => t.title == l.theme?.type?.name);
        if (typeChild) {
          const themeChild = typeChild.children?.find((t) => t.title == l.theme?.name);
          const themeIndex = typeChild.children?.findIndex((t) => t.title == l.theme?.name);
          if (themeChild) {
            const newTheme = {
              ...themeChild,
              children: [
                ...themeChild.children!,
                {
                  key: l.id!,
                  title: l.name!,
                  href: '#' + l.id,
                },
              ],
            } as AnchorLinkItemProps;
            items[itemIndex].children![typeIndex!].children![themeIndex!] = newTheme;
          } else {
            const themes = items[itemIndex].children![typeIndex!].children;
            const newTheme = [
              ...themes!,
              {
                key: l.theme?.id!,
                title: l.theme?.name!,
                href: '#' + l.theme?.id,
                children: [
                  {
                    key: l.id!,
                    title: l.name!,
                    href: '#' + l.id,
                  },
                ],
              },
            ];
            items[itemIndex].children![typeIndex!].children = newTheme;
          }
        } else {
          const newItem = {
            ...item,
            children: [
              ...item.children!,
              {
                key: l.theme?.type?.id!,
                title: l.theme?.type?.name!,
                href: '#' + l.theme?.type?.id,
                children: [
                  {
                    key: l.theme?.id!,
                    title: l.theme?.name!,
                    href: '#' + l.theme?.id,
                    children: [
                      {
                        key: l.id!,
                        title: l.name!,
                        href: '#' + l.id,
                      },
                    ],
                  },
                ],
              },
            ],
          } as AnchorLinkItemProps;
          items[itemIndex] = newItem;
        }
      } else {
        items.push({
          key: _field?.id!,
          title: _field?.name!,
          href: '#' + _field?.id,
          children: [
            {
              key: l.theme?.type?.id!,
              title: l.theme?.type?.name!,
              href: '#' + l.theme?.type?.id,
              children: [
                {
                  key: l.theme?.id!,
                  title: l.theme?.name!,
                  href: '#' + l.theme?.id,
                  children: [
                    {
                      key: l.id!,
                      title: l.name!,
                      href: '#' + l.id,
                    },
                  ],
                },
              ],
            },
          ],
        });
      }
    });

    return items;
  };
  const onSearchLevel = (text: string) => {
    if (text && levels) {
      const lowerCase = text.toLocaleLowerCase();
      const filter = levels.filter((l) => {
        return (
          l.name?.toLocaleLowerCase('tr').includes(lowerCase) ||
          l.theme?.name?.toLocaleLowerCase().includes(lowerCase) ||
          l.theme?.type?.name?.toLocaleLowerCase().includes(lowerCase) ||
          l.theme?.type?.field?.name?.toLocaleLowerCase().includes(lowerCase)
        );
      });
      setFilterLevels(filter);
    } else {
      setFilterLevels(levels);
    }
  };

  const updateVersionPakced = () => {
    return (
      <div className="grid grid-cols-4 h-full">
        {filterBrandContentVersionPackage.map((updatePackage) => (
          <div key={updatePackage.id}>{updateVersionBoxWithLevelId(updatePackage)}</div>
        ))}
      </div>
    );
  };

  const updateVersionBoxWithLevelId = (updatePackage: BrandContentVersionUpdatePackage) => {
    const { systemContentVersion, status } = updatePackage;
    return (
      <Alert
        key={systemContentVersion?.id}
        message={
          <div className="flex flex-col">
            <div className="flex flex-row justify-between space-x-1">
              <Tag color={status == BrandContentVersionUpdatePackageStatus.APPLIED ? 'green' : status == BrandContentVersionUpdatePackageStatus.FAILED ? 'red' : 'blue'}>
                {status}
              </Tag>
              <ActionDropdown
                data={systemContentVersion}
                onDelete={async ({ id }) => {
                  //onDeleteVersion(id);
                }}
                onEdit={({ id }) => {
                  //onEditVersion(id);
                }}
              />
            </div>
            <div className="flex flex-row justify-between ">
              <div className="space-x-1">
                <label className="font-bold">Adı:</label>
                <span>{systemContentVersion?.name}</span>
              </div>
            </div>

            <div className="flex flex-row space-x-1">
              <label className="font-bold">Açıklama:</label>
              <span>{systemContentVersion?.description}</span>
            </div>

            <div className="flex flex-row space-x-1">
              <label className="font-bold">Version:</label>
              <span>V{systemContentVersion?.version}</span>
            </div>
          </div>
        }
        type={status == BrandContentVersionUpdatePackageStatus.APPLIED ? 'success' : status == BrandContentVersionUpdatePackageStatus.FAILED ? 'error' : 'info'}
      />
    );
  };

  const publishedVersionsComponent = () => {
    if (publishedAnchorItems.length == 0) {
      return (
        <div className="w-full h-96 flex items-center justify-center">
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            description={
              <div className="flex flex-col space-y-2">
                <span className="text-lg">Henuz yayınlanmış bir ders bulunmamaktadır.</span>
                <Button
                  type="primary"
                  onClick={() => {
                    newVersionRequest();
                  }}
                >
                  Yeni Taslak Oluştur
                </Button>
              </div>
            }
          />
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-12 h-full">
          <div className="col-span-2">
            <Anchor getContainer={() => document.getElementById('published-scroll')!} showInkInFixed={true} affix={true} items={publishedAnchorItems} />
          </div>
          <div className="col-span-10 overflow-y-scroll" id="published-scroll">
            {publishedAnchorItems.map((field) => (
              <div key={field.key} id={field.href.replace('#', '')}>
                <div className="flex flex-row space-x-2">
                  <CaretDownOutlined className="text-[#1677FF]" />
                  <span className="text-[#1677FF] text-xl ">{field.title}</span>
                </div>
                {field.children?.map((type) => (
                  <div key={type.key} id={type.href.replace('#', '')}>
                    <div className="flex flex-row space-x-2 pl-3">
                      <CaretDownOutlined className="text-[#1677FF]" />
                      <span className="text-[#1677FF] text-lg">{type.title}</span>
                    </div>
                    {type.children?.map((theme) => (
                      <div key={theme.key} id={theme.href.replace('#', '')}>
                        <span className="text-[#1677FF] text-xs pl-8">{theme.title}</span>
                        <div className="grid grid-cols-2 mt-2 gap-x-1">
                          {theme.children?.map((l) => {
                            const label = l.title + '';
                            return versionBoxWithLevelId(label, l.key, ContentVersionStatus.PUBLISHED);
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const draftVersionsComponent = () => {
    if (draftAnchorItems.length == 0) {
      return (
        <div className="w-full h-96 flex items-center justify-center">
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            description={
              <div className="flex flex-col space-y-2">
                <span className="text-lg">Henuz taslak çalışma oluşturulmamış.</span>
                <Button
                  type="primary"
                  onClick={() => {
                    newVersionRequest();
                  }}
                >
                  Yeni Taslak Oluştur
                </Button>
              </div>
            }
          />
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-12 h-full">
          <div className="col-span-2">
            <Anchor getContainer={() => document.getElementById('published-scroll')!} showInkInFixed={true} affix={true} items={draftAnchorItems} />
          </div>
          <div className="col-span-10 overflow-y-scroll" id="published-scroll">
            {draftAnchorItems.map((field) => (
              <div key={field.key} id={field.href.replace('#', '')}>
                <div className="flex flex-row space-x-2">
                  <CaretDownOutlined className="text-[#1677FF]" />
                  <span className="text-[#1677FF] text-xl ">{field.title}</span>
                </div>

                {field.children?.map((type) => (
                  <div key={type.key} id={type.href.replace('#', '')}>
                    <div className="flex flex-row space-x-2 pl-3">
                      <CaretDownOutlined className="text-[#1677FF]" />
                      <span className="text-[#1677FF] text-lg">{type.title}</span>
                    </div>
                    {type.children?.map((theme) => (
                      <div key={theme.key} id={theme.href.replace('#', '')}>
                        <span className="text-[#1677FF] text-xs pl-8">{theme.title}</span>
                        <div className="grid grid-cols-2 mt-2 gap-x-1">
                          {theme.children?.map((l) => {
                            const label = l.title + '';
                            return versionBoxWithLevelId(label, l.key, ContentVersionStatus.DRAFT);
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const generedDropdownItems = (id: string, versionStatus: ContentVersionStatus) => {
    let items: MenuProps['items'] = [];
    if (versionStatus == ContentVersionStatus.DRAFT) {
      items = [
        {
          key: id + '_dropdown',
          icon: <ShareAltOutlined />,
          label: 'İncele ve Yayına Al',
          onClick: async () => {
            onPublishVersion(id);
          },
        },
      ];
    }
    if (versionStatus == ContentVersionStatus.PUBLISHED) {
      if (brandContentVersionPackage) {
        items = [
          {
            key: id + '_dropdown_publish_fork',
            icon: <ForkOutlined />,
            label: 'Bu versiyonu taslak olarak çalışmaya al',
            onClick: async () => {
              onForkVersion(id);
            },
          },
          {
            key: id + '_dropdown_default',
            icon: <SlidersOutlined />,
            label: 'Bu versiyonu ana versiyon olarak ayarla',
            onClick: async () => {
              if (onDefaultVersion) {
                onDefaultVersion(id);
              }
            },
          },
        ];
      } else {
        items = [
          {
            key: id + '_dropdown_publish_fork',
            icon: <ForkOutlined />,
            label: 'Bu versiyonu taslak olarak çalışmaya al',
            onClick: async () => {
              onForkVersion(id);
            },
          },
        ];
      }
    }

    return items;
  };

  const versionBoxWithLevelId = (title: string, levelId, versionStatus: ContentVersionStatus) => {
    let arr;
    if (versionStatus == ContentVersionStatus.DRAFT) {
      arr = draft?.filter((d) => d.level?.id == levelId);
    } else if (versionStatus == ContentVersionStatus.PUBLISHED) {
      arr = published?.filter((d) => d.level?.id == levelId);
    }
    return (
      <Card title={<div className="m-1 p-0">{title}</div>} className="p-0 m-0" type="inner" size="small" bordered>
        <div className="grid grid-cols-2 space-x-1">
          {arr.map((v) => (
            <Card key={v.id} className={v.isDefault ? 'border border-blue-500 m-1' : 'bg-gray-50 m-1 '} size="small" hoverable>
              <Card.Meta
                title={
                  <div className="flex flex-row justify-between">
                    <span className="line-clamp-1 hover:line-clamp-4 text-wrap">{v.name}</span>
                    <ActionDropdown
                      data={v}
                      items={generedDropdownItems(v.id, versionStatus)}
                      //TODO:silme islemi geri alınmalı hakan hoca geçici kapatılmasını istedi
                      //isDelete={v.status == ContentVersionStatus.DRAFT}
                      isDelete={false}
                      onDelete={async ({ id }) => {
                        onDeleteVersion(id);
                      }}
                      isEdit={v.status == ContentVersionStatus.DRAFT}
                      onEdit={({ id }) => {
                        onEditVersion(id);
                      }}
                    />
                  </div>
                }
                description={
                  v.isDefault ? (
                    <div className="flex flex-col justify-between">
                      <span className="line-clamp-2">{v.description}</span>

                      <div className="flex flex-row justify-between">
                        <Tag color="green">
                          <TagOutlined className="mr-1" />
                          {v.version}
                          {v.brandVersion ? `.${v.brandVersion}` : '.0'}
                        </Tag>
                        <Tag color="blue">Default</Tag>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col justify-between">
                      <span className="line-clamp-2">{v.description}</span>
                      <div className="flex flex-row justify-between">
                        <Tag color="green">
                          <TagOutlined className="mr-1" /> {v.version}
                          {v.brandVersion ? `.${v.brandVersion}` : '.0'}
                        </Tag>
                      </div>
                    </div>
                  )
                }
              />
            </Card>
          ))}
        </div>
      </Card>
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-row content-center  items-baseline justify-between ">
        <Tabs
          size="large"
          items={tabs}
          onChange={(key: string) => {
            setTabKey(key);
          }}
        />
        <div className="flex flex-row space-x-2">
          <Button
            type="primary"
            ghost
            onClick={() => {
              newVersionRequest();
            }}
          >
            Yeni Çalışma Başlat
          </Button>
          <Input.Search
            onChange={(e) => {
              onSearchLevel(e.target.value);
            }}
            placeholder="Version ara"
            enterButton
          />
        </div>
      </div>
      {tabKey == ContentVersionStatus.PUBLISHED && publishedVersionsComponent()}
      {tabKey == ContentVersionStatus.DRAFT && draftVersionsComponent()}
      {tabKey == 'package' && updateVersionPakced()}
    </div>
  );
};
