import { useUserContext } from '@shared/contexts';
import { AccessableFeatures } from '@shared/models';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const AuthGuardContext = () => {
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();
  const [scope, setScope] = useState<Partial<{ branchId: string; brandId: string }>>({});
  const [accessables, setAccessables] = useState<AccessableFeatures>({} as AccessableFeatures);

  useEffect(() => {
    if (searchParams) {
      setScope({ branchId: searchParams.get('branchId')!, brandId: searchParams.get('brandId')! });
    }
  }, [searchParams, user]);

  useEffect(() => {
    if (user?.jwt) {
      setAccessables(user.features!);
    }
  }, [user, searchParams]);

  const hasBranchPermission = (permissions: string[]): boolean => {
    if (!scope || !accessables) {
      return false;
    }

    if (!permissions || permissions.length == 0) {
      return true;
    }

    if (scope.branchId) {
      const accessableBranchPermissions = accessables.branch[scope.branchId].permissions;
      return accessableBranchPermissions?.role == 'ADMIN' || !!permissions.find((p) => accessableBranchPermissions?.values?.includes(p));
    }

    return false;
  };

  const hasBrandPermission = (permissions: string[]): boolean => {
    if (!scope || !accessables) {
      return false;
    }

    if (!permissions || permissions.length == 0) {
      return true;
    }

    if (scope.brandId) {
      const accessableBrandPermissions = accessables.brand[scope.brandId].permissions;
      return accessableBrandPermissions?.role == 'ADMIN' || !!permissions.find((p) => accessableBrandPermissions?.values?.includes(p));
    }

    return false;
  };

  return {
    SearchParams: searchParams,
    Scope: scope,
    Accessables: accessables,
    HasBrandPermission: hasBrandPermission,
    HasBranchPermission: hasBranchPermission,
    User: user,
  };
};
