import { NationalIdInput, PhoneInput } from '@shared/components';
import { useForm } from '@shared/hooks';
import { Enrollment } from '@shared/models';
import { Button, Form, Input } from 'antd';
import { useCallback } from 'react';
import { z } from 'zod';

const CreateRegisterStepForm = z.object({
  name: z.string({ required_error: 'Kayıt adı zorunlu alandır' }).min(1, 'Kayıt adı 1 karekterden fazla olmalı'),
  email: z.string({ required_error: 'Email zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
  nationalId: z.string({ required_error: 'TC/VKN zorunlu alandır' }).min(1),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
});

type CreateEnterpriseInfoStepProps = {
  enrollment?: Enrollment;
  onSave: (enrollment: Enrollment) => void;
  isEditMod?: boolean;
  isPreview?: boolean;
};

export const CreateEnterpriseInfoStep = ({ enrollment = {}, onSave, isPreview = false }: CreateEnterpriseInfoStepProps) => {
  const form = useForm<
    Partial<{
      name: string;
      email: string;
      nationalId: string;
      'phone.number': string;
      'phone.code': string;
    }>,
    Enrollment
  >(CreateRegisterStepForm, enrollment);

  const sendData = useCallback(async () => {
    const result = form.parse();
    if (result.success) {
      onSave({
        ...result.data,
      });
    } else {
      form.setAllTouched();
    }
  }, [form]);

  return (
    <div className="grid grid-cols-2">
      <div>
        <Form layout="vertical">
          <div className="grid grid-cols-2 gap-x-2">
            <Form.Item {...form.formItem('name')} label={'Firma Adı:'}>
              <Input {...form.input('name')} disabled={isPreview} />
            </Form.Item>
            <NationalIdInput form={form} disabled={isPreview} />
          </div>
          <div className="grid grid-cols-2 gap-x-2">
            <PhoneInput form={form} disabled={isPreview} />
            <Form.Item {...form.formItem('email')} label={'Firma E-Posta:'}>
              <Input {...form.input('email')} disabled={isPreview} />
            </Form.Item>
          </div>
        </Form>
      </div>
      <div></div>
      <div className="col-span-2 flex justify-end">
        <Button type="primary" onClick={sendData}>
          Sonraki adım
        </Button>
      </div>
    </div>
  );
};
