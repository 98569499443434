import { useForm } from '@shared/hooks';
import { Alert, Button, Form, Input, notification } from 'antd';
import { ReactElement } from 'react';
import { z } from 'zod';

type DeleteAlertProps = {
  name: string | undefined;
  description: string | ReactElement;
  onDelete: (deleteItemInput: string) => void;
  onCancel: () => void;
};

const DeleteAlertForm = z.object({
  deleteItemInput: z.string({ required_error: 'Bu alan boş bırakılamaz' }).min(1, 'Alan 1 karekterden fazla olmalı'),
});

export const DeleteAlert = ({ name, description, onDelete, onCancel }: DeleteAlertProps) => {
  const form = useForm<{ deleteItemInput: string }>(DeleteAlertForm, {});
  return (
    <>
      <Alert description={description} type="warning" showIcon />
      <Form layout="vertical">
        <Form.Item
          className="mt-2"
          label={
            <i>
              Silmek istediğiniz varlığın adını (<b>{name}</b>) yazınız
            </i>
          }
          {...form.formItem('deleteItemInput')}
        >
          <Input {...form.input('deleteItemInput')} />
        </Form.Item>
        <div className="flex justify-end gap-1">
          <Button
            key="cancel"
            onClick={() => {
              onCancel();
            }}
          >
            İptal
          </Button>
          ,
          <Button
            key="submit"
            type="primary"
            danger
            onClick={() => {
              const result = form.parse();
              if (result.success) {
                if (result.data['deleteItemInput'] !== name) {
                  notification.warning({ message: 'Girmek istediğiniz isim eşleşmiyor!' });
                  return;
                }
                onDelete(result.data['deleteItemInput']);
                form.reset();
              } else {
                form.setAllTouched();
              }
            }}
          >
            Varlığı Kalıcı Olarak Silmek İstiyorum
          </Button>
        </div>
      </Form>
    </>
  );
};
