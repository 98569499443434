import { PermissionsModel } from './permissions';

export type LoginUser = Partial<{
  id: string;
  name: string;
  surname: string;
  email: string;
  jwt: string;
  features: AccessableFeatures;
}>;

export type BranchPermissionAndSubFeatures = {
  permissions: PermissionsModel;
  isStudent: Boolean;
  isTeacher: Boolean;
  isObserver: Boolean;
};

export type AccessableFeatures = {
  brand: {
    [brandId: string]: {
      permissions: PermissionsModel;
    };
  };
  branch: {
    [branchId: string]: BranchPermissionAndSubFeatures;
  };
  isSystemAdmin: boolean;
};
