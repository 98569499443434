import { RedoOutlined } from '@ant-design/icons';
import { BranchCalendarModel, BrandCalendarModel, PaginateModel } from '@shared/models';
import { convertDate } from '@shared/utils';
import { Button, Popconfirm, Table, TableProps, Tooltip } from 'antd';
import dayjs from 'dayjs';

type ExcludedHolidayTableProps = {
  excludedHolidayCalendar: PaginateModel<BranchCalendarModel | BrandCalendarModel> | undefined;
  loading: boolean;
  onBringBackHoliday: (id: string) => void;
  onPaginationChange: (current: number, pageSize: number) => void;
};

export const ExcludedHolidayTable = ({ excludedHolidayCalendar, loading, onBringBackHoliday, onPaginationChange }: ExcludedHolidayTableProps) => {
  const columns: TableProps<BrandCalendarModel | BranchCalendarModel>['columns'] = [
    {
      key: 'date',
      title: 'Tarih',
      align: 'center',

      render: ({ systemCalendarEntry, brandCalendarEntry, owner }) =>
        owner == 'BRAND' ? convertDate(brandCalendarEntry?.date, 'D MMMM YYYY dddd') : convertDate(systemCalendarEntry?.date, 'D MMMM YYYY dddd'),
    },
    {
      title: 'Açıklama',
      align: 'center',
      render: ({ systemCalendarEntry, brandCalendarEntry, owner }) => (
        <Tooltip title={owner == 'BRAND' ? brandCalendarEntry?.description : systemCalendarEntry?.description} placement="top">
          <div className="h-full w-full">{owner == 'BRAND' ? brandCalendarEntry?.name : systemCalendarEntry?.name}</div>
        </Tooltip>
      ),
    },

    {
      title: 'Eylemler',
      key: 'id',
      align: 'center',
      render: (record) => (
        <Popconfirm
          title="Tatili Aktif Et"
          description="Bu tatili aktif etmek istediğinize emin misiniz?"
          okText="Evet"
          cancelText="İptal"
          onConfirm={() => onBringBackHoliday(record.id)}
        >
          <Button className="text-[#1677ff]" type="text" icon={<RedoOutlined rotate={-90} />} />
        </Popconfirm>
      ),
    },
  ];
  return (
    <Table
      loading={loading}
      rowKey={(record) => record.id}
      dataSource={excludedHolidayCalendar?.data}
      columns={columns}
      bordered
      pagination={{
        showSizeChanger: true,
        showPrevNextJumpers: true,
        current: excludedHolidayCalendar?.page,
        pageSize: excludedHolidayCalendar?.limit,
        total: excludedHolidayCalendar?.count,
        position: ['bottomCenter'],
      }}
      onChange={({ current, pageSize }) => onPaginationChange(current!, pageSize!)}
    />
  );
};
