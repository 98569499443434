import { KanbanCard, KanbanCardProps } from '@platform/platform_components/kanban_view/KanbanCard';
import { Spinner } from '@shared/components';
import { Card, Spin } from 'antd';
import { ReactElement } from 'react';

export type KanbanColumnProps = {
  title: string | ReactElement;
  icon?: ReactElement;
  cards?: KanbanCardProps[];
  isLoading?: boolean;
  cardsStyle?: React.CSSProperties;
  footer?: string | ReactElement;
  loadMore?: string | ReactElement;
};

export const KanbanColumnTitle = (props: { title: string; count?: number; icon: ReactElement }) => {
  return (
    <span className="flex justify-between items-center">
      <div>
        {props.icon}
        <span className="ml-2"> {props.title}</span>
      </div>
      <div className="text-gray-400">{props.count || 0}</div>
    </span>
  );
};

export const KanbanColumn = (props: KanbanColumnProps) => {
  return (
    <div>
      <Card size="small" className="h-12">
        {typeof props.title == 'string' && props.icon ? (
          <>
            {props.icon}
            <span className="ml-2"> {props.title}</span>
          </>
        ) : (
          <>{props.title}</>
        )}
      </Card>

      <Card
        style={props.cardsStyle ?? { height: 'calc(100vh - 205px)' }}
        className="overflow-y-auto p-3 mt-3"
        bodyStyle={{ padding: '0', display: 'flex', flexDirection: 'column' }}
      >
        <Spin spinning={props.isLoading}>
          <div className="flex-grow flex flex-col gap-3">
            {props.cards && props.cards.length > 0 ? props.cards.map((card, index) => <KanbanCard key={index} {...card} />) : <div className="flex-grow" />}
          </div>
          {props.cards && props.cards.length > 0 && props.loadMore}
        </Spin>
      </Card>
    </div>
  );
};
