import { PlusCircleOutlined, PlusCircleTwoTone, UserDeleteOutlined, UserOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ActionDropdown } from '@shared/components';
import { useForm } from '@shared/hooks';
import { ClassDetailModel, PrePlacementDemandBasicInfo, StudentModel } from '@shared/models';
import { latinize, renderLevelsAsString } from '@shared/utils';
import { Button, Card, DatePicker, Divider, Empty, Form, MenuProps, Modal, notification, Popconfirm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import Search from 'antd/lib/input/Search';

type ClassStudentListProps = {
  classDetail: ClassDetailModel | undefined;
  availableStudentList: PrePlacementDemandBasicInfo[] | undefined;
  onRefleshClassDetail: () => void;
  hasBranchClassUpdatePermission?: boolean;
  isTeacherLayout?: boolean;
};

const RemoveStudentForm = z.object({
  //TODO type kaldırıldı eklenirse burası açılabilir.
  // type: z.string({ required_error: 'Lütfen işlem türü seçiniz' }),
  note: z.string({ required_error: 'Not giriniz' }).min(1, 'Not giriniz'),
});

const PendingStudentForm = z.object({
  // type: z.string({ required_error: 'Lütfen işlem türü seçiniz' }),
  note: z.string({ required_error: 'Not giriniz' }).min(1, 'Not giriniz'),
  pendingTillDate: z.string({ required_error: 'Askıya alma bitiş tarihi giriniz' }),
});

export const ClassStudentList = ({ classDetail, onRefleshClassDetail, hasBranchClassUpdatePermission, isTeacherLayout = false }: ClassStudentListProps) => {
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false);
  const [isRemoveStudentModalOpen, setIsRemoveStudentModalOpen] = useState(false);
  const [isPendingStudentModalOpen, setIsPendingStudentModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<StudentModel>();
  const navigate = useNavigate();
  const [availableStudentList, availableListLoading, getAvailableStudentList] = API.CLASS.getAvailableStudentsToAdd(classDetail?.id, { init: false });
  const [studentInput, setStudentInput] = useState<string>('');

  useEffect(() => {
    if (isAddStudentModalOpen) {
      getAvailableStudentList();
    }
  }, [isAddStudentModalOpen]);

  const onAddStudent = async (classId: string, demandId: string) => {
    const response = await API.CLASS.addStudentToClass(classId, demandId);
    if (response.ok) {
      await onRefleshClassDetail();
      await getAvailableStudentList();
      notification.success({ message: 'Öğrenci başarıyla sınıfa eklendi' });
    }
  };

  const onRemoveStudent = async (classId: string, studentId: string) => {
    const response = await API.CLASS.removeStudentFromClass(classId, studentId);
    if (response.ok) {
      await onRefleshClassDetail();
      await getAvailableStudentList();
      notification.success({ message: 'Öğrenci sınıftan çıkarıldı ve "Aksiyon Bekleyen Talep" durumuna alındı.' });
    }
  };
  const removeForm = useForm<
    Partial<{
      note: string;
      // type: string;
    }>
  >(RemoveStudentForm, {});

  const pendingForm = useForm<
    Partial<{
      note: string;
      // type: string;
      pendingTillDate: string;
    }>
  >(PendingStudentForm, {});

  //Kurs henüz başlamadıysa veya Kurs başlamış fakat üstünden henüz 7 gün geçmediyse buton gözükebilir
  // const studentAddButton = () => {
  //   return classDetail?.status == ClassStatus.NOT_STARTED || (classDetail?.status == ClassStatus.STARTED && dayjs().diff(dayjs(classDetail.calculatedStartDate), 'day') < 7);
  // };

  //TODO Şimdilik Kaydı Dondur ve Kaydı İptal Et için Modal componenti çağrılıyor fakat iptal nedeni ve iptal notu backende gönderilmiyor.
  //İbrahim abi kaydı iptal etme nedeni gibi bilgileri nasıl handle edeceğine karar verince Figma'ya uygun hale getirilecek.
  const createDropdownItems = (student: StudentModel): MenuProps['items'] => {
    return [
      {
        key: 'profil',

        label: 'Öğrenci Profili',
        icon: <UserOutlined />,
        onClick: () => {
          navigateStudentProfile(student.accountId!);
        },
      },

      {
        icon: <UserDeleteOutlined />,
        key: 'iptal',
        label: 'Öğrenciyi Sınıftan Çıkar',
        onClick: () => {
          setSelectedStudent(student);
          setIsRemoveStudentModalOpen(true);
        },
      },
    ];
  };

  const removeStudentOptions = [
    { label: 'Ödeme yapılmadı', value: 'payment' },
    { label: 'Para iadesi alındı', value: 'refund' },
    { label: 'Eğitimden vazgeçildi', value: 'cancel' },
    { label: 'Disiplin cezası', value: 'discipline' },
    { label: 'Vefat', value: 'death' },
  ];

  const pendingStudentOptions = [
    { label: 'Transfer', value: 'transfer' },
    { label: 'Geçici süreliğine dondurma', value: 'temporary' },
    { label: 'Geciken ödeme', value: 'latePayment' },
    { label: 'Şube kaynaklı', value: 'branch' },
  ];

  const removeStudentFromClass = useCallback(async () => {
    const result = removeForm.parse();
    if (result.success) {
      setIsRemoveStudentModalOpen(false);
      onRemoveStudent(classDetail?.id!, selectedStudent?.id!);
      removeForm.reset();
      setSelectedStudent(undefined);
    } else {
      removeForm.setAllTouched();
    }
  }, [removeForm]);

  const navigateStudentProfile = (id: string) => {
    if (isTeacherLayout) {
      navigate('/teacher/student_profile' + location.search + '&student_id=' + id);
    } else {
      navigate('/lms/student_profile' + location.search + '&student_id=' + id);
    }
  };

  return (
    <>
      <Card
        size="small"
        title={<span className=" font-normal text-[#00000073]"></span>}
        extra={
          /*studentAddButton() &&*/ classDetail?.status !== 'COMPLETED' &&
          hasBranchClassUpdatePermission && <PlusCircleOutlined className="cursor-pointer text-xl text-[#00000073]" onClick={() => setIsAddStudentModalOpen(true)} />
        }
        className="overflow-scroll"
      >
        {classDetail?.students?.map((student) => (
          <div key={student.id}>
            <div className="flex justify-between border-b border-[#0000000f]">
              <div>
                {student.name} {student.surname}
              </div>
              <div className="pr-1">
                {classDetail.status !== 'COMPLETED' && hasBranchClassUpdatePermission && (
                  <ActionDropdown placement="bottomRight" data={student} isDelete={false} isEdit={false} items={createDropdownItems(student)} />
                )}
              </div>
            </div>
            <Divider className="m-2" />
          </div>
        ))}
      </Card>
      <Modal
        className="max-h-[500px] 2xl:max-h-[600px] overflow-y-scroll"
        title={<span className="text-sm font-normal">Sınıfa Öğrenci Ekle</span>}
        open={isAddStudentModalOpen}
        loading={availableListLoading}
        footer={null}
        onCancel={() => setIsAddStudentModalOpen(false)}
        centered
      >
        <div className="font-semibold text-lg">{`${classDetail?.name}`}</div>
        <div className="font-semibold text-md">{`${renderLevelsAsString(classDetail?.level)}`}</div>
        <div className="italic text-[#00000073] text-base">
          {dayjs(classDetail?.calculatedStartDate).format('DD.MM.YYYY')} - {dayjs(classDetail?.calculatedEndDate).format('DD.MM.YYYY')}
        </div>
        <div className="mt-3">
          <Search
            value={studentInput}
            onChange={(e) => setStudentInput(e.target.value)}
            placeholder="Öğrenci ara..."
            onSearch={() => {
              getAvailableStudentList(undefined);
            }}
            enterButton
            className="mb-5"
          />
          {availableStudentList?.length! > 0 ? (
            availableStudentList
              ?.filter(
                (ogrenci) =>
                  studentInput === '' ||
                  latinize(ogrenci.prePlacement.name).includes(latinize(studentInput)) ||
                  latinize(ogrenci.prePlacement.surname).includes(latinize(studentInput))
              )
              .map((ogrenci) => (
                <div key={ogrenci.id}>
                  <div className="flex justify-between px-2 border-[#00000026]">
                    <div>
                      {ogrenci.prePlacement.name} {ogrenci.prePlacement.surname}
                    </div>
                    <Popconfirm
                      placement="bottom"
                      title={`${ogrenci.prePlacement.name} ${ogrenci.prePlacement.surname} isimli öğrenci ${classDetail?.level?.theme?.type?.field?.name} ${classDetail?.level?.theme?.type?.name} ${classDetail?.level?.theme?.name} sınıfına eklensin mi?`}
                      okText={'Evet'}
                      cancelText={'Hayır'}
                      onConfirm={() => onAddStudent(classDetail?.id!, ogrenci.id)}
                    >
                      <PlusCircleTwoTone className="cursor-pointer text-base text-[#00000073]" />
                    </Popconfirm>
                  </div>
                  <Divider className="m-3" />
                </div>
              ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Müsait öğrenci bulunamadı" />
          )}
        </div>
      </Modal>

      <Modal
        className="max-h-[500px] 2xl:max-h-[600px]"
        title={<span className="text-sm font-normal">Öğrenciyi sınıftan çıkar</span>}
        open={isRemoveStudentModalOpen}
        footer={null}
        onCancel={() => {
          removeForm.reset();
          setSelectedStudent(undefined);
          setIsRemoveStudentModalOpen(false);
        }}
        centered
      >
        <div className="font-semibold text-lg">{`${selectedStudent?.name} ${selectedStudent?.surname}`}</div>
        <div className="italic text-[#00000073] text-base">{dayjs().format('DD.MM.YYYY')}</div>

        <Form layout="vertical" className="mt-5">
          <Form.Item {...removeForm.formItem('note')} label="Not">
            <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 3, maxRows: 5 }} {...removeForm.input('note')} />
          </Form.Item>

          <Button type="primary" onClick={removeStudentFromClass} className="mt-3">
            Öğrenciyi sınıftan çıkar
          </Button>
        </Form>
      </Modal>

      <Modal
        className="max-h-[500px] 2xl:max-h-[600px]"
        title={<span className="text-sm font-normal">Kaydı Dondur</span>}
        open={isPendingStudentModalOpen}
        footer={null}
        onCancel={() => {
          pendingForm.reset();
          setSelectedStudent(undefined);
          setIsPendingStudentModalOpen(false);
        }}
        centered
      >
        <div className="font-semibold text-lg">{`${selectedStudent?.name} ${selectedStudent?.surname}`}</div>
        <div className="italic text-[#00000073] text-base">{dayjs().format('DD.MM.YYYY')}</div>

        <Form layout="vertical" className="mt-5">
          <div className="flex justify-between gap-4">
            <Form.Item {...pendingForm.formItem('pendingTillDate')} label="Kayıt dondurma süresi" className="w-full">
              <DatePicker className="w-full" placeholder="Seçiniz" {...pendingForm.inputDate('pendingTillDate')} minDate={dayjs()} />
            </Form.Item>
          </div>
          <Form.Item {...pendingForm.formItem('note')} label="Not">
            <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 3, maxRows: 5 }} {...pendingForm.input('note')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
