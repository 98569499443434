import { useFetch } from '@shared/hooks';
import { PaginateModel, UseFetchOptions } from '@shared/models';
import { AccountIssueProps, CreateAccountIssueProps } from '@shared/models/account_issue';
import { request } from '@shared/utils';

export default {
  paginate: (config: UseFetchOptions) => useFetch<PaginateModel<AccountIssueProps>>('/issue', { method: 'GET', ...config }),
  createIssue: (data: CreateAccountIssueProps) => request('/issue', 'POST', data),
  updateIssue: (id: string, data: CreateAccountIssueProps) => request('/issue/' + id, 'PUT', data),
  deleteIssue: (id: string) => request('/issue/' + id, 'DELETE'),
  retrive: (id: string) => request('/issue/' + id, 'GET'),
  createComment: (issueId: string, comment: string) => request('/issue/' + issueId + '/comment', 'PUT', { comment }),
  removeComment: (issueId: string, commentId: string) => request('/issue/' + issueId + '/comment/' + commentId, 'DELETE'),
};
