import { BuildOutlined, CheckCircleOutlined, CloseCircleOutlined, HistoryOutlined, ManOutlined, MoonOutlined, MoreOutlined, SkinOutlined, WomanOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { PrePlacementDemandStatus } from '@shared/constants';
import { Account, GanntCalendarItemType, GanntCalendarType, PrePlacementDemandBasicInfo, PrePlacementDemandHistoryProps, PrePlacementDemandStatusUpdate } from '@shared/models';
import { calculateAge, convertDate, covertCalendarItems, localizeDemandStatus, renderLevelsAsString } from '@shared/utils';
import { Badge, Button, DatePicker, Dropdown, MenuProps, Modal, notification, Radio, Timeline, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs, { Dayjs } from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TbUserCancel } from 'react-icons/tb';
import { LuBaby } from 'react-icons/lu';
import { LuCalendarClock } from 'react-icons/lu';
import enrollment from '@shared/api/enrollment';
import useOnScreen from '@shared/hooks/useOnScreen';

dayjs.extend(dayOfYear);

export interface GanttProps {
  data: GanntCalendarType[];
  startPoint: Dayjs;
  callDemandList: () => void;
}
export const Gantt = ({ data, startPoint, callDemandList }: GanttProps) => {
  const [ref, setRef] = useState<HTMLElement | null>();

  const months = useMemo(() => {
    return Array.from({ length: 25 }).map((v, i) => i);
  }, []);

  const monthOffset = useMemo(() => {
    return startPoint.month() - 12;
  }, [startPoint]);

  const dayOffset = useMemo(() => {
    return (
      Array.from({ length: 12 })
        .map((v, i) =>
          dayjs()
            .date(1)
            .month(i + monthOffset)
        )
        .reduce((acc, date) => acc + date.daysInMonth(), 0) - 1
    );
  }, [startPoint, monthOffset]);

  useEffect(() => {
    if (ref) {
      ref.scroll(336 * 10, 0);
    }
  }, [ref, startPoint]);

  const dataFormatted = useMemo(() => {
    const a = data.map((dataItem) => {
      const filtered = dataItem.dates.filter((date) => {
        const left = date.startDate.getTime() >= startPoint.date(1).month(monthOffset).toDate().getTime();
        const right =
          date.startDate.getTime() <
          startPoint
            .date(1)
            .month(25 + monthOffset)
            .toDate()
            .getTime();

        return left && right;
      });
      const converted = covertCalendarItems(filtered, startPoint);
      return { ...dataItem, rowCount: converted.rowCount, dates: converted.dates, enrollmentName: dataItem.enrollmentName };
    });
    return data.map((dataItem) => {
      const filtered = dataItem.dates.filter((date) => {
        const left = date.startDate.getTime() >= startPoint.date(1).month(monthOffset).toDate().getTime();
        const right =
          date.startDate.getTime() <
          startPoint
            .date(1)
            .month(25 + monthOffset)
            .toDate()
            .getTime();

        return left && right;
      });
      const converted = covertCalendarItems(filtered, startPoint);
      return { ...dataItem, rowCount: converted.rowCount, dates: converted.dates };
    });
  }, [startPoint, data, monthOffset]);

  const style = { '--day-offset': dayOffset } as React.CSSProperties;

  return (
    <div className="gantt" style={style}>
      <div className="flex flex-col">
        <div className="gantt-top-header">Öğrenci Listesi</div>
        {dataFormatted.map((item) => (
          <GanttRowHeader key={item.name} item={item} rowCount={item.rowCount} accountId={item.accountId} />
        ))}
      </div>
      <div ref={setRef} className="gannt-calendar">
        <div className="gannt-calendar-header">
          {months.map((month) => (
            <GanttHeaderMonth startPoint={startPoint} key={month} month={month} monthOffset={monthOffset} />
          ))}
        </div>
        {dataFormatted.map((item, index) => (
          <GanttCalendarRow key={index} text={item.name} item={item} rowCount={item.rowCount} calendarItems={item.dates} callDemandList={callDemandList} />
        ))}

        {months.map((month) => (
          <GanttCalendarDivider startPoint={startPoint} key={month} month={month} monthOffset={monthOffset} />
        ))}
        <GanttCalendarTodayDivider startPoint={startPoint} monthOffset={monthOffset} />
      </div>
    </div>
  );
};

export interface GanttHeaderMonthProps {
  startPoint: Dayjs;
  month: number;
  monthOffset: number;
}

export const GanttHeaderMonth = ({ startPoint, month, monthOffset }: GanttHeaderMonthProps) => {
  const date = startPoint.date(1).month(month + monthOffset);
  const dayCount = date.daysInMonth();

  const style = { '--day-count': dayCount } as React.CSSProperties;

  return (
    <div className="gannt-calendar-header-item" style={style}>
      {convertDate(date, 'YYYY MMMM')}
    </div>
  );
};

export interface GanttCalendarItemProps {
  text: string;
  item: any; //TODO
  status: string;
  isSuccess: boolean;
  dayStart: number;
  daySpan: number;
  rowIndex: number;
  prePlacementId: string;
  demandId: string;
  levelName: string;
  history: PrePlacementDemandHistoryProps[];
  callDemandList: () => void;
}

export const GanttCalendarItem = ({
  item,
  history,
  prePlacementId,
  demandId,
  levelName,
  text,
  dayStart,
  daySpan,
  rowIndex,
  status,
  isSuccess,
  callDemandList,
}: GanttCalendarItemProps) => {
  const style = { '--day-start': dayStart, '--day-span': daySpan, '--row-index': rowIndex } as React.CSSProperties;
  const [statusUpdateModel, setStatusUpdateModel] = useState<PrePlacementDemandStatusUpdate>({
    description: '',
  });
  const [modalOptions, setModalOptions] = useState<{
    title: string;
    status?: PrePlacementDemandStatus;
    callback: Function;
  }>();
  const [modalOptionData, setModalOptionOpenData] = useState<PrePlacementDemandBasicInfo[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<PrePlacementDemandBasicInfo>();
  const [modalRepetationOpen, setModalRepetationOpen] = useState<boolean>();
  const [demandHistoryModal, setDemandHistoryModal] = useState<boolean>();
  const [element, setElement] = useState<HTMLElement | null>();

  const isVisible = useOnScreen(element);

  //---->
  //TODO Burası dışarıdan hesaplanıp verilebilir
  const menu: MenuProps = {
    triggerSubMenuAction: 'click',
    items: [],
  };

  const demandLifeCycleActionMap = {
    'show.demand.history': {
      key: 'show.demand.history',
      label: (
        <div className="flex gap-2.5 px-2">
          <HistoryOutlined />
          <span>Tarihçesini Göster</span>
        </div>
      ),
      onClick: async () => {
        setDemandHistoryModal(true);
      },
    },

    //
    'send.to.planning': {
      key: 'send.to.planning',
      label: (
        <div className="flex gap-2.5 px-2">
          <BuildOutlined />
          <span>Planlamaya Gönder</span>
        </div>
      ),
      onClick: async () => {
        setModalOptions({
          title: 'Kaydı Planlamaya Gönder',
          callback: API.ENROLLMENT.updateDemandStatusAsPlanning,
        });
      },
    },
    cancel: {
      key: 'cancel',
      label: (
        <div className="flex gap-2.5 px-2 items-center">
          <TbUserCancel style={{ fontSize: '115%' }} />
          <span>İptal Et</span>
        </div>
      ),
      onClick: () =>
        setModalOptions({
          title: 'Kaydı İptal et',
          callback: API.ENROLLMENT.updateDemandStatusAsCanceled,
        }),
    },

    'delayed.action': {
      key: 'delayed.action',
      label: (
        <div className="flex gap-2.5 px-2 items-center">
          <LuCalendarClock />
          <span>1 Ay Sonra Gündeme Getir</span>
        </div>
      ),
      onClick: () =>
        setModalOptions({
          title: 'Kaydı 1 Ay Sonra Gündeme Getir',
          callback: API.ENROLLMENT.updateDemandStatusAsDelayedActionRequired,
        }),
    },
    'remove.from.planning': {
      key: 'remove.from.planning',
      label: (
        <div className="flex gap-2.5 px-2">
          <BuildOutlined />
          <span>Planlamadan Çıkar</span>
        </div>
      ),
      onClick: () =>
        setModalOptions({
          title: 'Kaydı Planlamadan Çıkar',
          callback: API.ENROLLMENT.updateDemandStatusAsDelayedActionRequired,
        }),
    },
    success: {
      key: 'success',
      label: (
        <div className="flex gap-2.5 px-2">
          <CheckCircleOutlined />
          <span>Kur Tekrarı Yapılmayacak</span>
        </div>
      ),
      onClick: () =>
        setModalOptions({
          title: 'Kur Tekrarı Yapılmayacak',
          callback: API.ENROLLMENT.updateDemandStatusAsSuccess,
        }),
    },
    repeat: {
      key: 'repeat',
      label: (
        <div className="flex gap-2.5 px-2">
          <CloseCircleOutlined />
          <span>Kur Tekrarı Yapılacak</span>
        </div>
      ),
      onClick: async () => {
        const response = await API.ENROLLMENT.repeatDemandRequest(prePlacementId, demandId, statusUpdateModel);
        if (response.ok) {
          if (response.data != null) {
            setModalOptionOpenData(response.data);
            setModalRepetationOpen(true);
            callDemandList();
          } else {
            setModalOptions({
              title: 'Kur Tekrarı Yapılacak',
              callback: API.ENROLLMENT.repeatDemandRequest,
            });
            notification.success({ message: 'İşlem başarıyla gerçekleşti' });
          }
        }
      },
    },
    freeze: {
      key: 'freeze',
      label: (
        <div className="flex gap-2.5 px-2">
          <MoonOutlined />
          <span>Dondur</span>
        </div>
      ),
      onClick: () =>
        setModalOptions({
          title: 'Kaydı Dondur',
          status: PrePlacementDemandStatus.FROZEN,
          callback: API.ENROLLMENT.updateDemandStatusAsFreeze,
        }),
    },
  };

  const gantItemClassMap = {
    ACTION_REQUIRED: 'bg-purple-50 border-purple-700 text-purple-700 flex items-center justify-center rounded-lg p-1 border-solid',
    DELAYED_ACTION_REQUIRED: 'bg-purple-50 border-purple-200 text-purple-300 flex items-center justify-center rounded-lg p-1 border-solid',
    PLANNING: 'bg-yellow-50 border-yellow-500 text-yellow-500 flex items-center justify-center rounded-lg p-1 border-dashed border-2',
    ACTIVE: 'bg-green-50 border-green-500 text-green-500 flex items-center justify-center rounded-lg p-1 border-solid',
    SUCCESS: 'bg-blue-50 border-blue-500 text-blue-400 flex items-center justify-center rounded-lg p-1 border-solid',
    FAILED: 'bg-blue-50 border-blue-400 text-blue-400 flex items-center justify-center rounded-lg p-1 border-solid',
    CONDITIONAL: 'bg-blue-50 border-blue-400 text-blue-400 flex items-center justify-center rounded-lg p-1 border-solid',
    CANCELED: 'bg-gray-200 border-gray-200 text-gray-500 flex items-center justify-center rounded-lg p-1 border-solid',
  };

  let gantItemClass = gantItemClassMap[status] || '';
  if (status == 'ACTION_REQUIRED') {
    menu.items = [demandLifeCycleActionMap['send.to.planning'], demandLifeCycleActionMap['cancel'], demandLifeCycleActionMap['delayed.action'], demandLifeCycleActionMap['freeze']];
  } else if (status == 'DELAYED_ACTION_REQUIRED') {
    menu.items = [demandLifeCycleActionMap['cancel'], demandLifeCycleActionMap['send.to.planning'], demandLifeCycleActionMap['delayed.action'], demandLifeCycleActionMap['freeze']];
  } else if (status == 'PLANNING') {
    menu.items = [demandLifeCycleActionMap['remove.from.planning'], demandLifeCycleActionMap['cancel'], demandLifeCycleActionMap['freeze']];
  } else if (status == 'ACTIVE') {
    menu.items = [demandLifeCycleActionMap['cancel']];
  }
  //Statü bu üç durumdan biriyse (Failed, Success, Conditional) ders tamamlanmış demektir
  else if (status == 'SUCCESS' || status == 'FAILED') {
  } else if (status == 'CONDITIONAL') {
    menu.items = [demandLifeCycleActionMap['success'], demandLifeCycleActionMap['repeat']];
  } else if (status == 'CANCELED') {
  } else if (status == 'FROZEN') {
    menu.items = [demandLifeCycleActionMap['send.to.planning']];
  }
  if (history?.length > 0) {
    menu.items?.push(demandLifeCycleActionMap['show.demand.history']);
  }
  //<----

  const dropdownColor = () => {
    if (status == 'ACTION_REQUIRED') return 'text-[#722ed1]';
    if (status == 'DELAYED_ACTION_REQUIRED') return 'text-[#d3adf7]';
    if (status == 'PLANNING') return 'text-[#faad14]';
    if (status == 'ACTIVE') return 'text-[#52c41a]';
    if (status == 'CANCELED') return 'text-[#8b8b8b]';
    if (status == 'SUCCESS' || status == 'FAILED' || status == 'CONDITIONAL') return 'text-[#69b1ff]';
    return '';
  };

  const onSave = async () => {
    if (!modalOptions) return;
    const response = await modalOptions.callback(prePlacementId, demandId, statusUpdateModel);
    if (response.ok) {
      setStatusUpdateModel({ description: '' });
      setModalOptions(undefined);
      callDemandList();
      notification.success({ message: 'İşlem başarıyla gerçekleşti' });
    }
  };

  const onSaveRepeatWith = async () => {
    const response = await API.ENROLLMENT.repeatDemandWith(prePlacementId, demandId, selectedCourse?.id, statusUpdateModel);
    if (response.ok) {
      setSelectedCourse(undefined);
      setModalRepetationOpen(false);
      setStatusUpdateModel({ description: '' });
      callDemandList();
      notification.success({ message: 'İşlem başarıyla gerçekleşti' });
    }
  };

  return (
    <>
      <div ref={setElement} className={'gannt-calendar-item' + ' ' + gantItemClass} style={style}>
        {isVisible && (
          <>
            <div className="flex items-center">
              {menu.items?.length! > 0 && (
                <Dropdown
                  // disabled={status === 'CANCELED' || status == 'SUCCESS' || status == 'FAILED'}
                  overlayClassName="plan-action-overlay"
                  trigger={['click']}
                  menu={menu}
                  placement="bottomRight"
                >
                  <Button type="text" size="small" icon={<MoreOutlined className={dropdownColor()} />}></Button>
                </Dropdown>
              )}

              <Tooltip title={'Başarılı'}>{status === 'SUCCESS' && <Badge status="success" />}</Tooltip>
              <Tooltip title={'Başarısız'}> {status === 'FAILED' && <Badge status="error" />}</Tooltip>
              <Tooltip title={'Şartlı'}> {status === 'CONDITIONAL' && <Badge color="purple" />}</Tooltip>
              <Tooltip title={'Donduruldu'}> {status === 'FROZEN' && <MoonOutlined />}</Tooltip>

              <Tooltip title={text}>
                <span className="truncate ml-2">{text}</span>
              </Tooltip>
            </div>
            <Modal
              className="max-h-[500px] 2xl:max-h-[600px]"
              title={<div className="font-bold text-md">{modalOptions?.title}</div>}
              open={!!modalOptions}
              footer={null}
              onCancel={() => {
                setModalOptions(undefined);
                setStatusUpdateModel({ description: '' });
              }}
              centered
            >
              <div className="flex flex-col space-y-2">
                <div className=""> {item.enrollmentName}</div>
                <div className="text-xs italic"> {levelName}</div>
                <div>Açıklama</div>
                <TextArea
                  value={statusUpdateModel.description}
                  placeholder="Açıklama yazınız"
                  onChange={(e) => setStatusUpdateModel({ ...statusUpdateModel, description: e.target.value })}
                  style={{ resize: 'none' }}
                  autoSize={{ minRows: 2, maxRows: 5 }}
                />
                {modalOptions?.status == PrePlacementDemandStatus.FROZEN && (
                  <div>
                    <div className="mb-1">Ne zamana kadar doldurulsun?</div>
                    <DatePicker
                      value={dayjs(statusUpdateModel?.takeActionDate)}
                      style={{ width: '100%' }}
                      onChange={(date: Dayjs, dateString: string | string[]) => setStatusUpdateModel({ ...statusUpdateModel, takeActionDate: date?.toDate() })}
                      minDate={dayjs()}
                      placeholder="Kaydı yeniden aktif etmek istediğiniz tarihi seçiniz"
                    />
                  </div>
                )}
              </div>
              <Button className="mt-2" onClick={onSave} type="primary">
                Kaydet
              </Button>
            </Modal>
          </>
        )}
      </div>
      {isVisible && (
        <>
          <Modal
            className="max-h-[500px] 2xl:max-h-[600px]"
            title={<span className="font-normal">Şartlı geçiş için kur seçimi</span>}
            open={modalRepetationOpen}
            footer={null}
            onCancel={() => {
              setModalRepetationOpen(false);
              setSelectedCourse(undefined);
              setStatusUpdateModel({ description: '' });
            }}
            centered
          >
            <div className="grid grid-cols-1 gap-y-5">
              <div className="mt-1">Kredisini harcamak istediğiniz kur seçimini yapınız</div>
              <div>
                <Radio.Group onChange={(e) => setSelectedCourse(e.target.value)}>
                  {modalOptionData?.map((data) => {
                    return (
                      <div className="w-full flex justify-between items-center mt-2" key={data.id}>
                        <Radio value={data}>
                          <div className={`${gantItemClassMap[data.status]} w-full`}>{renderLevelsAsString(data.level)}</div>
                        </Radio>
                      </div>
                    );
                  })}
                </Radio.Group>
              </div>

              <div>
                <div className="mb-2">Not</div>
                <TextArea
                  value={statusUpdateModel.description}
                  placeholder="Açıklama yazınız"
                  onChange={(e) => setStatusUpdateModel({ ...statusUpdateModel, description: e.target.value })}
                  style={{ resize: 'none' }}
                  autoSize={{ minRows: 2, maxRows: 5 }}
                />
              </div>
              <div>
                <Button className="mt-2" onClick={onSaveRepeatWith} type="primary">
                  Seçimi onayla
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            title={
              <h4>
                {item.name}
                <div>Talep Tarihçesi</div>
              </h4>
            }
            open={demandHistoryModal}
            width={800}
            footer={null}
            onCancel={() => {
              setDemandHistoryModal(false);
            }}
            centered
          >
            <div className="timeline_container overflow-hidden pt-3">
              <Timeline
                reverse={false}
                className="class-timeline"
                mode="left"
                items={history?.map((h) => {
                  return {
                    label: (
                      <div className="flex flex-col">
                        <div>
                          <b>{localizeDemandStatus(h.status)}</b>
                        </div>
                        <div className="text-[#00000073] text-xs italic">
                          <div>{convertDate(h.at, 'DD.MM.YYYY HH:mm')}</div>
                          <small>{dayjs(h.at).fromNow()}</small>
                        </div>
                      </div>
                    ),
                    children: (
                      <div>
                        <div>{h.description}</div>
                        <div>
                          <small className="text-[#00000073] text-xs">{h.updatedBy ? h.updatedBy?.name : 'System'}</small>
                        </div>
                      </div>
                    ),
                  };
                })}
              />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export interface GanttCalendarRowProps {
  text: string;
  item: any; //TODO
  calendarItems: GanntCalendarItemType[];
  rowCount: number;
  callDemandList: () => void;
}

export const GanttCalendarRow = ({ item, calendarItems, rowCount, callDemandList }: GanttCalendarRowProps) => {
  const style = { '--row-count': rowCount } as React.CSSProperties;

  return (
    <div className="gannt-calendar-row" style={style}>
      {calendarItems.map((calendarItem, index) => (
        <GanttCalendarItem
          key={index}
          item={item}
          history={calendarItem.history}
          status={calendarItem.status}
          isSuccess={calendarItem.isSuccess}
          text={calendarItem.text}
          levelName={calendarItem.levelName}
          rowIndex={calendarItem.rowIndex || 0}
          dayStart={calendarItem.dayStart || 0}
          daySpan={calendarItem.daySpan || 0}
          prePlacementId={calendarItem.prePlacementId}
          demandId={calendarItem.demandId}
          callDemandList={callDemandList}
        />
      ))}
    </div>
  );
};

export interface GanttCalendarDividerProps {
  startPoint: Dayjs;
  month: number;
  monthOffset: number;
}

export const GanttCalendarDivider = ({ startPoint, month, monthOffset }: GanttCalendarDividerProps) => {
  const startDay = useMemo(() => {
    return Array.from({ length: month + 1 })
      .map((v, i) => {
        return startPoint
          .date(1)
          .month(i + monthOffset)
          .daysInMonth();
      })
      .reduce((acc, days) => acc + days, 0);
  }, [month, monthOffset, startPoint]);

  const style = { '--start-day': startDay } as React.CSSProperties;

  return <div className="gantt-calendar-divider" style={style}></div>;
};

export interface GanttCalendarTodayDividerProps {
  startPoint: Dayjs;
  monthOffset: number;
}

export const GanttCalendarTodayDivider = ({ startPoint, monthOffset }: GanttCalendarTodayDividerProps) => {
  const today = useMemo(() => {
    return dayjs();
  }, []);

  const startDay = useMemo(() => {
    const diff = today.diff(startPoint, 'day');
    if (diff < 0) {
      return diff + 1;
    }
    return diff + 1;
  }, [today, startPoint]);

  const todayVisible = useMemo(() => {
    return (
      today.toDate().getTime() <
      startPoint
        .date(1)
        .month(25 + monthOffset)
        .toDate()
        .getTime()
    );
  }, [startPoint, monthOffset]);

  const style = { '--start-day': startDay } as React.CSSProperties;

  if (todayVisible) {
    return <div className="gantt-calendar-divider today" style={style}></div>;
  }

  return null;
};

export interface GanttRowHeaderProps {
  item: Account;
  accountId: string;
  rowCount: number;
}

export const GanttRowHeader = ({ item, rowCount, accountId }: GanttRowHeaderProps) => {
  const navigate = useNavigate();
  const style = { '--row-count': rowCount } as React.CSSProperties;

  return (
    <div className="gannt-row-header items-center cursor-pointer" style={style} onClick={() => navigate('/lms/student_profile' + location.search + '&student_id=' + accountId)}>
      {item.gender == 'MALE' && (
        <span className="mr-1">
          <Tooltip title="Erkek">
            <ManOutlined style={{ color: 'blue' }} />
          </Tooltip>
        </span>
      )}
      {item.gender == 'FEMALE' && (
        <span className="mr-1">
          <Tooltip title="Kadın">
            <WomanOutlined style={{ color: 'magenta' }} />
          </Tooltip>
        </span>
      )}
      {calculateAge(item.birthDate) < 18 && (
        <span className="mr-1">
          <Tooltip title="18 yaş altı">
            <LuBaby size={15} color="orange" />
          </Tooltip>
        </span>
      )}
      <span className="text-sm">
        {item.name} {item.surname}
      </span>
    </div>
  );
};
