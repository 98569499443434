import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { ExerciseDefinitionContentType, ExerciseDefinitionInteractionType, ExerciseLayoutType } from '@shared/constants';
import { ExerciseDefinitionModel } from '@shared/models';
import { Button, Form, Input, InputRef, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect, useRef, useState } from 'react';

type CreateReadingGapFillingProps = {
  item: ExerciseDefinitionModel;
  onSave: (item: ExerciseDefinitionModel) => void;
  onChange: (item: ExerciseDefinitionModel) => void;
  cdnScope: CdnScope;
};

export const CreateReadingGapFilling = ({ item, onSave, onChange, cdnScope }: CreateReadingGapFillingProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [instructionTitle, setInstructionTitle] = useState<string>();
  const [instructionSubTitle, setInstructionSubTitle] = useState<string>();
  const [questionTextArea, setQuestionTextArea] = useState<string>();
  const [answer, setAnswer] = useState<string>();

  const contentRef = useRef<InputRef>(null);


  /*useEffect(() => {
    if (!isCallRef.current) {
      setTitle(item.content?.content?.title);
      setQuestionTextArea(item.content?.content?.description);
      setInstructionTitle(item.interaction?.instruction?.title);
      setInstructionSubTitle(item.interaction?.instruction?.description);
    }
  }, [item]);

  useEffect(() => {
    return () => {
      isCallRef.current = false;
    };
  }, [item]);*/

  useEffect(() => {
    setTitle(item.content?.content?.title);
    setQuestionTextArea(item.content?.content?.description);
    setInstructionTitle(item.interaction?.instruction?.title);
    setInstructionSubTitle(item.interaction?.instruction?.description);
  }, [item.id]);

  useEffect(() => {
    if (title || questionTextArea || instructionTitle || instructionSubTitle) {
      const i = {
        ...item,
        layout: ExerciseLayoutType.READING_GAP_FILLING,
        content: {
          ...item.content,
          content: {
            ...(item.content ? item.content.content : {}),
            title: title,
            description: questionTextArea,
          },
          type: ExerciseDefinitionContentType.FILL_IN_THE_BLANKS,
        },
        interaction: {
          ...item.interaction,
          type: ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS,
          orderItems: buildOrderItems(),
          instruction: {
            title: instructionTitle,
            description: instructionSubTitle,
          },
        },
      } as ExerciseDefinitionModel;
      onChange(i);

    }
  }, [title, questionTextArea, instructionTitle, instructionSubTitle]);

  const handleAnswer = () => {
    setQuestionTextArea((prev) => (prev ? prev : '').trim() + ` [${answer}] `);
    setAnswer('');
    setIsModalOpen(false);
  };

  const afterModalClose = () => {
    contentRef.current?.focus();
  };

  const buildOrderItems = () => {
    const values: string[][] = [];
    const regex = /\[([^\]]+)\]/g;
    let match;

    while ((match = regex.exec(questionTextArea ? questionTextArea : '')) !== null) {
      values.push([match[1]]);
    }
    return values;
  };

  const send = useCallback(() => {
    const i = {
      ...item,
      layout: ExerciseLayoutType.READING_GAP_FILLING,
      content: {
        ...item.content,
        content: {
          ...(item.content ? item.content.content : {}),
          title: title,
          description: questionTextArea,
        },
        type: ExerciseDefinitionContentType.FILL_IN_THE_BLANKS,
      },
      interaction: {
        ...item.interaction,
        type: ExerciseDefinitionInteractionType.FILL_IN_THE_BLANKS,
        orderItems: buildOrderItems(),
        instruction: {
          title: instructionTitle,
          description: instructionSubTitle,
        },
      },
    } as ExerciseDefinitionModel;
    onSave(i);
  }, [title, questionTextArea, instructionTitle, instructionSubTitle]);

  return (
    <>
      <div className="p-5">
        <div className="flex justify-end">
          <Button type="primary" size="small" onClick={send}>
            {item.id ? 'Güncelle' : 'Ekle'}
          </Button>
        </div>

        <Form layout="vertical" className="w-2/3">
          <Form.Item label={'Başlık'}>
            <Input
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Form.Item>

          <div className=" mt-7">
            <div className="flex flex-row w-full justify-between">
              <div>Metin</div>
              <Button type="dashed" onClick={() => setIsModalOpen(true)}>
                Seçenek Ekle
              </Button>
            </div>
            <TextArea rows={4} className="mt-1" value={questionTextArea} onChange={(e) => setQuestionTextArea(e.target.value)} ref={contentRef} />
          </div>
          <div className="grid grid-cols-2 space-x-2 mt-5">
            <Form.Item label={'Bilgilendirme Başlığı'}>
              <Input value={instructionTitle} onChange={(e) => setInstructionTitle(e.target.value)} />
            </Form.Item>
            <Form.Item label={'Bilgilendirme Alt Başlığı'}>
              <Input value={instructionSubTitle} onChange={(e) => setInstructionSubTitle(e.target.value)} />
            </Form.Item>
          </div>
        </Form>
      </div>

      <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} afterClose={afterModalClose}>
        <Form.Item label={'Seçenek:'} className="w-2/3 mt-3">
          <Input value={answer} onChange={(e) => setAnswer(e.target.value)} />
        </Form.Item>

        <Button type="primary" size="small" onClick={handleAnswer}>
          Ekle
        </Button>
      </Modal>
    </>
  );
};
