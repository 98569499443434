import Layout, { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { AppNavigationView, RouteBreadcrumb } from '@shared/components';
import { useAccessableSeachParams, useWindowSize } from '@shared/hooks';
import { useState, useMemo, useEffect } from 'react';
import { menuGroups, pageToMenuItem } from '@shared/utils';
import { useAccessableContext, useUserContext } from '@shared/contexts';
import { LayoutColors, LayoutEnums, Role } from '@shared/constants';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ConfigProvider, Menu } from 'antd';
import { BellOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { PoweredByTag, HeaderComponent } from '@shared/components';
import { HeaderDropDown } from '../header/HeaderDropDown';

const bc = new BroadcastChannel('auth.channel.joedtech');
export const BrandManagerLayout = ({ pages, brands, branchs }) => {
  const { brand } = useAccessableContext();
  const { sm, md } = useWindowSize();
  const [collapsed, setCollapsed] = useState(false);
  const [siderHovered, setSiderHovered] = useState(false);

  const { user } = useUserContext();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  useEffect(() => {
    bc.onmessage = (event) => {
      if (event.data.type === 'LOGOUT') {
        window.location.reload();
      }
    };
  }, []);

  const menuItems = pages.map(pageToMenuItem);
  const { userMenuItems, menu } = useMemo(() => {
    if (user?.jwt) {
      const {
        permissions: { values, role },
      } = user.features?.brand[brand?.id!]!;
      const userMenuItems = menuItems.filter((item) => {
        return (role == Role.ADMIN || (item.roles.includes(Role.USER) && item.permissions?.find((ip) => values?.includes(ip)))) && item.layouts.includes(LayoutEnums.BRANDMANAGER);
      });

      let menu = menuGroups.map((group) => ({
        ...group,

        label: typeof group.title === 'string' ? <span>{group.title}</span> : group.title,
        children: userMenuItems.filter((item) => item.group === group.key),
      })) as any[];

      menu = menu.filter((item) => item.children.length > 0);
      menu = [...userMenuItems.filter((item) => item.group === 'flat'), ...menu];
      return { userMenuItems, menu };
    }
    return { userMenuItems: [], menu: [] };
  }, [menuItems, user]);

  const menuSelectedKeys = useMemo(() => {
    const path = location.pathname;
    const menu = userMenuItems.find((item) => item.key === path);
    if (menu) {
      return [menu.group, menu.key];
    }

    return [];
  }, [location, userMenuItems]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemSelectedColor: LayoutColors[LayoutEnums.BRANDMANAGER]['color'],
            itemSelectedBg: LayoutColors[LayoutEnums.BRANDMANAGER]['backgroundColor'],
          },
        },
      }}
    >
      <Layout style={{ height: '100svh' }}>
        <HeaderComponent
          logoSrc={require('../../../../../statics/starlogo.png')}
          appNavigationView={<AppNavigationView brands={brands} branchs={branchs} />}
          appNavigationViewBackgroundColor={'FFE4DE'}
          headerDropDown={<HeaderDropDown issueDemandUrl={`/lms/issue-kanban`} profilUrl={`/${LayoutEnums.BRANDMANAGER}/setting`} bgColor="#FFFFFF" bgOpacity="70" />}
        />
        <Layout hasSider>
          <Sider
            width={sm ? 230 : 'calc(100vw - 60px)'}
            collapsed={collapsed}
            breakpoint="lg"
            zeroWidthTriggerStyle={{ top: 0 }}
            onCollapse={() => setCollapsed(!collapsed)}
            style={{ backgroundColor: '#FFFFFF', paddingTop: 15, position: 'relative' }}
            collapsible={true}
            trigger={null}
            onMouseEnter={() => setSiderHovered(true)}
            onMouseLeave={() => setSiderHovered(false)}
          >
            <div className="flex flex-col h-full">
              <Menu
                mode="inline"
                items={menu}
                onClick={() => {}}
                openKeys={openKeys}
                selectedKeys={menuSelectedKeys}
                onOpenChange={(keys) => {
                  setOpenKeys([keys[keys.length - 1]]);
                }}
                style={{ backgroundColor: '#FFFFFF' }}
              />

              {/* {<PoweredByTag collapsed={collapsed} />} */}

              <div
                onClick={() => setCollapsed(!collapsed)}
                className={`absolute flex items-center justify-center -translate-y-1/2 p-1 rounded-full shadow-lg cursor-pointer text-black hover:text-[#1677FF] bg-[#FFFFFF] hover:bg-[#E6F4FF] border-solid border-gray-200 hover:border-[#1677FF]`}
                style={{
                  top: '7%',
                  right: -10,
                  opacity: siderHovered ? 1 : 0,
                  transition: 'opacity 0.3s',
                }}
              >
                {collapsed ? <RightOutlined /> : <LeftOutlined />}
              </div>
            </div>
          </Sider>

          <Layout>
            <RouteBreadcrumb items={userMenuItems} />
            <Content style={{ margin: '12px 16px 16px', overflow: 'auto', backgroundColor: '#F5F5F5' }}>
              <Routes>
                {pages.map(([key, Comp]) => (
                  <Route key={key} path={Comp.path} element={<Comp />} handle={(a, b, c) => {}} />
                ))}
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
