import { DownloadOutlined } from '@ant-design/icons';
import { FileDownload } from '@shared/components';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { Role } from '@shared/constants';
import { StudentHomeworkProps } from '@shared/models';
import { Card, Drawer } from 'antd';
import { HomeworkDetailContentsDrawer } from './HomeworkDetailContentsDrawer';

type EvulatedHomeworkDetailDrawerProps = {
  open: boolean;
  onCloseDrawer: () => void;
  studentHomework: StudentHomeworkProps;
};

export const EvulatedHomeworkDetailDrawer = ({ open, onCloseDrawer, studentHomework }: EvulatedHomeworkDetailDrawerProps) => {
  return (
    <Drawer forceRender={true} open={open} onClose={onCloseDrawer} closable={true} title={'Değerlendirilmiş Ödev Detayı'}>
      <div className="text-lg font-bold">{studentHomework?.classHomework?.name}</div>
      <div className="italic opacity-45">{studentHomework?.classHomework?.name}</div>
      <div className="mt-4"></div>
      <div className="mt-4">
        <h4>Yüklediğiniz Dosyalar</h4>
        <div className="mt-2">
          {studentHomework?.files?.map((file) => (
            <div className="mt-1">
              <FileDownload file={file} icon={<DownloadOutlined />} cdnScope={CdnScope.BRANCH} />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-2 text-xs"></div>
      <div className="mt-2">
        <h4 className="mb-2">Değerlendirme Açıklaması</h4>
        {studentHomework?.evaluateNote}
      </div>

      {studentHomework?.evaluateFiles && studentHomework?.evaluateFiles?.length > 0 && (
        <div className="mt-4">
          <h4>Değerlendirme Dosyaları</h4>
          <div className="mt-2">
            {studentHomework?.evaluateFiles?.map((file) => (
              <div className="mt-1">
                <FileDownload file={file} icon={<DownloadOutlined />} cdnScope={CdnScope.BRANCH} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="mt-3">
        <Card bodyStyle={{ padding: '0' }} className="p-4  border-1 border-[#91CAFF] bg-[#E6F4FF]  rounded-lg">
          <div className="flex justify-between">
            <div>
              <b className="text-[#1677FF]">Değerlendirme Puanı</b>
              <div className="text-[#1677FF]">{studentHomework?.score}</div>
            </div>
            <div className="flex flex-col justify-end h-auto">
              <div className="text-[#1677FF]">
                {studentHomework?.evaluatedBy?.name} {studentHomework?.evaluatedBy?.surname}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <HomeworkDetailContentsDrawer studentHomework={studentHomework!} />
      </div>
    </Drawer>
  );
};
