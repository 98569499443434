import { ChoiceAnswerResponse, ExerciseAnswerRequestModel, ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel } from '@shared/models';
import { Button } from 'antd';

type ReadingMultiSelectProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  isInClass: boolean;

  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel: ExerciseAnswerRequestModel) => void;
  answerResponseModel?: ChoiceAnswerResponse;
};

export const ReadingMultiSelect = ({
  content,
  interaction,
  isPreview = false,
  isInClass,
  answerRequestModel,
  onChangeAnswerRequestModel,
  answerResponseModel,
}: ReadingMultiSelectProps) => {
  return (
    <>
      {isInClass ? (
        <div className="flex flex-row items-center h-full w-full bg-white pt-10 pb-10 gap-4">
          <div className="flex flex-col h-full w-3/6">
            <div className="font-bold text-base">{content.content?.title}</div>
            <div className=" mt-5 break-words h-full w-full overflow-y-auto md:text-lg xl:text-lg 2xl:text-xl">{content.content?.description}</div>
          </div>
          <div className="flex flex-col h-full w-3/6 justify-around items-center">
            <div className="mt-10 text-center">
              <div className="p-0 m-0 font-extrabold text-3xl text-custom-purple text-center">{interaction.instruction?.title}</div>
              <div className="text-lg text-light-purple text-center">{interaction.instruction?.description}</div>
            </div>

            <div className="text-center mt-4">
              {interaction.choices ? (
                <div className="flex flex-col  justify-center gap-3 px-4">
                  {interaction.choices.map((item, index) => (
                    <Button
                      key={index}
                      type="default"
                      className={
                        answerResponseModel
                          ? answerRequestModel?.choiceIds?.includes(item.id!)
                            ? answerResponseModel?.corrections?.find((corrections) => corrections.choiceId == item.id)?.isCorrect
                              ? 'bg-[#F6FFED] border-[1px] border-solid border-[#52C41A] whitespace-normal h-auto'
                              : 'bg-[#FFF1F0] border-[1px] border-solid border-[#FF4D4F] whitespace-normal h-auto'
                            : ''
                          : answerRequestModel?.choiceIds?.includes(item.id!)
                          ? ' border-[#1677FF] bg-[#E6F4FF] border-[1px] border-solid cursor-pointer whitespace-normal h-auto'
                          : 'border-[1px] border-solid hover:border-[#E6F4FF] cursor-pointer whitespace-normal h-auto'
                      }
                      onClick={() => {
                        if (onChangeAnswerRequestModel) {
                          if (answerRequestModel && answerRequestModel?.choiceIds?.includes(item.id!)) {
                            onChangeAnswerRequestModel({ ...answerRequestModel, choiceIds: answerRequestModel?.choiceIds?.filter((choiceId) => choiceId != item.id) });
                          } else {
                            onChangeAnswerRequestModel({
                              ...(answerRequestModel ? answerRequestModel : { choiceIds: [] }),
                              choiceIds: [...(answerRequestModel?.choiceIds! ? answerRequestModel?.choiceIds : []), item.id!],
                            });
                          }
                        }
                      }}
                    >
                      <span> {item.title}</span>
                    </Button>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-full w-full bg-white py-3 px-5 relative">
          <div className="font-bold text-base">{content.content?.title}</div>
          <div className="mt-3 break-words md:h-4/6 lg:h-full overflow-y-auto">{content.content?.description}</div>

          <div className="mt-1 text-center">
            <div className="text-center text-[#722ED1]">{interaction.instruction?.title}</div>
            <div className="mt-2 text-[#D3ADF7]">{interaction.instruction?.description}</div>
          </div>

          <div className="text-center mt-3 h-full">
            {interaction.choices ? (
              <div className="flex flex-col justify-center gap-3 text-wrap">
                {interaction.choices.map((item, index) => (
                  <Button
                    key={index}
                    type="default"
                    className={
                      answerResponseModel
                        ? answerRequestModel?.choiceIds?.includes(item.id!)
                          ? answerResponseModel?.corrections?.find((corrections) => corrections.choiceId == item.id)?.isCorrect
                            ? 'bg-[#F6FFED] border-[1px] border-solid border-[#52C41A] whitespace-normal h-auto'
                            : 'bg-[#FFF1F0] border-[1px] border-solid border-[#FF4D4F] whitespace-normal h-auto'
                          : answerResponseModel?.expected?.find((expect) => expect.id === item.id)?.isCorrect
                          ? 'bg-[#F6FFED] border-[1px] border-solid border-[#52C41A] whitespace-normal h-auto '
                          : ''
                        : answerRequestModel?.choiceIds?.includes(item.id!)
                        ? ' border-[#1677FF] bg-[#E6F4FF] border-[1px] border-solid cursor-pointer whitespace-normal h-auto'
                        : 'border-[1px] border-solid hover:border-[#E6F4FF] cursor-pointer whitespace-normal h-auto'
                    }
                    onClick={() => {
                      if (onChangeAnswerRequestModel) {
                        if (answerRequestModel && answerRequestModel?.choiceIds?.includes(item.id!)) {
                          onChangeAnswerRequestModel({ ...answerRequestModel, choiceIds: answerRequestModel?.choiceIds?.filter((choiceId) => choiceId != item.id) });
                        } else {
                          onChangeAnswerRequestModel({
                            ...(answerRequestModel ? answerRequestModel : { choiceIds: [] }),
                            choiceIds: [...(answerRequestModel?.choiceIds! ? answerRequestModel?.choiceIds : []), item.id!],
                          });
                        }
                      }
                    }}
                  >
                    {item.title}
                  </Button>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};
