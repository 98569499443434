import { ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined, DashboardOutlined } from '@ant-design/icons';
import { convertDate } from '@shared/utils';
import { Role } from '@shared/constants';
import { Avatar, Card, Popconfirm, Progress, Switch, Tooltip } from 'antd';
import * as API from '@shared/api';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { BrandUsers } from '@shared/components/BrandUsers';
import { useBrandSearchParams } from '@shared/hooks/useBrandSearchParams';
import { EnableProduct, EnableProductModel, PaginationQuery } from '@shared/models';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const BrandDashboard = () => {
  const { brand } = useBrandSearchParams();
  const [result, loading, getAllBrandUsers] = API.BRAND.getBrandUsers({ init: false });
  const [enableProductTypes, setEnableProductTypes] = useState<string[]>([]);
  const [enableBrandProduct, brandProductLoading, getEnableBrandProduct] = API.PRODUCT.getEnableProductBrand(brand?.id!, { init: false });
  const [product, setProduct] = useState<EnableProductModel>({});
  const [searchParams] = useSearchParams();
  const brandId = searchParams.get('brandId');
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 4, brandId: brandId! });

  useEffect(() => {
    if (brand && brand.id && brand.id.length > 0) {
      getAllBrandUsers(undefined, { ...paginationQuery, brandId: brand.id });
      getEnableBrandProduct();
    }
  }, [paginationQuery, brand]);

  useEffect(() => {
    if (enableBrandProduct) {
      const list = enableBrandProduct!.map((product) => {
        return product.type;
      }) as string[];
      setEnableProductTypes(list);
    }
  }, [enableBrandProduct]);

  const getFirstLetters = (item: string) => {
    return item.charAt(0);
  };

  const onSave = async (data, isUpdateMode) => {
    if (isUpdateMode) {
      const result = await API.BRAND.updateBrandUser(data);
      if (result.ok) {
        notification.success({ message: 'Marka kullanıcısı güncellendi.' });
      }
    } else {
      const result = await API.BRAND.createBrandUser({ brandId: brand?.id!, ...data });
      if (result.ok) {
        notification.success({ message: 'Marka kullanıcısı eklendi.' });
      }
    }
    getAllBrandUsers(undefined, { ...paginationQuery, page: 1 });
    getEnableBrandProduct();
  };

  const onDelete = async (id) => {
    await API.BRAND.deleteBrandUser(id);
    notification.success({ message: 'Marka kullanıcısı başarıyla silinmiştir!' });
    await getAllBrandUsers(undefined, { ...paginationQuery, page: 1 });
  };

  const BrandDashboardChart1 = () => (
    <div>
      <Progress className="mb-8" size={[310, 20]} strokeLinecap="butt" percent={78} />
    </div>
  );

  const BrandDashboardFooter = (high: number, low: number) => (
    <div className="ml-4">
      aylık <ArrowUpOutlined /> <span className="text-green-600">{high}%</span> <ArrowDownOutlined /> <span className="text-red-600">{low}%</span>
    </div>
  );

  const BrandDashboardCard1 = (label: string) => (
    <div>
      <Card
        hoverable
        title={
          <div className="mt-4">
            <label className="font-normal text-black opacity-45">{label}</label>
            <div className="text-2xl mt-4 mb-2">78%</div>
            {BrandDashboardChart1()}
          </div>
        }
        size="small"
      >
        <div className="flex items-center">
          <div className="text-normal ml-4">
            Tahsilatları incele <ArrowRightOutlined />
          </div>
        </div>
      </Card>
    </div>
  );

  const BrandDashboardCard2 = (label: string) => (
    <div>
      <Card
        title={
          <div className="mt-4">
            <label className="font-normal text-black opacity-45">{label}</label>
            <div className="text-2xl mt-4 mb-2">$3,056</div>
            <Progress className="mb-8" size={[310, 20]} strokeLinecap="butt" percent={78} />
          </div>
        }
        size="small"
      >
        <div className="flex items-center">
          <div className="text-normal">{BrandDashboardFooter(2, 4)}</div>
        </div>
      </Card>
    </div>
  );

  const BrandDashboardCard3 = (label: string) => (
    <div>
      <Card
        title={
          <div className="mt-4">
            <label className="font-normal text-black opacity-45">{label}</label>
            <div className="text-2xl mt-4 mb-2">$3,056</div>
            <Progress className="mb-8" size={[310, 20]} strokeLinecap="butt" percent={78} />
          </div>
        }
        size="small"
      >
        <div className="flex items-center">
          <div className="text-normal">
            <div className="text-normal">{BrandDashboardFooter(2, 4)}</div>
          </div>
        </div>
      </Card>
    </div>
  );

  const BrandDashboardProductCard = (productType: EnableProduct) => (
    <div>
      <Card
        size="small"
        hoverable
        title={
          <div className="flex items-center mt-4 mb-8 ml-3">
            <Avatar shape="square" size="large">
              {getFirstLetters(productType?.name!)}
            </Avatar>

            <div className="ml-4 flex-grow">
              <div className="text-lg flex justify-between items-center">
                {productType?.type!}
                <Popconfirm
                  title="Ürünü yayınlamak istediğinizden emin misiniz ?"
                  description="Tüm sisteme bu değişiklik yansıtılacak"
                  onConfirm={async () => {
                    const a = await API.PRODUCT.enableBrandProduct(product);

                    notification.success({ message: 'Ürün yayınlandı' });
                    getEnableBrandProduct();
                  }}
                  onCancel={() => {}}
                  okText="Evet"
                  cancelText="İptal"
                  className=""
                >
                  <Tooltip title="Active/Passive">
                    <Switch
                      size={'small'}
                      checked={productType.active}
                      defaultValue={productType.active}
                      onChange={(e) => {
                        const updatedProduct: EnableProduct = { ...productType, active: e.valueOf() };

                        const enableProductModel: EnableProductModel = {
                          brandId: brandId!,
                          products: [updatedProduct],
                        };
                        setProduct(enableProductModel);
                      }}
                    />
                  </Tooltip>
                </Popconfirm>
              </div>
              <div className="text-black font-light">Son geçerlilik tarihi: {convertDate(productType?.createdAt)}</div>
            </div>
          </div>
        }
      >
        <div className="ml-4">
          Ürünü incele <ArrowRightOutlined />
        </div>
      </Card>
    </div>
  );
  return (
    <div>
      <div className="grid grid-cols-3 gap-x-4 mb-4">
        {BrandDashboardCard1('Mart ayı için yapılmış tahsilat tutarı')}
        {BrandDashboardCard2('Tahsil edilen toplam tutar')}
        {BrandDashboardCard3('Tahsil edilen tutarın aylara göre dağılımı')}
      </div>
      <BrandUsers
        paginationQuery={paginationQuery}
        onChangePage={(currentPage: number, pageSize: number) => setPaginationQuery((prev) => ({ ...prev, page: currentPage, limit: pageSize }))}
        users={result}
        enableProductTypes={enableProductTypes}
        onSave={onSave}
        onDelete={onDelete}
        loading={loading}
      />
      <div className="mb-2 ">Ürünler</div>
      <div className="grid grid-cols-3 gap-x-4">
        {enableBrandProduct && enableBrandProduct.map((productType, index) => <div key={index}> {BrandDashboardProductCard(productType)}</div>)}
      </div>
    </div>
  );
};

BrandDashboard.path = 'brand-dashboard';
BrandDashboard.title = 'Marka Kontrol Paneli';
BrandDashboard.group = 'selectedBrand';
BrandDashboard.roles = [Role.ADMIN];
BrandDashboard.contexts = ['brand'];
