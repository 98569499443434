import {
  ArrowUpOutlined,
  CloseCircleOutlined,
  CommentOutlined,
  DeleteOutlined,
  FileUnknownOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  ManOutlined,
  PaperClipOutlined,
  RiseOutlined,
  SendOutlined,
  WomanOutlined,
} from '@ant-design/icons';
import * as API from '@shared/api';
import { AvatarFromName, Spinner } from '@shared/components';
import { Role } from '@shared/constants';
import i18n from '@shared/localization/i18n';
import { AccountIssueCategory, AccountIssueProps, AccountIssueStatus } from '@shared/models/account_issue';
import { calculateAge, convertDate } from '@shared/utils';
import { Badge, Button, Card, Dropdown, MenuProps, Popconfirm, Popover, Tabs, Tag } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const AdminAccountIssueDetail = () => {
  const [searchParams] = useSearchParams();
  const [issueId, setIssueId] = useState<string>();
  const [issue, setIssue] = useState<AccountIssueProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (searchParams.size > 0) {
      const id = searchParams.get('issue_id');
      if (id) {
        setIssueId(id);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (issueId) {
      getRetriveIssue();
    }
  }, [issueId]);

  useEffect(() => {
    if (issue?.id) {
      setComment(
        `Merhaba ${issue.account.name} ${
          issue.account.gender === 'MALE' ? 'Bey' : 'Hanım'
        },\nTalebinizi en kısa sürede değerlendirip, son durumu sizlerle paylaşıyor olacağız.\nGeri bildiriminiz için teşekkür ederiz.\nİyi Günler`
      );
    }
  }, [issue?.id]);

  const getRetriveIssue = async () => {
    if (issueId) {
      setLoading(true);
      const response = await API.ADMIN_ACCOUNT_ISSUE.retrive(issueId);
      if (response.ok) {
        setIssue(response.data);
      }
      setLoading(false);
    }
  };

  const createComment = useCallback(async () => {
    if (!comment || !issueId) {
      return;
    }

    await API.ADMIN_ACCOUNT_ISSUE.createComment(issueId, comment);
    setComment(undefined);
    getRetriveIssue();
  }, [comment, issueId]);

  const removeComment = useCallback(
    async (commentId: string) => {
      if (!issueId) {
        return;
      }

      await API.ADMIN_ACCOUNT_ISSUE.removeComment(issueId, commentId);
      getRetriveIssue();
    },
    [issueId]
  );

  const items: (ItemType & { forStatus: AccountIssueStatus[]; transitionTo: AccountIssueStatus })[] = [
    {
      label: 'İnceleniyor',
      key: '1',
      icon: <Badge color="blue" />,
      forStatus: [AccountIssueStatus.OPEN, AccountIssueStatus.RESOLVED, AccountIssueStatus.NOT_RESOLVED, AccountIssueStatus.REJECTED],
      transitionTo: AccountIssueStatus.IN_PROGRESS,
    },
    {
      label: 'Çözümlendi',
      key: '2',
      icon: <Badge color="green" />,
      forStatus: [AccountIssueStatus.IN_PROGRESS, AccountIssueStatus.NOT_RESOLVED, AccountIssueStatus.REJECTED],
      transitionTo: AccountIssueStatus.RESOLVED,
    },
    {
      label: 'Çözümlenemez/Çözümlenemedi',
      key: '3',
      icon: <Badge color="red" />,
      forStatus: [AccountIssueStatus.IN_PROGRESS, AccountIssueStatus.RESOLVED, AccountIssueStatus.REJECTED],
      transitionTo: AccountIssueStatus.NOT_RESOLVED,
    },
    {
      label: 'Reddet',
      key: '4',
      icon: <Badge color="red" />,
      forStatus: [AccountIssueStatus.IN_PROGRESS, AccountIssueStatus.RESOLVED, AccountIssueStatus.NOT_RESOLVED],
      transitionTo: AccountIssueStatus.REJECTED,
    },
  ];

  const handleTransition: MenuProps['onClick'] = async (e) => {
    if (!issueId) {
      return;
    }
    const item = items.find((item) => item.key === e.key);

    await API.ADMIN_ACCOUNT_ISSUE.transition(issueId, item?.transitionTo!);
    getRetriveIssue();
  };

  const statusMap = {
    [AccountIssueStatus.OPEN]: (
      <div>
        <Dropdown.Button
          menu={{ items: items.filter((item) => item.forStatus.find((status) => status === AccountIssueStatus.OPEN)), onClick: handleTransition }}
          placement="bottom"
        >
          <Badge color="orange" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.OPEN)}
        </Dropdown.Button>
      </div>
    ),
    [AccountIssueStatus.IN_PROGRESS]: (
      <div>
        <Dropdown.Button
          menu={{ items: items.filter((item) => item.forStatus.find((status) => status === AccountIssueStatus.IN_PROGRESS)), onClick: handleTransition }}
          placement="bottom"
        >
          <Badge color="blue" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.IN_PROGRESS)}
        </Dropdown.Button>
      </div>
    ),
    [AccountIssueStatus.RESOLVED]: (
      <div>
        <Dropdown.Button
          menu={{ items: items.filter((item) => item.forStatus.find((status) => status === AccountIssueStatus.RESOLVED)), onClick: handleTransition }}
          placement="bottom"
        >
          <Badge color="green" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.RESOLVED)}
        </Dropdown.Button>
      </div>
    ),
    [AccountIssueStatus.NOT_RESOLVED]: (
      <div>
        <Dropdown.Button
          menu={{ items: items.filter((item) => item.forStatus.find((status) => status === AccountIssueStatus.NOT_RESOLVED)), onClick: handleTransition }}
          placement="bottom"
        >
          <Badge color="red" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.NOT_RESOLVED)}
        </Dropdown.Button>
      </div>
    ),
    [AccountIssueStatus.REJECTED]: (
      <div>
        <Dropdown.Button
          menu={{ items: items.filter((item) => item.forStatus.find((status) => status === AccountIssueStatus.REJECTED)), onClick: handleTransition }}
          placement="bottom"
        >
          <Badge color="red" className="mr-2" /> {i18n.t('app.accountIssueStatus.' + AccountIssueStatus.REJECTED)}
        </Dropdown.Button>
      </div>
    ),
  };

  const categoryMap = {
    [AccountIssueCategory.BUG]: (
      <Tag color="red">
        <CloseCircleOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.BUG)}
      </Tag>
    ),
    [AccountIssueCategory.FEATURE_REQUEST]: (
      <Tag color="pink">
        <RiseOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.FEATURE_REQUEST)}
      </Tag>
    ),
    [AccountIssueCategory.IMPROVEMENT]: (
      <Tag color="green">
        <ArrowUpOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.IMPROVEMENT)}
      </Tag>
    ),
    [AccountIssueCategory.OTHER]: (
      <Tag>
        <FileUnknownOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.OTHER)}
      </Tag>
    ),
    [AccountIssueCategory.PERFORMANCE]: (
      <Tag color="blue">
        <LineChartOutlined /> {i18n.t('app.accountIssueCategory.' + AccountIssueCategory.PERFORMANCE)}
      </Tag>
    ),
  };

  return (
    <>
      {!loading ? (
        <Card>
          <div className="grid grid-cols-12">
            <div className="col-span-9 space-y-5">
              <div>
                <InfoCircleOutlined className="mr-1" /> Talep bilgileri
              </div>

              <div className=" mt-10">
                <span className="font-bold text-lg">#{issue?.no}</span>
                <div>
                  {issue?.title}
                  <div className="opacity-45 italic mt-2">{issue?.description}</div>
                </div>
              </div>

              <div>
                <PaperClipOutlined className="mr-1 mt-3" />
                Eklenen Dosyalar
              </div>

              <div>
                <hr />
                <h3>
                  <InfoCircleOutlined /> Device Info
                </h3>
                {issue &&
                  Object.keys(issue?.device)?.map((key) => (
                    <>
                      <div className="flex items-center">
                        <div className="font-bold pr-2">{key} : </div>
                        {issue?.device[key] ? issue?.device[key].toString() : 'false'}
                      </div>
                    </>
                  ))}
              </div>
            </div>

            <div className="col-span-3 ml-7">
              <div>
                <InfoCircleOutlined className="mr-1" /> Talep Detayları
              </div>
              <div className="opacity-45 mt-7">Durum</div>
              <div className="mt-1">{statusMap[issue?.status!]}</div>
              <div className="opacity-45 mt-4">Talebi Oluşturan</div>

              <Popover
                placement="left"
                content={
                  <>
                    <h3>Accessables</h3>
                    <pre>{JSON.stringify((issue ?? ({} as any))?.accountAccessables, null, 2)}</pre>
                  </>
                }
              >
                <div className="mt-1">
                  <b>
                    {issue?.account.name} {issue?.account.surname}
                    <span className="ml-1">
                      {issue?.account.gender == 'MALE' && <ManOutlined style={{ color: 'blue' }} />}
                      {issue?.account.gender == 'FEMALE' && <WomanOutlined style={{ color: 'magenta' }} />}
                    </span>
                  </b>
                  <br />
                  <i>
                    {convertDate(issue?.account.birthDate)} ({calculateAge(issue?.account.birthDate)} Yaşında)
                  </i>
                  <br />
                  <i>{issue?.account.email}</i>
                  <br />
                  <i>
                    {issue?.account.phone?.code}
                    {issue?.account.phone?.number}
                  </i>
                </div>
              </Popover>
              <div className="opacity-45 mt-4">Oluşturma Tarihi</div>
              <div className="mt-1">{convertDate(issue?.createdAt, 'DD.MM.YYYY HH:mm')}</div>
              <div className="opacity-45 mt-4">Kategori</div>
              <div className="mt-1">{categoryMap[issue?.category!]}</div>
              <div className="opacity-45 mt-4 mb-0">Tip</div>
              <div className="mt-1">{i18n.t('app.accountIssueType.' + issue?.type)}</div>

              <div className="opacity-45 mt-4 mb-0">Referrer Url</div>
              <div className="mt-1"> {issue?.referrerUrl}</div>
            </div>
          </div>

          <Tabs
            className="mt-5"
            defaultActiveKey="2"
            items={[
              {
                key: 'comments',
                label: `Yorumlar`,
                icon: <CommentOutlined />,
                children: (
                  <div>
                    <div className="flex flex-col">
                      <div className="flex flex-row  w-full space-x-2">
                        <AvatarFromName title={issue?.account.name!} subtitle={''} />

                        <TextArea showCount maxLength={1000} autoSize={{ minRows: 2, maxRows: 5 }} value={comment} onChange={(e) => setComment(e.target.value)} />
                        <Popconfirm title="Yorum Gönderilecek" description="Emin misiniz?" onConfirm={createComment} onCancel={() => {}} okText="Evet" cancelText="İptal">
                          <Button icon={<SendOutlined />} />
                        </Popconfirm>
                      </div>

                      <div className="flex-col flex space-y-2">
                        {issue?.comments?.map((comment) => (
                          <div className="justify-start items-center flex flex-row space-x-2" key={comment.id}>
                            <AvatarFromName title={comment.account.name!} subtitle={''} />
                            <div className="flex flex-col w-full">
                              <h4 className="space-x-2 mb-0">
                                <span>
                                  {comment.account.name} {comment.account.isPlatformReply ? <Tag color="blue">Platform Admin</Tag> : ''}
                                </span>
                                <small className="opacity-50">
                                  <i>{convertDate(comment.at, 'DD.MM.YYYY HH:mm')}</i>
                                </small>
                                <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => removeComment(comment.id)} okText="Evet" cancelText="İptal">
                                  <DeleteOutlined className="text-red-500" />
                                </Popconfirm>
                              </h4>

                              <pre className="m-0">{comment.comment}</pre>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                key: 'history',
                label: `Tarihçe`,
                icon: <HistoryOutlined />,
                children: (
                  <div>
                    <div className="flex-col flex space-y-2">
                      {issue?.history?.map((history, i) => (
                        <div className="flex flex-row space-x-2" key={'history' + i}>
                          <div className="flex flex-col w-full">
                            <h4 className="space-x-2 mb-0">
                              <span> {history.account.name}</span>
                              <small className="opacity-50">
                                <i>{convertDate(history.at, 'DD.MM.YYYY HH:mm')}</i>
                              </small>
                              <span className="opacity-50">{history.type}</span>
                              <span className="opacity-50">, now: {history.status}</span>
                            </h4>
                            <small>
                              <i> {history.description}</i>
                            </small>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </Card>
      ) : (
        <Spinner loading={true} />
      )}
    </>
  );
};

AdminAccountIssueDetail.path = '/account-issue-detail';
AdminAccountIssueDetail.roles = [Role.ADMIN];
