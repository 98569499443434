import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, PlusCircleOutlined, StopOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ClassDetailModel, StudentsSuccessSummaryItems } from '@shared/models';
import { Button, Dropdown, MenuProps, notification, Popconfirm, Table, TableProps, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { TeacherClassStudentList } from '../teacher_class/dashboard_components/TeacherClassStudentList';

type ClassSuccessSummaryProps = {
  classDetail: ClassDetailModel | undefined;
  visibleButton?: boolean;
};

export const ClassSuccessSummary = ({ classDetail, visibleButton = true }: ClassSuccessSummaryProps) => {
  const [studentsSuccessSummaryItems, setStudentsSuccessSummaryItems] = useState<StudentsSuccessSummaryItems[]>([]);
  const [columns, setColumns] = useState<TableProps<StudentsSuccessSummaryItems>['columns']>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (classDetail) {
      getStudentsSuccessSummary();
    }
  }, [classDetail]);

  const statusIcons = {
    SUCCESS: {
      icon: <CheckCircleOutlined style={{ color: '#52C41A' }} />,
      tooltip: 'Başarılı',
    },
    CONDITIONAL: {
      icon: <ExclamationCircleOutlined style={{ color: '#722ed1' }} />,
      tooltip: 'Şartlı',
    },
    FAILED: {
      icon: <StopOutlined style={{ color: '#F5222D' }} />,
      tooltip: 'Başarısız',
    },
  };

  const menu: MenuProps = {
    triggerSubMenuAction: 'click',
    items: [],
  };

  useEffect(() => {
    if (studentsSuccessSummaryItems.length > 0) {
      const startColumns: TableProps<StudentsSuccessSummaryItems>['columns'] = [
        {
          title: 'Öğrenci Adı',
          dataIndex: 'student',
          align: 'center',
          fixed: 'left',

          render: (_, record) => (
            <div>
              {record.student?.name} {record.student?.surname}
            </div>
          ),
        },
      ];
      const dynamicColumns: TableProps<StudentsSuccessSummaryItems>['columns'] =
        studentsSuccessSummaryItems[0]?.teacherEvaluations?.map((evaluation, index) => ({
          title: evaluation?.teacher?.name,
          children: [
            {
              title: 'Puan',
              dataIndex: ['teacherEvaluations', index, 'score'],
              align: 'center',
              render: (score: string) => score,
            },
            {
              title: 'Açıklama',
              dataIndex: ['teacherEvaluations', index, 'description'],
              align: 'center',
              render: (description: string) => description,
            },
          ],
        })) || [];

      const endColumns: TableProps<StudentsSuccessSummaryItems>['columns'] = [
        {
          title: 'Öğretmen averaj',
          dataIndex: 'teacherEvaluationsAvg',
          align: 'center',
        },
        {
          title: 'Devamsızlık',
          dataIndex: 'attendanceRate',
          align: 'center',
        },
        {
          title: 'Ortalama puan',
          dataIndex: 'avgScore',
          align: 'center',
          render: (text) => text?.toFixed(2),
        },
        {
          title: 'Durum',
          dataIndex: 'status',
          align: 'center',
          width: '8%',
          fixed: 'right',

          render: (_, record) => {
            const status = statusIcons[record.status as keyof typeof statusIcons];
            if (!status) return null;

            const menuItems = [
              {
                key: '1',
                label: (
                  <Popconfirm
                    title="Bu öğrenciyi başarılı olarak işaretlemek istediğinize emin misiniz?"
                    onConfirm={async () => {
                      const response = await API.ENROLLMENT.updateDemandStatusAsSuccess(record?.student?.prePlacementId!, record?.student?.demandId!, { description: '' });
                      if (response.ok) {
                        notification.success({ message: 'Öğrencinin durumu başarıyla başarılı olarak güncellendi.' });
                        getStudentsSuccessSummary();
                      }
                    }}
                    okText="Evet"
                    cancelText="İptal"
                  >
                    <div className="flex gap-2.5 px-2">
                      <CheckCircleOutlined />
                      <span>Başarılı</span>
                    </div>
                  </Popconfirm>
                ),
              },
              {
                key: '2',
                label: (
                  <Popconfirm
                    title="Bu öğrenciye başarısız yaptırmak istediğinize emin misiniz?"
                    onConfirm={async () => {
                      const response = await API.ENROLLMENT.updateDemandStatusAsFailed(record?.student?.prePlacementId!, record?.student?.demandId!, { description: '' });
                      if (response.ok) {
                        notification.success({ message: 'Öğrencinin durumu başarıyla başarısız olarak güncellendi' });
                        getStudentsSuccessSummary();
                      }
                    }}
                    okText="Evet"
                    cancelText="İptal"
                  >
                    <div className="flex gap-2.5 px-2">
                      <CloseCircleOutlined />
                      <span>Başarısız</span>
                    </div>
                  </Popconfirm>
                ),
              },
            ];

            const menu = {
              items: menuItems,
            };

            return status.tooltip === 'Şartlı' ? (
              classDetail?.status === 'COMPLETED' ? (
                <Dropdown className="cursor-pointer" overlayClassName="plan-action-overlay" trigger={['click']} menu={menu} placement="bottomRight">
                  <Tooltip title={status.tooltip}>
                    <div>{status.icon}</div>
                  </Tooltip>
                </Dropdown>
              ) : (
                <Tooltip title={status.tooltip}>
                  <div>{status.icon}</div>
                </Tooltip>
              )
            ) : (
              <Tooltip title={status.tooltip}>
                <div>{status.icon}</div>
              </Tooltip>
            );
          },
        },
      ];

      setColumns([...startColumns, ...dynamicColumns, ...endColumns]);
    }
  }, [studentsSuccessSummaryItems]);

  const getStudentsSuccessSummary = async () => {
    const response = await API.CLASS.getStudentsSuccessSummary(classDetail?.id);
    if (response.ok) {
      setStudentsSuccessSummaryItems(response.data.items);
    } else {
    }
  };

  const onCloseModal = () => {
    getStudentsSuccessSummary();
    setIsModalOpen(false);
  };

  return (
    <div>
      {visibleButton && (
        <div className="flex justify-end mb-1">
          <Button icon={<PlusCircleOutlined />} type="dashed" onClick={() => setIsModalOpen(true)}>
            Performans notu girin
          </Button>
        </div>
      )}
      <TeacherClassStudentList classDetail={classDetail} isModalOpen={isModalOpen} onCloseModal={onCloseModal} />
      <Table rowKey="id" columns={columns} dataSource={studentsSuccessSummaryItems} bordered scroll={{ x: 1300 }} pagination={false} />
    </div>
  );
};
