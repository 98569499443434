import Layout, { Content } from 'antd/es/layout/layout';
import { ConfigProvider } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { AppNavigationView, HeaderComponent, RouteBreadcrumb } from '@shared/components';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '@shared/contexts';
import { menuGroups, pageToMenuItem } from '@shared/utils';
import { LayoutColors, LayoutEnums, Role } from '@shared/constants';
import { HeaderDropDown } from '../header/HeaderDropDown';
import { BellOutlined } from '@ant-design/icons';

const bc = new BroadcastChannel('auth.channel.joedtech');
export const TeacherLayout = ({ pages, brands, branchs }) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const { user } = useUserContext();
  const location = useLocation();

  useEffect(() => {
    bc.onmessage = (event) => {
      if (event.data.type === 'LOGOUT') {
        window.location.reload();
      }
    };
  }, []);

  const menuItems = pages.map(pageToMenuItem);
  const { userMenuItems, menu } = useMemo(() => {
    if (user?.jwt) {
      const userMenuItems = menuItems.filter((item) => item.roles.includes(Role.ADMIN) && item.layouts.includes(LayoutEnums.TEACHER));
      let menu = menuGroups.map((group) => ({
        ...group,
        label: typeof group.title === 'string' ? <span>{group.title}</span> : group.title,
        children: userMenuItems.filter((item) => item.group === group.key),
      })) as any[];

      menu = menu.filter((item) => item.children.length > 0);
      menu = [...userMenuItems.filter((item) => item.group === 'flat'), ...menu];
      return { userMenuItems, menu };
    }
    return { userMenuItems: [], menu: [] };
  }, [menuItems, user]);

  const menuSelectedKeys = useMemo(() => {
    const path = location.pathname;
    const menu = userMenuItems.find((item) => item.key === path);
    if (menu) {
      return [menu.group, menu.key];
    }

    return [];
  }, [location, userMenuItems]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemColor: LayoutColors[LayoutEnums.TEACHER]['color'],
            itemHoverColor: LayoutColors[LayoutEnums.TEACHER]['color'],
            horizontalItemSelectedColor: LayoutColors[LayoutEnums.TEACHER]['color'],
            horizontalItemHoverColor: '#191919',
            horizontalItemHoverBg: LayoutColors[LayoutEnums.TEACHER]['selectedBackground'],
            horizontalItemSelectedBg: LayoutColors[LayoutEnums.TEACHER]['selectedBackground'],
            horizontalItemBorderRadius: 6,
          },
        },
      }}
    >
      <Layout className="min-h-svh">
        <HeaderComponent
          logoSrc={require('../../../../../statics/starlogo.png')}
          appNavigationView={<AppNavigationView textColor="#FFFFFF" brands={brands} branchs={branchs} />}
          menuItems={menu}
          selectedKeys={menuSelectedKeys}
          headerBackground={'#191919'}
          appNavigationViewBackgroundColor={'rgba(255, 255, 255, 0)'}
          onMenuOpenChange={(keys) => {
            setOpenKeys([keys[keys.length - 1]]);
          }}
          openKeys={openKeys}
          headerDropDown={
            <HeaderDropDown issueDemandUrl={`/lms/issue-kanban`} profilUrl={`/${LayoutEnums.TEACHER}/setting`} bgColor="#191919" bgOpacity="70" textColor="#FFFFFF" />
          }
          notificationIconColor="#FFFFFF"
        />
        <Layout>
          <Content className="container mx-auto">
            <RouteBreadcrumb items={userMenuItems} />
            <Routes>
              {pages.map(([key, Comp]) => (
                <Route key={key} path={Comp.path} element={<Comp />} handle={(a, b, c) => {}} />
              ))}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
