import { ExerciseLayoutType } from '@shared/constants';
import { ExerciseDefinitionModel } from '@shared/models';
import { useMemo } from 'react';
import {
  CreateDialogBase,
  CreateListeningBase,
  CreateMatchingBase,
  CreateReadingGapFilling,
  CreateReadingMultiSelect,
  CreateReadingSingilSelect,
  CreateVisualBase,
  CreateVisualSingleSelect,
  CreateGapFillingSelected,
  CreateListeningSingleSelect
} from '../exercise_types';
import { CreateMatchingImage } from '../exercise_types/match/CreateMatchingImage';
import { CdnScope } from '../../FileUpload';

type ExerciseCreateHandlerProp = {
  item: ExerciseDefinitionModel;
  onSave: (item: ExerciseDefinitionModel) => void;
  onChange: (item: ExerciseDefinitionModel) => void;
  cdnScope: CdnScope;
};
export const ExerciseCreateHandler = ({ item, onSave, onChange, cdnScope }: ExerciseCreateHandlerProp) => {
  /*const [element, setElement] = useState<ReactElement>();

  useEffect(() => {
    if (item) {
      switch (item.layout) {
        case ExerciseLayoutType.VISUAL_BASE:
          setElement(<CreateVisualBase item={item} onSave={onSave} onChange={onChange} isAdminCdn={isAdminCdn} />);
          break;
        case ExerciseLayoutType.VISUAL_SINGLE_SELECT:
          setElement(<CreateVisualSingleSelect item={item} onSave={onSave} onChange={onChange} isAdminCdn={isAdminCdn} />);
          break;
        case ExerciseLayoutType.DIALOG_BASE || ExerciseLayoutType.DIALOG_SELECT || ExerciseLayoutType.DIALOG_SORTING:
          setElement(<CreateDialogBase item={item} onSave={onSave} onChange={onChange} isPreview={true} />);
          break;
        case ExerciseLayoutType.MATCHING_BASE:
          setElement(<CreateMatchingBase item={item} onSave={onSave} onChange={onChange} />);
          break;
        case ExerciseLayoutType.READING_GAP_FILLING:
          setElement(<CreateReadingGapFilling item={item} onSave={onSave} onChange={onChange} isAdminCdn={isAdminCdn} />);
          break;
        case ExerciseLayoutType.READING_SINGLE_SELECT:
          setElement(<CreateReadingSingilSelect item={item} onSave={onSave} onChange={onChange} isAdminCdn={isAdminCdn} />);
          break;
        case ExerciseLayoutType.READING_MULTI_SELECT:
          setElement(<CreateReadingMultiSelect item={item} onSave={onSave} onChange={onChange} isAdminCdn={isAdminCdn} />);
          break;
        case ExerciseLayoutType.LISTINING_BASE:
          setElement(<CreateListeningBase item={item} onSave={onSave} onChange={onChange} isAdminCdn={isAdminCdn} />);
          break;
        default:
          setElement(
            <div className="flex h-full text-center content-center justify-center items-center">
              <span className="text-red-600 text-3xl">Bu egzersiz tipi şu an için mevcut değil</span>
            </div>
          );
          break;
      }
    }
  }, [item?.id]);*/

  const a = useMemo(() => {
    if (!item) {
      return null;
    }
    switch (item.layout) {
      case ExerciseLayoutType.VISUAL_BASE:
        return <CreateVisualBase item={item} onSave={onSave} onChange={onChange} cdnScope={cdnScope} />;
        break;
      case ExerciseLayoutType.VISUAL_SINGLE_SELECT:
        return <CreateVisualSingleSelect item={item} onSave={onSave} onChange={onChange} cdnScope={cdnScope} />;
        break;
      case ExerciseLayoutType.DIALOG_BASE || ExerciseLayoutType.DIALOG_SELECT || ExerciseLayoutType.DIALOG_SORTING:
        return <CreateDialogBase item={item} onSave={onSave} onChange={onChange} isPreview={true} />;
        break;
      case ExerciseLayoutType.MATCHING_BASE:
        return <CreateMatchingBase item={item} onSave={onSave} onChange={onChange} />;
        break;
      case ExerciseLayoutType.MATCHING_IMAGE:
        return <CreateMatchingImage item={item} onSave={onSave} onChange={onChange} cdnScope={cdnScope} />;
        break;
      case ExerciseLayoutType.READING_GAP_FILLING:
        return <CreateReadingGapFilling item={item} onSave={onSave} onChange={onChange} cdnScope={cdnScope} />;
        break;
      case ExerciseLayoutType.READING_SINGLE_SELECT:
        return <CreateReadingSingilSelect item={item} onSave={onSave} onChange={onChange} cdnScope={cdnScope} />;
        break;
      case ExerciseLayoutType.READING_MULTI_SELECT:
        return <CreateReadingMultiSelect item={item} onSave={onSave} onChange={onChange} cdnScope={cdnScope} />;
        break;
      case ExerciseLayoutType.LISTENING_BASE:
        return <CreateListeningBase item={item} onSave={onSave} onChange={onChange} cdnScope={cdnScope} />;
        break;
        case ExerciseLayoutType.LISTENING_SINGLE_SELECT:
          return <CreateListeningSingleSelect item={item} onSave={onSave} onChange={onChange} cdnScope={cdnScope} />;
          break;
      case ExerciseLayoutType.GAP_FILLING_SELECT:
        return <CreateGapFillingSelected item={item} onSave={onSave} onChange={onChange} cdnScope={cdnScope}/>;
        break;
      default:
        return (
          <div className="flex h-full text-center content-center justify-center items-center">
            <span className="text-red-600 text-3xl">Bu egzersiz tipi şu an için mevcut değil</span>
          </div>
        );
        break;
    }
  }, [item, item?.id]);

  return a;
};
