import { AppNavigationView, HeaderComponent, RouteBreadcrumb } from '@shared/components';
import { LayoutColors, LayoutEnums, Role } from '@shared/constants';
import { useUserContext } from '@shared/contexts';
import { menuGroups, pageToMenuItem } from '@shared/utils';
import { ConfigProvider } from 'antd';
import Layout, { Content } from 'antd/es/layout/layout';
import { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HeaderDropDown } from '../header/HeaderDropDown';
import { AzureAuthContextProvider } from '@shared/contexts/useAzureAuth';
import { isMobile } from 'react-device-detect';

const bc = new BroadcastChannel('auth.channel.joedtech');
export const StudentLayout = ({ pages, brands, branchs }) => {
  const { user } = useUserContext();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  useEffect(() => {
    bc.onmessage = (event) => {
      if (event.data.type === 'LOGOUT') {
        window.location.reload();
      }
    };
  }, []);

  const menuItems = pages.map(pageToMenuItem);
  const { userMenuItems, menu } = useMemo(() => {
    if (user?.jwt) {
      const userMenuItems = menuItems.filter((item) => item.roles.includes(Role.ADMIN) && item.layouts.includes(LayoutEnums.STUDENT));
      let menu = menuGroups.map((group) => ({
        ...group,
        label: typeof group.title === 'string' ? <span>{group.title}</span> : group.title,
        children: userMenuItems.filter((item) => item.group === group.key),
      })) as any[];

      menu = menu.filter((item) => item.children.length > 0);
      menu = [...userMenuItems.filter((item) => item.group === 'flat'), ...menu];
      return { userMenuItems, menu };
    }
    return { userMenuItems: [], menu: [] };
  }, [menuItems, user]);

  const menuSelectedKeys = useMemo(() => {
    const path = location.pathname;
    const menu = userMenuItems.find((item) => item.key === path);
    if (menu) {
      return [menu.group, menu.key];
    }

    return [];
  }, [location, userMenuItems]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemColor: LayoutColors[LayoutEnums.STUDENT]['color'],
            itemHoverColor: LayoutColors[LayoutEnums.STUDENT]['color'],
            horizontalItemSelectedColor: LayoutColors[LayoutEnums.STUDENT]['color'],
            horizontalItemHoverColor: 'rgb(197 226 248)',
            horizontalItemHoverBg: LayoutColors[LayoutEnums.STUDENT]['selectedBackground'],
            horizontalItemSelectedBg: LayoutColors[LayoutEnums.STUDENT]['selectedBackground'],
            horizontalItemBorderRadius: 6,
          },
        },
      }}
    >
      <AzureAuthContextProvider>
        <Layout className="min-h-svh">
          <HeaderComponent
            logoSrc={require('../../../../../statics/starlogo.png')}
            appNavigationView={<AppNavigationView textColor="#001D66" brands={brands} branchs={branchs} />}
            menuItems={menu}
            openKeys={openKeys}
            selectedKeys={menuSelectedKeys}
            headerBackground={'rgb(197 226 248)'}
            appNavigationViewBackgroundColor={'rgba(255, 255, 255, 0.40)'}
            onMenuOpenChange={(keys) => {
              setOpenKeys([keys[keys.length - 1]]);
            }}
            headerDropDown={
              <HeaderDropDown
                issueDemandUrl={`/lms/issue-kanban`}
                profilUrl={`/${LayoutEnums.STUDENT}/setting`}
                settingUrl={`/${LayoutEnums.TEACHER}/setting`}
                bgColor="#FFFFFF"
                bgOpacity="70"
                textColor="#001D66"
              />
            }
            notificationIconColor="#001D66"
          />

          <Layout>
            <Content className="container mx-auto">
              {!isMobile && <RouteBreadcrumb items={userMenuItems} />}
              <Routes>
                {pages.map(([key, Comp]) => (
                  <Route key={key} path={Comp.path} element={<Comp />} handle={(a, b, c) => {}} />
                ))}
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </AzureAuthContextProvider>
    </ConfigProvider>
  );
};
