import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { FileDownload } from '@shared/components';
import { CdnScope, FileUpload } from '@shared/components/curriculum/FileUpload';
import { UploadFileType } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { HomeworkTeacherDetail, StudentHomeworkProps } from '@shared/models';
import { convertDate } from '@shared/utils';
import { Button, Drawer, InputNumber, notification, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { HomeworkDetailContentsDrawer } from './HomeworkDetailContentsDrawer';

type SubmitedStudentDrawerOpenProps = {
  submitedStudentDrawerOpen: boolean;
  submitedStudentDrawerOnClose: () => void;
  submitedStudentData: StudentHomeworkProps;
  homeworkDetail: HomeworkTeacherDetail;
};

//TODO düzelt zodları
const SubmitedStudentDrawerOpenForm = z.object({
  score: z.string({ required_error: 'Öğretmen soyadı zorunlu alandır' }).min(1, 'Öğretmen soyadı 1 karekterden fazla olmalı'),
  evaluateNote: z.string({ required_error: 'Öğretmen soyadı zorunlu alandır' }).min(1, 'Öğretmen soyadı 1 karekterden fazla olmalı'),
});

export const SubmitedStudentDrawerOpen = ({ submitedStudentDrawerOpen, submitedStudentDrawerOnClose, submitedStudentData, homeworkDetail }: SubmitedStudentDrawerOpenProps) => {
  const [evaluatedFiles, setEvaluatedFiles] = useState<UploadFile[]>();
  const [startUpload_, startUpload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<Partial<StudentHomeworkProps>, StudentHomeworkProps>(SubmitedStudentDrawerOpenForm, { ...submitedStudentData });

  useEffect(() => {
    if (submitedStudentData) {
      setEvaluatedFiles(submitedStudentData?.evaluateFiles?.map((f) => ({ name: f.name!, uid: f.resourceId!, url: f.resourceUrl })));
      form.setValue(submitedStudentData);
    }
  }, [submitedStudentData]);

  const onCreateDraftHomework = async (evaluateFiles) => {
    const result = form.parse();
    const response = await API.HOMEWORK.studentEvulate(homeworkDetail?.classHomework?.id!, submitedStudentData?.student?.id!, { ...result.data, evaluateFiles });
    if (response.ok) {
      submitedStudentDrawerOnClose();
      notification.success({
        message: `${submitedStudentData?.student?.name} öğrencisinin değerlendirmesi başarılıyla yapılmıştır.`,
      });
    } else {
      notification.error({ message: `${submitedStudentData?.student?.name} öğrencisinin değerlendirmesi yapılamamıştır.` });
    }

    startUpload(false);
  };

  return (
    <Drawer
      forceRender={true}
      open={submitedStudentDrawerOpen}
      onClose={submitedStudentDrawerOnClose}
      closable={true}
      title={
        <div>
          <span>Ödev detayı</span>
        </div>
      }
    >
      <div>
        <div className="mb-3 font-bold text-md">
          {submitedStudentData?.student?.name} {submitedStudentData?.student?.surname}
        </div>
        <div className="text-sm italic opacity-45"> {convertDate(submitedStudentData?.student?.createdAt)}</div>
      </div>
      <div className="mt-4">
        <div>Öğrencinin yüklediği dosyalar</div>
        <div className="mt-2">
          {submitedStudentData?.files?.map((file) => (
            <div className="mt-1">
              <FileDownload file={file} icon={<DownloadOutlined />} cdnScope={CdnScope.BRANCH} />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4">
        <div className="mb-3">Öğrencinin notu</div>
        <div className="mb-3 text-italic opacity-45">{submitedStudentData?.note}</div>
      </div>
      <div className="mt-4">
        <div className="mb-3">Değerlendirme sonucu <span className="text-red-500 text-xs font-extralight"> 100 üzerindendir</span></div>
        <div className="mb-3">
          <InputNumber {...form.inputNumber('score')} className="w-1/4" min={0} max={100} onChange={(value) => form.setValue({ ...form.value, score: value! })} />

        </div>
      </div>
      <div className="mt-4">
        <div className="mb-3">Ödevle ilgili notunuz</div>
        <div className="mb-3">
          <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 2, maxRows: 5 }} {...form.input('evaluateNote')} />
        </div>
      </div>
      <div className="mt-4">
        <div>Öğretmenin yüklediği dosyalar</div>
        <div className="mt-2">
          {submitedStudentData?.evaluateFiles?.map((file) => (
            <div className="mt-1">
              <FileDownload file={file} icon={<DownloadOutlined />} cdnScope={CdnScope.BRANCH} />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4">
        <div className="mb-3">Yüklemek istediğiniz dosyaları seçiniz</div>
        <div className="mb-3">
          <FileUpload
            watchThisIfTrueStartUpload={startUpload_}
            multiple={true}
            files={evaluatedFiles}
            onChange={(files) => {
              setEvaluatedFiles(files);
            }}
            onChangeLoading={(b) => {
              setLoading(b);
            }}
            cdnScope={CdnScope.BRANCH}
            onUploadCompleted={(files) => {
              onCreateDraftHomework(files);
            }}
            allowedMimes={UploadFileType('*')}
          />
        </div>
        <div className="mb-3 text-xs">
          <span className="text-red-500">*</span> Desteklenen türler: Resim, Doküman, Ses, Video, Zip
        </div>
      </div>

      <Button loading={loading} className="w-full mt-5" type="primary" onClick={() => startUpload(true)}>
        Değişiklikleri kaydet
      </Button>

      <HomeworkDetailContentsDrawer studentHomework={homeworkDetail!} />
    </Drawer>
  );
};
