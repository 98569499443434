import { useFetch } from '@shared/hooks';
import { Account, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  getObserverStudents: (config: UseFetchOptions) => useFetch<Account[]>('/observer/student', { method: 'GET', ...config }),
  getObserverStudentDemands: (studentAccountId: string) => request('/observer/student/' + studentAccountId + '/demands', 'GET'),
  getObserverStudentDemandDetail: (demandId: string) => request('/observer/demand/' + demandId, 'GET'),
  getObserverStudentCalendar: (studentAccountId: string, begin: Date, end: Date) =>
    request('/observer/student/' + studentAccountId + '/calendar', 'GET', undefined, { begin, end }),
};
