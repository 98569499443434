export type ExerciseAnswerRequestModel = Partial<{
  conversationItemId: string;
  choiceIds: string[];
  orderItems: string[];
  mediaOrderItems: {
    mediaId?: string;
    word?: string;
  }[];
  rate: number;
  success: boolean;
}>;
