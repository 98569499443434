import { CalendarOutlined } from '@ant-design/icons';
import { ActionDropdown, AvatarFromName, UpdateStudentProfile } from '@shared/components';
import { UpdatePasswordStudentProfile } from '@shared/components/UpdatePasswordStudentProfile';
import { Enrollment, Observer, PrePlacement } from '@shared/models';
import { Card, MenuProps, Modal, Tabs, TabsProps, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { LiaUserEditSolid } from 'react-icons/lia';

type EnrollmentUserCardProp = {
  user: Observer | PrePlacement;
  isDelete?: boolean;
  isEdit?: boolean;
  onDelete: (user: Observer | PrePlacement) => void;
  onEdit: (user: Observer | PrePlacement) => void;
  onUpdateCalendar?: (user: Observer | PrePlacement) => void;
  onUpdateStudentInfo?: (student: PrePlacement) => void;
  disabled?: boolean;
  mainUserText?: string;
  isSmall?: boolean;
  className?: string;
  enrollment: Enrollment;
};
export const EnrollmentUserCard = ({
  user,
  isDelete = true,
  isEdit = true,
  onDelete,
  onEdit,
  disabled = false,
  mainUserText,
  isSmall = false,
  className,
  onUpdateCalendar,
  onUpdateStudentInfo,
  enrollment,
}: EnrollmentUserCardProp) => {
  const [studentProfileModalOpen, setStudentProfileModalOpen] = useState<boolean>(false);
  //const menu: MenuProps['items'] = [];
  const [menu, setMenu] = useState<MenuProps['items']>([]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Öğrenci Profili',
      children: (
        <UpdateStudentProfile
          student={user}
          onUpdateStudentInfo={(updatedStudent) => {
            setStudentProfileModalOpen(false);
            if (onUpdateStudentInfo) {
              onUpdateStudentInfo(updatedStudent);
            }
          }}
        />
      ),
    },
    {
      key: '2',
      label: 'Şifre Güncelleme',
      children: <UpdatePasswordStudentProfile student={user} enrollment={enrollment} />,
    },
  ];

  useEffect(() => {
    const _menu: MenuProps['items'] = [];
    if (onUpdateCalendar) {
      _menu.push({
        key: 'onUpdateCalendar',
        icon: <CalendarOutlined />,
        label: 'Takvimi Güncelle',
        onClick: () => {
          onUpdateCalendar(user);
        },
      });
      setMenu(_menu);
    }
    if (onUpdateStudentInfo) {
      _menu.push({
        key: 'onUpdateStudentInfo',
        icon: <LiaUserEditSolid size={15} />,
        label: 'Öğrenci Profili Düzenle',
        onClick: () => {
          setStudentProfileModalOpen(true);
        },
      });
    }
  }, [onUpdateCalendar, onUpdateStudentInfo]);
  return (
    <React.Fragment key={user.id}>
      <Card
        size={isSmall ? 'small' : 'default'}
        className={className}
        title={
          <Card.Meta
            className="flex items-center"
            avatar={
              <div className="pt-2 pb-2">
                <AvatarFromName title={user.name! + ' ' + user.surname} subtitle={user.email!} bgColor="#E6F4FF" txColor="#1677FF" />
              </div>
            }
            title={[user.name, user.surname].join(' ')}
          />
        }
        extra={
          <div className="flex gap-2 items-center">
            {(isDelete || isEdit || (menu && menu.length > 0)) && (
              <ActionDropdown
                disabled={disabled}
                data={user}
                isEdit={isEdit}
                isDelete={isDelete}
                items={menu}
                onDelete={(data) => {
                  onDelete(data);
                }}
                onEdit={(data) => {
                  onEdit(data);
                }}
              />
            )}
          </div>
        }
      >
        <div className="flex flex-col space-y-2">
          <div className="grid grid-cols-[60px_1fr] items-center">
            <label className="font-medium">Email:</label>
            <span className="text-dark-gray text-xs ">{user.email}</span>

            <label className="font-medium ">Telefon:</label>
            <span className="text-dark-gray text-xs ">
              {user.phone?.code}
              {user.phone?.number}
            </span>
          </div>
          {mainUserText && (
            <Tag className="w-full" color="cyan">
              {mainUserText}
            </Tag>
          )}
        </div>
      </Card>

      <Modal title="Öğrenci Profilini Düzenle" open={studentProfileModalOpen} footer={null} onCancel={() => setStudentProfileModalOpen(false)}>
        <Tabs defaultActiveKey="1" items={items} />
      </Modal>
    </React.Fragment>
  );
};
