import { BirthdayCalendar, NationalIdInput, PhoneInput, TextInput } from '@shared/components';
import { Gender } from '@shared/constants';
import { useUserContext } from '@shared/contexts';
import { useAccessableSeachParams, useForm } from '@shared/hooks';
import { Account } from '@shared/models';
import { Button, Form, notification, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { z } from 'zod';

const ProfileSettingForm = z.object({
  // image: z.string({ required_error: 'Resim yüklenmelidir' }),
  name: z.string({ required_error: 'Adı zorunlu alandır' }).min(1, 'Adı 1 karekterden fazla olmalı'),
  surname: z.string({ required_error: 'Soyadı zorunlu alandır' }).min(1, 'Soyadı 1 karekterden fazla olmalı'),
  nationalId: z.string({ required_error: 'TC zorunlu alandır' }).min(1, 'TC 1 karekterden fazla olmalı'),

  // email: z.string({ required_error: 'Email zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
  'phone.code': z.string({ required_error: 'Lütfen telefon ülke kodunu seçiniz' }),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
});

type ProfileSettingProps = {
  profile: Account;
  updateProfile: (value) => void;
};

export const ProfileSetting = ({ profile, updateProfile }: ProfileSettingProps) => {
  useAccessableSeachParams();
  const form = useForm<Account>(ProfileSettingForm, { ...profile });

  useEffect(() => {
    if (profile) {
      form.setValue(
        {
          ...profile,
          'phone.code': profile?.phone?.code,
          'phone.number': profile?.phone?.number,
        }! as any
      );
    }
  }, [profile]);

  return (
    <div className="w-1/2">
      <Form layout="vertical">
        {/* <Form.Item {...form.formItem('image')} label={<Avatar shape="square" size={64} icon={<UserOutlined />} />}>
          <Upload>
            <Button icon={<UploadOutlined />}>Profil fotoğrafı yükle</Button>
          </Upload>
        </Form.Item> */}

        <div className="grid grid-cols-2 gap-x-4">
          <TextInput formItem={form.formItem('name')} formInput={form.input('name')} label={'Ad'} />
          <TextInput formItem={form.formItem('surname')} formInput={form.input('surname')} label={'Soyad'} />
        </div>

        <div className="grid grid-cols-2 gap-x-4">
          <Form.Item {...form.formItem('gender')} label={'Cinsiyet:'}>
            <Radio.Group {...form.input('gender')}>
              <Radio value={Gender.FEMALE}>Kadın</Radio>
              <Radio value={Gender.MALE}>Erkek</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item {...form.formItem('birthDate')} label={'Doğum tarihi:'}>
            <BirthdayCalendar form={form} />
          </Form.Item>
        </div>
        <PhoneInput form={form} />

        <NationalIdInput form={form} label={'TC No:'} />

        {/* <TextInput formItem={form.formItem('email')} formInput={form.input('email')} label={'E-posta Adresi:'} maxLength={50} /> */}

        <Form.Item>
          <Button
            onClick={async () =>
              updateProfile({
                ...form.value,
                phone: {
                  code: form.value['phone.code'],
                  number: form.value['phone.number'],
                },
              })
            }
            type="primary"
          >
            Değişiklikleri kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
