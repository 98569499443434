import { ClockCircleOutlined, FieldNumberOutlined, LineChartOutlined, MailOutlined, MoonOutlined, PhoneOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { ClassStatus, LayoutEnums, PrePlacementDemandStatus } from '@shared/constants';
import { PrePlacementDemandBasicInfo, PrePlacementDemandDetail } from '@shared/models';
import { convertDate, localizeDemandStatus, renderLevelsAsString } from '@shared/utils';
import { Badge, Card, Divider, Empty, Spin, Table, Tabs, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StudentCourseInfo } from '..';

type StudentProfileTabsProps = {
  demandDetails: PrePlacementDemandDetail | null;
  demandList: PrePlacementDemandBasicInfo[] | undefined;
  requestLoading: boolean;
  onChangeKey: (key: string) => void;
  activeKey: string | undefined;
};

export const StudentProfileTabs = ({ demandDetails, demandList, requestLoading, activeKey, onChangeKey }: StudentProfileTabsProps) => {
  //Devamsızlık Tablosu demandDetails.atteandances'ı Ant Design Table uygun haline getirmek
  const data = Object.keys(demandDetails?.attendances || {}).map((key) => ({
    day: new Date(parseInt(key)).toLocaleDateString('tr-TR', { weekday: 'long', day: 'numeric', month: 'long' }),
    seances: demandDetails?.attendances[key].seances,
  }));

  const uniqueTeachers = useCallback(
    (c: PrePlacementDemandDetail | null) => {
      const teacherList: { id: string; name: string }[] = [];
      if (c) {
        c?.class?.classSeances?.forEach((seance) => {
          seance.classSeanceItems?.forEach((item) => {
            if (item.teacher) {
              teacherList.push({
                id: item?.teacher?.id!,
                name: `${item?.teacher?.name} ${item?.teacher?.surname}`,
              });
            }
          });
        });
      }
      return teacherList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
    },
    [demandDetails]
  );

  const uniqueClassrooms = useCallback(
    (c: PrePlacementDemandDetail | null) => {
      const classroomList: { id: string; name: string }[] = [];
      if (c) {
        c?.class?.classSeances?.forEach((seance) => {
          seance.classSeanceItems?.forEach((item) => {
            if (item.classRoom) {
              classroomList.push({
                id: item?.classRoom?.id!,
                name: item?.classRoom?.name!,
              });
            }
          });
        });
      }
      return classroomList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
    },
    [demandDetails]
  );

  const uniqueSeances = useMemo(() => {
    const seanceList: { id: string; name: string }[] = [];
    if (demandDetails) {
      demandDetails?.class?.classSeances?.forEach((seance) => {
        if (seance) {
          seanceList.push({ id: seance.id!, name: seance.name! });
        }
      });
    }
    return seanceList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
  }, [demandDetails]);

  const attendanceColumns = [
    {
      title: 'Gün',
      dataIndex: 'day',
      key: 'day',
    },
    {
      title: 'Seanslar',
      dataIndex: 'seances',
      key: 'seances',
      render: (seances) => (
        <div className="flex gap-4">
          {seances.map((seance, index) => (
            <Badge key={index} status={seance.attended ? 'success' : 'error'} />
          ))}
        </div>
      ),
    },
  ];

  const Content = () => {
    /*  return;
  demandDetails?.class == null ? (
      <Spin spinning={requestLoading} tip="Yükleniyor">
        <Card className="flex justify-center items-center" style={{ height: 'calc(100vh - 350px)' }}>
          <Empty description="Öğrenci bu kursta henüz ders almıyor!" />
        </Card>
      </Spin>
    ) : */

    if (!demandDetails) {
      return <></>;
    }

    return (
      <Spin spinning={requestLoading} tip="Yükleniyor">
        {demandDetails ? (
          <Card>
            {demandDetails?.class?.status == ClassStatus.COMPLETED && <StudentCourseInfo demandDetails={demandDetails} className="mb-10" />}

            {demandDetails?.class && (
              <Card type="inner" className="mt-2">
                <div className="grid grid-cols-3 gap-5">
                  <div className="flex justify-between">
                    <div>
                      <TeamOutlined className="text-lg text-[#1677FF]" />
                      <Typography.Text className="ml-2 text-[#1677FF] text-sm">Öğretmenler</Typography.Text>
                      {uniqueTeachers(demandDetails).map((teacher) => (
                        <div className="mt-2" key={teacher.id}>
                          {teacher.name}
                        </div>
                      ))}
                    </div>
                    <Divider className="h-full" type="vertical" />
                  </div>

                  <div className="flex justify-between">
                    <div>
                      <ClockCircleOutlined className="text-lg text-[#1677FF]" />
                      <Typography.Text className="ml-2 text-[#1677FF] text-sm">Derslik</Typography.Text>
                      {uniqueClassrooms(demandDetails).map((classroom) => (
                        <div className="mt-2" key={classroom.id}>
                          {classroom.name}
                        </div>
                      ))}
                    </div>
                    <Divider className="h-full" type="vertical" />
                  </div>
                  <div className="seans">
                    <div>
                      <LineChartOutlined className="text-lg text-[#1677FF]" />
                      <Typography.Text className="ml-2 text-[#1677FF] text-sm">Seans</Typography.Text>
                      {uniqueSeances.map((seance) => (
                        <div className="mt-2" key={seance.id}>
                          {seance.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            )}

            <div className="grid grid-cols-3 gap-5 mt-5">
              <Card>
                <div className="text-bold mb-4 text-base">Kayıt Bilgileri</div>

                <div className="flex items-center mb-2">
                  <UserOutlined className="text-blue-500 mr-2" />
                  <Typography.Text className="text-sm">{demandDetails.demand.prePlacement.name + ' ' + demandDetails.demand.prePlacement.surname}</Typography.Text>
                </div>

                <div className="flex items-center mb-2">
                  <FieldNumberOutlined className="text-blue-500 mr-2" />
                  <Typography.Text className="text-sm">{demandDetails.demand.prePlacement.nationalId}</Typography.Text>
                </div>

                <div className="flex items-center mb-2">
                  <MailOutlined className="text-blue-500 mr-2" />
                  <Typography.Text className="text-sm">{demandDetails.demand.prePlacement.email}</Typography.Text>
                </div>

                <div className="flex items-center mb-2">
                  <PhoneOutlined className="text-blue-500 mr-2" />
                  <Typography.Text className="text-sm">{demandDetails.demand.prePlacement.phone.code + ' ' + demandDetails.demand.prePlacement.phone.number}</Typography.Text>
                </div>
              </Card>

              <Card className="col-span-2">
                <div className="text-bold mb-4 text-base text-center">Gözetmen Bilgileri</div>
                <div className="flex justify-around">
                  {demandDetails?.observers?.map((obs) => (
                    <div key={obs.id}>
                      <div className="flex items-center mb-2">
                        <UserOutlined className="text-blue-500 mr-2" />
                        <Typography.Text className="text-sm">{obs.name + ' ' + obs.surname}</Typography.Text>
                      </div>

                      <div className="flex items-center mb-2">
                        <MailOutlined className="text-blue-500 mr-2" />
                        <Typography.Text className="text-sm">{obs.email}</Typography.Text>
                      </div>

                      <div className="flex items-center mb-2">
                        <PhoneOutlined className="text-blue-500 mr-2" />
                        <Typography.Text className="text-sm">{obs.phone?.code + ' ' + obs.phone?.number}</Typography.Text>
                      </div>
                    </div>
                  ))}
                </div>
              </Card>
            </div>
            <div className="mt-5">
              <div>Geçmiş</div>
              <div className="student_flow_container mt-7">
                <Timeline
                  className="student-flow-timeline"
                  mode="left"
                  items={demandList
                    ?.find((demand) => demand.id === demandDetails.id)
                    ?.history.map((h) => {
                      return {
                        label: (
                          <div className="flex flex-col">
                            <div>
                              <b>{localizeDemandStatus(h.status)}</b>
                            </div>
                            <div className="text-[#00000073] text-xs italic">
                              <div>{convertDate(h.at, 'DD.MM.YYYY HH:mm')}</div>
                              <small>{dayjs(h.at).fromNow()}</small>
                            </div>
                          </div>
                        ),
                        children: (
                          <div>
                            <div>{h.description}</div>
                            <div>
                              <small className="text-[#00000073] text-sm">{h.updatedBy ? h.updatedBy?.name : 'System'}</small>
                            </div>
                          </div>
                        ),
                      };
                    })}
                />
              </div>
            </div>
            <div className="mt-2">
              <Table
                locale={{ emptyText: <Empty description="Devamsızlık bilgisi bulunmamaktadır" /> }}
                title={() => 'Devamsızlık Çizelgesi'}
                dataSource={data}
                pagination={false}
                columns={attendanceColumns}
                bordered
              />
            </div>
          </Card>
        ) : (
          <div>
            <Empty className="mt-10" description="Öğrenci bilgisi bulunmamaktadır" />
          </div>
        )}
      </Spin>
    );
  };

  return (
    <Tabs
      activeKey={activeKey}
      onChange={(key) => onChangeKey && onChangeKey(key)}
      type="card"
      className="student-tab"
      items={demandList?.map((demand) => {
        return {
          label: (
            <>
              <span className=" mr-7">{renderLevelsAsString(demand.level)}</span>
              {demand.status === PrePlacementDemandStatus.SUCCESS && <Badge color={'green'} />}
              {demand.status === PrePlacementDemandStatus.FAILED && <Badge color={'red'} />}
              {demand.status === PrePlacementDemandStatus.CONDITIONAL && <Badge color={'blue'} />}
              {demand.status === PrePlacementDemandStatus.FROZEN && <MoonOutlined />}
            </>
          ),
          key: demand.id,
          children: <Content />,
        };
      })}
    />
  );
};
