import { DndContext, DragOverlay, KeyboardSensor, MouseSensor, TouchSensor, pointerWithin, useSensor, useSensors } from '@dnd-kit/core';
import { createContext, useContext, useMemo, useState } from 'react';

import { SwapOutlined } from '@ant-design/icons';
import type { KeyboardEvent, MouseEvent, ReactElement } from 'react';

export class MyMouseSensor extends MouseSensor {
  static activators = [
    {
      eventName: 'onMouseDown' as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

export class MyKeyboardSensor extends KeyboardSensor {
  static activators = [
    {
      eventName: 'onKeyDown' as const,
      handler: ({ nativeEvent: event }: KeyboardEvent<Element>) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

function shouldHandleEvent(element: HTMLElement | null) {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
}

type DragDropContextType = { dragItem?: any };
type DragDropContextProviderType = { children?: any; onDrop?: (type: any, dropItem: any, dragItem: any) => void };

const DragDropContext = createContext<DragDropContextType>({});

export const DragDropContextProvider = ({ children, onDrop }: DragDropContextProviderType) => {
  const [dragItem, setDragItem] = useState<any>(undefined);

  const mouseSensor = useSensor(MyMouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  function onDragStart(event) {
    setDragItem(event.active.data.current);
  }

  function onDragEnd(event) {
    if (event.over) {
      const dropZone = event.over;
      const dropType = dropZone.data.current.type;

      const dragItem = event.active;
      const dragType = dragItem.data.current.type;

      if (dropType === dragType) {
        onDrop?.(dropType, dropZone.data.current, dragItem.data.current);
      }
    }

    setDragItem(undefined);
  }

  const dragItemElement = useMemo(() => {
    let dragItemElement: ReactElement = <></>;
    if (dragItem) {
      switch (dragItem.type) {
        case 'STUDENT':
          dragItemElement = (
            <div className="planblock bg-white">
              <div>
                <SwapOutlined className="flex items-center justify-center w-[32px] py-[4px]" />
              </div>
              <span className="flex-1 text-[12px] overflow-hidden text-ellipsis whitespace-nowrap select-none">
                <small className="text-black/50 mr-1">{dragItem.value.level.name}</small>
                {dragItem.value.prePlacement.name + ' ' + dragItem.value.prePlacement.surname}
                <small className="text-black/50 ml-1">({dragItem.value.waitingInDays} Gün)</small>
              </span>
              <div className="ml-0.5 mr-1"></div>
            </div>
          );
          break;
        case 'TEACHER':
          dragItemElement = (
            <div className="planblock bg-white">
              <div>
                <SwapOutlined className="flex items-center justify-center w-[32px] py-[4px]" />
              </div>
              <span className="flex-1 text-[12px] overflow-hidden text-ellipsis whitespace-nowrap select-none">
                {dragItem.value.name + ' ' + dragItem.value.surname}
                <br />
                <small className="text-black/50">{dragItem.value.abilityLevels?.map((i) => i.name).join(',')}</small>
              </span>
              <div className="ml-0.5 mr-1"></div>
            </div>
          );
          break;
        case 'ROOM':
          dragItemElement = (
            <div className="planblock bg-white">
              <div>
                <SwapOutlined className="flex items-center justify-center w-[32px] py-[4px]" />
              </div>
              <span className="flex-1 text-[12px] overflow-hidden text-ellipsis whitespace-nowrap select-none">
                <small className="text-black/50 mr-1">
                  {dragItem.value.quota}-{dragItem.value.maxQuota}
                </small>
                {dragItem.value.name}
              </span>
              <div className="ml-0.5 mr-1"></div>
            </div>
          );
          break;
        case 'SEANCE':
          dragItemElement = (
            <div className="planblock bg-white">
              <div>
                <SwapOutlined className="flex items-center justify-center w-[32px] py-[4px]" />
              </div>
              <span className="flex-1 text-[12px] overflow-hidden text-ellipsis whitespace-nowrap select-none">{dragItem.value.name}</span>
              <div className="ml-0.5 mr-1"></div>
            </div>
          );
          break;
      }
    }
    return dragItemElement;
  }, [dragItem]);

  return (
    <DragDropContext.Provider value={{ dragItem }}>
      <DndContext sensors={sensors} onDragStart={onDragStart} onDragEnd={onDragEnd} autoScroll={false} collisionDetection={pointerWithin}>
        {children}
        {dragItem ? <DragOverlay>{dragItemElement}</DragOverlay> : dragItem}
      </DndContext>
    </DragDropContext.Provider>
  );
};

export const useDragDropContext = () => {
  const context = useContext(DragDropContext);
  if (context == undefined) {
    throw new Error('useDragDropContext must be used within a DragDropContextProvider');
  }
  return context;
};
