import { CountrySelector, PhoneInput, TextInput } from '@shared/components';
import { BranchType } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { CreateBranchModel } from '@shared/models';
import { Button, Form, Input, Radio, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect } from 'react';
import { z } from 'zod';

type CreateBranchPropsProps = {
  branch: CreateBranchModel;
  onSave: (branch: CreateBranchModel) => void;
};
const CreateBranchPropsForm = z.object({
  name: z.string({ required_error: 'Şube adı zorunlu alandır' }).min(1, 'Şube adı 1 karekterden fazla olmalı'),
  email: z.string({ required_error: 'Email zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
  'address.city.code': z.string({ required_error: 'Lütfen şehir seçiniz' }),
  'address.country.code': z.string({ required_error: 'Lütfen ülke seçiniz' }),
  'address.address': z.string({ required_error: 'Lütfen açık addres giriniz' }),
  type: z.string({ required_error: 'Lütfen şube tipi seçiniz' }),
});
export const CreateBranchProps = ({ branch = { active: true }, onSave }: CreateBranchPropsProps) => {
  const form = useForm<
    Partial<{
      name: string;
      email: string;
      'phone.number': string;
      'phone.code': string;
      'address.country.code': string;
      'address.country.name': string;
      'address.city.code': string;
      'address.address': string;
      type: BranchType;
    }>,
    CreateBranchModel
  >(CreateBranchPropsForm, { ...branch, type: BranchType.BRANCH });

  useEffect(() => {
    if (Object.keys(branch).length > 1) {
      form.setInitialValue(branch);
    }
  }, [branch]);

  const sendData = useCallback(async () => {
    const result = form.parse();
    if (result.success) {
      const { data } = result;
      onSave({
        ...data,
        address: {
          ...data.address,
          city: { ...data.address?.city, name: data.address?.city?.code },
          country: { ...data.address?.country },
          postalCode: '2',
        },
      });
    } else {
      form.setAllTouched();
    }
  }, [form]);

  return (
    <Form layout="vertical">
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col gap-0">
          <TextInput formItem={form.formItem('name')} formInput={form.input('name')} label={'Şube Adı:'} />
          <TextInput formItem={form.formItem('email')} formInput={form.input('email')} label={'E-Posta:'} maxLength={50} />

          <PhoneInput form={form} />
          <div></div>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-0 gap-x-4 pt-6">
            <CountrySelector form={form} />

            <div className="col-span-2">
              <Form.Item label={'Adres:'}>
                <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 2, maxRows: 5 }} {...form.input('address.address')} />
              </Form.Item>
            </div>
            <Form.Item className="col-span-2" label={'Şube Tipi:'}>
              <Radio.Group {...form.input('type')}>
                <Radio value={BranchType.BRANCH}>Şube</Radio>
                <Radio value={BranchType.FRANCHISE}>Bayilik</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
        <div className="col-span-2 flex justify-end">
          <Button type="primary" onClick={sendData}>
            Sonraki adım
          </Button>
        </div>
      </div>
    </Form>
  );
};
