import { InfoCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { AvailabilityCalendar, ClassRoomModel, Enrollment, PrePlacement, PrePlacementDemand, SessionDefinitionsModel, TeacherModel } from '@shared/models';
import { renderLevelsAsString } from '@shared/utils';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { RoomInfoPopever } from './RoomInfoPopever';
import { SeanceInfoPopever } from './SeanceInfoPopever';
import { StudentInfoPopever } from './StudentInfoPopever';
import { TeacherInfoPopever } from './TeacherInfoPopever';

type InfoPopeverProps = {
  type?: 'STUDENT' | 'TEACHER' | 'SEANCE' | 'ROOM';
  student?: PrePlacementDemand;
  teacher?: TeacherModel;
  room?: ClassRoomModel;
  seance?: SessionDefinitionsModel;
};

export const InfoPopever = ({ type, student, teacher, room, seance }: InfoPopeverProps) => {
  const [teacherData, setTeacherData] = useState<TeacherModel>({});
  const [studentData, setStudentData] = useState<PrePlacement>({});
  const [prePlacement, setPrePlacement] = useState<Enrollment[]>([]);
  const [course, setCourse] = useState('');
  const [availabilityCalendar, setAvailabilityCalendar] = useState<AvailabilityCalendar>({});
  const [open, setOpen] = useState<boolean>(false);

  const onOpenChange = (v) => {
    setOpen(v);
  };

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);

  const getData = async () => {
    if (type == 'TEACHER') {
      const response = await API.TEACHER.getById(teacher?.id!);
      const availability = await API.TEACHER.getAvailableCalendar(teacher?.id!);
      setAvailabilityCalendar(availability.data);
      setTeacherData(response.data);
    } else if (type == 'STUDENT') {
      const response = await API.ENROLLMENT.getPrePlacementById(student?.prePlacement?.id!);
      const responsePrePlacement = await API.ENROLLMENT.getPrePlacementByPrePlacement(student?.prePlacement?.id!);
      const prePlacement = responsePrePlacement?.data?.map((x) => x);
      setPrePlacement(prePlacement);
      setStudentData(response.data);
      setCourse(renderLevelsAsString(student?.level));
      setAvailabilityCalendar({ ...response.data.availabilityCalendar! });
    }
  };

  return (
    <>
      <div className="flex mb-1 justify-end">
        {type === 'STUDENT' && (
          <StudentInfoPopever data={studentData} availabilityCalendar={availabilityCalendar} onOpenChange={onOpenChange} course={course} open={open} prePlacement={prePlacement} />
        )}
        {type === 'TEACHER' && <TeacherInfoPopever data={teacherData} availabilityCalendar={availabilityCalendar} onOpenChange={onOpenChange} open={open} />}
        {type === 'ROOM' && <RoomInfoPopever data={room!} onOpenChange={onOpenChange} open={open} />}
        {type === 'SEANCE' && <SeanceInfoPopever key={Math.random().toString(36).substring(7) + seance?.id} data={seance!} onOpenChange={onOpenChange} open={open} />}
      </div>
      <Button
        data-no-dnd="true"
        disabled={false}
        type="text"
        onClick={() => {
          setOpen(true);
        }}
        icon={<InfoCircleOutlined />}
      />
    </>
  );
};
