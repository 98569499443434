import { CalendarOutlined, DeleteOutlined, InfoCircleOutlined, MoreOutlined, ReadOutlined, SettingOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { TimeFormatter } from '@shared/components';
import { DayOfWeek } from '@shared/constants';
import { ClassDetailModel, PrePlacementDemandBasicInfo } from '@shared/models';
import { AuthGuard } from '@shared/permission';
import { urlCleaner } from '@shared/utils';
import { Button, Card, DatePicker, Divider, Dropdown, Input, Menu, Modal, notification, Popconfirm, Popover, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ReactElement, useMemo, useState } from 'react';
import { MdOutlineTimelapse } from 'react-icons/md';
import { PiChalkboardTeacherLight } from 'react-icons/pi';
import { SiGoogleclassroom } from 'react-icons/si';
import { TbCalendarCancel } from 'react-icons/tb';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClassContentVersion } from './ClassContentVersion';
import { ClassSettings } from './ClassSettings';
import { ClassStudentList } from './ClassStudentList';
import { CgRename } from 'react-icons/cg';

type ClassInfoProps = {
  classDetail: ClassDetailModel | undefined;
  getClassDetail: () => void;
  calendar: ReactElement;
  isSettings?: boolean;
  availableStudentList?: PrePlacementDemandBasicInfo[] | undefined;
  isTeacherLayout?: boolean;
};

export const ClassInfo = ({ classDetail, calendar, getClassDetail, isSettings = true, availableStudentList, isTeacherLayout = false }: ClassInfoProps) => {
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [classEndModalOpen, setClassEndModalOpen] = useState<boolean>(false);
  const [changeClassNameModalOpen, setChangeClassNameModalOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const [updatedEndDate, setUpdatedEndDate] = useState<Dayjs>();
  const [contentVersionModal, setContentVersionModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { BranchGuards } = AuthGuard();

  const uniqueTeachers = useMemo(() => {
    const teacherList: { id: string; name: string }[] = [];
    if (classDetail) {
      classDetail!.classSeances?.forEach((seance) => {
        seance.classSeanceItems?.forEach((item) => {
          if (item.teacher) {
            teacherList.push({
              id: item?.teacher?.id!,
              name: `${item?.teacher?.name} ${item?.teacher?.surname}`,
            });
          }
        });
      });
    }
    setInput(classDetail?.name!);
    return teacherList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
  }, [classDetail]);

  const uniqueClassrooms = useMemo(() => {
    const classroomList: { id: string; name: string }[] = [];
    if (classDetail) {
      classDetail!.classSeances?.forEach((seance) => {
        seance?.classSeanceItems?.forEach((item) => {
          if (item.classRoom) {
            classroomList.push({ id: item?.classRoom?.id!, name: item?.classRoom?.name! });
          }
        });
      });
    }
    return classroomList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
  }, [classDetail]);

  const uniqueSeances = useMemo(() => {
    const seanceList: { id: string; name: string }[] = [];
    if (classDetail) {
      classDetail!.classSeances?.forEach((seance) => {
        if (seance) {
          seanceList.push({ id: seance.id!, name: seance.name! });
        }
      });
    }
    return seanceList.filter((value, index, array) => array.findIndex((v) => v.id === value.id) === index);
  }, [classDetail]);

  const seanceDetailList = useMemo(() => {
    const result: { id: string; day: string; hour: JSX.Element; room: string; teacher: string }[] = [];
    if (classDetail) {
      classDetail!.classSeances?.forEach((seance) => {
        seance.classSeanceItems?.forEach((item) => {
          if (item) {
            const hour = <TimeFormatter startHour={item.beginHour!} startMinute={item.beginMinute!} endHour={item.endHour!} endMinute={item.endMinute!} />;
            const teacher = item.teacher ? `${item.teacher?.name} ${item.teacher?.surname}` : 'Lütfen ögretmen bilgilerini doldurunuz.';
            result.push({ id: item.id!, day: seance.dayOfWeeks!.map((day) => DayOfWeek[day]).join(', '), hour, room: item.classRoom?.name!, teacher });
          }
        });
      });
    }
    return result;
  }, [classDetail]);

  const popoverContent = (
    <div className="min-w-[270px] max-h-[520px] overflow-y-scroll">
      {classDetail?.classSeances?.map((seance) => (
        <div key={seance.id} className="mt-4 border border-solid border-[#f5f5f5] rounded p-2">
          <div className="py-1 px-2 bg-[#f7f7f7] rounded-lg">{seance.dayOfWeeks?.map((day) => DayOfWeek[day]).join(', ')}</div>
          <div className="mt-2 py-2 px-2 bg-[#E6F4FF] rounded-lg">{seance.name}</div>
          {seance.classSeanceItems?.map((item) => (
            <div key={item.id}>
              <div className="border border-solid border-[#91CAFF] rounded-md mt-3 px-1 text-sm">
                <TimeFormatter startHour={item.beginHour!} startMinute={item.beginMinute!} endHour={item.endHour!} endMinute={item.endMinute!} />
              </div>
              <div className="grid grid-cols-5 mt-2 gap-x-3">
                <div className="flex flex-col col-span-2">
                  <div>Derslik</div>
                  <div className="py-1 px-2 bg-[#f7f7f7] rounded-lg">{item.classRoom?.name}</div>
                </div>
                <div className="flex flex-col col-span-3">
                  <div>Öğretmen</div>
                  <div className="py-1 px-2 bg-[#f7f7f7] rounded-lg">
                    {item.teacher?.name} {item.teacher?.surname}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const onUpdateEndDate = async () => {
    const response = await API.CLASS.updateClassEndDate(classDetail?.id!, updatedEndDate?.toDate());
    if (response.ok) {
      notification.success({ message: 'Sınıf Bitiş Tarihi Güncellenmiştir!' });
      getClassDetail();
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <div className="w-full text-left cursor-pointer" onClick={() => setContentVersionModalOpen(true)}>
          <ReadOutlined className="mr-2" />
          Sınıfın mevcut ders içeriğini değiştir
        </div>
      </Menu.Item>
      <Menu.Item key="2">
        <div
          className="w-full text-left cursor-pointer"
          onClick={() => {
            setSettingsModalOpen(true);
          }}
        >
          <SettingOutlined className="mr-2" />
          Seans Ayarlarını Görüntüle
        </div>
      </Menu.Item>
      <Menu.Item key="3">
        <div
          className="w-full text-left cursor-pointer"
          onClick={() => {
            setClassEndModalOpen(true);
          }}
        >
          <CalendarOutlined className="mr-2" />
          Bitiş Tarihini Değiştir
        </div>
      </Menu.Item>
      <Menu.Item key="4">
        <div
          className="w-full text-left cursor-pointer"
          onClick={() => {
            setChangeClassNameModalOpen(true);
          }}
        >
          <div className="flex items-center">
            <CgRename className="mr-2" />
            Sınıf Adını Güncelle
          </div>
        </div>
      </Menu.Item>
      {classDetail?.status === 'STARTED' && (
        <Menu.Item key="5">
          <Popconfirm
            title="Sınıfı sonlandırmak istediğinize emin misin?"
            onConfirm={async () => {
              const response = await API.CLASS.classComplete(classDetail?.id);
              if (response.ok) {
                notification.success({ message: 'Sınıf sonlandırma işlemi başarılı' });
                navigate('/lms/class?' + urlCleaner(searchParams));
              }
            }}
            onCancel={() => {}}
            okText="Evet"
            cancelText="İptal"
          >
            <div className="w-full text-left">
              <TbCalendarCancel className="mr-2" />
              Sınıfı Sonlandır
            </div>
          </Popconfirm>
        </Menu.Item>
      )}
      {(classDetail?.status === 'NOT_STARTED' || classDetail?.status === 'CANCELED') && (
        <Menu.Item key="4" danger>
          <Popconfirm
            title="Sınıfı silmek istediğine emin misin?"
            onConfirm={async () => {
              const response = await API.CLASS.deleteClass(classDetail?.id);
              if (response.ok) {
                notification.success({ message: 'Sınıf silme başarılı' });
                navigate('/lms/class?' + urlCleaner(searchParams));
              }
            }}
            onCancel={() => {}}
            okText="Evet"
            cancelText="İptal"
          >
            <div className="w-full text-left">
              <DeleteOutlined className="mr-2" />
              Sınıfı Sil
            </div>
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <>
      <Card
        size="small"
        title={<span className=" font-normal text-[#00000073]">Sınıf Bilgisi</span>}
        extra={
          classDetail?.status !== 'COMPLETED' &&
          BranchGuards.LMS.Class.update() && (
            <Dropdown overlay={menu} trigger={['click']}>
              <MoreOutlined className="cursor-pointer text-lg text-[#00000073]" />
            </Dropdown>
          )
        }
        style={{ background: 'linear-gradient(to bottom, #f8f8f8, #f3d9d5)', height: 'calc(100vh - 227px)' }}
        className="overflow-y-auto scrollbar-hide"
      >
        <Card type="inner" className="mt-2">
          <div className="flex justify-between text-[#00000073]">
            <span className="flex items-center">
              <PiChalkboardTeacherLight className="text-lg mr-1" />
              <span className="text-sm">Öğretmenler</span>
            </span>
            <Popover placement="left" trigger="click" content={popoverContent}>
              <InfoCircleOutlined className="cursor-pointer flex items-center" />
            </Popover>
          </div>
          {uniqueTeachers.length > 0 ? (
            uniqueTeachers.map(({ id, name }) => (
              <div key={id} className="mt-1 text-sm">
                <span>{name}</span>
              </div>
            ))
          ) : (
            <span className="mt-1 text-xs">Lütfen öğretmen ekleyiniz</span>
          )}

          <Divider className="my-2" />

          <div className="text-[#00000073] mt-3 flex items-center">
            <SiGoogleclassroom className=" mr-1" />
            <span className="text-sm">Derslik</span>
          </div>

          <div className="mt-1 text-sm">{uniqueClassrooms.map((room) => room.name).join(', ')}</div>
          <Divider className="my-2" />

          <div className="text-[#00000073] mt-3 flex items-center">
            <MdOutlineTimelapse className=" mr-1" />
            <span className="text-sm">Seans</span>
          </div>

          <div className="mt-1 text-sm">{uniqueSeances.map((seance) => seance.name).join(', ')}</div>
        </Card>
        <div className="mt-2 mb-1 text-sm text-[#00000073]">Öğrenci Listesi</div>
        <ClassStudentList
          hasBranchClassUpdatePermission={BranchGuards.LMS.Class.update()}
          availableStudentList={availableStudentList}
          classDetail={classDetail}
          onRefleshClassDetail={getClassDetail}
          isTeacherLayout={isTeacherLayout}
        />
        <div className="mt-2 text-sm text-[#00000073]">Ders Programı</div>
        {seanceDetailList.map((seance) => (
          <Card className="mt-1" key={seance.id} type="inner">
            <div>
              <CalendarOutlined className="mr-1" /> {seance.day}
            </div>
            <div>
              <MdOutlineTimelapse className="mr-1" /> {seance.hour}
            </div>
            <div>
              <SiGoogleclassroom className="mr-2" />
              {seance.room}
            </div>
            <div>
              <PiChalkboardTeacherLight className="mr-2" />
              {seance.teacher}
            </div>
          </Card>
        ))}
        <div className="mt-2 text-sm text-[#00000073]">Sınıf Takvimi</div>
        {calendar}
      </Card>

      <Modal title={<span className="font-normal">Ayarlar</span>} open={settingsModalOpen} footer={null} onCancel={() => setSettingsModalOpen(false)}>
        <ClassSettings classDetail={classDetail} getClassDetail={getClassDetail!} />
      </Modal>
      <Modal title={<span>Sınıfın mevcut ders içeriğini değiştir</span>} open={contentVersionModal} footer={null} onCancel={() => setContentVersionModalOpen(false)}>
        <ClassContentVersion classDetail={classDetail} getClassDetail={getClassDetail!} />
      </Modal>

      <Modal title={<span className="font-normal">Seans Bitiş Tarihini Düzenle</span>} open={classEndModalOpen} footer={null} onCancel={() => setClassEndModalOpen(false)}>
        <h4 className="text-lg">{classDetail?.name}</h4>
        <div className="w-full">
          <div className="grid grid-cols-2 my-1">
            <Typography.Text>Kur Başlangıç Tarihi</Typography.Text>
            <Typography.Text>Kur Bitiş Tarihi</Typography.Text>
          </div>
          <DatePicker.RangePicker
            allowClear={false}
            disabled={[true, false]}
            defaultValue={[dayjs(classDetail?.calculatedStartDate), updatedEndDate ?? dayjs(classDetail?.calculatedEndDate)]}
            format="DD/MM/YYYY"
            onChange={(dates) => {
              if (dates) {
                const [startDate, endDate] = dates;
                setUpdatedEndDate(endDate!);
              }
            }}
            className="w-full"
          />
        </div>
        <Button
          block
          disabled={updatedEndDate && !updatedEndDate?.isSame(dayjs(classDetail?.calculatedEndDate), 'day') ? false : true}
          onClick={() => onUpdateEndDate()}
          type="primary"
          className="mt-5"
        >
          Bitiş Tarihini Güncelle
        </Button>
      </Modal>
      <Modal title={<span>Sınıfın adını değiştir</span>} open={changeClassNameModalOpen} footer={null} onCancel={() => setChangeClassNameModalOpen(false)}>
        <Input
          placeholder="Yeni sınıf adını girin"
          value={input}
          className="mt-2"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        <Button
          type="primary"
          className="mt-4 w-full"
          onClick={async () => {
            if (!input.trim()) {
              return notification.warning({ message: 'Sınıf adı boş bırakılamaz. Lütfen bir ad girin.' });
            }
            const response = await API.CLASS.updateClassName(classDetail?.id!, { name: input });
            if (response.ok) {
              setChangeClassNameModalOpen(false);
              notification.success({ message: 'Sınıf adı başarıyla güncellendi.' });
              getClassDetail();
            }
          }}
        >
          Sınıf Adını Güncelle
        </Button>
      </Modal>
    </>
  );
};
