import { BranchUserModel } from '@shared/models';
import { Button, Drawer, Form, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { CreateBranchUsers } from '@platform/platform_components';
import * as API from '@shared/api';
import { Role } from '@shared/constants';
import { z } from 'zod';
import { useForm } from '@shared/hooks';
import { CreateBrandModel } from '@shared/models';
import { PhoneInput, CountrySelector, TextInput } from '@shared/components';
import TextArea from 'antd/lib/input/TextArea';

type BrandAndBranchUserFormType = {
  brand?: CreateBrandModel;
  open: boolean;
  onCancelDrawer: () => void;
};

const CreateBrandForm = z.object({
  name: z.string({ required_error: 'Marka adı zorunlu alandır' }).min(1, 'Marka adı 1 karekterden fazla olmalı'),
  email: z.string({ required_error: 'Email zorunlu alandır' }).min(1, 'TODO Bu değişecek ama şimdilik en az 1 karakter').email('Geçersiz e-mail adresi'),
  'phone.code': z.string({ required_error: 'Lütfen telefon ülke kodunu seçiniz' }),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
  'address.city.code': z.string({ required_error: 'Lütfen şehir seçiniz' }),
  'address.country.code': z.string({ required_error: 'Lütfen ülke seçiniz' }),
  'address.address': z.string({ required_error: 'Lütfen açık adres giriniz' }),
});

export const UpdateBrand = ({ brand, open, onCancelDrawer }: BrandAndBranchUserFormType) => {
  const form = useForm<
    Partial<{
      name: string;
      email: string;
      'phone.code': string;
      'phone.number': string;
      'address.country.code': string;
      'address.country.name': string;
      'address.city.code': string;
      'address.address': string;
    }>,
    CreateBrandModel
  >(CreateBrandForm, { ...brand });

  useEffect(() => {
    if (brand) {
      form.setInitialValue(brand);
    }
  }, [brand]);

  return (
    <Drawer open={open} onClose={onCancelDrawer}>
      <Form layout="vertical">
        <div className="grid grid-cols-1 gap-1">
          <TextInput formItem={form.formItem('name')} formInput={form.input('name')} label={'Marka Adı:'} />

          <TextInput formItem={form.formItem('email')} formInput={form.input('email')} label={'E-Posta:'} maxLength={50} />
          <PhoneInput form={form} />
          <div className="grid grid-cols-2">
            <CountrySelector form={form} />
          </div>
          <Form.Item {...form.formItem('address.address')} label={'Adres:'}>
            <TextArea showCount maxLength={300} style={{ resize: 'none' }} autoSize={{ minRows: 2, maxRows: 5 }} {...form.input('address.address')} />
          </Form.Item>
        </div>
        <div className="col-span-2 flex justify-end">
          <Button
            type="primary"
            onClick={async () => {
              const result = form.parse();
              const response = await API.BRAND.update(result.data);
              if (response.ok) {
                onCancelDrawer();
                notification.success({ message: 'Marka güncellendi' });
              } else {
                notification.success({ message: 'Marka güncellenemedi' });
              }
            }}
          >
            Güncelle
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

UpdateBrand.roles = [Role.ADMIN];
