import { useFetch } from '@shared/hooks';
import { AccessableBranch, BranchUserModel, CreateBranchModel, CreateBranchUser, PaginateModel, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  create: async (branch: CreateBranchModel) => request('/branch', 'POST', branch),
  update: async (branch: CreateBranchModel) => request('/branch/' + branch.id, 'PUT', branch),
  delete: async (branchId: string) => request('/branch/' + branchId, 'DELETE'),
  getBranchById: async (branchId: string) => request('/branch/' + branchId, 'GET'),
  getAllBranch: (config: UseFetchOptions) => useFetch<PaginateModel<CreateBranchModel>>('/branch', { method: 'GET', ...config }),
  getBrandBranchUsers: (branchId: string, config: UseFetchOptions) => useFetch<PaginateModel<BranchUserModel>>('/brand/branch-user', { method: 'GET', ...config }),
  getBranchUsers: (config: UseFetchOptions) => useFetch<PaginateModel<BranchUserModel>>('/branch-user', { method: 'GET', ...config }),
  getBranchUsersWithBranchId: (config: UseFetchOptions) => useFetch<PaginateModel<BranchUserModel>>('/brand/branch-user', { method: 'GET', ...config }),
  createBrandBranchUser: async (user: CreateBranchUser) => request('/brand/branch-user', 'POST', user),
  createBranchUser: async (user: CreateBranchUser) => request('/branch-user', 'POST', user),
  getUserByEmail: async (email: string) => request('/branch/account/search/' + email, 'GET'),
  deleteBrandBranchUser: async (branchId: string, userId: string) => request('/brand/branch-user/' + branchId + '/' + userId, 'DELETE'),
  updateBrandBranchUser: async (user: BranchUserModel) => request('/brand/branch-user/permissions', 'PUT', user),
  updateBranchUser: async (user: CreateBranchUser) => request('/branch-user/permissions', 'PUT', user),
  deleteBranchUser: async (userId: string) => request('/branch-user/' + userId, 'DELETE'),
  accessableBranches: (config: UseFetchOptions) => useFetch<PaginateModel<AccessableBranch>>('/account/accessable-branches', { method: 'GET', ...config }),
  accessableBranchesWithBrandId: async (brandId: string, limit: number = 999999) => request(`/account/accessable-branches?brandId=${brandId}&limit=${limit}`, 'GET'),
};
