import * as API from '@shared/api';
import { ExerciseGroups } from '@shared/components';
import { Role } from '@shared/constants';
import { CascaderOption, PaginationQuery } from '@shared/models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AdminExerciseGroups = () => {
  const [groups, loading, getAllExerciseGroup] = API.CURRICULUM.EXERCISE.fetchAllExerciseGroup({ init: false });
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 10 });
  const navigate = useNavigate();
  const [fields, fieldLoading, getFields] = API.EDUCATION_LEVEL.SYSTEM.useFetchField({ init: true });
  const [types, typeLoading, getTypes] = API.EDUCATION_LEVEL.SYSTEM.useFetchType({ init: true });
  const [themes, themesLoading, getTheme] = API.EDUCATION_LEVEL.SYSTEM.useFetchTheme({ init: true });
  const [levels, levelsLoading, getLevel] = API.EDUCATION_LEVEL.SYSTEM.useFetchLevel({ init: true, initParams: { active: true, limit: 1000 } });
  const [levelCascaderOptions, setLevelCascaderOptions] = useState<CascaderOption[]>([]);

  useEffect(() => {
    getAllExerciseGroup(undefined, paginationQuery);
  }, [paginationQuery]);

  useEffect(() => {
    if (levels && fields && types && themes) {
      const _items: CascaderOption[] = [];
      fields.data?.map((f) => {
        _items.push({
          value: f.id!,
          label: f.name!,
          children: types.data
            ?.filter((t) => t.field?.id == f.id)
            .map((t) => {
              return {
                value: t.id!,
                label: t.name,
                children: themes
                  .data!.filter((m) => m.type?.id == t.id)
                  .map((m) => {
                    return {
                      value: m.id,
                      label: m.name,
                      children: levels?.data
                        ?.filter((l) => l.theme?.id == m.id)
                        .map((l) => {
                          return {
                            value: l.id,
                            label: l.name,
                          } as CascaderOption;
                        }),
                    };
                  }) as CascaderOption[],
              };
            }) as CascaderOption[],
        });
      });
      setLevelCascaderOptions(_items);
    }
  }, [levels, fields, types, themes]);

  return (
    <ExerciseGroups
      isLoading={loading}
      groups={groups}
      levels={levelCascaderOptions}
      onCreateRequest={() => {
        navigate('/create-admin-exercise-group');
      }}
      onPaginationChange={(query) => {
        setPaginationQuery(query);
      }}
      onDelete={async (id) => {
        try {
          await API.CURRICULUM.EXERCISE.deleteExerciseGroup(id);
          getAllExerciseGroup(undefined, { ...paginationQuery, page: 1 });
        } catch (error) {}
      }}
      onEdit={(id) => {
        const params = new URLSearchParams({
          exerciseGroupId: id,
        });
        navigate('/create-admin-exercise-group?' + params);
      }}
      onFork={async (exerciseId) => {
        const {
          data: { id },
        } = await API.CURRICULUM.EXERCISE.cloneExerciseGroup(exerciseId);
        const params = new URLSearchParams({
          exerciseGroupId: id,
        });
        navigate('/create-admin-exercise-group?' + params);
      }}
    />
  );
};

AdminExerciseGroups.path = 'admin-exercises-groups';
AdminExerciseGroups.title = 'Egzersiz Grupları';
AdminExerciseGroups.group = 'curriculum';
AdminExerciseGroups.roles = [Role.ADMIN];
