import { ExerciseLayoutType } from '@shared/constants';
import {
  DialogBase,
  DialogBaseSkeleton,
  DialogSelectedSkeleton,
  DialogSortingSkeleton,
  GapFillingKeyboardSkeleton,
  GapFillingSelectedSkeleton,
  ListiningBaseSkeleton,
  ListiningMultiSelectSkeleton,
  ListiningSingilSelectSkeleton,
  MatchSkeletons,
  MatchImageSkeletons,
  ReadingMultiSelectSkeleton,
  ReadingSingilSelectSkeleton,
  ReadingGapFillingSkeleton,
  VisualBaseSkeleton,
  VisualMultiSelectSkeleton,
  VisualSingilSelectSkeleton,
} from './exercise_types';
import { Divider } from 'antd';

type ExerciseProps = {
  onChange: (type: ExerciseLayoutType) => void;
};
export const ExerciseTypeSelective = ({ onChange }: ExerciseProps) => {
  return (
    <div className="border border-dashed border-mid-gray rounded">
      <div className="border-separate p-2">
        <h3 className="opacity-45 font-normal">Görsel</h3>
        <div className="grid grid-cols-2 gap-2 mt-2">
          <VisualBaseSkeleton onChange={onChange} />
          <VisualSingilSelectSkeleton onChange={onChange} />
          {/*<VisualMultiSelectSkeleton onChange={onChange} />*/}
        </div>
      </div>
      <Divider className="m-0" dashed />
      <div className="border-separate p-2">
        <h3 className="opacity-45 font-normal">Dinleme</h3>
        <div className="grid grid-cols-2 gap-2 mt-2">
          <ListiningBaseSkeleton onChange={onChange} />
          <ListiningSingilSelectSkeleton onChange={onChange} />
          {/*
          
          
  <ListiningMultiSelectSkeleton onChange={onChange} /> */}
        </div>
      </div>

      <Divider className="m-0" dashed />
      <div className="border-separate p-2">
        <h3 className="opacity-45 font-normal">Okuma</h3>
        <div className="grid grid-cols-2 gap-2 mt-2">
          <ReadingSingilSelectSkeleton onChange={onChange} />
          <ReadingMultiSelectSkeleton onChange={onChange} />
          <ReadingGapFillingSkeleton onChange={onChange} />
        </div>
      </div>

      <Divider className="m-0" dashed />
      <div className="border-separate p-2">
        <h3 className="opacity-45 font-normal">Eşleme</h3>
        <div className="grid grid-cols-2 gap-2 mt-2">
          <MatchSkeletons onChange={onChange} />
          <MatchImageSkeletons onChange={onChange} />
        </div>
      </div>

      <Divider className="m-0" dashed />
      <div className="border-separate p-2">
        <h3 className="opacity-45 font-normal">Boşluk Doldurma</h3>
        <div className="grid grid-cols-2 gap-2 mt-2">
          <GapFillingSelectedSkeleton onChange={onChange} />
          {/*<GapFillingKeyboardSkeleton onChange={onChange} />*/}
        </div>
      </div>

      <Divider className="m-0" dashed />
      <div className="border-separate p-2">
        <h3 className="opacity-45 font-normal">Diyalog</h3>
        <div className="grid grid-cols-2 gap-2 mt-2">
          <DialogBaseSkeleton onChange={onChange} />
          {/*<DialogSelectedSkeleton onChange={onChange} />
          <DialogSortingSkeleton onChange={onChange} />*/}
        </div>
      </div>
    </div>
  );
};
