import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as API from '@shared/api';
import { ClassActivityStatus, LayoutEnums } from '@shared/constants';
import { ClassActivity } from '@shared/models';
import { Button, notification, Popconfirm } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TimeFormatter } from './TimeFormatter';

type NearTimeNotificationProps = {
  activity: ClassActivity;
};

export const NearTimeNotification = ({ activity }: NearTimeNotificationProps) => {
  const navigate = useNavigate();
  const targetDate = new Date();
  const [title, setTitle] = useState<ReactElement>(<></>);
  const location = useLocation();

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {
      minutes: 0,
      seconds: 0,
      str: '00:00',
    };

    if (difference > 0) {
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      timeLeft = {
        minutes,
        seconds,
        str: minutes + ':' + seconds,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<{ minutes: number; seconds: number; str: string }>(calculateTimeLeft());

  useEffect(() => {
    targetDate.setHours(activity.classSeanceItem?.beginHour ?? 0, activity.classSeanceItem?.beginMinute ?? 0);
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [activity]);

  useEffect(() => {
    if (activity.status == ClassActivityStatus.NEAR_TIME && !(timeLeft.minutes == 0 && timeLeft.seconds == 0)) {
      setTitle(
        <span className="text-white text-sm">
          <span className="font-bold mr-1">
            {
              <TimeFormatter
                startHour={activity.classSeanceItem?.beginHour!}
                startMinute={activity.classSeanceItem?.beginMinute!}
                endHour={activity.classSeanceItem?.endHour!}
                endMinute={activity.classSeanceItem?.endMinute!}
              />
            }
          </span>
          {activity.classCalendarEntry?.class?.name} sınıfınızın {activity.classSeanceItem?.classRoom?.name} dersliğindeki seansı
          <span className="text-[#FFFF00] font-bold ml-1 mr-1">
            {timeLeft.minutes} dakika {timeLeft.seconds} saniye
          </span>
          içinde başlıyor.
        </span>
      );
    }
    if (activity.status == ClassActivityStatus.NEAR_TIME && timeLeft.minutes == 0 && timeLeft.seconds == 0) {
      setTitle(
        <span className="text-white text-sm">
          <span className="font-bold mr-1">
            {
              <TimeFormatter
                startHour={activity.classSeanceItem?.beginHour!}
                startMinute={activity.classSeanceItem?.beginMinute!}
                endHour={activity.classSeanceItem?.endHour!}
                endMinute={activity.classSeanceItem?.endMinute!}
              />
            }
          </span>
          {activity.classCalendarEntry?.class?.name} sınıfınızın {activity.classSeanceItem?.classRoom?.name} dersliğindeki seans başlamıştır.
        </span>
      );
    }
    if (activity.status == ClassActivityStatus.DANGLED) {
      setTitle(
        <span className="text-white text-sm">
          <span className="font-bold mr-1">
            {
              <TimeFormatter
                startHour={activity.classSeanceItem?.beginHour!}
                startMinute={activity.classSeanceItem?.beginMinute!}
                endHour={activity.classSeanceItem?.endHour!}
                endMinute={activity.classSeanceItem?.endMinute!}
              />
            }
          </span>
          {activity.classCalendarEntry?.class?.name} sınıfınızın {activity.classSeanceItem?.classRoom?.name} dersliğindeki seansının süresi dolmuştur.
          <span className="font-black"> Seansı sonlandırmanız gerekmektedir.</span>
        </span>
      );
    }
    if (activity.status == ClassActivityStatus.WAITING_FOR_ATTENDANCE || activity.status == ClassActivityStatus.STARTED) {
      setTitle(
        <span className="text-white text-sm">
          <span className="mr-1 font-bold">
            {
              <TimeFormatter
                startHour={activity.classSeanceItem?.beginHour!}
                startMinute={activity.classSeanceItem?.beginMinute!}
                endHour={activity.classSeanceItem?.endHour!}
                endMinute={activity.classSeanceItem?.endMinute!}
              />
            }
          </span>
          {activity.classCalendarEntry?.class?.name} sınıfınızın {activity.classSeanceItem?.classRoom?.name} dersliğindeki seansı devam etmektedir.
        </span>
      );
    }
  }, [timeLeft, activity]);

  const onCloseClass = async () => {
    try {
      await API.CLASS.compeletedInClass(activity.classCalendarEntry?.class?.id!, activity.id!);
      if (location.pathname == '/teacher/lesson-content') {
        navigate('/teacher/class' + location.search);
      }
    } catch (error) {
      notification.error({
        message: 'Hata',
        description: 'Seansı sonlandırırken bir hata oluştu',
      });
    }
  };

  const goToClass = (activity: ClassActivity) => {
    const currentParams = new URLSearchParams(window.location.search);

    //Eğer o sırada öğretmen farklı bi şubedeki url'ye sahipse, sınıfın olduğu branchid'sine geçiş yapacak
    if (currentParams.get('branchId') !== activity.branchId) {
      currentParams.set('branchId', activity.branchId!);
    }

    const params = new URLSearchParams({
      layout: LayoutEnums.TEACHER,
      brandId: activity.brandId!,
      branchId: activity.branchId!,
      class_id: activity.classCalendarEntry?.class?.id ?? '',
    });

    const url = `/${LayoutEnums.TEACHER}/class?${params.toString()}`;
    navigate(url, { replace: true });
  };

  const goToInClass = () => {
    const params = new URLSearchParams({
      layout: LayoutEnums.TEACHER,
      brandId: activity.brandId!,
      branchId: activity.branchId!,
      class_id: activity.classCalendarEntry?.class?.id ?? '',
    });

    const url = `/${LayoutEnums.TEACHER}/lesson-content?${params.toString()}`;
    navigate(url, { replace: true });
  };

  return (
    <div key={activity.id} className="bg-[#00299A] p-2 flex flex-row justify-center space-x-4">
      <div className="text-center">{title}</div>
      {activity.status == ClassActivityStatus.NEAR_TIME && timeLeft.minutes == 0 && timeLeft.seconds == 0 && (
        <Button type="primary" size="small" onClick={() => goToClass(activity)}>
          Seansa Katıl
        </Button>
      )}
      {activity.status == ClassActivityStatus.DANGLED && (
        <Popconfirm title="Seans Sonlandırma" description="Seansı sonlandırmak istediğinize emin misiniz?" onConfirm={onCloseClass} okText="Evet" cancelText="İptal">
          <Button type="primary" size="small" danger>
            Seansı sonlandır
          </Button>
        </Popconfirm>
      )}
      {(activity.status == ClassActivityStatus.WAITING_FOR_ATTENDANCE || activity.status == ClassActivityStatus.STARTED) && (
        <div className=" space-x-2">
          <Button type="primary" className="bg-green-500" size="small" onClick={goToInClass}>
            Seansa Devam Et
          </Button>

          <Popconfirm title="Seans Sonlandırma" description="Seansı sonlandırmak istediğinize emin misiniz?" onConfirm={onCloseClass} okText="Evet" cancelText="İptal">
            <Button type="primary" size="small" danger>
              Seansı Sonlandır
            </Button>
          </Popconfirm>
        </div>
      )}
    </div>
  );
};
