import { DownOutlined } from '@ant-design/icons';
import i18n from '@shared/localization/i18n';
import { PaginationQuery } from '@shared/models';
import { AccountIssueCategory, AccountIssueType } from '@shared/models/account_issue';
import { Badge, Button, Cascader, Space } from 'antd';
import { useState } from 'react';
import { TbFilterX } from 'react-icons/tb';

type CategoryFilterProps = {
  onFilter: (key: any, value: any, reset?: boolean) => void;
  filters: PaginationQuery;
};

export const CategoryFilter = ({ onFilter, filters }: CategoryFilterProps) => {
  const [cascaderKey, setCascaderKey] = useState(Date.now());

  const categoryOptions = Object.keys(AccountIssueCategory).map((key) => ({
    label: i18n.t('app.accountIssueCategory.' + key),
    value: AccountIssueCategory[key],
  }));

  const typeOptions = Object.keys(AccountIssueType).map((key) => ({
    label: i18n.t('app.accountIssueType.' + key),
    value: AccountIssueType[key],
  }));

  const anyFilterActive = Object.entries(filters).some(
    ([key, filter]) => key !== 'orders' && ((Array.isArray(filter) && filter.length > 0) || (typeof filter === 'string' && filter.trim() !== ''))
  );

  return (
    <div className="flex w-1/3">
      <div className="flex w-1/2 items-center justify-around gap-2">
        <Badge style={{ lineHeight: '0' }} dot={filters.categories!.length > 0} className={filters.categories!.length > 0 ? 'bg-[#E6F4FF]' : ''}>
          <Cascader
            value={filters.categories}
            key={cascaderKey}
            options={categoryOptions}
            expandTrigger="hover"
            multiple
            onChange={(value) => {
              onFilter('categories', value);
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Kategori
                <DownOutlined />
              </Space>
            </a>
          </Cascader>
        </Badge>
        <Badge style={{ lineHeight: '0' }} dot={filters.types!.length > 0} className={filters.types!.length > 0 ? 'bg-[#E6F4FF]' : ''}>
          <Cascader
            value={filters.types}
            key={cascaderKey}
            options={typeOptions}
            expandTrigger="hover"
            multiple
            onChange={(value) => {
              onFilter('types', value);
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Tip
                <DownOutlined />
              </Space>
            </a>
          </Cascader>
        </Badge>

        {anyFilterActive && (
          <div>
            <Button
              onClick={() => {
                setCascaderKey(Date.now()); //Cascader'ı butonla resetlemek için key'ini değiştiriyoruz.
                onFilter(null, null, true);
              }}
              type="text"
              size="small"
              icon={<TbFilterX />}
            >
              Filtreyi Kaldır
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
