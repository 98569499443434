import { CalendarOutlined, PaperClipOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { KanbanCard } from '@platform/platform_components/kanban_view/KanbanCard';
import * as API from '@shared/api';
import { ActionDropdown } from '@shared/components';
import { Role } from '@shared/constants';
import { HomeworkTeacherDefinition, PaginationQuery } from '@shared/models';
import { convertDate } from '@shared/utils';
import { Button, Drawer, notification, Pagination, Tabs, TabsProps } from 'antd';
import Search, { SearchProps } from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import { AssignDraftDrawer } from './AssignDraftDrawer';
import { CreateDraftViewDrawer } from './CreateDraftViewDrawer';
import { TeacherClassFilter } from './TeacherClassFilter';

type DraftViewDrawerProps = {
  draftDrawerOpen: boolean;
  draftDrawerOnClose: () => void;
  onRenderColumn: () => void;
  defaultClassId?: string;
  levelId?: string;
  brandLevelId?: string;
};

export const DraftViewDrawer = ({ draftDrawerOpen, draftDrawerOnClose, onRenderColumn, defaultClassId, levelId, brandLevelId }: DraftViewDrawerProps) => {
  const [draftDrawerCreateOpen, setDraftDrawerCreateOpen] = useState<boolean>(false);
  const [assignDraftDrawerOpen, setAssignDraftDrawerOpen] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [dataDraftCard, setDataDraftCard] = useState<HomeworkTeacherDefinition>();
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({ page: 1, limit: 8, levelIds: [], name: undefined });
  const [paginationQueryBrand, setPaginationQueryBrand] = useState<PaginationQuery>({ page: 1, limit: 8, levelIds: [], name: undefined, orders: ['name'] });

  const [dataDraftCards, classListLoading, getPaginationDefinitions] = API.HOMEWORK.getPaginationDefinition({ init: false });
  const [dataDraftCardsBrand, classListLoadingBrand, getPaginationDefinitionsBrand] = API.HOMEWORK.getBrandPaginationDefinition({ init: false });

  const [searchInput, setSearchInput] = useState<string>();
  const [searchInputBrand, setSearchInputBrand] = useState<string>();

  useEffect(() => {
    if (levelId) {
      setPaginationQuery({ ...paginationQuery, levelIds: [levelId] });
    }
  }, [levelId]);

  useEffect(() => {
    if (brandLevelId) {
      setPaginationQueryBrand({ ...paginationQueryBrand, levelIds: [brandLevelId] });
    }
  }, [brandLevelId]);

  useEffect(() => {
    if (paginationQuery) {
      getPaginationDefinitions(undefined, paginationQuery);
    }
  }, [paginationQuery]);

  useEffect(() => {
    if (paginationQueryBrand) {
      getPaginationDefinitionsBrand(undefined, paginationQueryBrand);
    }
  }, [paginationQueryBrand]);

  const draftDrawerCreateOnClose = () => {
    getPaginationDefinitions(undefined, paginationQuery);
    getPaginationDefinitionsBrand(undefined, paginationQueryBrand);
    setDraftDrawerCreateOpen(false);
  };
  const assignDraftDrawerOnClose = () => {
    getPaginationDefinitions(undefined, paginationQuery);
    onRenderColumn();
    setAssignDraftDrawerOpen(false);
    setDraftDrawerCreateOpen(false);
  };

  const onEditDraft = (draft) => {
    setDraftDrawerCreateOpen(true);
    setDataDraftCard(draft);
    setIsUpdate(true);
  };

  const onDeleteDraft = async (draft) => {
    const response = await API.HOMEWORK.deleteHomeworkDefinition(draft.id);
    if (response.ok) {
      notification.success({ message: 'Başarıyla silindi' });
      setPaginationQuery((prev) => ({ ...prev, page: 1 }));
      getPaginationDefinitions(undefined, paginationQuery);
    }
  };

  const onAssignHomeworkClasses = (draft) => {
    setAssignDraftDrawerOpen(true);
    setDataDraftCard(draft);
  };

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setPaginationQuery({ ...paginationQuery, page: 1, name: value });
  };

  const onSearchBrand: SearchProps['onSearch'] = (value, _e, info) => {
    setPaginationQueryBrand({ ...paginationQueryBrand, page: 1, name: value });
  };

  const onFilter = (key: any, value?: any, reset: boolean = false) => {
    if (reset) {
      setPaginationQuery({ ...paginationQuery, levelIds: [], name: undefined, page: 1 });
      setSearchInput('');
      return;
    }
    setPaginationQuery((prev) => ({
      ...prev,
      [key]: value,
      page: 1,
    }));
  };

  const onFilterBrand = (key: any, value?: any, reset: boolean = false) => {
    if (reset) {
      setPaginationQueryBrand({ ...paginationQueryBrand, levelIds: [], name: undefined, page: 1 });
      setSearchInputBrand('');
      return;
    }
    setPaginationQueryBrand((prev) => ({
      ...prev,
      [key]: value,
      page: 1,
    }));
  };

  const onChangeTab = (v) => {
    if (v == 2) {
      getPaginationDefinitionsBrand(undefined, paginationQueryBrand);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Oluşturduğum Taslaklar',
      children: (
        <div className="flex flex-col" style={{ height: 'calc(100vh - 170px)' }}>
          <div>
            <Button
              className="w-full text-blue-500"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                setDraftDrawerCreateOpen(true);
                setIsUpdate(false);
              }}
            >
              Yeni ödev taslağı oluştur
            </Button>
          </div>
          <div className="mt-4 flex flex-row">
            <Search value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className="w-full" placeholder="Ara" onSearch={onSearch} enterButton />
            {searchInput && (
              <Button
                type="dashed"
                onClick={() => {
                  setSearchInput(undefined);
                  setPaginationQuery({ ...paginationQuery, name: undefined });
                }}
              >
                Temizle
              </Button>
            )}
          </div>

          {!brandLevelId && (
            <div className="mt-3">
              <TeacherClassFilter onFilter={onFilter} filters={paginationQuery} />
            </div>
          )}

          <div className="mt-3">
            {dataDraftCards?.data?.map((draft) => (
              <div className="mt-2" key={draft.id}>
                <KanbanCard
                  key={draft.id}
                  title={draft.name}
                  //content={<Tooltip title={draft.description}>{draft.description.length > 50 ? `${draft.description.slice(0, 50)}...` : draft.description}</Tooltip>}
                  action={
                    <ActionDropdown
                      onAssignHomeworkClasses={() => onAssignHomeworkClasses(draft)}
                      onEditDraft={() => onEditDraft(draft)}
                      onDeleteDraft={() => onDeleteDraft(draft)}
                      isAssignHomeworkClasses={true}
                      isDeleteDraft={true}
                      isEditDraft={true}
                      isDelete={false}
                      isEdit={false}
                      data={{}}
                    />
                  }
                  footer={
                    <div className="flex justify-between">
                      <div>
                        <CalendarOutlined />
                        <span className="ml-1">{convertDate(draft.createdAt)}</span>
                      </div>
                      <div>
                        <PaperClipOutlined />
                        <span className="ml-2">{draft.files?.length}</span>
                      </div>
                    </div>
                  }
                />
              </div>
            ))}
          </div>
          <div className="mt-auto">
            <Pagination
              current={paginationQuery.page}
              pageSize={paginationQuery.limit}
              total={dataDraftCards?.count ?? 0}
              className="mt-5 text-center"
              onChange={(page) => setPaginationQuery((prev) => ({ ...prev, page }))}
            />
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Sistem Taslakları',
      children: (
        <div className="flex flex-col" style={{ height: 'calc(100vh - 170px)' }}>
          <div className="flex flex-row space-x-1">
            <Search value={searchInputBrand} onChange={(e) => setSearchInputBrand(e.target.value)} className="w-full" placeholder="Ara" onSearch={onSearchBrand} enterButton />
            {searchInputBrand && (
              <Button
                type="dashed"
                onClick={() => {
                  setSearchInputBrand(undefined);
                  setPaginationQueryBrand({ ...paginationQueryBrand, name: undefined });
                }}
              >
                Temizle
              </Button>
            )}
          </div>

          {!levelId && (
            <div className="mt-3">
              <TeacherClassFilter onFilter={onFilterBrand} filters={paginationQueryBrand} />
            </div>
          )}

          <div className="mt-3">
            {dataDraftCardsBrand?.data?.map((draft) => (
              <div className="mt-2" key={draft.id}>
                <KanbanCard
                  key={draft.id}
                  title={draft.name}
                  //content={<Tooltip title={draft.description}>{draft.description.length > 50 ? `${draft.description.slice(0, 50)}...` : draft.description}</Tooltip>}
                  action={
                    <ActionDropdown
                      onAssignHomeworkClasses={() => onAssignHomeworkClasses(draft)}
                      isAssignHomeworkClasses={true}
                      isDeleteDraft={false}
                      isEditDraft={false}
                      isDelete={false}
                      isEdit={false}
                      data={{}}
                    />
                  }
                  footer={
                    <div className="flex justify-between">
                      <div>
                        <CalendarOutlined />
                        <span className="ml-1">{convertDate(draft.createdAt)}</span>
                      </div>
                      <div>
                        <PaperClipOutlined />
                        <span className="ml-2">{draft.files.length}</span>
                      </div>
                    </div>
                  }
                />
              </div>
            ))}
          </div>
          <div className="mt-auto">
            <Pagination
              current={paginationQueryBrand.page}
              pageSize={paginationQueryBrand.limit}
              total={dataDraftCardsBrand?.count ?? 0}
              className="mt-5 text-center"
              onChange={(page) => setPaginationQueryBrand((prev) => ({ ...prev, page }))}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Drawer width={'450px'} forceRender={true} open={draftDrawerOpen} onClose={draftDrawerOnClose} closable={true} title={'Ödev Taslakları'}>
        <Tabs defaultActiveKey="1" items={items} onChange={(v) => onChangeTab(v)} />
      </Drawer>
      <CreateDraftViewDrawer isUpdate={isUpdate} dataDraftCard={dataDraftCard!} draftDrawerCreateOpen={draftDrawerCreateOpen} draftDrawerCreateOnClose={draftDrawerCreateOnClose} />
      <AssignDraftDrawer
        assignDraftDrawerOpen={assignDraftDrawerOpen}
        assignDraftDrawerOnClose={assignDraftDrawerOnClose}
        dataDraftCard={{ ...dataDraftCard!, classIds: defaultClassId ? [defaultClassId] : [] }}
      />
    </div>
  );
};

DraftViewDrawer.roles = [Role.ADMIN];
