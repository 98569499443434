import { useFetch } from '@shared/hooks';
import { AccountUpdatePassword, PrePlacementDemandBasicInfo, UseFetchOptions } from '@shared/models';
import { request } from '@shared/utils';

export default {
  getStudentDemands: (config: UseFetchOptions) => useFetch<PrePlacementDemandBasicInfo[]>('/student/demand', { method: 'GET', ...config }),
  getStudentDemandDetail: (demandId: string) => request('/student/demand/' + demandId, 'GET'),
  getStudentCalendar: (begin: Date, end: Date) => request('/student/calendar', 'GET', undefined, { begin: begin, end: end }),
  updatePasswordStudent: async (accountId, data: AccountUpdatePassword) => request('/enrollment/student-personal-info/' + accountId, 'PUT', data),
};
