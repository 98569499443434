import { AppstoreOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { LayoutColors, LayoutEnums } from '@shared/constants';
import { useAccessableContext, usePlatformLayoutContext, useUserContext } from '@shared/contexts';
import { useWindowSize } from '@shared/hooks';
import { AccessableBranch, AccessableBrand, PaginateModel } from '@shared/models';
import { AuthGuard } from '@shared/permission';
import { Avatar, Popover } from 'antd';
import { useEffect, useState } from 'react';
import { AppNavigation } from './AppNavigation';

type AppNavigationViewProps = {
  textColor?: string;
  brands?: PaginateModel<AccessableBrand>;
  branchs?: PaginateModel<AccessableBranch>;
};

export const AppNavigationView = ({ textColor, brands, branchs }: AppNavigationViewProps) => {
  const authGuard = AuthGuard();
  const { width, height, sm, md, lg } = useWindowSize();
  const { selectedLayout } = usePlatformLayoutContext();
  const { brand, branch } = useAccessableContext();
  const [title, setTitle] = useState<string>('');
  const [appNavigationStyle, setAppNavigationStyle] = useState<{ backgroundColor: string; color: string }>();
  const [subtitle, setSubttile] = useState<string>('');
  //const [brands, brandsLoading, getBrands] = API.BRAND.accessableBrands({ init: false, initParams: { limit: 99999 } });
  //const [branchs, branchLoading, getBranchs] = API.BRANCH.accessableBranches({ init: false, initParams: { limit: 99999 } });
  const [open, setOpen] = useState<boolean>(false);
  const { user } = useUserContext();

  useEffect(() => {
    if (brands && branchs && brand) {
      const brandName = brands?.data?.find((d) => {
        return d.id == brand?.id;
      })?.name;
      const branchName = branchs?.data?.find((d) => {
        return d.id == branch?.id;
      })?.name;
      let _subTitle = '';
      if (brandName) {
        _subTitle = brandName;
      }
      if (branchName) {
        _subTitle = _subTitle + '/' + branchName;
      }
      setSubttile(_subTitle);
    }
  }, [brands, branchs, brand, branch]);

  useEffect(() => {
    if (selectedLayout) {
      setAppNavigationStyle(LayoutColors[selectedLayout]);
      switch (selectedLayout) {
        case LayoutEnums.BRANDMANAGER:
          setTitle(authGuard.isBrandAdmin() ? 'Marka Yöneticisi' : 'Marka Kullanıcısı');
          break;
        case LayoutEnums.BRANCHMANAGER:
          setTitle(authGuard.isBranchAdmin() ? 'Şube Yöneticisi' : 'Şube Kullanıcısı');
          break;
        case LayoutEnums.TEACHER:
          setTitle('Öğretmen');
          break;
        case LayoutEnums.STUDENT:
          setTitle('Öğrenci');
          break;
        case LayoutEnums.OBSERVER:
          setTitle('Gözetmen');
          break;
        default:
          setTitle('Hoşgeldiniz');
          setSubttile('Lütfen marka/şube seçiniz');
          break;
      }
    }
  }, [selectedLayout, textColor, authGuard]);

  return (
    <>
      {lg ? (
        <div className="flex flex-row p-2">
          <div>
            <Popover
              content={
                <div className={'w-[36rem]'}>
                  <div className="flex flex-row justify-between">
                    <span className="text-[16px] font-bold">Kullanıcı Seçiniz</span>
                    <CloseCircleOutlined
                      style={{ fontSize: 20 }}
                      className="text-gray-400"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <AppNavigation />
                </div>
              }
              trigger={'hover'}
              open={open}
              onOpenChange={setOpen}
              placement="bottomRight"
            >
              <Avatar
                onClick={() => {
                  setOpen(true);
                }}
                shape="square"
                size={'large'}
                icon={<AppstoreOutlined />}
                style={{ ...appNavigationStyle }}
              />
            </Popover>
          </div>
          <div className={' ml-2 grid grid-flow-row-dense mt-3 gap-2 '} style={{ color: textColor }}>
            <div className={'font-bold '}>{title}</div>
            <div className={'opacity-45 '}>{subtitle}</div>
          </div>
        </div>
      ) : (
        <div>
          <Popover
            content={
              <div className={'w-[' + (width - 200) + ']'}>
                <div className="flex flex-row justify-between">
                  <span className="text-[16px] font-bold">Kullanıcı Seçiniz</span>
                  <CloseCircleOutlined
                    style={{ fontSize: 20 }}
                    className="text-gray-400"
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                </div>
                <AppNavigation />
              </div>
            }
            trigger={'hover'}
            open={open}
            onOpenChange={setOpen}
            placement="bottom"
          >
            <Avatar
              onClick={() => {
                setOpen(true);
              }}
              shape="square"
              size={'large'}
              icon={<AppstoreOutlined />}
              style={{ ...appNavigationStyle }}
            />
          </Popover>
        </div>
      )}
    </>
  );
};

//<AppNavigation />
