import { CheckCircleOutlined, ManOutlined, WomanOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import { PiMedal } from 'react-icons/pi';

const GenderBar = ({ femaleCount, maleCount }) => {
  const totalCount = femaleCount + maleCount;
  const femalePercentage = totalCount > 0 ? (femaleCount / totalCount) * 100 : 0;
  const malePercentage = totalCount > 0 ? (maleCount / totalCount) * 100 : 0;

  return (
    <div className="flex items-center">
      <div className="flex items-center mr-2">
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center rounded-full bg-[#FFF0F6] p-1">
            <WomanOutlined className="text-[#EB2F96]" />
          </div>
        </div>
        <span className="ml-1 text-[#EB2F96]">{femaleCount}</span>
      </div>
      <Tooltip title={`Planlamada ${femaleCount} kadın, ${maleCount} erkek bulunmaktadır.`}>
        <div className="relative w-full">
          <div className="relative w-full border-2 border-green-500 rounded-full p-0.5 outline outline-gray-300 outline-1 outline-offset-1 opacity-70">
            <div className="flex">
              <div className="bg-[#FFADD2] h-2 rounded-l-full" style={{ width: `${femalePercentage}%` }} />
              <div className="bg-[#91CAFF] h-2 rounded-r-full" style={{ width: `${malePercentage}%` }} />
            </div>
          </div>
        </div>
      </Tooltip>
      <div className="flex items-center ml-2">
        <span className="mr-1 text-[#1677FF]">{maleCount}</span>
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center rounded-full bg-[#E6F4FF] p-1">
            <ManOutlined className="text-[#1677FF]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenderBar;
