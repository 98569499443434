import { CourseTopicType } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { CourseTopicDefinetion, CourseTopicModel } from '@shared/models';
import { Button, Form, Input, Select } from 'antd';
import { useEffect } from 'react';
import { z } from 'zod';

const CreateCourseUnitFormValidation = z.object({
  name: z.string({ required_error: 'Konu adı zorunlu alandır' }).min(1, 'Konu adı 1 karekterden fazla olmalı'),
  description: z.string({ required_error: 'Lütfen topik açıklamasını giriniz' }),
  type: z.string({ required_error: 'Konu tipi seçiniz' }),
});
type CreateCourseTopicProp = {
  value?: CourseTopicModel;
  onSave: (value: CourseTopicModel) => void;
};
export const CreateCourseTopicForm = ({ value, onSave }: CreateCourseTopicProp) => {
  const form = useForm<CourseTopicModel>(CreateCourseUnitFormValidation, {});

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      form.setInitialValue(value);
    } else {
      form.setValue({});
      form.reset();
    }
  }, [value]);

  const send = () => {
    const result = form.parse();
    if (result.success) {
      onSave(result.data);
    } else {
      form.setAllTouched();
    }
  };
  return (
    <>
      <Form layout="vertical">
        <Form.Item {...form.formItem('name')} label={'Topic adı:'}>
          <Input {...form.input('name')} />
        </Form.Item>
        <Form.Item {...form.formItem('description')} label={'Topic açıklaması:'}>
          <Input {...form.input('description')} />
        </Form.Item>
        <Form.Item {...form.formItem('type')} label={'Topic tipi:'}>
          <Select
            placeholder="Select a type"
            value={form.value.type}
            onChange={(t) => {
              form.setValue({
                ...form.value,
                type: t,
              });
            }}
            options={[
              {
                label: CourseTopicType.PRE_CLASS,
                value: CourseTopicType.PRE_CLASS,
              },
              {
                label: CourseTopicType.IN_CLASS,
                value: CourseTopicType.IN_CLASS,
              },
              {
                label: CourseTopicType.POST_CLASS,
                value: CourseTopicType.POST_CLASS,
              },
            ]}
          />
        </Form.Item>
      </Form>
      <div className="flex flex-row justify-end">
        <Button type="primary" onClick={send}>
          Kaydet
        </Button>
      </div>
    </>
  );
};
