import { CalendarOutlined, CheckOutlined, CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Role } from '@shared/constants';
import { Button, Switch, Table, TableColumnsType, Tooltip } from 'antd';
import * as API from '@shared/api';
import { AdminCalendarModel } from '@shared/models';
import dayjs from 'dayjs';
import { ActionDropdown } from '@shared/components';
import { MdOutlineEditCalendar } from 'react-icons/md';

dayjs.locale('tr');

export const HolidayManagement = () => {
  const [holidayCalendar, holidayCalendarLoading, getHolidayCalendar] = API.CALENDAR.getAdminCalendar({ init: true });

  const deleteHoliday = async (record) => {
    const response = await API.CALENDAR.deleteAdminHoliday(record.id);
    if (response.ok) {
      getHolidayCalendar();
    }
  };

  const columns: TableColumnsType<AdminCalendarModel> = [
    {
      title: 'Tarih',
      align: 'center',

      render: ({ date }) => dayjs(date).format('D MMMM YYYY dddd'),
    },
    {
      title: 'Açıklama',
      align: 'center',
      render: ({ name, description }) => (
        <Tooltip title={description} placement="top">
          <div className="h-full w-full">{name}</div>
        </Tooltip>
      ),
    },

    {
      title: 'Eylemler',
      key: 'id',
      align: 'center',
      render: (record) => <ActionDropdown data={record} isDelete={true} isEdit={false} onDelete={deleteHoliday} placement="bottom" />,
    },
  ];
  return (
    <>
      <Table
        loading={holidayCalendarLoading}
        rowKey="id"
        pagination={{ showSizeChanger: true, showPrevNextJumpers: true, position: ['bottomCenter'] }}
        columns={columns}
        dataSource={holidayCalendar}
        bordered
      />
    </>
  );
};

HolidayManagement.path = 'holiday-management';
HolidayManagement.title = 'Tatil Günlerini Yönet';
HolidayManagement.group = 'flat';
HolidayManagement.roles = [Role.ADMIN];
HolidayManagement.icon = <MdOutlineEditCalendar size={17} />;
