import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import createSpeechRecognitionPonyfill from 'web-speech-cognitive-services';
import {isIOS,isMobile} from 'react-device-detect';


type AzureAuthContextType = { azure: any};

const AzureAuthContext = createContext<AzureAuthContextType>({ azure: undefined });


export const AzureAuthContextProvider = ({ children }) => {
    
    const azure = useRef();

    useEffect(() => {
        onRegister();
    }, []);

    const onRegister = useCallback(async () => {
        if(isIOS&&isMobile){
            const {
                SpeechRecognition
            } = await createSpeechRecognitionPonyfill({
                credentials: {
                    region: 'eastus',
                    subscriptionKey: 'f0daf84bee1042d9aa00f3e3aeb520a2'
                },
            });
            azure.current = SpeechRecognition;
        }
        azure.current = undefined;
        
    }, [createSpeechRecognitionPonyfill])

    return (
        <AzureAuthContext.Provider value={{ azure }}>
            {children}
        </AzureAuthContext.Provider>
    )
}

export const useAzureAuthContext = () => {
    const context = useContext(AzureAuthContext);
    if (context == undefined) {
        throw new Error("useAzureAuthContext must be used within a AzureAuthContextProvider");
    }
    return context;
}
