import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { ImageView } from '@shared/components/curriculum/ImageView';
import { useWindowSize } from '@shared/hooks';
import { ExerciseAnswerRequestModel, ExerciseDefinitionContentModel, ExerciseDefinitionInteractionModel, OrderAnswerResponse } from '@shared/models';
import { shuffleToArray } from '@shared/utils';
import { Button } from 'antd';
import { useEffect, useState } from 'react';

type ExerciseProps = {
  content: ExerciseDefinitionContentModel;
  interaction: ExerciseDefinitionInteractionModel;
  isPreview?: boolean;
  cdnScope: CdnScope;
  isInClass: boolean;
  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel: ExerciseAnswerRequestModel) => void;
  answerResponseModel?: OrderAnswerResponse;
};

export const GapFillingSelected = ({
  content,
  interaction,
  isPreview = false,
  cdnScope,
  isInClass,
  answerRequestModel,
  onChangeAnswerRequestModel,
  answerResponseModel,
}: ExerciseProps) => {
  const { lg } = useWindowSize();
  const [blanks, setBlanks] = useState<(string | null)[]>(new Array(interaction.orderItems?.length).fill(null));
  const [disabledButtons, setDisabledButtons] = useState<string[]>([]);
  const [shuffledWords, setShuffledWords] = useState<string[]>([]); //Butonda her değişiklik olduğunda shuffleToArray metodunun tekrar tekrar kullanılmaması için

  useEffect(() => {
    if (interaction.orderItems) {
      setBlanks(new Array(interaction.orderItems?.length).fill(null));
      setDisabledButtons([]);
      setShuffledWords(shuffleToArray(interaction.orderItems));
    }
  }, [interaction,interaction.orderItems]);

  //Seçilen kelimeyi boşluk doldurma kısmına ekliyoruz
  const handleAddClick = (word: string) => {
    const nextBlankIndex = blanks.indexOf(null);
    if (nextBlankIndex !== -1) {
      const newBlanks = [...blanks];
      newBlanks[nextBlankIndex] = word;
      setBlanks(newBlanks);
      setDisabledButtons([...disabledButtons, word]);

      if (onChangeAnswerRequestModel) {
        onChangeAnswerRequestModel({
          ...(answerRequestModel ? answerRequestModel : { orderItems: [] }),
          orderItems: [...newBlanks.map((item) => item && item[0]).filter(Boolean)] as any,
        });
      }
    }
  };

  //Seçilen kelime boşluk doldurmadan kaldırılıyor ve tekrardan seçilebilir hale geliyor.
  const handleRemoveClick = (index: number) => {
    const word = blanks[index];

    if (word) {
      const newBlanks = [...blanks];
      newBlanks[index] = null;
      setBlanks(newBlanks);
      setDisabledButtons(disabledButtons.filter((w) => w !== word));

      const updatedOrderItems = answerRequestModel?.orderItems?.filter((item) => item !== word[0]) || [];

      if (onChangeAnswerRequestModel) {
        onChangeAnswerRequestModel({
          ...(answerRequestModel ? answerRequestModel : { orderItems: [] }),
          orderItems: updatedOrderItems,
        });
      }
    }
  };

  return (
    <>
      {isInClass ? (
        <div className="flex flex-row items-center h-full w-full bg-white pt-10 pb-10">
          <div className="border border-double border-mid-gray rounded m-2 h-full w-3/6 text-center ">
            <ImageView image={content?.image ? content.image : {}} height="100%" width="100%" className="object-scale-down" isPreview={isPreview} />
          </div>
          <div className="flex flex-col w-3/6 h-full items-center content-center justify-around ">
            {interaction.orderItems ? (
              <div className={' mt-3 text-center space-y-6 space-x-1 items-center justify-center content-center'}>
                {interaction.instruction?.title && (
                  <span className="p-0 m-0 font-extrabold lg:text-xl xl:text-xl 2xl:text-2xl text-custom-purple text-center">{interaction.instruction?.title}</span>
                )}
                {interaction.instruction?.description && <span className="text-lg text-light-purple text-center">{interaction.instruction?.description}</span>}
                <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                  {blanks.map((blank, i) => (
                    <Button
                      key={i}
                      disabled={!blank}
                      type="default"
                      onClick={() => handleRemoveClick(i)}
                      danger={answerResponseModel && !answerResponseModel.corrections![i].isCorrect}
                      className={answerResponseModel && answerResponseModel.corrections![i].isCorrect ? '!border-2 !border-solid !border-[#70C040]' : ''}
                    >
                      {blank ? blank : '__'}
                    </Button>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
            {interaction.orderItems ? (
              <div className=" mt-3 text-center space-y-6 space-x-1">
                <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                  {shuffledWords.map((word, i) => (
                    <Button key={i} type="default" disabled={disabledButtons.includes(word)} onClick={() => handleAddClick(word)}>
                      {word}
                    </Button>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : isPreview ? (
        <div className="flex flex-col items-center h-full w-full bg-white py-3 px-5 relative">
          {content.image && (
            <div className="border border-double border-mid-gray rounded m-2 h-2/6 text-center">
              <ImageView image={content?.image ? content.image : {}} />
            </div>
          )}

          {interaction.orderItems ? (
            <div
              className={
                content.image
                  ? 'h-1/6 mt-3 text-center space-y-6 space-x-1 items-center justify-center content-center'
                  : 'h-3/6 mt-3 text-center space-y-6 space-x-1 items-center justify-center content-center'
              }
            >
              {interaction.instruction?.title && <span className='p-0 m-0 font-semibold lg:text-base xl:text-lg 2xl:text-xl text-custom-purple text-center'>{interaction.instruction?.title}</span>}
              {interaction.instruction?.description && <span className='p-0 m-0 font-medium lg:text-sm xl:text-base 2xl:text-lg text-custom-purple text-center'>{interaction.instruction?.description}</span>}
              <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                {interaction.orderItems!.map((o, i) => (
                  <Button key={i} disabled type="default">
                    __
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          {interaction.orderItems ? (
            <div className="h-3/6 mt-3 text-center space-y-6 space-x-1 items-center justify-center content-center overflow-y-auto">
              <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center text-wrap">
                {shuffleToArray(interaction.orderItems!).map((o, i) => (
                  <Button key={i} className="" type="default">
                    <span className="text-wrap">{o}</span>
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center h-full w-full bg-white  relative">
          {content.image && (
            <div className="border  border-mid-gray rounded m-2 h-2/6 text-center">
              <ImageView image={content?.image ? content.image : {}} width={lg ? 'auto' : '130px'} height={lg ? '100%' : '130px'}  className='object-scale-down'/>
            </div>
          )}

          {interaction.orderItems ? (
            <div
              className={
                content.image
                  ? ' mt-2 text-center space-y-4 space-x-1 items-center justify-center content-center '
                  : ' mt-2 text-center space-y-4 space-x-1 items-center justify-center content-center'
              }
            >
              {interaction.instruction?.title && <span className='p-0 m-0 font-semibold lg:text-base xl:text-lg 2xl:text-xl text-custom-purple text-center'>{interaction.instruction?.title}</span>}
              {interaction.instruction?.description && <span className='p-0 m-0 font-medium lg:text-sm xl:text-base 2xl:text-lg text-custom-purple text-center'>{interaction.instruction?.description}</span>}
              <div className="space-x-2 flex w-full h-full items-center justify-center content-start">
                <div className="space-y-2 w-fit h-full flex-wrap text-start justify-start content-start">
                  {blanks.map((blank, i) => (
                    <Button
                      key={i}
                      disabled={!blank}
                      type="default"
                      onClick={() => handleRemoveClick(i)}
                      danger={answerResponseModel && !answerResponseModel.corrections![i].isCorrect}
                      className={answerResponseModel && answerResponseModel.corrections![i].isCorrect ? '!border-1 !border-solid !border-[#70C040] mr-1' : 'mr-1'}
                    >
                      {blank ? blank : '__'}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {interaction.orderItems ? (
            <div className=" mt-2 text-center space-y-6 space-x-1">
              <div className="space-x-2 space-y-2 w-fit items-center justify-center content-center">
                {shuffledWords.map((word, i) => (
                  <Button key={i} type="default" disabled={disabledButtons.includes(word)} onClick={() => handleAddClick(word)}>
                    {word}
                  </Button>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};
