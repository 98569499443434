import { CheckCircleOutlined, CloseCircleOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { ActionDropdown } from '@shared/components/ActionDropdown';
import { CourseTopicPageType, ExerciseLayoutType } from '@shared/constants';
import { CourseTopicModel, CourseTopicPageModel } from '@shared/models';
import { ChoiceAnswerResponse, ExerciseAnswerRequestModel, ExerciseDefinitionModel, MediaOrderAnswerResponse, OrderAnswerResponse } from '@shared/models/curriculum/';
import { Button, Progress } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { CdnScope } from '../FileUpload';
import { TextPage, VideoPage } from '../learning_content_version';
import { ImagePage } from '../learning_content_version/content_page/image_page';
import { ExerciseHandler } from './exercise_handler';

type ExerciseOrPageHandlerProps = {
  page: CourseTopicPageModel;
  cdnScope: CdnScope;
  isPreview?: boolean;
  isInClass: boolean;
  onNext: () => void;
  onPrev: () => void;
  selectedTopic?: CourseTopicModel;
  answerRequestModel?: ExerciseAnswerRequestModel;
  onChangeAnswerRequestModel?: (answerRequestModel?: ExerciseAnswerRequestModel) => void;
  onSendDialogResult?: () => void;
  exercisePage?: number;
  onChangeExercisePage?: (exercisePage: number) => void;
  onCheckAnswer?: () => void;
  answerResponseModel?: ChoiceAnswerResponse | OrderAnswerResponse | MediaOrderAnswerResponse;
  setAnswerResponseModel?: any;
  onMobileGoBack?: () => void;
  onResponseModelPronounciation?: (success: boolean, rate: number) => void;
  onResponseModelListiningBase?: (success: boolean, rate: number) => Promise<void>;
  onResponseModelDialogPronounciation?: (conversationItemId: string, success: boolean, rate: number) => void;
  isExpand?: boolean;
};
export const ExerciseOrPageHandler = ({
  page,
  cdnScope,
  isPreview = true,
  isInClass,
  onPrev,
  onNext,
  selectedTopic,
  answerRequestModel,
  onChangeAnswerRequestModel,
  onSendDialogResult,
  exercisePage = 0,
  onChangeExercisePage,
  onCheckAnswer,
  answerResponseModel,
  onMobileGoBack,
  onResponseModelPronounciation,
  onResponseModelDialogPronounciation,
  onResponseModelListiningBase,
  isExpand = false,
}: ExerciseOrPageHandlerProps) => {
  const [exercise, setExercise] = useState<ExerciseDefinitionModel | null>(null);

  useEffect(() => {
    if (page.type == CourseTopicPageType.EXERCISE) {
      setExercise(page.exercise?.exercises![exercisePage] as ExerciseDefinitionModel);
    }
  }, [exercisePage, page.id, page]);

  const goToNextExercise = useCallback(() => {
    if (exercisePage < page.exercise?.exercises?.length! - 1 && onChangeExercisePage) {
      onChangeExercisePage(exercisePage + 1);
    } else {
      onNext();
    }
    if (onChangeAnswerRequestModel) {
      onChangeAnswerRequestModel(undefined);
    }
  }, [exercisePage, page, onChangeExercisePage, onNext, onChangeAnswerRequestModel]);

  const showCorrectAnswers = useCallback(() => {
    if (
      [
        ExerciseLayoutType.VISUAL_SINGLE_SELECT,
        ExerciseLayoutType.VISUAL_MULTI_SELECT,
        ExerciseLayoutType.LISTENING_SINGLE_SELECT,
        ExerciseLayoutType.LISTENING_MULTI_SELECT,
        ExerciseLayoutType.READING_SINGLE_SELECT,
        ExerciseLayoutType.READING_MULTI_SELECT,
      ].includes(page.exercise?.exercises![exercisePage]?.layout!)
    ) {
      const answer = answerResponseModel as ChoiceAnswerResponse;
      return answer?.expected
        ?.filter((i) => i.isCorrect)
        .map((i) => i.title)
        .join(', ');
    } else if ([ExerciseLayoutType.READING_GAP_FILLING].includes(page.exercise?.exercises![exercisePage]?.layout!)) {
      const answer = answerResponseModel as OrderAnswerResponse;
      return answer.corrections?.map((correction) => correction.expected[0]).join(', ');
    } else if ([ExerciseLayoutType.MATCHING_BASE].includes(page.exercise?.exercises![exercisePage]?.layout!)) {
      const answer = answerResponseModel as OrderAnswerResponse;
      return answer.corrections?.map((correction) => correction.expected[1]).join(', ');
    } else if ([ExerciseLayoutType.GAP_FILLING_SELECT].includes(page.exercise?.exercises![exercisePage]?.layout!)) {
      const answer = answerResponseModel as OrderAnswerResponse;
      return answer.corrections?.map((correction) => correction.expected[0]).join(' ');
    } else {
      return '';
    }
  }, [answerResponseModel]);

  return (
    <div className="h-full w-full">
      <div className="h-full w-full ">
        {page.type === CourseTopicPageType.EXERCISE ? (
          <>
            {isInClass ? (
              <div className="h-full w-full flex flex-col overflow-hidden relative">
                <div className="flex flex-col space-y-2 w-full h-[10%] max-h-fit">
                  <div className="bg-[#F9F0FF] rounded-xl">
                    <div className="flex flex-row w-full text-wrap px-3 py-1">
                      <div className="flex flex-col w-full flex-wrap text-wrap ">
                        <span className="text-[#722ED1] text-sm font-semibold text-wrap">Topic:{selectedTopic?.name}</span>
                        <span className="text-[#D3ADF7] text-sm font-semibold text-wrap">{page.exercise?.name}</span>
                      </div>
                      <div className="flex flex-row w-4/6 justify-between space-x-2 items-center content-center text-center ">
                        <div className="flex flex-row w-auto items-center content-center justify-center text-center">
                          <span className="text-sm  text-[#8d7473] font-mono text-center">{exercisePage + 1}</span>
                          <span className="text-sm text-[#D3ADF7] font-mono text-center ">/{page.exercise?.exercises?.length}</span>
                        </div>
                        <Progress
                          percent={((exercisePage + 1) / (page.exercise?.exercises?.length || 1)) * 100}
                          showInfo={false}
                          className="w-full"
                          strokeWidth={15}
                          strokeColor={{
                            '0%': '#F9E681',
                            '48%': '#F69D35',
                            '100%': '#F60000',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-[80%] max-h-fit">
                  <ExerciseHandler
                    item={exercise as ExerciseDefinitionModel}
                    isPreview={isPreview}
                    cdnScope={cdnScope}
                    isInClass={isInClass}
                    onChangeAnswerRequestModel={onChangeAnswerRequestModel}
                    onSendDialogResult={onSendDialogResult}
                    answerRequestModel={answerRequestModel}
                    answerResponseModel={answerResponseModel}
                    onResponseModelPronounciation={onResponseModelPronounciation}
                    onResponseModelDialogPronounciation={onResponseModelDialogPronounciation}
                  />
                </div>
                <div className="flex content-center max-h-fit items-center h-[10%] absolute bottom-0 margin-auto w-full">
                  {answerResponseModel &&
                  page.exercise?.exercises![exercisePage]?.layout != (ExerciseLayoutType.DIALOG_BASE || ExerciseLayoutType.DIALOG_SELECT || ExerciseLayoutType.DIALOG_SORTING) ? (
                    <div
                      className={
                        'flex flex-row space-y-2 w-full p-3 h-full rounded-xl justify-center items-center content-center space-x-4 ' +
                        (answerResponseModel.success ? 'bg-light-success' : 'bg-light-error')
                      }
                    >
                      <div>
                        <span className={'text-center text-2xl ' + (answerResponseModel.success ? 'text-success' : 'text-error')}>
                          {answerResponseModel.success ? 'Tebrikler' : 'Yanlış cevap'}
                        </span>
                      </div>
                      <Button
                        type="default"
                        className={answerResponseModel.success ? 'bg-light-success border-success' : 'bg-light-error border-error'}
                        ghost={false}
                        onClick={goToNextExercise}
                      >
                        <span className={answerResponseModel.success ? 'text-success' : 'text-error'}>Sonraki</span>
                      </Button>
                    </div>
                  ) : (
                    <div className="flex flex-row space-x-4 justify-center w-full ">
                      {exercisePage > 0 && (
                        <div
                          className="p-1 text-center bg-[#D3ADF7] rounded-lg items-center flex justify-center gap-2 w-36 cursor-pointer"
                          onClick={() => {
                            if (exercisePage > 0 && onChangeExercisePage) {
                              onChangeExercisePage(exercisePage - 1);
                            } else {
                              onPrev();
                            }
                            if (onChangeAnswerRequestModel) {
                              onChangeAnswerRequestModel(undefined);
                            }
                          }}
                        >
                          <LeftCircleOutlined style={{ fontSize: 25 }} className="text-white" disabled={exercisePage == 0} />
                          <span className="text-white text-xl text-wrap">Önceki</span>
                        </div>
                      )}
                      <div className=" p-1 text-center bg-[#D3ADF7] rounded-lg items-center flex justify-center w-36 gap-2  cursor-pointer" onClick={goToNextExercise}>
                        <span className="text-white text-xl text-wrap"> İleri</span>
                        <RightCircleOutlined style={{ fontSize: 25 }} className="text-white" disabled={exercisePage + 1 == page.exercise?.exercises?.length} />
                      </div>
                      {answerRequestModel &&
                        page.exercise?.exercises![exercisePage]?.layout !=
                          (ExerciseLayoutType.DIALOG_BASE || ExerciseLayoutType.DIALOG_SELECT || ExerciseLayoutType.DIALOG_SORTING) && (
                          <div
                            className=" p-1 text-center bg-[#D3ADF7] rounded-lg items-center flex justify-center w-45 gap-2  cursor-pointer"
                            onClick={() => {
                              if (onCheckAnswer) {
                                onCheckAnswer();
                              }
                            }}
                          >
                            <span className="text-white text-xl text-wrap line-clamp-1"> Cevapları göster</span>
                            <RightCircleOutlined style={{ fontSize: 25 }} className="text-white" disabled={exercisePage + 1 == page.exercise?.exercises?.length} />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            ) : isPreview ? (
              //Ön İzleme ve İnceleme Ekranı
              <div className="h-full bg-white  w-full flex flex-col overflow-hidden relative">
                <div className="flex flex-col space-y-1 h-[15%]">
                  <div className="bg-[#F9F0FF] py-4 px-2 rounded-xl h-1/2">
                    <div className="flex flex-row justify-between space-x-2 items-center content-center text-center">
                      <div className="flex flex-row w-auto items-center content-center justify-center text-center">
                        <span className="text-xs text-[#8d7473] font-mono text-center">{exercisePage + 1}</span>
                        <span className="text-xs text-[#D3ADF7] font-mono text-center">/{page.exercise?.exercises?.length}</span>
                      </div>
                      <Progress
                        percent={((exercisePage + 1) / (page.exercise?.exercises?.length || 1)) * 100}
                        showInfo={false}
                        className="w-full"
                        trailColor="rgb(219 209 233)"
                        strokeWidth={15}
                        strokeColor={{
                          '0%': '#F9E681',
                          '48%': '#F69D35',
                          '100%': '#F60000',
                        }}
                      />
                    </div>
                  </div>
                  <div className="bg-[#F9F0FF] px-2 rounded-t-xl">
                    <div className="flex flex-row justify-between w-full">
                      <div className="flex flex-col w-full flex-wrap text-wrap ">
                        <span className="text-[#722ED1] text-sm font-semibold text-wrap">Topic:{selectedTopic?.name}</span>
                        <span className="text-[#D3ADF7] text-sm font-semibold text-wrap">{page.exercise?.name}</span>
                      </div>
                      <ActionDropdown
                        data={null}
                        isDelete={false}
                        isEdit={false}
                        items={[
                          {
                            key: 'back',
                            icon: <LeftCircleOutlined size={20} />,
                            label: 'Geri Dön',
                            onClick: () => {
                              if (onMobileGoBack) {
                                onMobileGoBack();
                              }
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className=" h-[85%] flex flex-col">
                  <div className="h-[90%]">
                    <ExerciseHandler item={exercise as ExerciseDefinitionModel} isPreview={isPreview} cdnScope={cdnScope} isInClass={isInClass} />
                  </div>

                  <div className={`flex flex-col justify-end w-full pb-1  items-center bg-white h-[10%]`}>
                    <Button
                      block
                      size="small"
                      disabled={exercisePage == page.exercise?.exercises?.length! - 1}
                      className={`bg-[#D3ADF7]  h-auto text-lg text-custom-purple w-full ${
                        exercisePage !== page.exercise?.exercises?.length! - 1 ? 'hover:!bg-purple-400 hover:!border-purple-500 hover:!text-white !transition-colors' : ''
                      }  `}
                      onClick={() => {
                        if (exercisePage < page.exercise?.exercises?.length! - 1 && onChangeExercisePage) {
                          onChangeExercisePage(exercisePage + 1);
                        }
                      }}
                    >
                      {exercisePage == page.exercise?.exercises?.length! - 1 ? 'Onayla veya Reddet Seçiniz' : 'Sıradaki Egzersiz'}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              //Öğrenci Ekranı <Button onClick={goToNextExercise}>Quick Next</Button>
              <div className={' w-full flex flex-col overflow-hidden relative ' + (isMobile ? 'h-[calc(100svh-75px)]' : 'h-[calc(100svh-115px)]')}>
                <div className="flex flex-col  h-[15%]  rounded-sm">
                  <div className="bg-[#F9F0FF] py-4 px-2  h-1/2">
                    <div className="flex flex-row justify-between space-x-2 items-center content-center text-center">
                      <div className="flex flex-row w-auto items-center content-center justify-center text-center">
                        <span className="text-sm text-[#8d7473] font-mono text-center">{exercisePage + 1}</span>
                        <span className="text-sm text-[#D3ADF7] font-mono text-center">/{page.exercise?.exercises?.length}</span>
                      </div>
                      <Progress
                        percent={((exercisePage + 1) / (page.exercise?.exercises?.length || 1)) * 100}
                        showInfo={false}
                        className="w-full"
                        trailColor="rgb(219 209 233)"
                        strokeWidth={15}
                        strokeColor={{
                          '0%': '#F9E681',
                          '48%': '#F69D35',
                          '100%': '#F60000',
                        }}
                      />
                    </div>
                  </div>
                  <div className="bg-[#F9F0FF] ">
                    <div className="flex flex-row justify-between w-full px-2">
                      <div className="flex flex-col w-full flex-wrap text-wrap ">
                        <span className="text-[#722ED1] text-xs font-semibold text-wrap">Topic:{selectedTopic?.name}</span>
                        <span className="text-[#D3ADF7] text-xs font-semibold text-wrap">{page.exercise?.name}</span>
                      </div>
                      <ActionDropdown
                        data={null}
                        isDelete={false}
                        isEdit={false}
                        items={[
                          {
                            key: 'back',
                            icon: <LeftCircleOutlined size={20} />,
                            label: 'Geri Dön',
                            onClick: () => {
                              if (onMobileGoBack) {
                                onMobileGoBack();
                              }
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className=" h-[85%] flex flex-col ">
                  <div
                    className={
                      (page.exercise?.exercises![exercisePage]?.layout == ExerciseLayoutType.VISUAL_BASE  && page.exercise?.exercises![exercisePage]?.content?.isPronounEnabled) ||
                      page.exercise?.exercises![exercisePage]?.layout == ExerciseLayoutType.DIALOG_BASE ||
                      page.exercise?.exercises![exercisePage]?.layout == ExerciseLayoutType.DIALOG_SELECT ||
                      page.exercise?.exercises![exercisePage]?.layout == ExerciseLayoutType.DIALOG_SORTING
                        ? 'h-[100%]'
                        : 'h-[95%]'
                    }
                  >
                    <ExerciseHandler
                      item={exercise as ExerciseDefinitionModel}
                      isPreview={isPreview}
                      cdnScope={cdnScope}
                      isInClass={isInClass}
                      onChangeAnswerRequestModel={onChangeAnswerRequestModel}
                      onSendDialogResult={onSendDialogResult}
                      answerRequestModel={answerRequestModel}
                      answerResponseModel={answerResponseModel}
                      onNextExerciseDialog={() => {
                        if (!isPreview) {
                          goToNextExercise();
                        }
                      }}
                      onResponseModelPronounciation={onResponseModelPronounciation}
                      onResponseModelDialogPronounciation={onResponseModelDialogPronounciation}
                    />
                  </div>

                  {page.exercise?.exercises![exercisePage]?.layout != (ExerciseLayoutType.DIALOG_BASE || ExerciseLayoutType.DIALOG_SELECT || ExerciseLayoutType.DIALOG_SORTING) ? (
                    <div className={`flex flex-col justify-end w-full pb-1 items-center  ${answerResponseModel ? 'absolute z-10 bottom-0 ' : 'h-[5%]'}`}>
                      {answerResponseModel ? (
                        answerResponseModel.success ? (
                          <div className="space-y-5 items-center flex flex-col pt-3 justify-between bg-light-success w-full ">
                            <div className="flex items-center space-x-2 ">
                              <CheckCircleOutlined className="text-success text-xl" />
                              <span className="text-success font-semibold text-lg">Süper!</span>
                            </div>
                            <Button
                              type="default"
                              className="bg-green-500 hover:!bg-green-600 border-green-600 text-white hover:!text-white font-semibold py-2 px-8 rounded-full"
                              size="large"
                              onClick={goToNextExercise}
                            >
                              DEVAM ET
                            </Button>
                          </div>
                        ) : (
                          <div className=" bg-red-400 shadow-light-error opacity-90 items-center justify-between flex flex-col w-full p-2 rounded bottom-0 z-10">
                            <div className="flex items-center space-x-2">
                              <CloseCircleOutlined className="text-white text-xl" />
                              <span className="text-white font-semibold text-lg">Yanlış</span>
                            </div>

                            <div className="bg-red-400 items-center text-xl">
                              <span className="text-white">{showCorrectAnswers() != '' ? 'Doğru Cevap: ' + showCorrectAnswers() : ''}</span>
                            </div>

                            <div className="flex text-center h-full justify-center w-full mt-2 mx-auto">
                              <Button
                                className="bg-red-400 hover:!bg-red-600 text-white border-red-600 hover:!text-white font-semibold pb rounded-full w-full"
                                onClick={goToNextExercise}
                                size="large"
                              >
                                DEVAM ET
                              </Button>
                            </div>
                          </div>
                        )
                      ) : page.exercise?.exercises![exercisePage]?.layout != ExerciseLayoutType.VISUAL_BASE ? (
                        <Button
                          block
                          className={`bg-[#D3ADF7]  h-auto text-lg text-custom-purple w-full ${
                            answerRequestModel != undefined ? 'hover:!bg-purple-400 hover:!border-purple-500 hover:!text-white !transition-colors' : ''
                          }  `}
                          disabled={answerRequestModel == undefined && page.exercise?.exercises![exercisePage]?.layout != ExerciseLayoutType.LISTENING_BASE}
                          onClick={async() => {
                            if (isPreview) {
                              if (exercisePage < page.exercise?.exercises?.length! - 1 && onChangeExercisePage) {
                                onChangeExercisePage(exercisePage + 1);
                              } else {
                                onNext();
                              }
                            } else {
                              if (page.exercise?.exercises![exercisePage]?.layout == ExerciseLayoutType.LISTENING_BASE) {
                                if(onResponseModelListiningBase != null){
                                   await onResponseModelListiningBase(true, answerRequestModel?.rate ?? 100);
                                }
                                if (exercisePage < page.exercise?.exercises?.length! - 1 && onChangeExercisePage) {
                                  onChangeExercisePage(exercisePage + 1);
                                } else {
                                  onNext();
                                }
                              } else {
                                if (onCheckAnswer) {
                                  onCheckAnswer();
                                }
                              }
                            }
                          }}
                        >
                          {answerRequestModel == undefined && page.exercise?.exercises![exercisePage]?.layout == ExerciseLayoutType.LISTENING_BASE
                            ? 'Devam Et'
                            :page.exercise?.exercises![exercisePage]?.layout == ExerciseLayoutType.LISTENING_BASE ? 'Devam Et': 'Cevabı Kontrol Et'}
                        </Button>
                      ) : (
                        page.exercise?.exercises![exercisePage]?.content?.isPronounEnabled == false ? (
                          <Button
                          block
                          className='bg-[#D3ADF7] h-auto text-lg text-custom-purple w-full  hover:!bg-purple-400 hover:!border-purple-500 hover:!text-white !transition-colors'
                          onClick={() => {
                            if (isPreview) {
                              if (exercisePage < page.exercise?.exercises?.length! - 1 && onChangeExercisePage) {
                                onChangeExercisePage(exercisePage + 1);
                              } else {
                                onNext();
                              }
                            } else {
                              if (page.exercise?.exercises![exercisePage]?.layout == ExerciseLayoutType.LISTENING_BASE || (page.exercise?.exercises![exercisePage]?.layout == ExerciseLayoutType.VISUAL_BASE &&page.exercise?.exercises![exercisePage]?.content?.isPronounEnabled == false)) {
                                if(onResponseModelListiningBase != null){
                                    onResponseModelListiningBase(true, 10);
                                }
                                if (exercisePage < page.exercise?.exercises?.length! - 1 && onChangeExercisePage) {
                                  onChangeExercisePage(exercisePage + 1);
                                } else {
                                  onNext();
                                }
                              } else {
                                if (onCheckAnswer) {
                                  onCheckAnswer();
                                }
                              }
                            }
                          }}
                          >Devam Et</Button>
                        ):('')
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="h-full w-full">
            {page.type == CourseTopicPageType.TEXT && <TextPage text={page.content?.text || ''} />}
            {page.type == CourseTopicPageType.IMAGE && <ImagePage page={page} cdnScope={cdnScope} isExpand={isExpand} />}
            {page.type == CourseTopicPageType.VIDEO && <VideoPage page={page} cdnScope={cdnScope} />}
          </div>
        )}
      </div>
    </div>
  );
};
