import { Gender } from '@shared/constants';
import { useForm } from '@shared/hooks';
import { PrePlacement } from '@shared/models';
import { Button, Form, Radio } from 'antd';
import { useEffect } from 'react';
import { z } from 'zod';
import { BirthdayCalendar } from './BirthdayCalendar';
import { NationalIdInput, PhoneInput, TextInput } from './form_inputs';

type UpdateStudentProfile = {
  student: PrePlacement;
  onUpdateStudentInfo: (student: PrePlacement) => void;
};

const UpdateStudentProfileForm = z.object({
  name: z.string({ required_error: 'Öğrenci ismi boş bırakılamaz' }).min(1, 'Öğrenci ismi boş bırakılamaz!'),
  surname: z.string({ required_error: 'Öğrenci soyismi boş bırakılamaz' }).min(1, 'Öğrenci soyismi boş bırakılamaz!'),
  birthDate: z.string({ required_error: 'Lüften doğum günü seçiniz' }),
  nationalId: z.string({ required_error: 'Lütfen TC/Vergi No seçiniz' }),
  'phone.number': z
    .string({ required_error: 'Telefon numarası zorunlu alandır' })
    .min(10, 'Lütfen telefon numarası giriniz')
    .refine((p) => {
      //TODO phone validasyon için kullanabilir
      return true;
    }),
});

export const UpdateStudentProfile = ({ student, onUpdateStudentInfo }: UpdateStudentProfile) => {
  const form = useForm<
    Partial<{
      name: string;
      surname: string;
      'phone.number': string;
      'phone.code': string;
      gender: string;
      nationalId: string;
      birthDate: string;
    }>,
    PrePlacement
  >(UpdateStudentProfileForm, {
    name: student.name,
    surname: student.surname,
    phone: student.phone,
    birthDate: student.birthDate,
    gender: student.gender,
    nationalId: student.nationalId,
  });

  const onUpdate = () => {
    const result = form.parse();
    if (result.success) {
      onUpdateStudentInfo({ ...result.data, id: student.id });
      form.reset();
    } else {
      form.setAllTouched();
    }
  };
  return (
    <Form layout="vertical">
      <div className="grid xl:grid-cols-2 lg:grid-cols-1 gap-2">
        <TextInput formItem={form.formItem('name')} formInput={form.input('name')} label={'Öğrenci Adı'} />

        <TextInput formItem={form.formItem('surname')} formInput={form.input('surname')} label={'Öğrenci Soyadı'} maxLength={50} />
        <PhoneInput form={form} label="Telefon" />
        <Form.Item {...form.formItem('birthDate')} label={'Doğum tarihi'}>
          <BirthdayCalendar form={form} />
        </Form.Item>
        <NationalIdInput form={form} label={'TC No'} />
        <Form.Item {...form.formItem('gender')} label={'Cinsiyet'}>
          <Radio.Group {...form.input('gender')}>
            <Radio value={Gender.FEMALE}>Kadın</Radio>
            <Radio value={Gender.MALE}>Erkek</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      <div className="flex justify-end">
        <Button type="primary" onClick={() => onUpdate()}>
          Profili Güncelle
        </Button>
      </div>
    </Form>
  );
};
