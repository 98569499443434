import { ApartmentOutlined, BranchesOutlined, MoneyCollectOutlined, ReadOutlined, SnippetsOutlined, UserOutlined } from '@ant-design/icons';
import { BrandMenuGroupItem } from '@shared/components/layouts/BrandMenuGroupItem';
import { Link, useSearchParams } from 'react-router-dom';
import { urlCleaner } from './extensions';
import { IoFileTrayFullOutline, IoLibraryOutline } from 'react-icons/io5';
import { PiBuildingOffice, PiChalkboardTeacherLight, PiMedal } from 'react-icons/pi';
import { FiLayers } from 'react-icons/fi';
import { MdLabelOutline } from 'react-icons/md';

export const pageToMenuItem = ([_, Page]) => {
  const [searchParams] = useSearchParams();

  const toRender = () => {
    return Page.path + '?' + urlCleaner(searchParams);
  };

  return {
    key: Page.path,
    label: <Link to={toRender()}>{Page.title}</Link>,
    icon: Page.icon,
    breadcrumbtitle: Page.title,
    roles: Page.roles,
    group: Page.group,
    title: Page.title,
    contexts: Page.contexts,
    layouts: Page.layouts ? Page.layouts : [],
    permissions: Page.permissions ? Page.permissions : [],
  };
};

export const menuGroups = [
  {
    key: 'admin_brands',
    title: 'Markalar',
    icon: <MdLabelOutline size={17} />,
  },
  {
    key: 'curriculum',
    title: 'Müfredat',
    icon: <IoLibraryOutline size={17} />,
  },
  {
    key: 'brand_manager_branch',
    title: 'Şubeler',
    icon: <PiBuildingOffice size={17} />,
  },
  {
    key: 'temp-record',
    title: 'Öğrenci Kayıtları',
    icon: <IoFileTrayFullOutline size={17} />,
  },

  {
    key: 'teacher',
    title: 'Öğretmen',
    icon: <PiChalkboardTeacherLight size={17} />,
  },
  {
    key: 'definitions',
    title: 'Tanımlar',
    icon: <FiLayers size={17} />,
  },
  {
    key: 'selectedBrand',
    contextname: 'brand',
    title: <BrandMenuGroupItem />,
  },
  {
    key: 'branch.lms.credit',
    title: 'Lms Kredi Yönetimi',
    icon: <PiMedal size={17} />,
  },

  {
    key: 'brand.lms.credit',
    title: 'Lms Kredi Yönetimi',
    icon: <PiMedal size={17} />,
  },
];
