import { DeleteOutlined } from "@ant-design/icons";
import { ImageUpload, ImageView } from "@shared/components";
import { CdnScope } from "@shared/components/curriculum/FileUpload";
import { ExerciseDefinitionInteractionType, ExerciseLayoutType, MediaType } from "@shared/constants";
import { ExerciseDefinitionModel, MediaOrderItem } from "@shared/models";
import { layoutToContentType } from "@shared/utils";
import { Button, Form, Input, Switch } from "antd";
import { useCallback, useEffect, useState } from "react";

type CreateExerciseProps = {
    item: ExerciseDefinitionModel,
    onSave: (item: ExerciseDefinitionModel) => void;
    onChange: (item: ExerciseDefinitionModel) => void;
    cdnScope: CdnScope;
}

export const CreateMatchingImage = ({ item, onSave, onChange, cdnScope }: CreateExerciseProps) => {
    const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false);
    const [mediaOrder, setMediaOrder] = useState<MediaOrderItem>({ askFor: "media" });

    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [mediaOrderItem, setMediaOrderItem] = useState<MediaOrderItem[]>([]);

    useEffect(() => {
        setTitle(item.content?.content?.title);
        setDescription(item.content?.content?.description);
        setMediaOrderItem(item.interaction?.mediaOrderItems ?? []);
    }, [item.id]);

    useEffect(() => {
        if (title || description  || mediaOrderItem.length > 0 ) {
            const i = {
                ...item,
                layout: ExerciseLayoutType.MATCHING_IMAGE,
                content: {
                    ...item.content,
                    content: {
                        ...(item.content ? item.content.content : {}),
                        title: title,
                        description: description
                    },
                    type: layoutToContentType(ExerciseLayoutType.MATCHING_IMAGE)
                },
                interaction: {
                    ...item.interaction,
                    mediaOrderItems: mediaOrderItem,
                    type: ExerciseDefinitionInteractionType.MEDIA_MATCHING,
                }
            } as ExerciseDefinitionModel;
            onChange(i);
        }
    }, [title, description, mediaOrderItem]);

    const send = useCallback(() => {
        const i = {
            ...item,
            layout: ExerciseLayoutType.MATCHING_IMAGE,
            content: {
                ...item.content,
                content: {
                    ...(item.content ? item.content.content : {}),
                    title: title,
                    description: description
                },
                type: layoutToContentType(ExerciseLayoutType.MATCHING_IMAGE)
            },
            interaction: {
                ...item.interaction,
                mediaOrderItems: mediaOrderItem,
                type: ExerciseDefinitionInteractionType.MEDIA_MATCHING,
            }
        } as ExerciseDefinitionModel;
        onSave(i);
    }, [title, description, mediaOrderItem]);

    return (
        <div className="p-5">
            <div className="flex justify-end">
                <Button type="primary" disabled={isMediaLoading} size="small" onClick={send}>{item.id ? "Güncelle" : "Ekle"}</Button>
            </div>
            <div className="flex flex-col">
                <span>Maksimum 4 görsel ekleyebilirsiniz</span>
                <div className="flex flex-row space-x-2 items-center justify-start">
                    <div>
                        <ImageUpload
                            image={mediaOrder?.media ? {
                                id: mediaOrder?.media?.resourceId,
                                variants: [...(mediaOrder?.media?.resourceUrl ? [mediaOrder?.media?.resourceUrl]! : [])]
                            } : {}}
                            onChange={(_) => {
                                setMediaOrder({ ...mediaOrder, media: { resourceId: _.id, resourceUrl: _.variants![0], type: MediaType.IMAGE } });
                            }}
                            onChangeLoading={setIsMediaLoading}
                            cdnScope={cdnScope}
                            disabled={mediaOrderItem?.length == 4 || mediaOrderItem?.length > 4}
                        />
                    </div>
                    <Form.Item label={"Eşlemek istediğiniz kelime"}>
                        <Input placeholder="Text"
                            value={mediaOrder?.word}
                            disabled={isMediaLoading || (mediaOrderItem?.length == 4 || mediaOrderItem?.length > 4)}
                            onChange={(d) => {
                                setMediaOrder({ ...mediaOrder, word: d.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={"Kullanıcıya Resmi/Metni sor"} >
                        <Switch checkedChildren="Image" unCheckedChildren="Text"
                            disabled={isMediaLoading || (mediaOrderItem?.length == 4 || mediaOrderItem?.length > 4)}
                            checked={mediaOrder?.askFor == "media"}
                            onChange={(e) => {
                                setMediaOrder({ ...mediaOrder, askFor: e ? "media" : "word" });
                            }} />
                    </Form.Item>
                    <Button
                        type="primary"
                        disabled={isMediaLoading || (mediaOrderItem?.length == 4 || mediaOrderItem?.length > 4)}
                        onClick={() => {
                            setMediaOrderItem([...mediaOrderItem, mediaOrder!]);
                            setMediaOrder({ askFor: "media" });
                        }}
                    >Bu soruyu ekle</Button>
                </div>
            </div>
            <div className="flex flex-row space-x-2 h-48">
                {
                    mediaOrderItem.map((m, index) => (
                        <div key={"mediaOrderItem" + index} className="flex flex-col h-full w-36 space-y-2 items-center">
                            <ImageView image={m.media!} width="100%" height="100%" />
                            <div className="flex flex-row space-x-2">
                                <span>AskFor:</span>
                                <Switch
                                    checkedChildren="Image"
                                    unCheckedChildren="Text"
                                    checked={m.askFor == "media"}
                                    onChange={(e) => {
                                        const _mediaOrderList = [...(mediaOrderItem ? mediaOrderItem : [])];
                                        _mediaOrderList[index] = { ..._mediaOrderList[index], askFor: e ? "media" : "word" };
                                        setMediaOrderItem(_mediaOrderList);
                                    }}
                                />
                            </div>
                            <Input placeholder="Text" value={m.word} onChange={(d) => { 
                                const _mediaOrderList = [...(mediaOrderItem ? mediaOrderItem : [])];
                                _mediaOrderList[index] = { ..._mediaOrderList[index], word: d.target.value };
                                setMediaOrderItem(_mediaOrderList);
                             }} />
                            <Button
                                size="small"
                                icon={<DeleteOutlined className="text-red-500" />}
                                onClick={() => {
                                    const _choices = [...(mediaOrderItem ? mediaOrderItem : [])];
                                    _choices.splice(index, 1);
                                    setMediaOrderItem(_choices);
                                }}
                            />
                        </div>
                    ))
                }
            </div>
            <Form layout="vertical" className="pt-10">
                <div className="flex flex-row space-x-2">

                    <Form.Item label={"Title"}>
                        <Input
                            value={title}
                            disabled={isMediaLoading}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }} />
                    </Form.Item>
                    <Form.Item label={"Subtitle"}>
                        <Input
                            value={description}
                            disabled={isMediaLoading}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }} />
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
}