import { Avatar, Tooltip } from "antd"
import { useEffect, useMemo, useState } from "react";

type AvatarFromNameType = {
    key?: string,
    title: string,
    subtitle: string,
    sorting?: boolean,
    bgColor?: string,
    txColor?: string,
}
export const AvatarFromName = ({ key = (Math.random() + 1).toString(36).substring(7), title, subtitle, sorting = true, bgColor, txColor }: AvatarFromNameType) => {
    const colors = ['#34568B', '#FF6F61', '#6B5B95', '#88B04B', ' #F7CAC9', '#92A8D1', '#955251', '#B565A7', '#009B77', '#DD4124', '#D65076', '#45B8AC', '#EFC050', '#5B5EA6', '#9B2335', '#DFCFBE', '#55B4B0', '#E15D44', '#7FCDCD', '#BC243C', '#C3447A', '#98B4D4'];

    const bg = useMemo(() => {
        if (title) {
            if (bgColor) {
                return bgColor;
            } else {
                return colors[title.split('').length > 23 ? 11 : title.split('').length];
            }
        } else {
            return colors[11];
        }
    }, [title, bgColor])

    return (
        <Tooltip key={key} title={subtitle} placement="top">
            <Avatar style={{ backgroundColor: bg, color: txColor }}>{sorting ? Array.from((title ?? "" as string) == '' ? 'P' : title as string)[0].toLocaleUpperCase() : title}</Avatar>
        </Tooltip>
    )
}