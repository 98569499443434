import { UploadOutlined } from '@ant-design/icons';
import { CdnScope } from '@shared/components/curriculum/FileUpload';
import { SoundUpload } from '@shared/components/curriculum/SoundUpload';
import { ExerciseLayoutType } from '@shared/constants';
import { ExerciseDefinitionModel, MediaModel } from '@shared/models';
import { layoutToContentType } from '@shared/utils';
import { Button, Form, Typography, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useCallback, useEffect,  useState } from 'react';

type CreateListeningBaseProps = {
  item: ExerciseDefinitionModel;
  onSave: (item: ExerciseDefinitionModel) => void;
  onChange: (item: ExerciseDefinitionModel) => void;
  cdnScope: CdnScope;
};

export const CreateListeningBase = ({ item, onSave, onChange, cdnScope }: CreateListeningBaseProps) => {
  const [transkript, setTranskript] = useState<string>();
  const [audio, setAudio] = useState<MediaModel>();


  useEffect(() => {
      setAudio(item.content?.sound);
      setTranskript(item.content?.content?.description);
  }, [item.id]);


  useEffect(() => {
    if (audio || transkript) {
        const updatedItem = {
          ...item,
          layout: ExerciseLayoutType.LISTENING_BASE,
          content: {
            ...item.content,
            sound: audio,
            content: {
              ...item.content?.content,
              description: transkript,
            },
            type: layoutToContentType(ExerciseLayoutType.LISTENING_BASE),
          },
        } as ExerciseDefinitionModel;

        onChange(updatedItem);
    }
  }, [audio, transkript]);

  const handleUpload = (info) => {
    const file = info.file.originFileObj;

    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result as string;
      setTranskript(text);
    };
    reader.readAsText(file);
  };

  const send = useCallback(() => {
    const i = {
      ...item,
      layout: ExerciseLayoutType.LISTENING_BASE,
      content: {
        ...item.content,
        sound: audio,
        content: {
          ...item.content?.content,
          description: transkript,
        },
        type: layoutToContentType(ExerciseLayoutType.LISTENING_BASE),
      },
    } as ExerciseDefinitionModel;
    onSave(i);
  }, [audio, transkript]);

  return (
    <div className="p-5">
      <div className="flex justify-end">
        <Button type="primary" size="small" onClick={send}>
          {item.id ? 'Güncelle' : 'Ekle'}
        </Button>
      </div>

      <Form layout="vertical" className="w-2/3">
        <div className="flex gap-20 items-center">
          <Form.Item label={'Ses dosyası'}>
            <SoundUpload
              sound={audio}
              onChangeLoading={(e) => {
              }}
              onChange={(sound) => {
                setAudio(sound);
              }}
              cdnScope={cdnScope}
            />
          </Form.Item>
          <Typography.Text type="secondary">
            <span className="text-red-500">*</span> Desteklenen formatlar: .mp3, .wav
          </Typography.Text>
        </div>
        <div className="flex gap-5 items-center">
          <Form.Item label={'Transkript dosyası'}>
            <Upload accept=".srt" showUploadList={false} onChange={handleUpload}>
              <Button icon={<UploadOutlined />}>{transkript ? 'Trasktript yüklendi' : 'Transkript ekleyiniz'}</Button>
            </Upload>
          </Form.Item>
          <Typography.Text type="secondary">
            <span className="text-red-500">*</span> Desteklenen formatlar: .srt
          </Typography.Text>
        </div>

        <Form.Item label={'Transkript'}>
          <TextArea disabled rows={6} className="mt-1" value={transkript} onChange={(e) => setTranskript(e.target.value)} />
        </Form.Item>
      </Form>
    </div>
  );
};
