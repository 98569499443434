import { AppConfig } from '@shared/config';
import { LocalStorageKeys } from '@shared/constants';
import { auth } from '@shared/contexts';
import i18n from '@shared/localization/i18n';
import { notification } from 'antd';
import { querytoUrlString } from './extensions';
const BASE_URL = AppConfig.apiUrl();

export const defaultOptions: RequestInit = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const request = async (
  url: string,
  method = 'GET',
  data: any = null,
  params: any = null,
  options: RequestInit = defaultOptions,
  noMessage = true,
  skipErrors = [] as any[]
) => {
  localStorage.setItem(LocalStorageKeys.LAST, new Date().getTime() + '');

  //const searchParams = new URLSearchParams(params).toString();
  //const urlTail = params ? `?${searchParams}` : '';

  const searchParams = querytoUrlString(params);
  const fullUrl = `${BASE_URL}${url}${searchParams}`;

  options = { ...defaultOptions, ...options };

  const headers = options?.headers ? new Headers(options.headers) : new Headers();

  if (!headers.has('Authorization')) {
    headers.set('Authorization', 'Bearer ' + auth.basic);
  }

  let body: any = null;
  if (data instanceof FormData) {
    body = data;
    headers.delete('Content-Type');
  } else {
    headers.set('Content-Type', 'application/json');
    body = data ? JSON.stringify(data) : null;
  }

  options.headers = headers;
  let response;
  try {
    response = await fetch(fullUrl, {
      ...options,
      method,
      body,
    });
    let responseData: any = null;

    try {
      responseData = await response.json();
    } catch (error) {
      responseData = null;
    }

    if (response.ok) {
      !noMessage && notification.success({ message: 'İşlem başarılı' });
    } else if (!skipErrors.includes(response.status)) {
      if (responseData.status === 401) {
        localStorage.clear();
        notification.error({ message: i18n.t(`error.${responseData.message.toString().toLowerCase()}`) });
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      } else if (response.status === 409 || response.status === 422 || response.status === 402 || response.status === 500) {
        notification.error({ message: i18n.t(`error.${responseData.message.toString().toLowerCase()}`) });
      } else if (response.status === 404) {
        notification.warning({ message: i18n.t(`error.${responseData.message.toString().toLowerCase()}`) });
      } else if (response.status === 403) {
        notification.error({ message: i18n.t(`error.forbidden`) });
      }
    }
    return {
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
      data: responseData,
      error: response.ok ? null : responseData,
    };
  } catch (error) {
    console.error('Request Error : ', fullUrl, '\n', error);
    notification.error({ message: 'Sunucu Hatası' });
    return {
      ok: false,
      error: error,
      status: response?.status,
    };
  }
};
