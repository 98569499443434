import { CheckCircleOutlined, ManOutlined, WomanOutlined } from '@ant-design/icons';
import { PiMedal } from 'react-icons/pi';
import { Tooltip } from 'antd';
import { MdOutlineElderlyWoman } from 'react-icons/md';
import { LuBaby } from 'react-icons/lu';

const AverageAgeBar = ({ oldAge, youngAge, averageAge, aboveAverageCount, underAverageCount }) => {
  const totalCount = aboveAverageCount + underAverageCount;
  const aboveAverageCountPercentage = totalCount > 0 ? (aboveAverageCount / totalCount) * 100 : 0;
  const underAverageCountPercentage = totalCount > 0 ? (underAverageCount / totalCount) * 100 : 0;
  return (
    <div className="flex items-center">
      <div className="flex items-center mr-2">
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center rounded-full bg-[#F5F5F5] p-1">
            <MdOutlineElderlyWoman className="text-black" />
          </div>
          <span className="ml-1 text-black">{oldAge}</span>
        </div>
      </div>
      <Tooltip
        title={`Sınıfta yer alan en genç öğrenci ${youngAge}, en yaşlı öğrenci ${oldAge} yaşındadır. Yaş ortalaması ${averageAge} ve yaşı ortalamadan büyük ${aboveAverageCount}, yaşı ortalamadan küçük ${underAverageCount} öğrenci vardır.`}
        placement="top"
      >
        <div className="relative w-full">
          <div className="relative w-full border-2 border-green-500 rounded-full p-0.5 outline outline-gray-300 outline-1 outline-offset-1 opacity-70">
            <div className="flex">
              <div className="bg-black h-2 rounded-l-full" style={{ width: `${aboveAverageCountPercentage}%` }} />
              <div className="bg-[#DADADA] h-2 rounded-r-full" style={{ width: `${underAverageCountPercentage}%` }} />
            </div>
          </div>
        </div>
      </Tooltip>
      <div className="flex items-center ml-2">
        <div className="flex items-center justify-center">
          <span className="mr-1 text-black">{youngAge}</span>
          <div className="flex items-center justify-center rounded-full bg-[#F5F5F5] p-1">
            <LuBaby className="text-black" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AverageAgeBar;
